// !!! THESE ARE VALUES ACCORDING TO DFA DESIGN SYSTEM !!!
// !!! USE THESE VALUES WHILE DEVELOPING AND REFACTORING !!!
// !!! THERE ARE OLD VALUES LOCATED IN /src/styles/utils/_varibales CURRENTLY USED IN PROJECT !!!
// !!! USE STYLES FROM /src/styles/global/_varibales INSTEAD !!!

// box-shadow
$box-shadow-01: 0px 10px 30px 0px rgba(58, 112, 191, 0.15);
$box-shadow-02: 0px 4px 15px 0px rgba(58, 112, 191, 0.10);
$box-shadow-03: 0px 4px 14px 0px rgba(126, 164, 220, 0.08);
$box-shadow-04: 0px 1px 3px 1px rgba(119, 149, 255, 0.10);
// button shadow
$button-shadow-searates: 0px 4px 20px rgba(0, 136, 255, 0.15);
$button-shadow-landrates: 0px 4px 20px rgba(2, 188, 177, 0.15);
$button-shadow-airrates: 0px 4px 20px rgba(245, 44, 92, 0.15);
$button-shadow-virtual-office: 0px 4px 20px rgba(88, 100, 255, 0.15);
// transition
$transition: all 0.3s ease;

// !!! OLD VALUES THAT SHUOLD BE REPLACED WITH UPPER ONES !!!
$box-shadow: 0px 4px 15px rgba(58, 112, 191, 0.1);
$box-shadow-red: 0px 1px 6px rgba(253, 143, 29, 0.2);