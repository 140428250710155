.attendees-list {
  position: relative;
  top: 65px;
  margin-bottom: 65px;
  padding: 30px 0;

  &__title {
    font-size: 48px;
    line-height: 54px;
    text-align: center;
    font-weight: 600;
    max-width: 1175px;
    margin: auto;

    @media (max-width: 768px) {
      font-size: 44px;
      line-height: 50px;
    }

    //@include mediaMixin(max, 768) {
    //    font-size: 44px;
    //    line-height: 50px;
    //}

    @media (max-width: 576px) {
      font-size: 24px;
      line-height: 30px;
    }

    //@include mediaMixin(max, 576) {
    //    font-size: 24px;
    //    line-height: 30px;
    //}
  }

  &__list {
    border-radius: 10px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 15%);
    padding: 20px;
    display: block;

    div {
      font-size: 14px;
      font-weight: 500;
    }

    img {
      max-height: 60px;
      max-width: 150px;

      @media (max-width: 576px) {
        max-height: 40px;
        max-width: 100px;
      }

      //@include mediaMixin(max, 576) {
      //    max-height: 40px;
      //    max-width: 100px;
      //}
    }
  }

  &__list-item {
    display: flex;
    height: 60px;
    justify-content: space-between;
    box-sizing: content-box;
    padding: 20px 0;

    div {
      flex: 0 0 20%;
      display: flex;
      align-items: center;

      @media (max-width: 576px) {
        flex: 0 0 33%;
      }

      //@include mediaMixin(max, 576) {
      //    flex: 0 0 33%;
      //}
    }

    div:not(:first-child) {
      justify-content: center;
    }
  }

  &__list-item:not(:last-child) {
    border-bottom: 1px solid $grey;
  }

  &__list-item-flag {
    width: 50px;

    @media (max-width: 576px) {
      display: none;
    }

    //@include mediaMixin(max, 576) {
    //    display: none;
    //}
  }

  &__list-item-name {
    font-size: 14px;
    line-height: 18px;

    &.title {
      font-size: 16px;
      font-weight: 600;
      text-transform: uppercase;

      &-flag {
        @media (max-width: 576px) {
          display: none;
        }

        //@include mediaMixin(max, 576) {
        //    display: none;
        //}
      }
    }

    @media (max-width: 576px) {
      font-size: 12px;
      line-height: 16px;
    }

    //@include mediaMixin(max, 576) {
    //    font-size: 12px;
    //    line-height: 16px;
    //}
  }

  &__list-item-country {
    font-size: 14px;
    line-height: 18px;

    @media (max-width: 576px) {
      font-size: 12px;
      line-height: 16px;
    }

    //@include mediaMixin(max, 576) {
    //    font-size: 12px;
    //    line-height: 16px;
    //}
  }

  &__world-map {
    overflow-x: scroll;
    overflow-y: hidden;
    text-align: center;
    position: relative;
    margin-top: 60px;
  }

  &__plaque {
    width: 200px;
    height: 60px;
    border-radius: 5px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 10px 30px;
    align-items: flex-start;
    justify-content: space-evenly;
    position: absolute;
    right: 0;
  }
}
