.user-dropdown {
    visibility: hidden;
    opacity: 0;

    position: absolute;
    width: 296px;
    top: 52px;
    right: 0;
    z-index: 101;
    background: $white;
    border-radius: 10px;
    filter: drop-shadow(0px 10px 30px rgba(58, 112, 191, 0.15));
    transition: 0.2s ease-in;


    @include media-breakpoint-down(sm) {
        position: relative;
        width: 100%;
        padding: 0;
        top: initial;
        right: initial;
        border-radius: 0;
        background: transparent;
        filter: none;

        &.mobOpen {
            visibility: visible;
            opacity: 1;
        }
    }

    &.active {
        visibility: visible;
        opacity: 1;
    }

    &__header {
        position: relative;
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 6fr;
        grid-column-gap: 16px;
        padding: 20px;
        background: white;

        @include media-breakpoint-down(sm) {
            padding: 25px 20px 20px;
            margin-top: 25px;
            grid-template-columns: 50px 1fr;

            &:before {
                display: block;
                content : "";
                position: absolute;
                left    : 0;
                top  : 0;
                height  : 0;
                width   : 100%;
                border-top: 1px solid #E0E4EC;
            }
        }

        .header-icon {
            width: 44px;
            height: 44px;
            object-fit: contain;
        }

        .header-context {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            max-width: 196px;

            .header-context__username {
                font-size: 18px;
                font-weight: 600;
                color: $dark-blue;
                line-height: 1;
                -webkit-transition: color .25s ease;
                transition: color .25s ease;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .header-context__company {
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 14px;
                display: flex;
                align-items: center;
                color: $dark-grey;
            }
        }
    }

    &__context {
        padding: 20px;
        position: relative;

        @include media-breakpoint-up(md) {
            &:before {
                display: block;
                content : "";
                position: absolute;
                left    : 8%;
                top  : 0;
                height  : 0;
                width   : 82%;
                border-top: 1px solid #E0E4EC;
            }
        }

        .profile-line {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 16px;
            color: $dark-blue;
            margin-bottom: 30px;
            transition: 0.2s;

            @include media-breakpoint-up(md) {
                &:hover {
                    color: $accent;
                }
            }

            @include media-breakpoint-down(sm) {
                margin-bottom: 40px;
            }

            span {
                font-style: normal;
                font-weight: 600;
                font-size: 10px;
                line-height: 10px;
                display: flex;
                align-items: center;
                text-align: center;
                letter-spacing: 0.03em;
                padding: 6px;
                text-transform: capitalize;
                border-radius: 25px;

                &.member {
                    color: #4360FB;
                    background: $light-blue;
                }
            }
        }

        .signout-btn {
            background: transparent;
            border: none;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 16px;
            color: $dark-blue;
            margin: 0;
            padding: 0;
            cursor: pointer;

            @include media-breakpoint-up(md) {
                &:hover {
                    color: $accent;
                }
            }
        }
    }

    .user-dropdown__footer {
        margin: 0 8px 8px;
        padding: 16px;
        background: $white1;
        border: 1px solid $white1;
        border-radius: 8px;

        @include media-breakpoint-down(sm) {
            margin-bottom: 20px;
        }

        .expires-status {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-style: normal;
            font-size: 14px;
            line-height: 16px;
            color: $dark-grey;

            span {
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 16px;
                color: #000C37;
            }

            @include media-breakpoint-down(sm) {
                justify-content: flex-start;
                font-weight: 500;
                font-size: 16px;

                span {
                    padding-left: 1rem;
                    font-size: 16px;
                }
            }
        }

        .expires-btn {
            display: block;
            background: transparent;
            border: none;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            color: $accent;
            margin: 10px 0 0;
            padding: 0;
            cursor: pointer;

            @include media-breakpoint-down(sm) {
                font-size: 16px;
                margin-top: 1rem;
            }
        }
    }
}
