@import 'Form.scss';

.container {
  max-width: 1200px;
  padding: 0 15px;
  margin: auto;
}

.events {
  position: relative;
  top: 65px;
  padding-bottom: 65px;
}

.events .intro-section {
  background-image: url('/images/main/events/intro/1.png');
  background-size: cover;
  display: flex;
  align-items: center;
  padding: 131px 0 80px;
  background-position-x: 50%;
  overflow: hidden;

  @include mediaMixin(max, 576) {
    padding-bottom: 54px;
  }

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include mediaMixin(max, 998) {
      flex-direction: column;
      align-items: flex-start;
    }

    @include mediaMixin(max, 576) {
      align-items: center;
    }
  }

  &__description {
    flex: 0 0 60%;
  }

  &__description-suptitle {
    font-size: 12px;
    text-transform: uppercase;
    opacity: 0.6;
    color: $white;
  }

  &__description-title {
    @include h1;
    line-height: 54px;
    color: $white;
    margin: 15px 0 32px;
  }

  &__description-buttons-box {
    display: flex;
    justify-content: space-between;
    width: 65%;

    @include mediaMixin(max, 576) {
      flex-direction: column;
      width: 100%;
      max-width: 343px;
      margin: auto;
    }
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    color: $white;
    flex: 0 0 48%;
    border-radius: 6px;
    padding: 9px;
    line-height: 22px;

    @include mediaMixin(max, 576) {
      margin-bottom: 8px;
    }
  }

  &__button.register {
    background: $blue-middle;
  }

  &__button.transparent {
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(4px);
    border: 1px solid #6e7e92;
  }

  &__plaque {
    flex: 0 0 35%;
    background: rgba(25, 33, 69, 0.2);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    padding: 30px;
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    color: $white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    @include mediaMixin(max, 998) {
      margin-top: 40px;
    }

    @include mediaMixin(max, 576) {
      margin-top: 32px;
    }
  }

  &__plaque-item-box {
  }

  &__plaque-item {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  &__plaque-item:nth-child(2n) {
    margin-bottom: 0;
  }

  &__plaque-image {
    margin-right: 20px;
  }

  &__plaque-text {
    font-size: 14px;
  }

  &__plaque-link {
    text-decoration: underline;
    line-height: 22px;
    font-size: 14px;
    color: $blue-middle;
    margin-left: 44px;
  }

  &__plaque-logo-box {
    margin-top: 24px;
  }

  &__plaque-logo {
    transform: scale(1.4);
  }
}

.events .article-section {
  padding: 56px 0 180px;
  position: relative;

  &__content {
    display: flex;
    flex-direction: column;
    position: relative;
  }

  &__image-box {
    position: absolute;
    top: -15%;
    right: 0;
    transform: translate(0, 0);
    transition: all 0.2s linear;

    @include mediaMixin(max, 998) {
      top: -24%;
      right: 4%;
    }

    @include mediaMixin(max, 576) {
      top: -19%;
      right: 17%;
    }
  }

  &__image {
    width: 280px;

    @include mediaMixin(max, 998) {
      max-width: 197px;
    }

    @include mediaMixin(max, 576) {
      display: none;
    }
  }

  &__description {
    color: $blue-dark;
    width: 55%;

    @include mediaMixin(max, 998) {
      width: 70%;
    }

    @include mediaMixin(max, 768) {
      width: 82%;
    }

    @include mediaMixin(max, 576) {
      width: 100%;
    }
  }

  &__description-title {
    text-transform: uppercase;
    color: $gray-middle-02;
    font-weight: 600;
    font-size: 12px;
  }

  &__description-content {
    margin: 16px 0 32px;
  }

  &__description-text {
    line-height: 26px;
  }

  &__description-text .bold {
    font-weight: 600;
  }

  &__description-text:not(:last-child) {
    margin-bottom: 24px;
  }

  &__content-icons-box {
    width: 100%;
    background: #f8f9fd;
    border: 1px solid #c9cfdb;
    border-radius: 10px;
    margin-top: 140px;

    @include mediaMixin(max, 998) {
      margin-top: 120px;
    }
  }

  &__content-icons {
    display: flex;
    flex-wrap: wrap;
    padding: 50px 30px;
    justify-content: space-between;

    @include mediaMixin(max, 576) {
      justify-content: center;
    }
  }

  &__content-icon {
    flex: 0 0 28%;
    padding: 30px 20px;
    display: flex;
    align-items: center;

    @include mediaMixin(max, 998) {
      flex: 0 0 45%;
      padding: 30px 0px;
    }

    @include mediaMixin(max, 576) {
      flex: 0 0 100%;
    }
  }

  &__content-image {
    margin-right: 20px;
  }

  &__content-text {
    font-weight: 600;
    font-size: 18px;
    color: $dark-blue;
  }
}

.events .table-section {
  padding: 0 0 140px;

  &__content {
  }

  &__header {
    text-align: center;
    margin-bottom: 40px;
  }

  &__title-h2 {
    @include h2;
    margin-bottom: 24px;
  }

  &__title-text {
    font-size: 18px;
    color: $blue-dark-02;
    line-height: 28px;
    font-weight: 600;
    width: 53%;
    margin: auto;

    @include mediaMixin(max, 768) {
      width: 80%;
    }

    @include mediaMixin(max, 576) {
      width: 100%;
    }
  }

  &__table {
    width: 100%;
  }

  &__table-title-box {
    padding: 16px 40px;
    text-align: left;
    background: $blue-dark-02;
    line-height: 28px;

    @include mediaMixin(max, 576) {
      padding: 16px 15px;
    }
  }

  &__table-title-box.border {
    border-radius: 10px 10px 0 0;
  }

  &__table-title {
    font-size: 18px;
    font-weight: 600;
    color: $white;

    @include mediaMixin(max, 576) {
      font-size: 16px;
    }
  }

  &__table-content {
  }

  &__table-content-row {
    display: flex;
  }

  &__table-content-cell {
    padding: 16px 40px;
    border: 1px solid $blue-dark;
    border-top: none;

    @include mediaMixin(max, 576) {
      padding: 16px 15px;
    }
  }

  &__table-content-cell-first {
    flex: 0 0 25%;
    border-right: none;
    display: flex;
    align-items: center;

    @include mediaMixin(max, 998) {
      flex: 0 0 30%;
    }

    @include mediaMixin(max, 768) {
      flex: 0 0 40%;
    }
  }

  &__table-content-cell-first.border {
    border-radius: 0 0 0 10px;
  }

  &__table-content-cell-first.name {
    background: #eff1f6;
  }

  &__table-content-cell-second {
    flex: 0 0 75%;

    @include mediaMixin(max, 998) {
      flex: 0 0 70%;
    }

    @include mediaMixin(max, 768) {
      flex: 0 0 60%;
    }
  }

  &__table-content-cell-second.border {
    border-radius: 0 0 10px 0;
  }

  &__table-content-cell-second.name {
    background: #eff1f6;
  }

  &__table-content-text {
    font-size: 18px;
    line-height: 28px;
    font-weight: 400;

    @include mediaMixin(max, 576) {
      font-size: 14px;
    }
  }

  &__table-content-text.bold {
    font-weight: 600;
  }
}
