.search {
    position: relative;

    &__input {
        box-sizing: border-box;
        width: 100%;
        padding: 20px 55px 20px 31px;
        outline: none;
        border: none;
        border-radius: 6px;
        @include new-text(18px, 500, 20px, none, none, $blue-dark);
    }

    &__input::placeholder {
        @include new-text(18px, 500, 20px, none, none, $gray-middle-02);
    }

    &__clear {
        width: 28px;
        height: 28px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background-color: $gray-middle-02;
        @include new-text(16px, 600, 100%, none, none, $blue-dark);
        position: absolute;
        top: 18px;
        right: 64px;
        cursor: pointer;
    }

    &__button {
        width: 55px;
        height: 55px;
        position: absolute;
        top: 2.5px;
        right: 3px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        background-color: $blue-dark;
        box-shadow: $box-shadow;
        cursor: pointer;
    }

    &__button:hover {
        background-color: $blue-dark-hover;
    }

    &__button-icon {
        cursor: pointer;
    }
}
