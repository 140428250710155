.c-arrow {
    position: relative;
    display: inline-block;
    height: 10px;
    width: 10px;
    margin: 0 0 0 5px;
    stroke-width: 1.5px;
    fill: none;
    stroke-linecap: round;
    stroke: $dark-blue;
    transition: 0.25s ease;


    // elements
    &__line {
        opacity: 0;
        transition: 0.15s ease;
    }
    &__tip {
        transform: translate(0, 0);
        transition: 0.15s ease;
    }
    // elements


    // modifiers
    &_directionLeft {
        transform: translate(0, 0%) scaleX(-1);
    }
    &_directionRight {
        transform: translate(0, 0%) scaleX(1);
    }
    &_directionUp {
        transform: rotate(-90deg);
    }
    &_directionDown {
        transform: rotate(90deg);
    }

    &_positionLeft {
        margin: 0 10px 0 0;
    }
    &_positionRight {
        margin: 0 0 0 10px;
    }
    // modifiers

}
