.custom-button {
    width: 160px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: none;
    border-radius: 6px;
    outline: none;
    cursor: pointer;
    background-color: $white;
    transition: .2s ease;
    position: relative;
    @include text(16px, 900, none, none, $blue-dark);

    // Dark preset:
    &.isDark {
        color: $white;
        background-color: $blue-dark;
        box-shadow: $shadow-01;
    }

    &.isDark:hover {
        background-color: $blue-dark-hover;
    }

    // Disabled preset:
    &.isDisabled {
        color: $disabled-text;
        background-color: $disabled-background;
        pointer-events: none;
    }

    // Bordered preset:
    &.isBordered {
        border: 1px solid $blue-dark;
        background-color: transparent;
    }

    // No bordered preset:
    &.noBordered {
        border: none;
        border-radius: 0;
        background-color: transparent;

        &:hover {
            opacity: 0.7;
        }
    }

    // Arrow preset:
    &.isArrowPrev .custom-button__value {
        margin-left: 15px;
    }

    &.isArrowNext .custom-button__value {
        margin-right: 15px;
    }

    &__arrow-box {
        position: relative;
    }

    &__arrow {
        width: 12px;
        height: 12px;
        transition: 0.2s ease;
    }

    &__arrow.isArrowPrev {
        stroke: $dark;
    }

    &__arrow.isArrowNext {
        fill: $dark;
    }

    &:hover .custom-button__arrow.isArrowPrev {
        transform: translateX(-10px);
    }

    &:hover .custom-button__arrow.isArrowNext {
        transform: translateX(10px);
    }

    &:hover .custom-button__line {
        opacity: 1;
    }

    &__line {
        width: 12px;
        border: 0.5px solid $dark;
        border-radius: 2px;
        opacity: 0;
        transition: 0.2s ease;
    }

    &__line.isArrowPrev {
        position: absolute;
        top: 6px;
        left: -5px;
    }

    &__line.isArrowNext {
        position: absolute;
        top: 6px;
        left: 6px;
    }
}
