.intro-section__description-title {
    max-width: 550px;
}

// SECTION: ARTICLE
.article-section {

    &__page-image-box {
        margin-bottom: 30px;
    }

    &__page-image {
        width: 570px;
        height: 400px;

        @media (max-width: 600px) {
            width: 100%;
            height: auto;
        }
    }

    &__hotel-description {
        max-width: 610px;
        margin-bottom: 24px;
        @include new-text(16px, 400, 26px, none, none, $blue-dark);
    }

    &__hotel-image-box {
        height: 85px;
        margin-bottom: 24px;
        background-image: url("/images/pages/Events/FutureOfFreight/hotel-section__desktop.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;

        @media (max-width: 768px) {
            height: 54px;
        }

        @media (max-width: 576px) {
            height: 26px;
        }
    }
}

// SECTION: REGISTRATION
.registration-section {
    padding: 56px 0;
    background-color: $blue-future;

    & .container {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media (max-width: 768px) {
            text-align: center;
            flex-direction: column;
        }
    }

    &__title {
        @include new-text(36px, 600, 48px, none, none, $white);

        @media (max-width: 830px) {
            margin-right: 20px;
            @include new-text(30px, 600, 42px, none, none, $white);
        }

        @media (max-width: 768px) {
            margin: 0 0 24px 0;
        }

        @media (max-width: 525px) {
            margin-right: 0;
            @include new-text(26px, 600, 38px, none, none, $white);
        }
    }

    &__title-span {
        display: block;
    }

    &__title-span:first-child {
        max-width: 375px;

        @media (max-width: 768px) {
            max-width: unset;
        }
    }

    &__button-box {
        @media (max-width: 525px) {
            width: 100%
        }
    }

    & .new-link-block.full {
        background-color: $white;
        color: $blue-dark;
        transition: $transition;

        @media (max-width: 525px) {
            width: 100%;
        }
    }

    & .new-link-block.full:hover {
        color: $blue-middle;
    }
}
