.dfa-questions {
    width: 60%;
    position: relative;
    z-index: 1;

    &__item {
        border-radius: 5px;
        @include shadow_1();
        margin-bottom: 10px;
    }

    &__question {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px 10px;
    }

    &__question.isActive {
        color: $blue;
    }

    &__text {
        @include body1();
    }

    &__answer {
        padding: 0 5px;
        text-align: justify;
    }

    &__icons-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__button-copy {
        margin-right: 8px;
        padding: 3px 8px;
        background-color: $white;
        outline: none;
        border: none;
        cursor: pointer;
    }

    &__icon-copy {
        width: 14px;
        height: 14px;
        color: $dark-grey;
    }

    &__icon-text {
        margin: 0 25px 0 0;
        font-size: 12px;
        font-weight: 500;
        color: $dark-grey;
    }

    &__icon-arrow {
        width: 12px;
        height: 12px;
        color: $dark-grey;
    }

    &__icon-success {
        width: 12px;
        height: 12px;
        color: green;
    }

}

@media (max-width: 768px) {
    .dfa-questions {
        width: 100%;

        &__question {
            padding: 0 5px;
        }

        &__answer {
            padding: 0;
        }
    }
}
