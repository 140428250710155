.radio-button {
    &.isBordered {
        position: relative;
        margin-bottom: 24px;
    }

    &.isBordered.isActive .radio-button__label {
        border: 1px solid $accent;
    }

    &__label {
        width: 270px;
        min-height: 60px;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 24px 15px;
        border: 1px solid $disabled-background;
        border-radius: 10px;
        cursor: pointer;
        transition: $transition;
    }

    &__label.isError {
        border: 1px solid $red-error;
    }

    &__label:hover {
        border: 1px solid $accent;
    }

    &__label:hover .radio-button__checkmark {
        border: 1px solid $accent;
    }

    &.isBordered.isActive .radio-button__checkmark {
        border: 1px solid $accent;
    }

    &__label-text {
        font-size: 14px;
        margin-left: 30px;
    }

    &__input {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
    }

    &__checkmark {
        position: absolute;
        top: calc(50% - 8px);
        left: 20px;
        width: 16px;
        height: 16px;
        border: 1px solid $disabled-background;
        border-radius: 50%;
        transition: $transition;
    }

    &__checkmark:after {
        content: '';
        width: 8px;
        height: 8px;
        position: absolute;
        top: 3px;
        left: 3px;
        opacity: 0;
        border-radius: 50%;
        background-color: $accent;
        transition: $transition;
    }

    &__label .radio-button__input:checked ~ .radio-button__checkmark:after {
        opacity: 1;
    }

    &__error {
        margin-top: 5px;
        @include text(12px, 400, none, none, $red-error);
        transition: $transition;
    }
}

// Media:
@media (max-width: 930px) {
    .radio-button__label {
        width: 200px;
    }
}

@media (max-width: 768px) {
    .radio-button.isBordered {
        margin-bottom: 5px;
    }

    .radio-button__label {
        width: 320px;
    }
}
