.header__nav-list {
    margin-bottom: 0;
}

.faq {
    position: relative;
    padding: 200px 0 180px;
}

.dfa-app {
    &__header {
        margin-bottom: 80px;
    }

    &__heading {
        text-align: center;
        @include h1();
        margin-bottom: 30px;
    }

    &__content {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }
}

@media (max-width: 768px) {
    .dfa-app {

        &__content {
            flex-direction: column;
        }

        &__heading {
            @include h1();
        }

    }
}

@media (max-width: 425px) {
    .dfa-app {

        &__heading {
            font-size: 22px;
            font-weight: 600;
            color: $dark;
        }

    }
}
