.input-with-checkbox {
    width: 270px;
    min-height: 60px;
    position: relative;

    &.isError .input-with-checkbox__input {
        border: 1px solid $red-error;
    }

    &__input {
        width: calc(98% - 50px);
        padding: 20px 0 20px 50px;
        outline: none;
        cursor: pointer;
        border: 1px solid $disabled-background;
        border-radius: 10px;
        transition: $transition;
        @include text(14px, 400, none, none, $dark);
    }

    &__input:hover {
        border: 1px solid $accent;
    }

    &__input::placeholder {
        @include text(14px, 400, none, none, $disabled-text);
    }

    &__checkmark {
        width: 16px;
        height: 16px;
        position: absolute;
        top: 22px;
        left: 20px;
        border: 1px solid $disabled-background;
        border-radius: 2px;
        transition: $transition;
    }

    &.isActive .input-with-checkbox__checkmark {
        background-color: $accent;
        border: 1px solid $accent;
    }

    &__input:hover ~ .input-with-checkbox__checkmark {
        border: 1px solid $accent;
    }

    &__checkmark:after {
        content: '';
        width: 10px;
        height: 10px;
        position: absolute;
        top: 3px;
        left: 2px;
        background-image: url(/images/global/mark.svg);
        background-repeat: no-repeat;
        background-position: center;
        opacity: 0;
        transition: $transition;
    }

    &.isActive .input-with-checkbox__checkmark:after {
        opacity: 1;
    }

    &__error {
        @include text(12px, 400, none, none, $red-error);
    }
}

//Media:
@media (max-width: 930px) {
    .input-with-checkbox {
        width: 200px;
    }

    .input-with-checkbox__input {
        width: 70%;
    }
}

@media (max-width: 768px) {
    .input-with-checkbox {
        width: 320px;
    }

    .input-with-checkbox__input {
        width: 84%;
    }
}
