.snpl {
  background-color: $blue-dark-1;
  background-image: url('/images/pages/Services/SNPL/snplPage_section1.png'),
    url('/images/pages/Services/SNPL/snplPage_section1_circle.png');
  background-size: 1170px auto;
  background-repeat: no-repeat;
  background-position: center 100px;
  color: $lapland;

  @media (max-width: 769px) {
    background-image: url('/images/pages/Services/SNPL/snplPage_section1_tablet.png'),
      url('/images/pages/Services/SNPL/snplPage_section1_circle.png');
    background-size: auto auto;
    background-position: center 150px;
  }

  @media (max-width: 475px) {
    background-image: url('/images/pages/Services/SNPL/snplPage_section1_mobile.png'),
      url('/images/pages/Services/SNPL/snplPage_section1_circle.png');
    background-size: auto auto;
    background-position: center 96px;
  }

  &-container {
    max-width: 1200px;
    padding: 0 15px;
    margin: 0 auto;
  }

  &-header {
    margin-top: 280px;
    max-width: 533px;
    width: 100%;

    @media (max-width: 769px) {
      margin-top: 200px;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      max-width: 547px;
    }

    @media (max-width: 475px) {
      text-align: left;
      margin-top: 120px;
    }

    &__title {
      margin-bottom: 16px;
      @include new-text(48px, 600, 60px, none, none, $lapland);

      @media (max-width: 769px) {
        @include new-text(44px, 600, 54px, none, none, $lapland);
      }

      @media (max-width: 475px) {
        @include new-text(24px, 600, 34px, none, none, $lapland);
      }
    }

    &__subtitle {
      max-width: 473px;
      margin-bottom: 48px;
      @include new-text(16px, 400, 26px, none, none, $white);

      @media (max-width: 769px) {
        max-width: 547px;
      }
    }

    &__btn-demo {
      background-color: $lapland;
      @include new-text(16px, 600, 26px, none, none, $dubai);
      border: none;
      cursor: pointer;
      border-radius: 6px;
      padding: 11px 24px;
      font-family: 'Montserrat', sans-serif;

      @media (max-width: 475px) {
        margin-top: 221px;
        width: 100%;
        padding: 15px 24px;
      }

      &:hover {
        opacity: 0.8;
      }
    }
  }

  &-how {
    margin-top: 465px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    @media (max-width: 769px) {
      margin-top: 555px;
    }

    @media (max-width: 475px) {
      margin-top: 136px;
    }

    &__circle {
      position: absolute;
      top: -50px;
    }

    h3 {
      z-index: 2;
      margin-bottom: 72px;
      @include new-text(36px, 600, 48px, none, none, $white);

      @media (max-width: 769px) {
        @include new-text(32px, 600, 42px, none, none, $white);
        margin-bottom: 78px;
      }

      @media (max-width: 475px) {
        margin-bottom: 44px;
        @include new-text(22px, 600, 32px, none, none, $white);
      }
    }

    &__content {
      z-index: 2;
      display: flex;
      align-items: center;
      flex-direction: column;
      max-width: 972px;
      width: 100%;

      @media (max-width: 475px) {
        display: none;
      }

      &-item-img {
        display: flex;
        align-items: center;
        gap: 30px;

        @media (max-width: 969px) {
          width: 100%;
          justify-content: space-around;
          padding: 0 60px;
          gap: 0;
          img {
            width: 189px;
          }
        }

        @media (max-width: 768px) {
          img {
            width: 140px;
          }
        }

        div {
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 8px;
          background-color: #50adff1a;
          width: 40px;
          min-width: 40px;
          height: 40px;
        }
      }

      &-item-text {
        margin-bottom: 84px;
        margin-top: 8px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        text-align: center;
        @include new-text(18px, 600, 28px, none, none, $white);

        @media (max-width: 969px) {
          margin-bottom: 65px;
        }

        .text-item-1 {
          margin-left: 24px;
          @media (max-width: 969px) {
            margin-left: 33px;
          }
        }
        .text-item-2 {
          @media (max-width: 969px) {
            margin-right: -18px;
          }
        }
        .text-item-3 {
          margin-right: 10px;
          @media (max-width: 969px) {
            margin-right: 15px;
          }
        }
        .text-item-4 {
          @media (max-width: 969px) {
            margin-left: 10px;
          }
        }
        .text-item-5 {
          margin-right: 30px;

          @media (max-width: 969px) {
            margin-right: 7px;
          }
        }
        .text-item-6 {
          margin-right: 10px;

          @media (max-width: 969px) {
            margin-right: 17px;
          }
        }
      }
    }

    &__content-mobile {
      display: none;
      position: relative;
      z-index: 2;
      width: 100%;

      @media (max-width: 475px) {
        display: block;
      }
      &-img {
        padding: 0 57px;
        margin-bottom: 4px;
        display: flex;
        align-items: center;
        justify-content: space-around;

        div {
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 8px;
          background-color: #50adff1a;
          width: 40px;
          height: 40px;
          transform: scale(0.8);

          svg {
            transform: scale(0.8334);
          }
        }
      }
      &-text {
        text-align: center;
        margin-bottom: 36px;
        display: flex;
        justify-content: space-between;
        @include new-text(16px, 600, 26px, none, none, $white);

        .text-item-1 {
          margin-left: 32px;
        }
        .text-item-2 {
          margin-right: 16px;
        }
        .text-item-3 {
          margin-left: 47px;
        }
        .text-item-4 {
          margin-right: 11px;
        }
        .text-item-5 {
          margin-left: 37px;
        }
        .text-item-6 {
          margin-right: 15px;
        }
      }
    }
  }

  &-advantages {
    margin-top: 120px;
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 475px) {
      margin-top: 100px;
    }
    h3 {
      margin-bottom: 72px;
      @include new-text(36px, 600, 48px, none, none, $white);

      @media (max-width: 769px) {
        @include new-text(32px, 600, 42px, none, none, $white);
        margin-bottom: 48px;
      }

      @media (max-width: 475px) {
        margin-bottom: 30px;
        @include new-text(22px, 600, 32px, none, none, $white);
      }
    }

    &__content {
      display: flex;
      gap: 24px;
      width: 100%;

      @media (max-width: 769px) {
        width: 100%;
        gap: 18px;
        transition: all 0.3s;
      }
    }

    &__item {
      min-height: 380px;
      max-width: 374px;
      width: 100%;

      @media (max-width: 475px) {
        min-height: 404px;
      }

      @media (max-width: 769px) {
        flex-shrink: 0;
        width: 548px;
        max-width: 548px;
      }

      @media (max-width: 548px) {
        width: 100%;
        max-width: 100%;
      }

      &-number {
        opacity: 0.5;
        margin-bottom: 8px;
        @include new-text(20px, 600, 30px, none, none, $future-hover);
      }
      &-line-wrapper {
        width: 100%;
        height: 2px;
        border-radius: 2px;
        background: #50adff80;
      }
      &-line {
        background-color: $future-hover;
        height: 2px;
        border-radius: 2px;
        transition: all 0.1s;
      }
      &-title {
        cursor: pointer;
        margin-top: 16px;
        margin-bottom: 8px;
        @include new-text(20px, 600, 30px, none, none, $future-hover);

        @media (max-width: 475px) {
          @include new-text(18px, 600, 28px, none, none, $future-hover);
        }
      }
      &-text {
        @include new-text(16px, 400, 26px, none, none, $white);

        max-height: 0;
        overflow: hidden;
        transition: all 0.3s;
      }
      &-active {
        .snpl-advantages__item-number {
          opacity: 1;
        }
        .snpl-advantages__item-title {
          color: $white;
          font-weight: 700;
        }
        .snpl-advantages__item-text {
          max-height: 100%;
          transition: all 0.3s;
        }
      }
    }
  }

  &-approach {
    margin-top: 198px;
    display: flex;
    gap: 56px;

    @media (max-width: 769px) {
      margin-top: 76px;
      flex-direction: column;
      gap: 45px;
    }

    @media (max-width: 475px) {
      margin-top: 120px;
      gap: 28px;
    }

    &__left {
      padding-right: 55px;
      max-width: 573px;
      @include new-text(36px, 600, 48px, none, none, $white);

      @media (max-width: 769px) {
        padding-right: 111px;
        @include new-text(32px, 600, 42px, none, none, $white);
      }

      @media (max-width: 475px) {
        @include new-text(22px, 600, 32px, none, none, $white);
        padding-right: 27px;
      }
    }

    &__right {
      display: flex;
      flex-direction: column;
      gap: 40px;
      max-width: 541px;
      @media (max-width: 769px) {
        max-width: 642px;
        gap: 32px;
      }

      &-item {
        display: flex;
        gap: 24px;
        @media (max-width: 475px) {
          flex-direction: column;
          gap: 16px;
        }
        &-icon {
          border-radius: 10px;
          background: #50adff1a;
          display: flex;
          justify-content: center;
          align-items: center;
          min-width: 48px;
          width: 48px;
          height: 48px;
        }
        &-text {
          @include new-text(16px, 400, 26px, none, none, $white);
        }
      }
    }
  }

  &-program {
    margin-bottom: 160px;
    overflow: hidden;
    position: relative;
    margin-top: 200px;
    width: 100%;
    padding: 72px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #ffffff0f;
    background-color: #ffffff0f;
    border-radius: 24px;

    @media (max-width: 769px) {
      margin-bottom: 80px;
      margin-top: 158px;
      padding: 40px;
    }

    @media (max-width: 475px) {
      padding: 28px 16px;
      padding-bottom: 15px;
      margin-top: 120px;
      margin-bottom: 60px;
      border-radius: 16px;
    }

    &__circle {
      position: absolute;
      bottom: -50px;
      left: 0px;
    }
    &__vector {
      position: absolute;
      right: 0;
      bottom: 0px;

      @media (max-width: 769px) {
        right: -300px;
      }

      @media (max-width: 475px) {
        right: -600px;
      }
    }

    &__title {
      position: relative;
      z-index: 2;
      @include new-text(36px, 700, 48px, none, none, $white);
      margin-bottom: 24px;

      @media (max-width: 769px) {
        @include new-text(32px, 700, 42px, none, none, $white);
        text-align: center;
      }

      @media (max-width: 475px) {
        margin-bottom: 35px;
        @include new-text(22px, 700, 32px, none, none, $white);
      }
    }
    &__subtitle {
      position: relative;
      z-index: 2;
      @include new-text(16px, 500, 26px, none, none, $white);
      margin-bottom: 40px;
      max-width: 620px;
      text-align: center;

      @media (max-width: 769px) {
        margin-bottom: 36px;
      }

      @media (max-width: 475px) {
        margin-bottom: 42px;
      }
    }
    &__btnApply {
      position: relative;
      z-index: 2;
      background-color: $lapland;
      @include new-text(16px, 600, 26px, none, none, $dubai);
      border: none;
      cursor: pointer;
      border-radius: 6px;
      padding: 11px 24px;
      font-family: 'Montserrat', sans-serif;

      @media (max-width: 475px) {
        width: 100%;
        padding: 15px 24px;
      }

      &:hover {
        opacity: 0.8;
      }
    }
  }
}
