.membership {
  max-width: 1001px;
  padding: 0 15px;
  margin: 175px auto 75px auto;
  width: 100%;

  @media (max-width: 950px) {
    margin-top: 128px;
    padding: 0 20px;
    margin-bottom: 26px;
  }

  @media (max-width: 475px) {
    margin-top: 120px;
    margin-bottom: 62px;
    padding: 0 16px;
  }

  & .btn {
    @media (max-width: 475px) {
      min-height: 44px;
    }
  }

  a {
    color: inherit;
  }

  &__title {
    @include page-heading-h1($blue-dark, true);
    text-align: center;
    text-transform: none;
  }

  &__subtitle {
    @include page-heading-h1-description($blue-dark, true);

    a {
      color: $blue-middle;
    }
  }

  &__description {
    @include page-heading-h1-description($blue-dark, true);
  }

  &__cards {
    margin-top: 32px;
    display: flex;
    justify-content: space-between;

    @media (max-width: 950px) {
      margin-top: 34px;
      gap: 16px;
    }

    @media (max-width: 475px) {
      flex-direction: column;
      margin-top: 24px;
      gap: 24px;
    }
  }

  &__card {
    &-free {
      border-radius: 24px;
      padding: 48px;
      box-shadow: 0px 10px 30px 0px #3a70bf26;
      background-color: $white;
      max-width: 465px;
      width: 100%;

      @media (max-width: 950px) {
        padding: 24px;
        // padding-right: 0px;
        max-width: 356px;
        width: 100%;
      }

      @media (max-width: 475px) {
        padding: 32px;
      }

      a {
        text-decoration: underline;
        color: $blue-dark;
      }

      &-icon {
        // margin-top: 4px;
        height: 20px;
      }

      &-description {
        display: inline-block;
        height: 20px;
        // margin-top: 4px;
      }

      &-title {
        @include new-text(20px, 600, 30px, none, none, $blue-dark);

        @media (max-width: 950px) {
          font-weight: 700;
          margin-bottom: 24px;
        }

        @media (max-width: 475px) {
          @include new-text(18px, 700, 28px, none, none, $blue-dark);
          margin-bottom: 10px;
        }
      }
      &-wrapper {
        margin: 24px 0;
        display: flex;
        align-items: center;
        gap: 4px;
        @include new-text(14px, 500, 22px, none, none, $blue-dark);

        @media (max-width: 950px) {
          margin: 16px 0;
          line-height: 22px;
          align-items: flex-start;
        }

        @media (max-width: 475px) {
          @include new-text(12px, 500, 20px, none, none, $blue-dark);
        }
      }
      &-texts {
        display: flex;

        svg {
          @media (max-width: 950px) {
            margin-bottom: -4px;
            margin-left: 4px;
          }

          @media (max-width: 475px) {
            margin-bottom: -6px;
          }
        }

        @media (max-width: 950px) {
          width: 100%;
          justify-content: space-between;
        }

        @media (max-width: 475px) {
          // margin-right: -13px;
        }
      }

      &-text {
        text-wrap: nowrap;

        display: flex;
        gap: 6px;
        align-items: center;

        a {
          color: $blue-dark;
          text-decoration: underline;
        }

        @media (max-width: 950px) {
          display: inline-block;
          text-wrap: initial;

          img {
            margin-left: 6px;
            position: absolute;
          }
        }
      }
      &-text-not_active {
        color: $gray-dark;
        a {
          text-decoration: underline;
          color: $gray-dark;
        }
      }
      &-price {
        margin-bottom: 24px;
        @include new-text(18px, 700, 28px, none, none, $blue-dark);

        @media (max-width: 950px) {
          // margin-top: -5px;
        }

        @media (max-width: 475px) {
          @include new-text(16px, 700, 26px, none, none, $blue-dark);
          margin-bottom: 16px;
        }
      }
    }

    &-premium {
      border-radius: 24px;
      padding: 48px;
      box-shadow: 0px 10px 30px 0px #3a70bf26;
      background-color: $blue-dark;
      max-width: 465px;
      width: 100%;
      position: relative;
      overflow: hidden;

      &-vector1 {
        position: absolute;
        bottom: 0;
        left: 0;
      }

      @media (max-width: 950px) {
        padding: 24px;
        // padding-right: 0px;
        max-width: 356px;
        width: 100%;
      }

      @media (max-width: 475px) {
        padding: 32px;
      }

      &-icon {
        // margin-top: 4px;
        height: 20px;
      }

      &-title {
        @include new-text(20px, 600, 30px, none, none, $white);

        @media (max-width: 950px) {
          font-weight: 700;
          margin-bottom: 24px;
        }

        @media (max-width: 475px) {
          @include new-text(18px, 700, 28px, none, none, $white);
          margin-bottom: 10px;
        }
      }
      &-wrapper {
        margin: 24px 0;
        display: flex;
        align-items: center;
        gap: 4px;
        position: relative;
        z-index: 10;
        @include new-text(14px, 500, 22px, none, none, $blue-dark);

        @media (max-width: 950px) {
          margin: 16px 0;
          line-height: 22px;
          align-items: flex-start;
        }

        @media (max-width: 475px) {
          @include new-text(12px, 500, 20px, none, none, $blue-dark);
        }
      }

      &-description {
        display: inline-block;
        height: 20px;
        // margin-top: -4px;
      }
      &-texts {
        display: flex;

        a {
          text-decoration: underline;
        }

        svg {
          @media (max-width: 950px) {
            margin-bottom: -4px;
            margin-left: 4px;
          }

          @media (max-width: 475px) {
            margin-bottom: -6px;
          }
        }

        @media (max-width: 950px) {
          width: 100%;
          justify-content: space-between;
        }

        @media (max-width: 475px) {
          // margin-right: -13px;
        }
      }
      &-text {
        color: $white;
        text-wrap: nowrap;

        display: flex;
        gap: 6px;
        align-items: center;

        @media (max-width: 950px) {
          display: inline-block;
          text-wrap: initial;

          img {
            margin-left: 6px;
            position: absolute;
          }
        }
      }
      &-text-not_active {
        color: $gray-dark;
      }
      &-price {
        margin-bottom: 24px;
        @include new-text(18px, 700, 28px, none, none, $white);

        @media (max-width: 950px) {
          // margin-top: -5px;
        }
        @media (max-width: 475px) {
          @include new-text(16px, 700, 26px, none, none, $white);
          margin-bottom: 16px;
        }
      }
    }
  }

  &__text-desktop {
    margin-top: 32px;
    @include new-text(16px, 400, 26px, none, none, $blue-dark);

    @media (max-width: 950px) {
      display: none;
    }

    .links {
      margin-top: 32px;
    }

    & a {
      color: $blue-middle;
    }
  }
  &__text-tablet {
    margin-top: 32px;
    @include new-text(16px, 400, 26px, none, none, $blue-dark);

    @media (max-width: 950px) {
      display: block;
    }

    @media (max-width: 475px) {
      margin-top: 24px;
      @include new-text(16px, 400, 26px, none, none, $blue-dark);
    }

    @media (min-width: 951px) {
      display: none;
    }

    .links {
      margin-top: 22px;
    }

    & a {
      color: $blue-middle;
    }
  }
}

.membership__card-premium-text.price {
  @include new-text(18px, 700, 28px, none, none, $white);

  @media (max-width: 475px) {
    @include new-text(16px, 700, 26px, none, none, $white);
    margin-bottom: 16px;
  }
}
