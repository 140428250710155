.video-player .article-section {
    &__video-box {
        max-width: 569px;
        width: 100%;

        @include mediaMixin(max, 768) {
            max-width: 720px;
            margin: auto;
        }
    }

    &__video {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 3;
        width: 689px;
        height: 460px;

        @include mediaMixin(max, 576) {
            width: 351px;
            height: 217px;
        }
    }

    &__video-closet {
        position: fixed;
        top: 5%;
        right: 5%;
        width: 30px;
        height: 30px;
        z-index: 4;
    }

    &__video-closet:hover {
        cursor: pointer;
    }

    &__video-closet:after {
        content: '';
        background: white;
        width: 30px;
        position: absolute;
        height: 4px;
        transform: rotate(45deg);
        transition: all .2s linear;
    }

    &__video-closet:hover:after {
        background: $blue-middle;
    }

    &__video-closet:before {
        content: '';
        background: white;
        width: 30px;
        position: absolute;
        height: 4px;
        transform: rotate(-45deg);
        transition: all .2s linear;
    }

    &__video-closet:hover:before {
        background: $blue-middle;
    }

    &__video-wrapper {
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
        background: #000000cf;
        z-index: 2;
        transition: all .2s linear;
        opacity: 0;
        display: none;
    }

    &__video-wrapper-active {
        opacity: 1;
        display: block;
    }

    &__video-preview {
        width: 569px;
        border-radius: 10px;
        position: relative;
        display: flex;
        transition: all .2s linear;

        @include mediaMixin(max, 768) {
            width: 100%;
        }
    }

    &__video-preview-box {
        position: relative;
        background: black;
        border-radius: 10px;

        &:hover {
            cursor: pointer;

            .play-button__circle {
                fill: $accent;
            }

            .play-button__triangle {
                fill: $white;
            }
        }
    }
}

.play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &__circle {
        fill: $white;
        transition: all .2s linear;
    }

    &__triangle {
        fill: $dark;
        transition: all .2s linear;
    }
}
