.statistics {
    width: 100%;
    display: flex;
    align-items: center;

    &__section {
        //height: 85px;
    }

    &__section:first-child {
        margin-right: 85px;
    }

    &__number {
        @include new-text(48px, 600, 60px, none, none, $white);
    }

    &__text {
        @include new-text(16px, 400, 26px, none, none, $gray-middle);
    }
}

// MEDIA: TABLET (768px)
@media (max-width: 769px) {
    .statistics {
        &__number {
            @include new-text(44px, 600, 54px, none, none, $white);
        }
    }
}

@media (max-width: 476px) {
    .statistics {
        &__number {
            @include new-text(24px, 600, 34px, none, none, $white);
        }
    }
}
