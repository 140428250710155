$image-path: '/images/pages/Resources/FreightDictionary/landings';

.freight-dictionary-landing {
  // BREADCRUMBS
  .breadcrumbs {
    position: static;
    margin-bottom: 32px;

    a {
      font-weight: 600;
    }

    .last-item {
      font-weight: 600;
      text-transform: uppercase;
      color: $disabled-text;
    }
  }

  &__head {
    padding: 112px 0 48px 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-image: url('#{$image-path}/mobile/hero-bg.webp');

    @media screen and (min-device-pixel-ratio: 2),
    screen and (min-resolution: 192dpi),
    screen and (min-resolution: 2dppx) {
      background-image: url('#{$image-path}/mobile/hero-bg@2x.webp');
    }

    @include for-size(tablet) {
      padding: 144px 0 80px 0;
      background-image: url('#{$image-path}/tablet/hero-bg.webp');

      @media screen and (min-device-pixel-ratio: 2),
      screen and (min-resolution: 192dpi),
      screen and (min-resolution: 2dppx) {
        background-image: url('#{$image-path}/tablet/hero-bg@2x.webp');
      }
    }

    @include for-size(desktop-m) {
      padding: 165px 0 176px 0;
      background-image: url('#{$image-path}/desktop/hero-bg.webp');

      @media screen and (min-device-pixel-ratio: 2),
      screen and (min-resolution: 192dpi),
      screen and (min-resolution: 2dppx) {
        background-image: url('#{$image-path}/desktop/hero-bg@2x.webp');
      }
    }
  }

  &__head-content {
    display: flex;
    align-items: center;
    min-height: 494px;

    @include for-size(tablet) {
      min-height: 320px;
    }

    @include for-size(desktop-m) {
      min-height: 326px;
    }
  }

  // HEAD: DESCRIPTION BLOCK
  &__head-description-box {
    max-width: 736px;

    @include for-size(desktop-m) {
      max-width: unset;
    }
  }

  &__head-title {
    margin-bottom: 16px;
    @include  text(54px, 600, none, none, $white);
    line-height: 111%;

    @include for-size(tablet) {
      line-height: 133%;
    }

    @include for-size(desktop-m) {
      font-size: 72px;
      line-height: 111%;
    }
  }

  &__head-text-box {
    margin-bottom: 24px;
  }

  &__head-text {
    @include  body4();
    color: $mood;

    &:not(:last-child) {
      margin-bottom: 16px;
    }

    @include for-size(desktop-m) {
      @include  body7();
      color: $mood;

      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }

    .freight-dictionary-landing__link {
      color: $mood;
    }
  }

  // HEAD: OVERRIDES - LINK BLOCK
  &__head .new-header-hero__links-box {
    flex-direction: column;
    gap: 24px;
    margin: 0;

    @media screen and (min-width: 600px) {
      flex-direction: row;
      align-items: center;
      gap: 24px;
    }
  }

  &__head .new-header-hero__links-box .new-link-block.full {
    padding: 10px 0;
    margin: 0;
    width: 100%;

    @media screen and (min-width: 600px) {
      //width: 168px;
      width: 224px;
    }
  }

  &__head .new-header-hero__links-box .new-link.arrow {
    .link-arrow {
      margin: 0 0 0 15px;
    }
  }

  // DESCRIPTION SECTION
  &__description {
    padding: 9px 0 81px 0;

    @include for-size(tablet) {
      padding: 40px 0 81px 0;
    }
  }

  &__description-title {
    margin: 40px 0 16px 0;
    @include text(22px, 600, none, none, $dubai);
    line-height: 145%;

    @include for-size(tablet) {
      font-size: 32px;
      line-height: 131%;
    }

    @include for-size(desktop-m) {
      font-size: 36px;
      line-height: 133%;
    }
  }

  &__description-paragraph {
    @include body4();

    &:not(:last-child) {
      margin-bottom: 16px;
    }

    &--bold {
      font-weight: 600;
    }
  }

  &__list-title {
    @include text(16px, 400, none, none, $dubai);
    margin: 16px 0 16px 0;
    font-weight: 600;
  }

  &__list-benefits {
    padding-left: 25px;
    margin-bottom: 32px;
    list-style: disc;

    @media (max-width: 768px) {
      margin-bottom: 16px;
    }
  }

  &__list-benefits-item {
    font-size: 14px;
    line-height: 26px;
    &:not(:last-child) {
      margin-bottom: 16px;
    }

    @media (max-width: 575px) {
      line-height: 22px;
    }
  }

  &__link {
    color: $dubai;
    text-decoration: underline;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  // RELATED TERMS SECTION
  &__other-terms {
    padding: 0 0 48px 0;

    @include for-size(tablet) {
      padding: 0 0 140px 0;
    }
  }

  &__other-terms-title {
    margin-bottom: 16px;
    @include  text(24px, 600, none, none, $dubai);
    line-height: 142%;

    @include for-size(tablet) {
      font-size: 44px;
      line-height: 123%;
    }

    @include for-size(desktop-m) {
      font-size: 48px;
      line-height: 125%;
    }
  }

  &__other-terms-list {
    @include for-size(tablet) {
      display: flex;
      align-items: center;
      gap: 24px;
    }

    @include for-size(desktop-m) {
      gap: 21px;
    }
  }

  &__other-terms-list-item {
    &:not(:last-child) {
      margin-bottom: 24px;
    }

    @include for-size(tablet) {
      &:not(:last-child) {
        margin-bottom: 0;
      }
    }
  }

  &__other-terms-link {
    display: inline-flex;
    align-items: center;
    gap: 11px;
    @include  body5();
    color: $future;

    .icon {
      position: relative;
      left: 0;
      transition: all 150ms ease;
      stroke: $future;
    }

    .line {
      transition: all 150ms ease;
      stroke-dasharray: 0 8;
      stroke-dashoffset: 0;
      opacity: 0;
    }

    &:hover,
    &:focus {
      .line {
        stroke-dasharray: 8 0;
        stroke-dashoffset: 0;
        opacity: 1;
      }

      .icon {
        left: 3px;
      }
    }
  }
}


//.freight-dictionary-landing {
//  // DESCRIPTION SECTION
//  &__description {
//    padding-top: 160px;
//    position: relative;
//    z-index: 2 !important;
//
//    @media (max-width: 768px) {
//      padding-top: 120px;
//    }
//
//    @media (max-width: 575px) {
//      padding-top: 60px;
//    }
//
//  }
//
//  &__description-title {
//    @include text(36px, 700, none, none, $dubai);
//    text-align: center;
//    margin-bottom: 32px;
//
//    @media (max-width: 768px) {
//      font-size: 32px;
//      margin-bottom: 16px;
//    }
//
//    @media (max-width: 575px) {
//      font-size: 22px;
//    }
//  }
//
//  &__description-title-text {
//    position: relative;
//  }
//
//  &__description-title svg {
//    position: absolute;
//    top: -145px;
//    left: -155px;
//    width: 175px;
//    height: 175px;
//
//    @media (max-width: 768px) {
//      top: -110px;
//      left: -100px;
//      width: 140px;
//      height: 140px;
//    }
//
//    @media (max-width: 575px) {
//      top: -65px;
//      left: -45px;
//      width: 100px;
//      height: 100px;
//    }
//  }
//
//  &__description-paragraph {
//    @include text(16px, 400, none, none, $dubai);
//    margin-bottom: 32px;
//    line-height: 26px;
//
//    @media (max-width: 768px) {
//      margin-bottom: 16px;
//    }
//
//    @media (max-width: 575px) {
//      line-height: 22px;
//    }
//
//    &--bold {
//      font-weight: 600;
//    }
//  }
//
//  &__list-title {
//    @include text(16px, 400, none, none, $dubai);
//    margin-bottom: 16px;
//    font-weight: 600;
//  }
//
//  &__list-benefits {
//    padding-left: 25px;
//    margin-bottom: 32px;
//    list-style: disc;
//
//    @media (max-width: 768px) {
//      margin-bottom: 16px;
//    }
//  }
//
//  &__list-benefits-item {
//    line-height: 26px;
//    &:not(:last-child) {
//      margin-bottom: 16px;
//    }
//
//    @media (max-width: 575px) {
//      line-height: 22px;
//    }
//  }
//
//  &__link {
//    color: $dubai;
//    text-decoration: underline;
//
//    &:hover,
//    &:focus {
//      text-decoration: underline;
//    }
//  }
//
//  // OTHER TERMS SECTION
//  &__other-terms {
//    padding-top: 160px;
//
//    @media (max-width: 575px) {
//      padding-top: 80px;
//    }
//  }
//
//  &__other-terms-title {
//    @include text(36px, 700, none, none, $dubai);
//    text-align: left;
//
//    @media (max-width: 1145px) {
//      text-align: center;
//    }
//
//    @media (max-width: 768px) {
//      font-size: 32px;
//      text-align: left;
//    }
//
//    @media (max-width: 575px) {
//      font-size: 22px;
//    }
//  }
//
//  &__other-terms-list {
//    margin: 32px 0 12px;
//    padding: -12px;
//    display: flex;
//    justify-content: space-between;
//    gap: 24px;
//    flex-wrap: wrap;
//
//    @media (max-width: 1145px) {
//      justify-content: center;
//    }
//
//    @media (max-width: 768px) {
//      flex-wrap: nowrap;
//      justify-content: flex-start;
//      overflow-x: scroll;
//      //margin: 32px 12px 12px 12px;
//      //padding: -12px;
//      padding: 32px 12px 12px 12px;
//      margin: -12px;
//    }
//  }
//
//  @media (max-width: 768px) {
//    &__other-terms-list::-webkit-scrollbar {
//      height: 6px;
//      width: 5px;
//      border: 3px;
//      -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.3);
//      border-radius:10px;
//      -webkit-border-radius: 10px;
//    }
//
//    &__other-terms-list::-webkit-scrollbar-track {
//      background-color: $mood;
//      border-radius: 3px;
//    }
//
//    &__other-terms-list::-webkit-scrollbar-thumb {
//      border-radius:10px;
//      background-color: $rain;
//    }
//  }
//
//  &__other-terms-item {
//    width: 356px;
//    height: 175px;
//    padding: 24px;
//    border-radius: 24px;
//    box-shadow: $shadow-04;
//    overflow: hidden;
//
//    @media (max-width: 768px) {
//      min-width: 356px;
//    }
//  }
//
//  &__other-terms-item-title {
//    @include text(18px, 600, none, none, $dubai);
//    padding-bottom: 16px;
//    border-bottom: 1px solid $ice;
//
//    @media (max-width: 575px) {
//      font-size: 16px;
//    }
//  }
//
//  &__other-terms-item-description {
//    @include text(14px, 400, none, none, $dubai);
//    line-height: 22px;
//    margin-top: 16px;
//    height: 87px;
//    overflow: hidden;
//    position: relative;
//  }
//
//  &__other-terms-item-link-box {
//    position: absolute;
//    top: 66px;
//    right: 0;
//    //padding-left: 16px;
//  }
//
//  &__other-terms-item-link-box-overlay {
//    position: absolute;
//    top: 71px;
//    left: 198px;
//    background: linear-gradient(rgba(255, 255, 255, 0.4705882353), rgba(255, 255, 255, 0.0901960784)) padding-box, linear-gradient(to right, rgb(255 255 255 / 50%), white) border-box;
//    height: 14px;
//    width: 20px;
//  }
//
//  &__other-terms-item-description a {
//    @include text(14px, 400, none, underline, $future);
//    background-color: $white;
//    padding: 4px 4px 4px 12px;
//    transition: $transition;
//
//    :hover {
//      color: $future-hover;
//    }
//  }
//
//  & section {
//    position: relative;
//  }
//}