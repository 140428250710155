section.terms-and-conditions {
    margin-top: 130px;
    margin-bottom: 120px;
    color: $dark-blue;

    h1 {
        margin: 0;
        padding-bottom: 45px;
        font-size: 35px;
        text-align: center;
        font-weight: 500;
        line-height: 39px;
    }

    h3 {
        padding: 20px 0;
        font-weight: 600;
        font-size: 24px;
        line-height: 28px;
    }

    p {
        margin: 0 0 15px;
        padding: 0;
        font-size: 16px;
        line-height: 22px;

        &.paragraph {
            //display: flex;

            .p-elem {
                margin-right: 5px;
                font-weight: 600;
            }
        }
    }

    a {
        color: #0088FF;
    }

    .list {
        margin-left: 20px;
        margin-bottom: 15px;
        list-style-type: disc;
        flex-wrap: wrap;
        align-items: flex-start;
        padding: 0 0 0 20px;
    }
}
