.new-section.greetings {
    padding: 150px 0 50px 0;
}

.greetings {
    &__heading {
        margin: 0 auto 50px auto;
        text-align: center;
        @include new-text(50px, 600, 100%, none, none, $blue-dark);

        @media (max-width: 768px) {
            @include new-text(44px, 600, 100%, none, none, $blue-dark);
        }

        @media (max-width: 575px) {
            @include new-text(38px, 600, 100%, none, none, $blue-dark);
        }

        @media (max-width: 425px) {
            @include new-text(32px, 600, 100%, none, none, $blue-dark);
        }

    }

    &__info {

    }

    &__paragraph {
        margin-bottom: 15px;
        @include new-text(16px, 14, 120%, none, none, $blue-dark);
    }

    // RULES LIST:
    &__rules-list {
        list-style: inside;
        padding-left: 20px;
    }

    &__rules-list-item {
        margin-bottom: 10px;
        @include new-text(16px, 14, 120%, none, none, $blue-dark);
    }
}
