.d-alert {
    padding: 8px;
    margin-top: 22px;

    border-radius: 8px;
    background: $white;
    @include shadow_1;

    &__body {
        display: flex;
        align-items: center;

        background: linear-gradient(59.74deg, rgba(255, 171, 29, 0.06) 0%, rgba(248, 80, 28, 0.06) 100%);
        border-radius: 6px;

        padding: 11px 16px;
    }

    &__icon {
        min-width: 20px;
        filter: drop-shadow(0px 3px 12px rgba(253, 139, 29, 0.53));
    }

    &__info {
        margin-left: 16px;

        font-weight: 500;
        font-size: 14px;
        line-height: 22px;

        color: #FFAB1D;

        span {
            cursor: pointer;
            text-decoration: underline;
        }
    }
}

@include media-breakpoint-down(sm) {
    .d-alert {
        padding: 6px;
        margin-top: 16px;

        &__body {
            padding: 8px 12px;
        }

        &__info {
            margin-left: 12px;

            font-size: 14px;
            line-height: 22px;
        }
    }
}

@include media-breakpoint-down(xs) {
    .d-alert {
        margin-top: 14px;

        &__body {
            padding: 6px 10px;
        }

        &__info {
            margin-left: 10px;

            font-size: 12px;
            line-height: 16px;
        }
    }
}
