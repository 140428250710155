.cargoes-flow {
  // HEADER
  &__header {
    padding: 210px 0;
    background-image: url('/images/pages/Services/CargoesFlow/header_background.png');
    background-size: cover;
    min-height: 800px;
    overflow: hidden;
    position: relative;

    @media (max-width: 998px) {
      min-height: 1024px;
    }
  }

  &__header-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__header-content-description {
    position: relative;
    z-index: 2;
    flex: 0 0 40%;

    @include mediaMixin(max, 998) {
      flex: 0 0 70%;
    }

    @include mediaMixin(max, 576) {
      flex: 0 0 100%;
    }
  }

  &__header-content-description-label {
    padding: 10px 20px;
    color: $white;
    text-align: center;
    border-radius: 6px;
    text-transform: uppercase;
    background: rgba(255, 255, 255, 0.1);
    font-size: 12px;
    display: inline-block;
    margin-bottom: 24px;
  }

  &__header-content-description-title {
    @include text(48px, 600, none, unset, $white);
    line-height: 58px;
    margin-bottom: 24px;
  }

  &__header-content-description-text {
    @include text(16px, 400, none, unset, $gray-light);
    line-height: 26px;
    margin-bottom: 24px;
  }

  &__header-content-description-buttons-box {
    display: flex;

    @include mediaMixin(max, 576) {
      flex-direction: column;
    }
  }

  &__header-content-description-button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    width: 168px;
    border-radius: 6px;
    background: $blue-middle;
    @include text(16px, 600, none, unset, $white);
    margin-right: 10px;

    @include mediaMixin(max, 576) {
      width: 100%;
      margin-top: 10px;
    }
  }

  &__header-content-description-button.empty {
    background: transparent;
    color: $blue-middle;

    .c-arrow {
      stroke: $blue-middle;
    }

    &:hover {
      .c-arrow {
        @include addArrowAnimation();
      }
    }
  }

  &__header-content-image-box {
    flex: 0 0 40%;
    position: absolute;
    right: -140px;
    top: -20px;

    @media (max-width: 1600px) {
      right: -200px;
    }

    @media (max-width: 1440px) {
      right: -400px;
    }

    @media (max-width: 1100px) {
      right: -430px;
    }

    @media (max-width: 1024px) {
      top: 25px;
      right: -340px;
    }

    @media (max-width: 998px) {
      top: unset;
      bottom: -145px;
      right: -80px;
    }

    @media (max-width: 800px) {
      right: unset;
      left: -80px;
    }

    @media (max-width: 768px) {
      right: -100px;
      bottom: -110px;
      //left: -75px;
    }

    @media (max-width: 675px) {
      bottom: -100px;
    }

    @media (max-width: 576px) {
      bottom: -60px;
      left: -50px;
    }

    @media (max-width: 425px) {
      bottom: -60px;
    }
  }

  &__header-content-image {
    @media (max-width: 1024px) {
      max-width: 980px;
    }

    @media (max-width: 768px) {
      max-width: 100%;
    }

    @media (max-width: 576px) {
      max-width: 105%;
    }

    @media (max-width: 425px) {
      max-width: 120%;
    }
  }

  // WHAT IS
  &__what-is {
    padding: 175px 0;
    position: relative;

    @media (max-width: 768px) {
      padding: 150px 0 100px 0;
    }
  }

  &__what-is .container {
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 1024px) {
      justify-content: flex-end;
    }

    @media (max-width: 768px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  &__what-is-image-box {
    margin-right: 150px;

    @media (max-width: 1024px) {
      margin-right: unset;
      position: absolute;
      top: 65px;
      left: -210px;
    }

    @media (max-width: 890px) {
      top: 100px;
    }

    @media (max-width: 830px) {
      top: 80px;
    }

    @media (max-width: 768px) {
      position: unset;
    }
  }

  &__what-is-image {
    width: 356px;
    height: 356px;

    @media (max-width: 1024px) {
      width: 620px;
      height: 620px;
    }

    @media (max-width: 768px) {
      width: 100%;
      height: auto;
    }
  }

  &__what-is-description {
    max-width: 500px;

    @media (max-width: 890px) {
      max-width: 450px;
    }

    @media (max-width: 830px) {
      max-width: 380px;
    }

    @media (max-width: 768px) {
      max-width: 100%;
    }
  }

  &__what-is-description-title {
    margin-bottom: 32px;
    @include new-text(36px, 600, 48px, none, none, $blue-dark);

    @media (max-width: 830px) {
      @include new-text(24px, 600, 34px, none, none, $blue-dark);
    }
  }

  &__what-is-description-paragraph {
    margin-bottom: 24px;
    @include new-text(16px, 400, 26px, none, none, $gray-dark);
  }

  &__what-is-description .new-text--bold {
    color: $blue-dark;
  }

  // ADVANTAGES
  &__advantages {
    padding: 180px 0;
    //background-color: $blue-dark;
    background-image: url('/images/pages/Services/CargoesFlow/advantages_background.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    @media (max-width: 768px) {
      padding: 80px 0 180px 0;
    }
  }

  &__advantages-title {
    margin-bottom: 64px;
    @include new-text(36px, 600, 48px, uppercase, none, $white);

    @media (max-width: 768px) {
      margin-bottom: 32px;
      @include new-text(22px, 600, 32px, uppercase, none, $white);
    }
  }

  // ADVANTAGES: CARDS
  &__advantages-cards-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }

  &__advantages-card {
    max-width: 552px;
    width: 100%;
    padding: 16px;
    margin-bottom: 32px;
    border-radius: 10px;
    background-color: $white-transparent;

    @media (max-width: 1165px) {
      max-width: 48%;
      min-height: 170px;
    }

    @media (max-width: 768px) {
      max-width: 100%;
    }
  }

  &__advantages-card-icon-box {
  }

  &__advantages-card-icon-wrapper {
    width: 48px;
    height: 48px;
    margin-bottom: 8px;
    padding: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    background-color: $blue-dark-03;
  }

  &__advantages-card-icon-wrapper.route {
    border-right: 2px solid $icon-crimson-hover;
  }

  &__advantages-card-icon-wrapper.link {
    border-right: 2px solid $advantages-icon-orange;
  }

  &__advantages-card-icon-wrapper.location {
    border-right: 2px solid $icon-blue-hover;
  }

  &__advantages-card-icon-wrapper.chart {
    border-right: 2px solid $advantages-icon-purple;
  }

  &__advantages-card-icon-wrapper.timer {
    border-right: 2px solid $icon-azure-hover;
  }

  &__advantages-card-icon {
  }

  &__advantages-card-title {
    margin-bottom: 8px;
    @include new-text(18px, 600, 28px, none, none, $white);
  }

  &__advantages-card-description {
    @include new-text(14px, 400, 22px, none, none, $gray-light);
  }

  // VISIBILITY
  &__visibility {
    padding: 50px 0;

    @media (max-width: 768px) {
      padding: 0 0 50px 0;
    }
  }

  &__visibility-title {
    max-width: 353px;
    margin-bottom: 12px;
    position: relative;
    @include new-text(36px, 600, 48px, none, none, $blue-dark);

    @media (max-width: 575px) {
      @include new-text(22px, 600, 32px, none, none, $blue-dark);
    }

    @media (max-width: 425px) {
      max-width: 280px;
    }
  }

  &__icon-arrow {
    position: absolute;
    top: 25px;
    right: -110px;

    @media (max-width: 575px) {
      top: 10px;
      right: -20px;
    }
  }

  // VISIBILITY: CARDS
  &__visibility-list {
    display: flex;
    justify-content: space-between;
    gap: 32px;
    flex-wrap: wrap;

    @media (max-width: 998px) {
      justify-content: flex-start;
    }
  }

  &__visibility-card {
    width: 22%;
    height: 256px;
    padding: 24px;
    border-radius: 10px;
    box-shadow: $box-shadow;

    @media (max-width: 998px) {
      width: 30%;
    }

    @media (max-width: 768px) {
      width: 47%;
    }

    @media (max-width: 575px) {
      width: 100%;
    }
  }

  &__visibility-card-percentage {
    margin-bottom: 26px;
    padding-bottom: 16px;
    @include new-text(48px, 700, 60px, none, none, $blue-dark);
    border-bottom: 1px solid $gray-disabled;
  }

  &__visibility-card-percentage.supply-chain {
    background: $icon-blue-webkit;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &__visibility-card-percentage.metric {
    background: $icon-orange-webkit;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &__visibility-card-percentage.improving {
    background: $icon-green-webkit;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &__visibility-card-percentage.real-time {
    background: $icon-purple-webkit;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &__visibility-card-percentage.saving {
    background: $icon-green-webkit;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &__visibility-card-percentage.reduction {
    background: $icon-purple-webkit;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &__visibility-card-percentage.growth {
    background: $icon-crimson-webkit;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &__visibility-card-percentage.delay {
    background: $icon-orange-webkit;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &__visibility-card-description {
    @include new-text(16px, 400, 26px, none, none, $gray-dark);
  }

  // SAAS
  &__saas {
    padding: 160px 0;
    position: relative;

    @media (max-width: 768px) {
      padding: 70px 0;
    }
  }

  &__slide-paragraph {
    @include new-text(16px, 400, 26px, none, none, $blue-dark);
  }

  & .vector01 {
    position: absolute;
    top: 60px;
    right: 400px;

    @media (max-width: 768px) {
      display: none;
    }
  }

  // FEATURES
  &__features {
    padding: 160px 0;
    position: relative;

    @media (max-width: 768px) {
      padding: 70px 0;
    }
  }

  &__features-slide-list {
  }

  &__features-slide-list-item {
    height: 50px;
    display: flex;
    align-items: flex-start;
    margin-bottom: 48px;
  }

  &__features-slide-list-item-icon {
    margin-right: 18px;
    padding-top: 5px;
  }

  &__features-slide-list-item-text {
    @include new-text(18px, 600, 28px, none, none, $blue-dark);
  }

  // HOW IT WORKS
  &__works {
    padding: 180px 0;
    background-color: $blue-dark;
    overflow-x: auto;
    overflow-y: hidden;

    @media (max-width: 576px) {
      padding: 80px 0;
      overflow-x: hidden;
      overflow-y: hidden;
    }
  }

  &__works .container {
    position: relative;
    max-width: 1280px;
    width: 1280px;
    overflow-x: auto;
    overflow-y: hidden;

    @media (max-width: 576px) {
      overflow-x: hidden;
      overflow-y: hidden;
    }
  }

  &__works-line {
    position: absolute;
    top: 115px;
    left: 15px;
    z-index: 2;

    @media (max-width: 576px) {
      top: 130px;
      left: 20px;
    }
  }

  &__works-title {
    margin-bottom: 64px;
    text-align: center;
    @include new-text(36px, 600, 48px, none, none, $white);

    @media (max-width: 899px) {
      text-align: left;
    }
  }

  &__works-lists-box {
    padding-bottom: 15px;
    display: flex;
    justify-content: center;
    align-items: flex-start;

    @media (max-width: 576px) {
      margin-left: 35px;
      flex-direction: column;
    }
  }

  &__works-list {
    margin-right: 80px;
  }

  &__works-list:last-child {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 760px;

    @media (max-width: 576px) {
      height: unset;
    }
  }

  &__works-list:last-child .cargoes-flow__works-list-item:nth-child(3) {
    margin-top: 135%;

    @media (max-width: 576px) {
      margin-top: unset;
    }
  }

  &__works-list:last-child .cargoes-flow__works-list-item:nth-child(1) {
    @media (max-width: 576px) {
      order: 6;
      margin-top: 60px;
    }
  }

  &__works-list:last-child .cargoes-flow__works-list-item:nth-child(2) {
    @media (max-width: 576px) {
      order: 7;
    }
  }

  &__works-list-item {
    width: 218px;
    padding: 14px 0 0 0;
    position: relative;

    @media (max-width: 576px) {
      width: unset;
      padding: 15px 0 0 0;
    }
  }

  &__works-list-item-icon-box {
    margin-bottom: 12px;
  }

  &__works-list-item-icon {
  }

  &__works-list-item-side-icon-box {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 3;
    top: 15px;
    left: -40px;
    border-radius: 4px;
    box-shadow: $box-shadow-red;
    background-color: $blue-dark;
  }

  &__works-list-item-side-icon {
  }

  &__works-list-item-text {
    @include new-text(16px, 400, 26px, none, none, $white);
  }
}

// Rearranging styles for the swiper:
.cargoes-runner__overview-title.centered,
.cargoes-runner__overview-slider.centered {
  text-align: center;
}

// form component
.form-section {
  background-image: url('/images/main/logisticsFinance/form/4.png');
  background-size: cover;
  padding: 120px 0 135px;

  @include mediaMixin(max, 576) {
    padding: 64px 0 80px;
  }

  &__content {
  }

  &__main-box {
    background: $color-white-opacity;
    border: 1px solid $color-white-opacity;
    border-radius: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include mediaMixin(max, 998) {
      justify-content: center;
      min-height: 474px;
    }
  }

  &__image-box {
    flex: 0 0 27%;
    padding: 5px;

    @include mediaMixin(max, 998) {
      display: none;
    }
  }

  &__image {
    display: inline-block;
    border-radius: 16px 50% 16px 16px;
    max-width: 423px;
  }

  &__form-content-box {
    flex: 0 0 55%;

    @include mediaMixin(max, 998) {
      flex: 0 0 100%;
      padding: 84px 0 56px;
    }

    @include mediaMixin(max, 576) {
      padding: 60px 0 24px;
    }
  }

  &__form-content-box-header {
    margin-bottom: 50px;
  }

  &__form-title-h3 {
    @include h3;
    font-size: 24px;
    color: $color-white;
    font-weight: 600;
    position: relative;

    @include mediaMixin(max, 998) {
      padding-left: 60px;
    }
  }

  &__form-title-image {
    position: absolute;
  }

  &__form-title-image.free {
    top: 0;
    left: 0;
    transform: translate(-140%, -65%);

    @include mediaMixin(max, 998) {
      top: -50px;
      left: 45px;
      transform: translate(0, 0);
    }
  }

  &__form-title-image.cloud {
    top: -30px;
    left: 250px;

    @include mediaMixin(max, 998) {
      left: 300px;
    }

    @include mediaMixin(max, 576) {
      left: 220px;
    }
  }

  &__disclamer-box {
    margin-top: 120px;
  }

  &__disclamer-title-h3 {
    @include h3;
    color: $color-white;
    font-size: 24px;
    margin-bottom: 24px;
  }

  &__disclamer-content {
    display: flex;
    justify-content: flex-start;

    @include mediaMixin(max, 576) {
      flex-wrap: wrap;
    }
  }

  &__text {
    font-size: 14px;
    color: $color-input;
    font-weight: 400;
    max-width: 400px;
    line-height: 22px;

    @include mediaMixin(max, 576) {
      flex: 0 0 100%;
      margin-bottom: 24px;
    }
  }

  &__text:first-child {
    margin-right: 68px;

    @include mediaMixin(max, 998) {
      margin-right: 30px;
    }
  }
}

.demo-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-right: 64px;

  @include mediaMixin(max, 998) {
    padding: 0 30px;
    width: 100%;
  }

  .select__phone {
    border-right: 1px solid $color-grey;
  }

  .select__panel--phone {
    border: 1px solid $color-grey;

    &:hover {
      border: 1px solid #08f;
      cursor: pointer;
    }
  }

  .input {
    flex: 0 0 48%;
    margin-bottom: 60px;

    @include mediaMixin(max, 576) {
      flex: 0 0 100%;
      margin-bottom: 40px;
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: white;
  }

  .input__body {
    background: transparent;
    border: 1px solid $color-grey;
    color: $color-white;
    transition: border 0.3s linear;

    &:hover {
      border: 1px solid #08f;
      cursor: pointer;
    }

    &:focus {
      border: 1px solid #08f;
    }
  }

  .input__label {
    font-weight: 700;
    color: $color-white;
    margin-bottom: 8px;
  }

  .select__panel--phone {
    background: transparent;
  }

  .select__panel-number--fill {
    color: $color-white;
  }

  .select__input {
    background: transparent;
    color: $color-white;
    padding: 0 16px;
  }

  &__button-wrapper {
    flex: 0 0 100%;
    display: flex;
    justify-content: flex-end;

    @include mediaMixin(max, 576) {
      justify-content: center;
    }
  }

  &__button {
    position: relative;
    padding: 10px 60px;
    font-size: 16px;
    color: $color-white;
    font-weight: 500;
    background: $color-blue;
    border-radius: 6px;
    outline: none;
    border: none;
    font-family: Montserrat, sans-serif;
    transition: all 0.2s linear;

    @include mediaMixin(max, 576) {
      width: 100%;
      max-width: 285px;
    }
  }

  &__button:hover {
    cursor: pointer;
    background: $color-btn-hover;
  }

  &__button:focus {
    background: $color-btn-press;
  }

  &__loader {
    left: 15px;
    top: 15%;
    position: absolute;
    height: 25px;
    width: 25px;
    margin-left: 10px;
    border-radius: 50%;
    border-top: 2px solid #fff;
    border-right: 2px solid transparent;
    animation: spinner 1s linear infinite;
  }
}

@keyframes spinner {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

$marker-orange: linear-gradient(59.74deg, #ffab1d 0%, #f8501c 100%);
$marker-cayan: linear-gradient(59.74deg, #2bdce3 0%, #42ffdb 100%);
$marker-green: linear-gradient(59.74deg, #42df90 0%, #149e8e 100%);
$marker-pink: linear-gradient(59.74deg, #f573c3 0%, #c13dff 100%);
$marker-blue: linear-gradient(59.74deg, #2dc8ed 0%, #548af0 100%);
$marker-purple: linear-gradient(59.74deg, #888bf4 0%, #5151c6 100%);

@mixin colorMarker($color, $shadow) {
  background: #{$color};
  box-shadow: 0px 0px 6px #{$shadow} !important;
}

.list-marker {
  content: '';
  width: 5px;
  height: 5px;
  border-radius: 50%;
  margin-right: 15px;
  margin-top: 10px;

  @include colorMarker($marker-orange, #ffab1d);

  &.cayan {
    @include colorMarker($marker-cayan, #2bdce3);
  }

  &.green {
    @include colorMarker($marker-green, #42df90);
  }

  &.pink {
    @include colorMarker($marker-pink, #f573c3);
  }

  &.blue {
    @include colorMarker($marker-blue, #2dc8ed);
  }

  &.purple {
    @include colorMarker($marker-purple, #888bf4);
  }
}
