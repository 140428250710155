.india {
  padding: 65px 0;
  position: relative;

  background: $gradient-dubai;

  // IMAGE BLOCK
  &__head-image-box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 520px;
    position: absolute;
    top: 150px;
    right: 150px;
    z-index: 1;

    @media (max-width: 1140px) {
      right: 120px;
    }

    @media (max-width: 1090px) {
      right: 60px;
    }

    @media (max-width: 1024px) {
      width: 600px;
      top: 150px;
      right: -220px;
    }

    @media (max-width: 575px) {
      max-width: 500px;
      width: 98%;
      top: 520px;
      right: 0;
    }

    svg {
      position: absolute;
      top: 30px;
      left: -175px;

      @media (max-width: 575px) {
        position: absolute;
        width: 28%;
        height: 28%;
        top: -15px;
        left: 52%;
        transform: rotate(45deg);
      }
    }
  }

  &__head-image {
    width: 100%;
    object-fit: contain;
  }

  &__head {
    padding: 245px 0;
    background-image: url('/images/pages/Resources/FreightDictionary/landings/head-bg.png');
    background-size: cover;
    overflow: hidden;

    @media (max-width: 768px) {
      padding: 245px 0 290px 0;
    }

    @media (max-width: 575px) {
      padding: 135px 0 575px 0;
    }
  }

  // HEAD: DESCRIPTION BLOCK
  &__head-description-box {
    max-width: 520px;
    width: 100%;
    position: relative;
    z-index: 2;
  }

  &__head-title {
    @include text(48px, 600, none, none, $white);
    display: flex;
    flex-direction: column;
    line-height: 60px;

    @media (max-width: 768px) {
      font-size: 44px;
    }

    @media (max-width: 575px) {
      font-size: 24px;
      line-height: 32px;
    }
  }

  &__head-text {
    @include text(16px, 400, none, none, $white);
    margin-top: 32px;
    line-height: 26px;
  }

  // HEAD: OVERRIDES - LINK BLOCK
  &__head .new-header-hero__links-box {
    margin-top: 16px;

    @media (max-width: 768px) {
      margin-top: 40px;
    }

    @media (max-width: 575px) {
      flex-direction: column;
    }
  }

  &__head .new-header-hero__links-box {
    @media (max-width: 575px) {
      margin-top: 40px;
    }
  }

  &__head .new-header-hero__links-box .new-link-block.full {
    @media (max-width: 575px) {
      width: 100%;
      margin: unset;
    }
  }

  &__head .new-header-hero__links-box .new-link.arrow {
    @media (max-width: 575px) {
      margin-top: 24px;
    }
  }

  // LICENSE
  &__license-title {
    @include text(36px, 600, none, none, $white);
    line-height: 26px;
    text-align: center;

    @media (max-width: 768px) {
      font-size: 32px;
      line-height: 42px;
    }

    @media (max-width: 575px) {
      font-size: 22px;
      line-height: 32px;
    }
  }

  &__license-description-box {
    margin-top: 24px;

    @media (max-width: 768px) {
      margin-top: 16px;
    }
  }

  &__license-paragraph {
    @include text(16px, 600, none, none, $white);
    line-height: 26px;
    max-width: 684px;
    width: 100%;
    text-align: center;
    margin: 0 auto;

    @media (max-width: 768px) {
      font-size: 14px;
      line-height: 22px;
    }

    @media (max-width: 575px) {
      font-size: 12px;
      line-height: 20px;
    }
  }

  &__license-button-box {
    margin-top: 24px;
    display: flex;
    justify-content: center;
    align-self: center;
  }

  &__license .new-link-block.full {
    @include text(16px, 600, none, none, $dubai);
    padding: 12px 24px;
    width: unset;
    background-color: $white;
    border: none;

    @media (max-width: 575px) {
      width: 100%;
    }
  }

  // INSTRUCTIONS
  &__instructions {
    margin-top: 160px;

    @media (max-width: 575px) {
      margin-top: 80px;
    }
  }

  &__instructions-section {
    display: flex;
    align-items: center;
    column-gap: 48px;

    @media (max-width: 1024px) {
      flex-direction: column;
    }
  }

  &__instructions-section.insurance {
    margin-top: 160px;

    @media (max-width: 575px) {
      margin-top: 80px;
    }
  }

  &__instructions-image-box {
    max-width: 540px;
    width: 100%;
    border-radius: 24px;

    @media (max-width: 1024px) {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }

  &__instructions-image-box img {
    border-radius: 24px;
  }

  &__instructions-title {
    @include text(36px, 600, none, none, $white);
    line-height: 48px;

    @media (max-width: 768px) {
      font-size: 32px;
      line-height: 42px;
    }

    @media (max-width: 575px) {
      font-size: 22px;
      line-height: 32px;
    }
  }

  &__instructions-section.business-and-tax &__instructions-title {
    @media (max-width: 1024px) {
      margin-top: 60px;
    }

    @media (max-width: 768px) {
      margin-top: 40px;
    }
  }

  &__instructions-section.insurance &__instructions-image-box {
    @media (max-width: 1024px) {
      margin-top: 60px;
    }

    @media (max-width: 768px) {
      margin-top: 40px;
    }
  }

  &__instructions-paragraphs {
    margin-top: 32px;

    @media (max-width: 768px) {
      margin-top: 24px;
    }
  }

  &__instructions-paragraph {
    @include text(16px, 400, none, none, $white);
    line-height: 26px;
    margin-top: 16px;

    @media (max-width: 768px) {
      font-size: 14px;
      line-height: 22px;
    }

    @media (max-width: 575px) {
      font-size: 12px;
      line-height: 20px;
    }
  }

  // MEMBERS
  &__members {
    margin-top: 160px;
    position: relative;

    @media (max-width: 575px) {
      margin-top: 80px;
    }
  }

  &__members-title {
    @include text(36px, 600, none, none, $white);

    @media (max-width: 768px) {
      font-size: 32px;
      line-height: 42px;
    }

    @media (max-width: 575px) {
      font-size: 22px;
      line-height: 32px;
    }
  }

  &__members-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50px;
    left: 0;
    width: 100%;
    height: 95%;
    backdrop-filter: blur(10px);
    background-color: rgba(0, 12, 55, 0.1);
  }

  &__members-cards-box {
    margin-top: 48px;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    column-gap: 24px;
    overflow: hidden;
  }

  &__members-card {
    min-width: 275px;
  }

  &__members-card-image-box {

  }

  &__members-card-image {
    width: 100%;
  }

  &__members-card-content-box {
    margin-top: 24px;
  }

  &__members-card-company {
    display: inline-block;
    text-align: center;
    padding: 6px 16px;
    border: 1px solid $ice;
    border-radius: 24px;
    background-color: $lapland;
  }

  &__members-card-company-text {
    @include text(12px, 500, none, none, $white);
  }

  &__members-card-name {
    @include text(20px, 700, none, none, $white);
    margin-top: 12px;
  }

  &__members-card-description {
    @include text(14px, 400, none, none, $white);
    margin-top: 12px;
    line-height: 22px;
  }

  &__members-card-links-box {
    margin-top: 12px;
    @include text(16px, 600, none, none, $future);
  }

  &__members .new-link-block.full {
    @include text(16px, 600, none, none, $dubai);
    padding: 12px 24px;
    width: unset;
    background-color: $white;
    border: none;
    color: rgba(0, 12, 55, 1) !important;

    //@media (max-width: 575px) {
    //  width: 100%;
    //}
  }

  // POSTS
  & .freight-dictionary__posts {
    margin-top: 160px;

    @media (max-width: 575px) {
      margin-top: 80px;
    }
  }

  & .freight-dictionary__posts-title-text,
  & .freight-dictionary__post-item-title {
    color: $white;
  }

  & .freight-dictionary__post-item {
    background-color: $storm;
  }

  & .freight-dictionary__post-item-date {
    color: $ice;
  }

  // ABOUT
  &__about {
    margin-top: 160px;

    @media (max-width: 575px) {
      margin-top: 80px;
    }
  }

  &__about-overlay {
    padding: 80px 48px;
    border-radius: 60px;
    background-color: $storm;
  }

  &__about-content {
    max-width: 800px;
    width: 100%;
    margin: 0 auto;
  }

  &__about-title {
    @include text(36px, 600, none, none, $white);
    line-height: 48px;
    text-align: center;
    margin: 0 auto;

    @media (max-width: 768px) {
      font-size: 22px;
      line-height: 32px;
    }

    @media (max-width: 575px) {
      font-size: 18px;
      line-height: 28px;
    }
  }

  &__about-paragraph {
    @include text(16px, 500, none, none, $white);
    line-height: 26px;
    text-align: center;
    margin-top: 24px;

    @media (max-width: 768px) {
      font-size: 12px;
      line-height: 20px;
      margin-top: 16px;
    }

    @media (max-width: 575px) {
      font-size: 18px;
      line-height: 28px;
      margin-top: 8px;
    }
  }
}