.button {
    @include shadow_1();
    border-radius: 5px;

    &__input {
        width: 80px;
        height: 30px;
        border: none;
        border-radius: 5px;
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
    }

}
