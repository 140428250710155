.drag-and-drop {

    // DROP AREA
    &__drop-area {
        height: 100%;
        width: 100%;
        padding: 25px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px dashed $gray-middle-02;
        border-radius: 4px;
    }

    &__drop-area.error {
        border: 1px dashed $icon-crimson-hover;
    }

    &__icon-upload {
        margin-right: 12px;
    }

    &__drop-area-text {
        @include new-text(14px, 500, 20px, none, none, $blue-dark);
    }

    & p {
        margin-bottom: 8px;
        text-align: center;
        @include new-text(14px, 500, 20px, none, none, $blue-dark);
    }

    // INPUT
    &__input-box {
        text-align: center;
    }

    &__input-label {
        box-sizing: border-box;
        padding: 8px;
        border-radius: 6px;
        background: $gray-disabled;
        cursor: pointer;
        @include new-text(14px, 500, 20px, none, none, $blue-dark);
    }

    &__input {
        width: 0;
        height: 0;
        visibility: hidden;
    }

    &__file-item {
        margin-top: 8px;
    }

    & .drag-and-drop__error {
        margin-top: 4px;
        @include new-text(11px, 400, 100%, none, none, $icon-crimson-hover);
    }
}
