.tracking-system {
  overflow: hidden;
  
  &__container {
    margin: 0 auto;
    max-width: 1200px;
    padding: 0 15px;
  }
  width: 100%;
  padding-bottom: 120px;

  @media (max-width: 960px) {
    padding-bottom: 80px;
  }
  @media (max-width: 568px) {
    padding-bottom: 60px;
  }

  &__header-wrapper {
  }

  &__header {
    padding-top: 280px;
    padding-bottom: 203px;
    display: flex;
    flex-direction: column;

    @media (max-width: 960px) {
      padding-top: 253px;
    }

    @media (max-width: 768px) {
      padding-bottom: 180px;
    }

    @media (max-width: 568px) {
      padding-bottom: 32px;
      padding-top: 42px;
      background: linear-gradient(67.19deg, rgba(19, 197, 73, 0.05) -154.96%, rgba(13, 187, 187, 0.05) 124.72%);
    }

    &-vectors {
      &-vector1 {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: -1;
        max-width: 100%;
        overflow: hidden;

        & img {
          width: 100%;
        }

        @media (max-width: 960px) {
          left: 0px;
          max-width: 100%;
        }
      }
    }

    &-logo-cover {
      & img {
        @media (max-width: 960px) {
          width: 46px;
        }
      }
      @media (max-width: 568px) {
        order: 2;
      }
    }
    &-title {
      max-width: 454px;
      margin-top: 22px;
      font-size: 100px;
      @include new-text(48px, 600, 60px, none, none, $blue-dark);

      @media (max-width: 960px) {
        max-width: 340px;
        @include new-text(32px, 600, 42px, none, none, $blue-dark);
      }

      @media (max-width: 568px) {
        order: 3;
        @include new-text(22px, 600, 32px, none, none, $blue-dark);
      }
    }
    &-description {
      max-width: 454px;
      margin-top: 31px;
      @include new-text(16px, 400, 26px, none, none, $blue-dark);

      @media (max-width: 960px) {
        max-width: 340px;
      }
      @media (max-width: 768px) {
        margin-top: 20px;
      }
      @media (max-width: 568px) {
        order: 4;
        margin-top: 20px;
      }
    }
    &-image_container-desk {
      position: absolute;
      top: 145px;
      left: 645px;
      right: 0;
      z-index: 0;
      overflow: hidden;

      & img {
        display: block;
        margin: 0 auto;
      }

      @media (max-width: 960px) {
        display: none;
      }
    }
    &-image_container-tablet {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      z-index: 0;
      overflow: hidden;

      & img {
        display: block;
        margin-left: auto;
      }

      @media (min-width: 961px) {
        display: none;
      }

      @media (max-width: 568px) {
        display: none;
      }
    }
    &-image_container-mobile {
      order: 1;
      margin-left: -15px;
      margin-bottom: -100px;
      display: block;

      @media (min-width: 569px) {
        display: none;
      }
    }
  }

  &__best-wrapper {
    position: relative;
    background-color: $white;
    z-index: 100;
  }

  &__best {
    display: flex;
    gap: 131px;
    padding-top: 100px;
    z-index: 1;
    position: relative;

    @media (max-width: 960px) {
      padding-top: 75px;
    }

    @media (max-width: 568px) {
      padding-top: 36px;
    }

    &-vectors {
      &-vector1 {
        z-index: -1;
        position: absolute;
        top: 0;
        left: -100px;
        width: 100%;
        height: 100%;

        @media (max-width: 960px) {
          display: none;
        }
      }
    }

    &-image-wrapper {
      position: relative;
      top: -17px;
      left: -28px;

      @media (max-width: 960px) {
        display: none;
      }
    }

    &-texts {
    }
    &-title {
      @include new-text(36px, 600, 48px, none, none, $blue-dark);

      @media (max-width: 960px) {
        @include new-text(32px, 600, 42px, none, none, $blue-dark);
      }

      @media (max-width: 568px) {
        @include new-text(22px, 600, 32px, none, none, $blue-dark);
      }
    }
    &-cards {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding-top: 65px;
      gap: 64px;

      @media (max-width: 960px) {
        padding-top: 50px;
        gap: 0px;
        justify-content: flex-start;
      }

      @media (max-width: 735px) {
        padding-top: 40px;
        justify-content: flex-start;
        gap: 0;
      }
    }
    &-card {
      width: 300px;
      // margin-bottom: 64px;
      margin-right: -4px;

      @media (max-width: 960px) {
        margin-right: 53px;
        margin-bottom: 72px;
      }

      @media (max-width: 735px) {
        margin-bottom: 40px;
        margin-right: 0px;
        width: 100%;
      }
      &-icon {
      }
      &-title {
        margin: 14px 0;
        @include new-text(18px, 600, 26px, none, none, $blue-dark);

        @media (max-width: 568px) {
          margin-top: 24px;
          @include new-text(16px, 600, 26px, none, none, $blue-dark);
        }
      }
      &-description {
        @include new-text(16px, 400, 26px, none, none, $blue-dark);
      }
    }
  }

  &__api {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 155px 15px 120px 15px;

    @media (max-width: 960px) {
      flex-direction: column;
      padding-top: 30px;
      padding-bottom: 60px;
    }

    @media (max-width: 668px) {
      padding-top: 46px;
      padding-bottom: 0px;
    }

    &-subtitle {
      margin-top: 32px;
      max-width: 425px;
      @include new-text(16px, 400, 26px, none, none, $blue-dark);

      @media (max-width: 960px) {
        margin: 0 auto 70px auto;
        text-align: center;
      }
    }

    &-title-desk {
      @include new-text(32px, 600, 42px, none, none, $blue-dark);
      @media (max-width: 960px) {
        display: none;
      }
    }
    &-title-tablet {
      @include new-text(32px, 600, 42px, none, none, $blue-dark);
      text-align: center;
      padding-bottom: 32px;
      @media (min-width: 961px) {
        display: none;
      }

      @media (max-width: 668px) {
        display: block;
        @include new-text(22px, 600, 32px, none, none, $blue-dark);
      }
    }
    &-logos {
      @media (max-width: 668px) {
        margin-top: -100px;
      }
      & img {
        @media (max-width: 960px) {
          transform: scale(1.03);
        }

        @media (max-width: 668px) {
          transform: scale(0.64);
        }
      }
    }
  }

  &__web-wrapper {
    padding-top: 155px;
    padding-bottom: 110px;
    background-color: $grey-lapland;

    @media (max-width: 960px) {
      padding-top: 80px;
      padding-bottom: 10px;
    }

    @media (max-width: 576px) {
      padding-top: 18px;
      padding-bottom: 0px;
    }
  }

  &__web {
    display: flex;
    justify-content: space-between;
    gap: 67px;

    @media (max-width: 960px) {
      flex-direction: column;
      gap: 25px;
    }

    @media (max-width: 668px) {
      gap: 25px;
    }

    &-image {
      margin-left: -15px;

      @media (max-width: 960px) {
        order: 2;
        margin-right: -19px;
        margin-left: -18px;

        & img {
          width: 100%;
        }
      }

      @media (max-width: 668px) {
        margin-right: -8px;
        margin-left: -8px;
      }
    }

    &-texts {
      @media (max-width: 960px) {
        order: 1;
      }
    }

    &-title {
      margin-top: 92px;
      margin-bottom: 33px;
      @include new-text(32px, 600, 42px, none, none, $blue-dark);

      @media (max-width: 960px) {
        margin-top: 3px;
        margin-bottom: 25px;
      }

      @media (max-width: 668px) {
        margin-top: 18px;
        @include new-text(22px, 600, 32px, none, none, $blue-dark);
      }
    }
    &-description-desk {
      @include new-text(16px, 400, 26px, none, none, $blue-dark);
      @media (max-width: 960px) {
        display: none;
      }
    }
    &-description-tablet {
      max-width: 600px;
      @include new-text(16px, 400, 26px, none, none, $blue-dark);
      @media (min-width: 961px) {
        display: none;
      }
      @media (max-width: 668px) {
        display: none;
      }
    }
    &-description-mobile {
      @include new-text(16px, 400, 26px, none, none, $blue-dark);
      @media (min-width: 669px) {
        display: none;
      }
    }
  }

  &__install {
    position: relative;
    padding-top: 55px;
    padding-bottom: 45px;
    background: linear-gradient(67.19deg, #f8fdfd -154.96%, #d4eeec 124.72%);
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 120px;
    overflow: hidden;

    @media (max-width: 960px) {
      margin-top: 80px;
      padding-bottom: 15px;
    }

    @media (max-width: 668px) {
      margin-top: 57px;
      padding-top: 30px;
      padding-bottom: 30px;
    }

    &-title {
      @include new-text(36px, 700, 48px, none, none, $blue-dark);
      margin-bottom: 38px;

      @media (max-width: 960px) {
        margin-bottom: 23px;
      }

      @media (max-width: 668px) {
        @include new-text(22px, 700, 32px, none, none, $blue-dark);
        margin-bottom: 27px;
      }
    }

    &-description-desk {
      margin-bottom: 48px;
      text-align: center;
      max-width: 740px;
      @include new-text(16px, 500, 26px, none, none, $blue-dark);

      @media (max-width: 960px) {
        display: none;
      }
    }

    &-description-tablet {
      margin-bottom: 48px;
      text-align: center;
      max-width: 600px;
      @include new-text(16px, 500, 26px, none, none, $blue-dark);

      @media (min-width: 961px) {
        display: none;
      }

      @media (max-width: 960px) {
        margin-bottom: 23px;
      }

      @media (max-width: 668px) {
        display: none;
      }
    }

    &-description-mobile {
      display: none;
      text-align: center;
      padding-left: 24px;
      padding-right: 24px;
      margin-bottom: 64px;
      @include new-text(16px, 500, 26px, none, none, $blue-dark);

      @media (max-width: 668px) {
        display: block;
      }
    }

    &-link {
      width: 112px;
      height: 48px;
      z-index: 10;

      @media (max-width: 668px) {
        max-width: 295px;
        width: 100%;
      }
    }

    &-vectors {
      &-vector1 {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        overflow: hidden;
        border-radius: 24px;
      }
      &-vector2 {
        position: absolute;
        bottom: -5px;
        left: -5px;

        & img {
          border-radius: 24px;
        }
      }
    }
  }
}
