// SECTION: HEADER
.new-digital-tools-header {
    padding: 245px 0 300px 0;
    position: relative;
    overflow: hidden;

    background-image: url("/images/pages/Benefits/DigitalTools/header_background.png");
    background-position: bottom;
    background-size: cover;
    background-repeat: no-repeat;

    @media (max-width: 769px) {
        padding: 185px 0 300px 0;
    }

    @media (max-width: 576px) {
        padding: 162px 0 300px 0;
        background-position: -240px;
    }

    @media (max-width: 476px) {
        padding: 162px 0 330px 0;
        background-position: -310px;
    }

    @media (max-width: 425px) {
        background-position: -480px;
    }

    @media (max-width: 375px) {
        background-position: -535px;
    }

    & .new-container {
        @include new-container();
    }

    // HEADER: INFO BOX
    &__info-box {
        @media (max-width: 476px) {
            width: 100%;
        }
    }

    &__heading {
        //margin-bottom: 24px;
        @include page-heading-h1($white, true);
        text-transform: none;
    }

    &__description {
        max-width: 400px;
        @include page-heading-h1-description($gray-light, true);
    }

    &__description-link {
        color: $accent;
    }

    &__links-box .new-link-block.full {
        @media (max-width: 476px) {
            width: 100%;
        }
    }

    // HEADER: IMAGE BOX
    &__image-box {
        position: absolute;
        bottom: 0;
        right: 0;
    }
}

// SECTION: ITEMS
.new-digital-tools-items {
    padding: 90px 0 100px 0;
    background-color: $gray-background;

    @media (max-width: 769px) {
        padding: 140px 0 60px 0;
    }

    @media (max-width: 576px) {
        padding: 100px 0 40px 0;
    }

    & .new-container {
        @media (max-width: 1101px) {
            display: block;
        }
    }

    // ITEMS: INFO BOX
    &__info-box {
        margin-top: 90px;

        @media (max-width: 1101px) {
            width: unset;
            margin-bottom: 56px;
        }
    }

    &__heading {
        margin-bottom: 24px;
        @include new-text(32px, 700, 42px, none, none, $blue-dark);

        @media (max-width: 769px) {
            @include new-text(30px, 600, 40px, none, none, $blue-dark);
        }

        @media (max-width: 576px) {
            @include new-text(28px, 600, 38px, none, none, $blue-dark);
        }

        @media (max-width: 1101px) {
            text-align: center;
        }

        @media (max-width: 769px) {
            text-align: left;
        }
    }

    &__heading:not(:first-child) {
        margin-top: 24px;
    }

    &__description {
        @include new-text(16px, 500, 26px, none, none, $gray-dark);

        @media (max-width: 1101px) {
            text-align: center;
        }

        @media (max-width: 769px) {
            text-align: left;
        }
    }

    &__description:last-child {
        margin-top: 12px;
    }

    &__description-link {
        color: $accent;
    }

    &__description .new-text--bold {
        color: $dubai;
    }

    // ITEMS: LIST BOX
    &__list {
        display: flex;
        gap: 70px;
        flex-wrap: wrap;

        @media (max-width: 1101px) {
            width: unset;
            justify-content: flex-start;
        }
    }

    &__card {
        width: 330px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @media (max-width: 1101px) {
            width: 45%;
        }

        @media (max-width: 768px) {
            width: 100%;
        }
    }

    &__card-icon-box {
        width: 48px;
        height: 48px;
        margin-bottom: 24px;
        border-radius: 10px;
    }

    &__card-icon-box.dt-logistics-explorer {
        padding: 11px 10px 10px 11px;
        background: $digital-tools-icon-blue;
    }

    &__card-icon-box.dt-tracking-system {
        padding: 9px 10px 10px 11px;
        background: $digital-tools-icon-green;
    }

    &__card-icon-box.dt-ship-schedules {
        padding: 10px 10px 10px 8px;
        border: 2px solid $digital-tools-icon-border;
        background: $digital-tools-icon-gray;
    }

    &__card-icon-box.dt-cargoes-runner {
        padding: 13px 10px 10px 9px;
        background: $icon-crimson;
    }

    &__card-heading {
        margin-bottom: 16px;
        @include new-text(20px, 600, 30px, none, none, $blue-dark);
    }

    &__card-description {
        //height: 120px;
        margin-bottom: 16px;
        //overflow-y: scroll;
        @include new-text(14px, 500, 22px, none, none, $gray-dark);
    }
}
