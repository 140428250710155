.d-filter-tags {
  display: flex;
  gap: 8px;
  margin-top: 16px;
  min-height: 24px;

  .filter-tag {
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: $gray-disabled;
    border-radius: 16px;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    min-height: 24px;
    padding: 2px 17px;
  }

  .filter-delete {
    width: 16px;
    height: 16px;
    cursor: pointer;
  }
}
