// Todo: remove "new" prefix when all pages are redesigned.
// SECTION: HEADER HERO
.new-header-hero {
  padding: 208px 0 80px 0;
  position: relative;
  overflow: hidden;

  background-image: url('/images/pages/Home/header-hero-bg_desktop.webp');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  & .new-container {
    display: flex;
  }

  &__info-box {
    max-width: 475px;
    width: 100%;
    position: relative;
    z-index: 2;
  }

  &__logo-box {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
  }

  &__logo-text {
    @include new-text(12px, 600, 20px, uppercase, none, $white);
  }

  &__heading {
    @include page-heading-h1($white, true);
    text-transform: none;
  }

  &__description {
    max-width: 425px;
    @include page-heading-h1-description($gray-light, true);
  }

  &__links-box {
    display: flex;
    align-items: center;
    margin-bottom: 55px;
  }

  &__links-box .new-link-block:first-child {
    margin-right: 24px;
  }

  &__planet-box {
    width: 65%;
    position: absolute;
    bottom: 65px;
    right: -160px;
    z-index: 1;
  }

  &__planet {
    width: 100%;
  }

  // BANNER - MONTHLY MEMBERS MEETING
  .home-banner {
    position: relative;
    z-index: 99;
    max-width: 1200px;
    padding: 0 15px;
    margin: 50px auto 0 auto;

    @media (max-width: 769px) {
      margin-top: 132px;
    }

    @media (max-width: 475px) {
      margin-top: 54px;
    }

    .content {
      display: grid;
      background-color: $white;
      border-radius: 10px;
      padding: 16px;

      grid-template-areas:
        "image title register"
        "image subtitle register";

      grid-template-columns: 54px 1fr auto;
      grid-row-gap: 4px;
      grid-column-gap: 16px;

      @media (max-width: 769px) {
        padding: 24px;
        grid-template-areas:
        "image title"
        "image subtitle"
        "image register";

        grid-template-columns: 54px 1fr;
        grid-row-gap: 16px;
        grid-column-gap: 2px;
      }

      @media (max-width: 475px) {
        grid-template-areas:
        "image"
        "title"
        "subtitle"
        "register";

        grid-template-columns: 1fr;
        grid-row-gap: 8px;
        grid-column-gap: 2px;
      }
    }

    .image-wrapper {
      width: 54px;
      height: 54px;

      grid-area: image;

      @media (max-width: 769px) {
        width: 40px;
        height: 40px;
      }

      @media (max-width: 475px) {
        width: 32px;
        height: 32px;
      }
    }

    .title {
      grid-area: title;
      @include new-text(20px, 700, 30px, none, none, $blue-dark);

      @media (max-width: 475px) {
        @include new-text(18px, 700, 28px, none, none, $blue-dark);
      }
    }
    .subtitle {
      grid-area: subtitle;
      @include new-text(14px, 500, 22px, none, none, $blue-dark);

      @media (max-width: 475px) {
        @include new-text(14px, 400, 22px, none, none, $blue-dark);
      }
    }

    .register {
      grid-area: register;
      border-radius: 6px;
      border: none;
      cursor: pointer;
      background-color: $blue-dark;
      margin-left: auto;
      height: 48px;
      padding: 0px;
      margin-top: 1px;

      @media (max-width: 769px) {
        margin-left: 0;
        width: fit-content;
        margin-top: 0;
      }

      a {
        font-family: 'Montserrat', sans-serif;
        padding: 13px 25px;
        @include new-text(14px, 600, 22px, none, none, $white);
      }

      &:hover {
        opacity: 0.8;
      }
    }
  }
}

// SECTION: MEMBER'S MEETING
.members-meeting {
  padding: 32px 0;

  & .new-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__box {
    display: flex;
    align-items: center;
    margin-right: 32px;
  }

  &__logo-box {
    margin-right: 32px;
  }

  &__logo {
    width: 110px;
  }

  &__info {
    @include new-text(16px, 400, 26px, none, none, $blue-dark);
  }

  &__info-paragraph {
    max-width: 793px;
  }

  &__info-paragraph:first-child {
    margin-bottom: 8px;
  }
}

// SECTION: ABOUT
.new-about {
  padding: 160px 0 90px 0;
  background-color: $gray-background;

  & .new-container {
    display: flex;
    justify-content: space-between;
  }

  &__map-box {
    width: 95%;
    margin-right: 60px;
  }

  &__map {
    width: 100%;
  }

  &__info-box {
  }

  //&__heading {
  //  margin-bottom: 32px;
  //  @include new-text(36px, 600, 48px, none, none, $blue-dark);
  //}

  &__heading {
    @include page-heading-h2($blue-dark, true);
  }

  &__description-paragraph {
    margin-bottom: 28px;
    @include new-text(16px, 400, 26px, none, none, $blue-dark);
  }

  &__links-box {
    display: flex;
    align-items: center;
  }

  &__links-box .new-link-block:first-child {
    margin-right: 24px;
  }
}

// SECTION: VIDEO
.video {
  padding: 70px 0;
  background-color: #002538ff;
  background-image: url('/images/pages/Home/video-section_bg.webp');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;

  &__bg-bubbles {
    position: absolute;
    top: 0;
    right: calc(50% - 630px);
    z-index: 1;
  }

  & .new-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 2;
  }

  &__outer-box {
    padding: 20px;
    border: 1px solid rgba(255, 255, 255, 0.12);
    border-radius: 20px;
    background-color: rgba(255, 255, 255, 0.2);
  }

  &__player {
    max-width: 725px;
    width: 100%;
    max-height: 420px;
    height: 100%;
    border-radius: 12px;
  }
}

// SECTION: BENEFITS
.new-benefits {
  padding: 90px 0 140px 0;
  background-color: $gray-background;

  &__info-box {
    margin-bottom: 40px;
  }

  //&__heading {
  //  margin-bottom: 16px;
  //  @include new-text(36px, 600, 48px, none, none, $blue-dark);
  //}

  &__heading {
    @include page-heading-h2($blue-dark, true);
  }

  &__description {
    max-width: 500px;
    @include new-text(16px, 600, 26px, none, none, $blue-dark);
  }

  &__info {
    margin-bottom: 40px;
    @include new-text(18px, 600, 28px, none, none, $blue-dark);
  }

  // BENEFITS: CARDS
  &__cards-box {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 48px;
    gap: 24px;
  }

  &__card {
    width: 370px !important;
    border-radius: 10px !important;
    box-shadow: $box-shadow !important;
  }

  &__card-link {
    display: block;
    padding: 24px;
  }

  &__card-heading-section {
    display: flex;
    align-items: center;
    padding: 0 0 16px 0;
    margin-bottom: 16px;
    border-bottom: 1px solid $gray-light;
  }

  &__card-icon-box {
    margin-right: 16px;
  }

  &__card-heading {
    @include new-text(18px, 600, 28px, none, none, $blue-dark);
  }

  &__card-content {
    min-height: 70px;
    @include new-text(14px, 400, 22px, none, none, $gray-dark);
  }

  &__card-content .new-text--bold {
    @include new-text(14px, 600, 22px, none, none, $blue-dark);
  }

  // BENEFITS: SERVICES
  &__services-box {
    display: flex;
    padding: 35px;
    border: 1px solid $violet-dark;
    border-radius: 16px;
    background-color: $gray-dark-transparent;
  }

  &__service {
    display: flex;
    width: 24%;
  }

  &__service:not(:last-child) {
    margin-right: 80px;
  }

  &__service-icon-box {
    margin-right: 12px;
  }

  &__service-icon {
  }

  &__service-description {
    @include new-text(14px, 600, 22px, none, none, $violet-middle);
  }
}

// SECTION: LATEST NEWS
.new-section.latest-news {
  padding: 140px 0;
  background-color: $gray-background;
}

.new-section__seo-info {
  padding: 40px 0;
  background-color: $gray-background;
  @include new-text(18px, 500, 28px, none, none, $blue-dark);

  p {
    margin-bottom: 16px;
  }
}

@media (max-width: 1109px) {
  // SECTION: BENEFITS
  .new-benefits {
    &__card {
      width: 350px;
    }
  }
}

@media (max-width: 769px) {
  // SECTION: HEADER HERO
  .new-header-hero {
    padding: 144px 0 80px 0;

    &__planet-box {
      width: 62%;
      bottom: -60px;
      right: -65px;
    }
  }

  // SECTION: VIDEO
  .video {
    padding: 80px 0;

    &__bg-bubbles {
      width: 150%;
      height: 100%;
      right: -15%;
    }

    &__outer-box {
      padding: 8px;
    }
  }

  // SECTION: MEMBERS MEETING
  .members-meeting {
    & .new-container {
      display: block;
    }

    &__box {
      margin: 0 0 24px 0;
    }

    &__info {
      @include new-text(16px, 400, 24px, none, none, $blue-dark);
    }

    &__info-paragraph {
      width: 100%;
    }

    &__links-box .new-link-block.dark {
      margin: 0 auto;
    }
  }

  // SECTION: ABOUT
  .new-about {
    padding: 78px 0 70px 0;

    & .new-container {
      display: block;
    }

    &__map-box {
      width: 95%;
      margin: 0 auto 60px auto;
    }

    //&__heading {
    //  @include new-text(32px, 600, 42px, none, none, $blue-dark);
    //}

    &__description-box {
      max-width: 500px;
      width: 100%;
    }
  }

  // SECTION: BENEFITS
  .new-benefits {
    padding: 70px 0 120px 0;

    &__heading {
      text-align: left;
      @include new-text(32px, 600, 42px, none, none, $blue-dark);
    }

    &__description {
      margin: unset;
    }

    &__card {
      width: 340px !important;
    }

    &__services-box {
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 35px 35px 0 35px;
    }

    &__service {
      width: 45%;
      margin-bottom: 40px;
    }

    &__service:not(:last-child) {
      margin-right: 0;
    }
  }

  // SECTION: LATEST NEWS
  .new-section.latest-news {
    padding: 120px 0;
  }
}

@media (max-width: 735px) {
  .new-benefits {
    &__cards-box {
      justify-content: center;
    }

    &__card {
      width: 360px;
      margin-bottom: 16px;
    }

    &__card:nth-child(1n) {
      margin-right: 0;
    }
  }
}

@media (max-width: 631px) {
  .new-header-hero {
    &__planet-box {
      width: 70%;
      bottom: -60px;
      right: -65px;
    }
  }
}

@media (max-width: 575px) {
  // SECTION: VIDEO
  .video {
    padding: 48px 0;
  }

  // SECTION: LATEST NEWS
  .new-section.latest-news {
    padding: 100px 0;
  }
}

@media (max-width: 476px) {
  // SECTION: HEADER HERO
  .new-header-hero {
    padding: 142px 0 80px 0;

    &__planet-box {
      width: 110%;
      bottom: -290px;
      right: -120px;
    }
  }

  // SECTION: MEMBERS MEETING
  .members-meeting {
    &__box {
      display: block;
    }

    &__logo-box {
      margin: 0 0 24px 0;
      text-align: center;
    }
  }

  // SECTION: ABOUT
  .new-about {
    padding: 56px 0 72px 0;

    //&__heading {
    //  @include new-text(22px, 600, 32px, none, none, $blue-dark);
    //}

    &__links-box {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    &__links-box .new-link-block:first-child {
      margin: 0 0 32px 0;
      width: 100%;
    }
  }

  // SECTION: BENEFITS
  .new-benefits {
    padding: 50px 0 100px 0;

    &__heading {
      @include new-text(22px, 600, 32px, none, none, $blue-dark);
    }

    &__card-heading {
      @include new-text(16px, 600, 26px, none, none, $blue-dark);
    }

    &__services-box {
      justify-content: flex-start;
      padding: 27px;
    }

    &__service {
      width: 100%;
      margin-bottom: 0;
    }

    &__services-box &__service:not(:last-child) {
      margin-bottom: 32px;
    }
  }
}

@media (max-width: 426px) {
  .new-header-hero {
    &__planet-box {
      width: 120%;
      bottom: -285px;
    }
  }
}

@media (max-width: 375px) {
  .new-header-hero {
    &__planet-box {
      width: 125%;
      bottom: -260px;
    }
  }
}
