.textarea {
    width: 100%;

    &__element {
        padding-right: 10px;
        width: 100%;
        outline: none;
        border: none;
        resize: none;
        font-family: Montserrat sans-serif;
        @include text(14px, 500, none, none, $dark);
    }

    &__error {
        margin-top: 5px;
        @include text(12px, 400, none, none, $red-error);
        transition: $transition;
    }

}

// Bordered preset:
.textarea.isBordered {
    padding: 18px 18px 10px 18px;
    border: 1px solid $disabled-background;
    border-radius: 10px;
}

.textarea.isBordered.isError {
    border: 1px solid $red-error;
}
