.events__survey {
  position: relative;
  top: 65px;
  margin-bottom: 100px;

  .input__body {
    height: 40px;

    &.isError {
      border-color: #f52c5c;
    }
  }

  .input {
    flex: 0 0 70%;
  }

  &-wrapper {
    display: flex;
    align-items: center;
  }

  &-pretext {
    font-size: 16px;
    font-weight: 500;
    margin-right: 10px;
  }

  &-questions-box {
    margin-bottom: 40px;
  }

  &-question {
    margin-bottom: 35px;

    svg {
      width: 15px;
      margin-left: 10px;
      margin-bottom: -3px;
      fill: $dark;
      transition: all 0.2s linear;

      &:hover {
        cursor: pointer;
        fill: $accent;
      }
    }
  }

  &-question-tooltip-box {
    display: inline-block;
    position: relative;
    margin: 5px 10px;
  }

  &-question-title-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  &-question-tooltip {
    position: absolute;
    top: 0;
    font-size: 14px;
    line-height: 18px;
    left: 0;
    padding: 18px;
    background: rgba(250, 250, 250, 0.1);
    border: solid 1px;
    z-index: 1;
    border-radius: 10px;
    backdrop-filter: blur(5px);
    transform: translate(-91%, -107%);
    min-width: 270px;
    transition: all 0.2s linear;
    opacity: 0;
  }

  &-group {
    background: #fff;
    border-radius: 15px;
    padding: 20px;
    box-shadow: 0 10px 30px rgb(58, 112, 191, 15%);
    margin-top: 10px;
  }

  &-title {
    @include text(48px, 600, none, unset, $dark-blue);
    text-align: center;
    margin: 40px 0 20px 0;
    line-height: 56px;

    @media (max-width: 768px) {
      font-size: 32px;
      line-height: 42px;
    }

    //@include mediaMixin(max, 768) {
    //    font-size: 32px;
    //    line-height: 36px;
    //}

    //@include mediaMixin(max, 768) {
    //    font-size: 24px;
    //    line-height: 30px;
    //}
  }

  &-subtitle {
    @include text(20px, 600, none, unset, #6e7e92);
    text-align: center;
    margin-bottom: 30px;
    line-height: 30px;

    @media (max-width: 768px) {
      font-size: 16px;
      line-height: 20px;
    }

    //@include mediaMixin(max, 768) {
    //    font-size: 16px;
    //    line-height: 20px;
    //}
  }

  .custom-checkbox__label {
    @media (max-width: 768px) {
      width: 300px;
    }

    //@include mediaMixin(max, 768) {
    //    width: 300px;
    //}
  }

  &-answer-box {
    width: 60%;
    margin-top: 15px;

    @media (max-width: 768px) {
      width: 100%;
    }

    //@include mediaMixin(max, 768) {
    //    width: 100%;
    //}

    & > div {
      margin-top: 20px;
    }
  }

  &-form-item {
    margin-top: 20px;
  }

  &-group-title {
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 24px;

    @media (max-width: 768px) {
      font-size: 18px;
    }

    //@include mediaMixin(max, 768) {
    //    font-size: 18px;
    //}
  }

  &-questions-box {
    margin-bottom: 20px;
  }

  &-answer-box {
    margin-bottom: 20px;
  }

  &-question-title {
    display: inline-block;
    max-width: 90%;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
    line-height: 24px;

    @media (max-width: 768px) {
      font-size: 16px;
    }

    //@include mediaMixin(max, 768) {
    //    font-size: 16px;
    //}
  }

  &-button {
    width: 222px;
    min-width: 222px;
    border: 1px solid #000c37;
    background: #000c37;
    color: #fff;
    border-radius: 6px;
    padding: 10px;
    font-size: 16px;
    font-weight: 500;
    float: right;
    margin-top: 30px;
    position: relative;
  }

  .preloader-wrap {
    margin-top: 30px;
  }
}
