.container {
    max-width: 1200px;
    padding: 0 15px;
    margin: auto;
}

.events-main {
    padding: 128px 0 180px;

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &__title-h1 {
        @include h1;
        position: relative;
        line-height: 85px;
        margin-bottom: 20px;
    }

    &__title-h1:after {
        position: absolute;
        bottom: 0;
        left: 0;
        content: "";
        height: 1px;
        width: 100%;
        background: $gray-light;
    }

    &__cards-box {
        @media (max-width: 576px) {
            margin: auto;
        }
    }

    &__card {
        display: flex;
        padding: 20px 0;

        @media (max-width: 998px) {
            flex-direction: column;
        }

        @media (max-width: 576px) {
            width: 345px;
            align-items: center;
        }
    }

    &__card-date {
        flex: 0 0 15%;
        padding: 10px 0;
    }

    &__card-date-title-h3 {
        @include h3;
        line-height: 34px;

        @media (max-width: 576px) {
            text-align: center;
        }
    }

    &__card-date-subtitle {
        color: $gray-dark;
        font-size: 14px;
        font-weight: 600;
        line-height: 22px;
    }

    &__card-content {
        display: flex;
        background: $blue-dark;
        flex: 0 0 85%;
        border-radius: 10px;
        justify-content: space-between;

        @media (max-width: 576px) {
            flex-direction: column;
        }

        &:hover {
            cursor: pointer;
        }
    }

    &__card-content-image-box {
        flex: 0 0 25%;
        margin: 5px;
        display: flex;
        justify-content: center;
        width: 248px;
        height: 262px;
        border-radius: 10px;
        //background-image: url('/images/main/eventsMain/1_1.png');
        background-size: cover;

        @media (max-width: 998px) {
            //background-image: url('/images/main/eventsMain/1_2.png');
            max-width: 99px;
            background-size: cover;
        }

        @media (max-width: 576px) {
            //background-image: url('/images/main/eventsMain/1_3.png');
            min-width: 335px;
            max-height: 226px;
            background-size: cover;
            overflow: hidden;
        }
    }

    //&__card-content-image-box.annual {
    //    background-image: url('/images/main/eventsMain/1_1.png');
    //
    //    @media (max-width: 998px) {
    //        background-image: url('/images/main/eventsMain/1_2.png');
    //    }
    //
    //    @media (max-width: 576px) {
    //        background-image: url('/images/main/eventsMain/1_3.png');
    //    }
    //}
    //
    //&__card-content-image-box.future-of-freight {
    //    background-image: url('/images/pages/Events/FutureOfFreight/card-image__desktop.png');
    //
    //    @media (max-width: 998px) {
    //        background-image: url('/images/pages/Events/FutureOfFreight/card-image__tablet.png');
    //    }
    //
    //    @media (max-width: 576px) {
    //        background-image: url('/images/pages/Events/FutureOfFreight/card-image__mobile.png');
    //    }
    //}
    //
    //&__card-content-image-box.members-meeting {
    //    background-image: url('/images/pages/Events/MembersMeeting/card-image__desktop.png');
    //
    //    @media (max-width: 998px) {
    //        background-image: url('/images/pages/Events/MembersMeeting/card-image__tablet.png');
    //    }
    //
    //    @media (max-width: 576px) {
    //        background-image: url('/images/pages/Events/MembersMeeting/card-image__mobile.png');
    //    }
    //}
    //
    //&__card-content-image-box.global-freight {
    //    background-image: url('/images/pages/Events/GlobalFreightSummit/card-image__desktop.png');
    //
    //    @media (max-width: 998px) {
    //        background-image: url('/images/pages/Events/GlobalFreightSummit/card-image__tablet.png');
    //    }
    //
    //    @media (max-width: 576px) {
    //        background-image: url('/images/pages/Events/GlobalFreightSummit/card-image__mobile.png');
    //    }
    //}
    //
    //&__card-content-image-box.global-freight-2023 {
    //    background-image: url('/images/pages/Events/GlobalFreightSummit/card-image-2023__desktop.png');
    //
    //    @media (max-width: 998px) {
    //        background-image: url('/images/pages/Events/GlobalFreightSummit/card-image-2023__tablet.png');
    //    }
    //
    //    @media (max-width: 576px) {
    //        background-image: url('/images/pages/Events/GlobalFreightSummit/card-image-2023__mobile.png');
    //    }
    //}

    &__card-content-list-box {
        flex: 0 0 45%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        color: $white;

        @media (max-width: 576px) {
            padding: 0 10px;
            margin-bottom: 30px;
        }

    }

    &__card-content-list-title {
        @include h3;
        line-height: 34px;
        font-size: 24px;
        color: $white;
        margin-bottom: 20px;
    }

    &__card-content-list {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
    }

    &__card-content-list-image {
        margin-right: 20px;
    }

    &__card-content-list-text {
        font-size: 14px;
    }

    &__card-content-list-link {
        text-decoration: underline;
        line-height: 22px;
        font-size: 14px;
        color: $blue-middle;
        margin-left: 44px;
    }

    &__card-content-footer {
        flex: 0 0 20%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: column;
        padding-right: 30px;

        @media (max-width: 576px) {
            padding: 0 10px;
        }
    }

    &__card-content-footer-button-box {
        width: 100%;
    }

    &__card-content-footer-button {
        width: 100%;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $white;
        font-weight: 600;
        font-size: 14px;
        text-decoration: none;
        background: $blue-middle;
        border-radius: 6px;

        @media (max-width: 576px) {
            padding: 15px;
        }
    }

    &__card-content-footer-button.more-details {
        @include text(14px, 600, unset, none, $white);
        background: rgba(255, 255, 255, 0.4);
        backdrop-filter: blur(3px);
        transition: all .2s linear;

        &:hover {
            background: rgba(255, 255, 255, 0.6);
        }
    }

    //&__card-content-more-box {
    //    margin-top: 10px;
    //    text-align: center;
    //}

    //&__card-content-more {
    //    @include text(14px, 600, unset, none, $white);
    //    width: 100%;
    //    padding: 10px;
    //    display: flex;
    //    align-items: center;
    //    justify-content: center;
    //    background: rgba(255, 255, 255, 0.4);
    //    border-radius: 6px;
    //    backdrop-filter: blur(3px);
    //    transition: all .2s linear;
    //
    //    @media (max-width: 576px) {
    //        padding: 15px;
    //    }
    //
    //    &:hover {
    //        background: rgba(255, 255, 255, 0.6);
    //    }
    //}

    &__card-content-footer-logos {
        display: flex;
        justify-content: space-between;

        @media (max-width: 576px) {
            margin: 40px 0;
            justify-content: space-evenly;
            width: 100%;
        }
    }

    &__card-content-footer-logos img:first-child {
        margin-right: 20px;
    }
}
