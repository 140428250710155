.freight-forwarders {
    $parent: &;
    padding: 120px 0 180px;

    &__header {
        position: relative;

        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 40px;

        &-title {
            position: relative;
            z-index: 1;
            //@include h1;
            //margin-bottom: 24px;
            @include page-heading-h1($dubai, true);
        }

        &-suptitle {
            position: relative;
            z-index: 1;
            @include page-heading-h1-description($dubai, true);
            //@include body1;
            //color: $dark-grey;
            //margin-bottom: 24px;

            max-width: 430px;
        }

        &-suptitle-link {
            color: $accent;
        }

        &-btn {
            position: relative;
            z-index: 1;
            max-width: 222px;
        }

        &-info {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        &-images {

            img {
                width: 100%;
            }
        }

        &:after {
            content: '';

            position: absolute;

            width: 401.36px;
            height: 318.11px;

            top: 15%;
            left: -320px;

            background: #C82DFF;
            opacity: 0.1;
            filter: blur(75px);
            transform: matrix(-0.04, 1, 1, -0.03, 0, 0);
        }

        &:before {
            content: '';

            position: absolute;

            width: 168.97px;
            height: 207.29px;

            top: 50%;

            left: -250px;

            background: #0088FF;
            opacity: 0.3;
            filter: blur(75px);
            transform: matrix(-0.04, 1, 1, -0.03, 0, 0);
        }
    }

    &__h2 {
        margin-top: 48px;
        @include page-heading-h2($dubai, true);
        //font-size: 32px;
        //font-weight: 700;
        //line-height: 42px;
        //text-transform: none;
        //text-decoration: none;
        //color: #000c37;
    }

    &__h2-description {
        margin-top: 12px;
        font-size: 16px;
        font-weight: 500;
        line-height: 26px;
        text-transform: none;
        text-decoration: none;
        color: #6e7e92;
    }

    &__list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);

        grid-column-gap: 30px;
        grid-row-gap: 80px;

        margin-top: 100px;

        &-icon {
            margin-bottom: 16px;
            user-select: none;

            img {
                border-radius: 8px;
            }
        }

        &-title {
            @include h3;
            margin-bottom: 8px;
        }

        &-text {
            @include body2;
            color: $dark-grey;
        }
    }

    &__info {
        display: flex;

        &-title {
            max-width: 315px;

            @include h2;

            span {
                color: $purple1;
            }
        }

        &-container {
            flex: 1;
            display: flex;
        }

        &-wrapper {
            display: flex;
            margin: 0 auto;
        }

        &-list {
            max-width: 320px;

            &.center {
                max-width: 390px;
                margin: 0 auto;
            }

            & + & {
                margin-left: 45px;
            }
        }

        &-item {
            position: relative;
            @include body1;

            & + & {
                margin-top: 10px;
            }

            &:after {
                content: '';

                position: absolute;
                top: 8px;
                right: calc(100% + 16px);

                width: 6px;
                height: 6px;

                border-radius: 50%;
                background: linear-gradient(59.74deg, #FFAB1D 0%, #F8501C 100%);
            }
        }
    }

    &.payment {

        #{$parent} {

            &__header {

                &-images {

                    img {
                        width: 70%;
                    }
                }
            }

            &__list {

                &-title {
                    @include h4;
                }
            }

            &__info {
                padding-top: 180px;
            }
        }
    }

    &.finance {

        #{$parent} {

            &__list {

                &-title {
                    @include h4;
                }
            }

            &__info {
                padding-top: 180px;
            }
        }
    }

    &.reliability {
        padding-top: 65px;

        #{$parent} {

            &__info {
                padding-top: 60px;

                &-item {

                    &:after {
                        background: linear-gradient(59.74deg, #888BF4 0%, #5151C6 100%);
                    }
                }
            }
        }
    }
}

// 992
@include media-breakpoint-down(md) {
    .freight-forwarders {
        $parent: &;
        padding: 110px 0 140px;

        &__header {

            &-suptitle,
            &-title {
                margin-bottom: 16px;
            }
        }

        &__list {
            grid-template-columns: repeat(2, 1fr);
            margin-top: 120px;
        }

        &__info {
            display: flex;
            padding-top: 180px;

            &-title {
                max-width: 280px;
                @include h2;

                span {
                    color: $purple1;
                }
            }

            &-container {
                flex: 1;
                display: flex;
            }

            &-wrapper {
                display: flex;
                margin: 0 auto;
                flex-direction: column;
            }

            &-list {
                max-width: 300px;

                & + & {
                    margin: 16px 0 0;
                }
            }

            &-item {
                position: relative;
                @include body1;

                & + & {
                    margin-top: 10px;
                }

                &:after {
                    content: '';

                    position: absolute;
                    top: 8px;
                    right: calc(100% + 16px);

                    width: 6px;
                    height: 6px;

                    border-radius: 50%;
                    background: linear-gradient(59.74deg, #FFAB1D 0%, #F8501C 100%);
                }
            }
        }

        &.payment {

            #{$parent} {

                &__info {
                    padding-top: 120px;
                }
            }
        }

        &.finance {

            #{$parent} {

                &__info {
                    padding-top: 120px;
                }
            }
        }

        &.reliability {
            padding-top: 105px;

            #{$parent} {

                &__info {
                    padding-top: 120px;
                }
            }
        }
    }
}

// 768
@include media-breakpoint-down(sm) {
    .freight-forwarders {
        $parent: &;
        padding: 130px 0 120px;

        &__h2 {
            font-size: 28px;
        }

        &__header {
            grid-template-columns: 1fr;

            &-info {
                order: 1;
            }

            &-images {
                order: 0;
                padding: 0 15%;
            }
        }

        &__info {
            flex-direction: column;

            &-title {
                margin-bottom: 20px;
            }

            &-wrapper {
                margin-left: 16px + 6px;
            }

            &-list {
                max-width: 100%;

                &.center {
                    margin-left: 16px + 6px;
                }

                & + & {
                    margin: 8px 0 0;
                }
            }

            &-item {

                & + & {
                    margin: 8px 0 0;
                }
            }
        }

        &.payment {

            #{$parent} {

                &__header {

                    &-images {
                        padding: 0 20%;

                        img {
                            width: 100%;
                        }
                    }
                }

                &__info {
                    padding-top: 80px;
                }
            }
        }

        &.finance {

            #{$parent} {

                &__info {
                    padding-top: 80px;
                }
            }
        }

        &.reliability {
            padding-top: 105px;

            #{$parent} {

                &__info {
                    padding-top: 80px;
                }

                &__header {

                    &-suptitle,
                    &-title {
                        text-align: center;
                    }

                    &-btn,
                    &-suptitle {
                        margin: {
                            left: auto;
                            right: auto;
                        };
                    }
                }
            }
        }
    }
}

// 576
@include media-breakpoint-down(xs) {
    .freight-forwarders {

        &__header {

            &-images {
                padding: 0;
            }
        }

        &__h2 {
            font-size: 22px;
            font-weight: 600;
            line-height: 30px;
        }

        &__list {
            grid-row-gap: 70px;
            grid-template-columns: repeat(1, 1fr);

            margin-top: 115px;
        }

        &__info {

            &-title {
                max-width: 230px;
            }
        }
    }
}

.freight-forwarders__list-item .new-text--bold {
    color: $dubai;
}
