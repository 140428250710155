@import "../../../global/typography";
@import "../../../global/colors";

.benefits {
    background-color: $dubai;

    // SECTION: HEADER HERO
    &__header-hero {
        position: relative;
        padding: 200px 0 180px;

        @media(max-width: 768px) {
            background-position: center;
        }

        @media(max-width: 575px) {
            padding: 180px 0 120px;
        }
    }

    &__header-hero-content {
        margin: 0 auto;
        max-width: 992px;
        width: 100%;
    }

    &__header-hero-title-box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    &__header-hero-title {
        @include heading-h2($color: $winter);
    }

    &__header-hero-title-description {
        @include text-01($align: center, $color: $winter);
        margin-top: 16px;
        max-width: 722px;
        width: 100%;
    }

    &__header-hero-buttons-box {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 24px;
        margin-top: 32px;

        @media(max-width: 575px) {
            flex-direction: column;
        };
    }

    & .new-link-block.full {
        background-color: $winter;
        border-color: $winter;
        color: $dubai;
    }

    & .new-link.arrow {
        color: $winter;
    }

    & .new-link.arrow .link-arrow {
        stroke: $winter;
    }

    // SECTION: PITCH
    &__pitch {
        padding: 120px 0;
        background-color: $winter;

        @media (max-width: 575px) {
            padding: 90px 0;
        }
    }

    &__pitch-title-box {
        margin: 0 auto;
        max-width: 660px;
        width: 100%;
    }

    &__pitch-title {
        @include heading-h3();
        position: relative;
    }

    &__pitch-title svg {
        position: absolute;
        top: -30%;
        left: 94%;

        @media (max-width: 768px) {
            top: -95%;
            left: 45%;
        }

        @media (max-width: 575px) {
            display: none;
        }
    }

    &__pitch-title-description {
        @include text-01($align: center);
        margin-top: 16px;
    }

    &__pitch-paragraphs {
        margin-top: 56px;
        display: flex;
        justify-content: space-between;
        gap: 32px;

        @media (max-width: 768px) {
            flex-direction: column;
            gap: 24px;
        }

        @media (max-width: 575px) {
            margin-top: 40px;
        }
    }

    &__pitch-paragraph {
        @include text-01();
    }

    &__pitch-buttons {
        margin-top: 48px;

        @media (max-width: 575px) {
            margin-top: 32px;
        }
    }

    &__pitch-buttons .new-link-block.full {
        margin: 0 auto;
        width: 220px;
        background-color: $dubai;
        color: $winter;
    }

    // SECTION: CARDS
    &__cards {
        padding-top: 160px;

        @media (max-width: 768px) {
            padding-top: 120px;
        }

        @media (max-width: 575px) {
            padding-top: 90px;
        }
    }

    &__cards-title-box {
        margin: 0 auto;
        width: 100%;
    }

    &__cards-title {
        @include heading-h3($color: $winter);
        display: flex;
        flex-direction: column;
    }

    &__cards-title-description {
        @include text-01($align: center, $color: $winter);
        margin: 0 auto;
        max-width: 600px;
        margin-top: 16px;
    }

    &__cards-list {
        margin-top: 40px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 24px;

        @media (max-width: 768px) {
            flex-direction: column;
        }
    }

    &__cards-item {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 326px;
        padding: 24px;
        border-radius: 24px;
        border: 1px solid $transparent-026;

        @media (max-width: 768px) {
            width: unset;
        }
    }

    &__cards-item-title-box {
        display: flex;
        padding-bottom: 16px;
        align-items: center;
        border-bottom: 1px solid $transparent-026;
    }

    &__cards-item-title {
        @include heading-h4($align: left, $color: $winter);
        margin-left: 16px;
    }

    &__cards-item-description {
        @include text-01($color: $winter);
        margin-top: 8px;
    }

    &__cards-item-links-box {
        margin-top: 16px;
    }

    &__cards-item .new-link.arrow {
        color: $winter;
    }

    &__cards-item .new-link.arrow .link-arrow {
        stroke: $winter;
    }

    // SECTION: FAQ 
    &__faq {
        padding: 160px 0;

        @media (max-width: 768px) {
            padding: 120px 0;
        }

        @media (max-width: 575px) {
            padding: 90px 0;
        }
    }

    &__faq-title {
        @include heading-h3($color: $winter);
    }

    &__faq-list {
        margin-top: 56px;

        @media (max-width: 768px) {
            margin-top: 48px;
        }

        @media (max-width: 575px) {
            margin-top: 40px;
        }
    }

    &__faq-list-item {
        max-width: 970px;
        width: 100%;
        margin: 16px auto 0 auto;
        position: relative;
        color: $winter;
        border: 1px solid $transparent-026;
        border-radius: 24px;
        background-color: $transparent-010;
    }

    &__faq-list-item input {
        position: absolute;
        opacity: 0;
        z-index: -1;
    }

    &__faq-list-item input:checked ~ &__faq-list-item-content {
        max-height: 12rem;
    }

    &__faq-list-item-label {
        @include heading-h4($color: $winter, $align: left);
        display: flex;
        justify-content: space-between;
        padding: 24px 32px;
        cursor: pointer;

        @media (max-width: 768px) {
            padding: 12px 20px;
        }
    }

    &__faq-list-item-label svg {
        margin: 4px 0 0 12px;
        width: 24px;
        height: 24px;
        transition: all ease 0.2s;
    }

    &__faq-list-item input:checked + &__faq-list-item-label svg {
        transform: rotate(45deg);
    }

    &__faq-list-item-content {
        max-height: 0;
        overflow: hidden;
        transition: all 0.35s;
    }

    &__faq-list-item-content-text {
        @include text-01($color: $winter);
        margin: 0;
        padding: 0 56px 24px 32px;

        @media (max-width: 768px) {
            padding: 0 44px 12px 20px;
        }
    }

    // SECTION: FORM
    &__form {
        padding: 0 0 160px 0;

        @media (max-width: 768px) {
            padding: 120px 0;
        }

        @media (max-width: 575px) {
            padding: 90px 0;
        }
    }

    &__form-title {
        @include heading-h3($color: $winter);
        margin: 0 auto;
        max-width: 720px;
        width: 100%;
    }

    &__form-title-description {
        @include text-01($align: center, $color: $winter);
        margin-top: 16px;
    }

    &__form-content-box {
        margin-top: 40px;
        display: flex;
        justify-content: center;
        column-gap: 48px;

        @media (max-width: 1024px) {
            flex-direction: column;
        }
    }

    &__form-content-list {
        width: 35%;
        list-style-type: disc;

        @media (max-width: 1024px) {
            width: unset;
        }
    }

    &__form-content-list-item {
        @include text-01($color: $winter);
        margin: 24px 0 0 16px;
    }

    .benefits-form {
        max-width: 656px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: unset;
        border-radius: unset;
        background-color: unset;

        @media (max-width: 1024px) {
            margin: 32px auto 0 auto;
        }

        @media (max-width: 768px) {
            max-width: unset;
            width: 100%;
        }
    }

    .benefits-form__form {
        display: flex;
        flex-wrap: wrap;
        gap: 24px;
        padding: 24px;
        border-radius: 16px;
        background-color: $grey-winter-bg-01;
    }

    .benefits-form .input {
        margin-bottom: unset;

        @media (max-width: 768px) {
            flex: 0 0 100%;
        }
    }

    .benefits-form__button {
        @media (max-width: 768px) {
            max-width: unset;
        }
    }

    .benefits-form .select__panel {
        @media(max-width: 768px) {
            justify-content: flex-start;
        }
    }
}
