.form {
    margin: 0 auto;
    max-width: 360px;
    width: 100%;
    position: relative;
    z-index: 100;

    &__heading {
        margin-bottom: 16px;
        @include h3;
        text-align: center;
    }

    &__description-paragraph {
        margin-bottom: 24px;
        line-height: 160%;
        text-align: center;
    }

    &__description-paragraph.isError {
        color: red;
    }

    &__description-paragraph.confirmed {
        max-width: 500px;
        width: 100%;
        text-align: center;
        margin: 0 auto;
    }

    &__elements .input {
        margin-bottom: 25px;
    }

    &__buttons {
        margin: 12px 0;
    }

    &__buttons button.accent_blue {
        font-weight: 400;
    }

    &__buttons button.isDisabled {
        color: $dark-grey;
        pointer-events: none;
    }

    &__analytics-image {
        display: none;
    }
}
