.new-footer {
    padding: 60px 0 24px 0;
    background-color: $blue-dark;
    position: relative;

    & .new-container {
        @include new-container();
    }

    &__section {
        display: flex;
        justify-content: space-between;
        margin-bottom: 40px;
    }

    // logo
    &__logo-box {
        margin-right: 127px;
    }

    &__logo-link {
        display: block;
        margin-bottom: 32px;
    }

    &__logo {

    }

    // social
    &__social {
        display: flex;
        align-items: center;
    }

    &__social-link {
        display: block;
    }

    &__social-link:not(:last-child) {
        margin-right: 24px;
    }

    // links
    &__links-box {
        display: flex;
        column-gap: 12px;
    }

    &__link-item {
        margin-bottom: 16px;
        width: 180px;
    }

    &__link-item-a {
        @include new-text(14px, 500, 22px, none, none, $white);
        opacity: 0.8;
        transition: $transition;
    }

    &__link-item-a:hover {
        opacity: 1;
    }

    &__link-item:first-child .new-footer__link-item-a {
        //opacity: 1 !important;
        opacity: 1;
        @include new-text(14px, 600, 22px, none, none, $white);
    }

    &__link-item:first-child .new-footer__link-item-span {
        @include new-text(14px, 600, 22px, none, none, $white);
    }

    &__copyrights {
        @include new-text(12px, 400, 16px, none, none, $gray-dark);
    }
}

// MEDIA: CUSTOM (1024px)
@media (max-width: 1200px) {
    .new-footer {
        &__links-box {
            flex-wrap: wrap;
            width: 50%;
        }

        &__links-list {
            margin-bottom: 30px;
        }
    }
}

// MEDIA: Tablet (768px)
@media (max-width: 769px) {
    .new-footer {
        padding: 40px 0 16px 0;

        &__logo-box {
            margin-right: 0;
        }

        &__links-box {
            width: 60%;
        }
    }
}

// MEDIA: MOBILE (630px)
@media (max-width: 631px) {
    .new-footer {
        padding: 30px 0 16px 0;

        &__section {
            display: block;
            margin-bottom: 0;
        }

        &__logo-box {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            margin-bottom: 30px;
        }

        &__links-box {
            width: unset;
            justify-content: flex-start;
            gap: 32px;
        }

        &__link-item {
            width: 210px;
        }
    }
}

// MEDIA: MOBILE (475px)
@media (max-width: 476px) {
    .new-footer {
        &__links-box {
            width: unset;
            justify-content: space-between;
        }

        &__link-item {
            width: 180px;
        }
    }
}

// MEDIA: MOBILE (390px)
@media (max-width: 391px) {
    .new-footer {
        &__link-item {
            width: 150px;
        }
    }
}

//.footer {
//    position: relative;
//    padding: 30px 0 90px;
//
//    z-index: 99;
//
//    &__logo {
//        img {
//            user-select: none;
//            width: 150px;
//        }
//    }
//
//
//    &__container {
//        display: flex;
//        align-items: flex-start;
//    }
//
//    &__links {
//        $links: &;
//
//        flex: 1;
//        display: flex;
//        flex-direction: column;
//
//        font-family: 'Lato', sans-serif;
//
//        &-container {
//            margin-left: auto;
//            display: flex;
//            list-style: none !important;
//        }
//
//        &-link {
//            color: $dark-blue;
//            text-decoration: none !important;
//            font-family: Montserrat, sans-serif;
//            transition: .3s 0s;
//        }
//
//        &-item {
//            font-size: 14px;
//            line-height: 14px;
//
//            &:hover {
//
//                #{$links} {
//
//                    &-link {
//                        opacity: .7;
//                    }
//                }
//            }
//
//            & + & {
//                margin-left: 16px;
//            }
//        }
//    }
//
//    &__copyright {
//        color: $light-grey;
//        font-size: 12px;
//        line-height: 12px;
//
//        margin-top: 24px;
//        text-align: right;
//    }
//}
//
//
//// 992
//@include media-breakpoint-down(md) {
//    .footer {
//
//        &__links {
//
//            &-container {
//                flex-direction: column;
//                min-width: 190px;
//            }
//
//            &-item {
//
//                & + & {
//                    margin: 24px 0 0;
//                }
//            }
//        }
//    }
//}
//
//// 768
//@include media-breakpoint-down(xs) {
//    .footer {
//
//        &__container {
//            flex-direction: column;
//        }
//
//        &__links {
//            margin-top: 34px;
//        }
//    }
//}
