.progress-bar {
    &__position {
        margin-bottom: 16px;
    }

    &__steps {
        display: flex;
        justify-content: space-between;
        border-radius: 4px;
        overflow: hidden;
    }

    &__steps:first-child {
        border-bottom-left-radius: 2px;
        border-top-left-radius: 2px;
    }

    &__steps:last-child {
        border-bottom-right-radius: 2px;
        border-top-right-radius: 2px;
    }

    &__step-element {
        height: 6px;
        width: 100%;
    }

    &__step-element.isColored {
        background-color: $accent;
    }

}
