.blacklist {
    &__header {
        padding: 100px 0 24px 0;
        background-color: $blue-dark;
    }

    &__heading {
        margin-bottom: 24px;
        text-align: center;
        @include new-text(36px, 600, 54px, none, none, $white);

        @media (max-width: 768px) {
            @include new-text(30px, 600, 48px, none, none, $white);
        }

        @media (max-width: 575px) {
            @include new-text(22px, 600, 28px, none, none, $white);
        }
    }

    &__main {
        padding: 40px 0;
    }

    &__main .preloader {
        background: linear-gradient(to right, #000 10%, rgba(255, 255, 255, 0) 42%);
    }

    &__main .preloader::before {
        background-color: $blue-dark;
    }

    &__main .preloader::after {
        background: linear-gradient(89.9deg, #ffffff -2.01%, #ffffff 30.82%);
    }

    // CONTROL PANEL:
    &__control-panel {
        position: relative;
    }

    &__control-panel-action-bar {
        max-width: 920px;
        width: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    &__control-panel-action-bar.isAdmin {
        display: flex;
        justify-content: space-between;
    }

    &__control-panel-action-bar .custom-button__value,
    &__control-panel-tooltip-value {
        padding: 12px;
        display: flex;
        align-items: center;
    }

    &__control-panel-tooltip-value {
        cursor: pointer;
    }

    &__control-panel-label {
        margin-left: 8px;
        @include new-text(14px, 500, 100%, none, none, $white);
    }

    &__control-panel .search {
        margin: 0 auto;
    }

    @media (max-width: 575px) {
        &__control-panel .search__input {
            padding: 12px 42px 12px 12px;
            font-size: 14px;
        }

        &__control-panel .search__input::placeholder {
            @include new-text(14px, 500, 16px, none, none, $gray-middle-02);
        }

        &__control-panel .search__button {
            width: 41.5px;
            height: 41.5px;
            top: 1.5px;
            right: 1.5px;
        }

        &__control-panel .search__clear {
            top: 8px;
            right: 50px;
        }
    }

    @media (max-width: 425px) {
        &__control-panel .search__input {
            padding: 12px 42px 12px 12px;
            font-size: 12px;
        }

        &__control-panel .search__input::placeholder {
            @include new-text(12px, 500, 14px, none, none, $gray-middle-02);
        }
    }

    & .custom-tooltip__tip {
        @media (max-width: 425px) {
            width: 356px !important;
        }

        @media (max-width: 375px) {
            width: 346px !important;
        }
    }

    // COMPANIES LIST:
    &__companies-list {
        margin-bottom: 24px;
    }

    &__companies-list-notification {
        text-align: center;
        @include new-text(16px, 400, 22px, none, none, $blue-dark);
    }

    // COMPANY CARD:
    &__company-card {
        padding: 32px;
        display: flex;
        border-radius: 16px;
        box-shadow: $box-shadow;

        @media (max-width: 1024px) {
            padding: 20px;
            flex-direction: column;
        }
    }

    &__company-card:not(:last-child) {
        margin-bottom: 8px;
    }

    &__company-card-logo-box {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 36px;

        @media (max-width: 1024px) {
            margin-right: 0;
        }
    }

    &__company-card-logo {
        width: 120px;
        height: 120px;
        border-radius: 50%;
        box-shadow: $box-shadow;
        object-fit: contain;
        padding: 3px;

        @media (max-width: 768px) {
            margin-bottom: 32px;
        }
    }

    &__company-card-info-box {
        width: 100%;
    }

    &__company-card-name {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;
        @include new-text(16px, 600, 26px, none, none, $blue-dark);
    }

    &__company-card-name span:first-child {
        margin-right: 8px;
    }

    &__company-card-actions-icon {
        padding: 8px;
        cursor: pointer;
        border-radius: 6px;
        background-color: $gray-disabled;
    }

    &__company-card-actions-icon:not(:last-child) {
        margin-right: 4px;
    }

    &__company-card-sections {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: 15px;

        @media (max-width: 768px) {
            flex-direction: column;
        }
    }

    &__company-card-section:nth-child(1) {
        width: 35%;
    }

    &__company-card-section:nth-child(2) {
        width: 20%;
    }

    &__company-card-section:nth-child(3) {
        width: 35%;
    }

    &__company-card-section:nth-child(1),
    &__company-card-section:nth-child(2),
    &__company-card-section:nth-child(3) {
        @media (max-width: 768px) {
            width: 100%;
        }
    }

    &__company-card-line {
        display: flex;
        align-items: flex-start;
        @include new-text(14px, 400, 22px, none, none, $blue-dark);
    }

    &__company-card-line-description {
        height: 65px;
        width: 100%;
        outline: none;
        resize: none;
        border: none;
        scroll-behavior: auto;
        font-family: Montserrat, sans-serif;
        @include new-text(14px, 400, 22px, none, none, $blue-dark);
    }

    &__company-card-line-description::-webkit-scrollbar {
        width: 5px;
        border: 3px;
        -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.3);
        border-radius:10px;
        -webkit-border-radius: 10px;
    }

    &__company-card-line-description::-webkit-scrollbar-track {
        background-color: $gray-middle;
        border-radius: 3px;
    }

    &__company-card-line-description::-webkit-scrollbar-thumb {
        border-radius:10px;
        background-color: $gray-middle-02;
    }

    &__company-card-files-list {
        display: flex;
    }

    &__company-card-line.files {
        margin-top: 4px;
        align-items: flex-start;
    }

    &__company-card-line-icon {
        margin-right: 8px;
        padding-top: 4px;
    }

    &__company-card-file-icon {
        width: 32px;
        height: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 3px;
        background-color: $gray-background;
    }

    &__company-card-file-icon:not(:last-child) {
        margin-right: 4px;
    }

    // FILTER
    &__filter {
        width: 346px;
        position: absolute;
        top: 100px;
        right: 0;
        padding: 16px;
        border-radius: 6px;
        z-index: 10;
        background-color: $white;
        box-shadow: $box-shadow;
        transition: $transition;
        opacity: 0;
        visibility: hidden;
    }

    &__filter.is-visible {
        opacity: 1;
        visibility: visible;
    }

    &__filter-form-section {
        margin-bottom: 16px;
    }

    &__filter-actions {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    // FORM
    &__form {
        position: relative;
        padding: 32px;
        border-radius: 16px;
        box-shadow: $box-shadow;
        transition: $transition;
        display: none;
    }

    &__form.is-visible {
        display: block;
        z-index: 5;
    }

    &__form-section:first-child {
        margin-bottom: 24px;
    }

    &__form-fields-box {
        margin-bottom: 12px;
        display: flex;
        justify-content: space-between;

        @media (max-width: 768px) {
            display: block;
        }
    }

    &__form-fields {
        width: 30%;

        @media (max-width: 768px) {
            width: 100%;
        }
    }

    &__form-fields .new-input {
        width: 100%;
        margin-bottom: 16px;
    }

    &__form-fields .new-select,
    &__form-fields .new-select-country,
    &__form-fields .select-city,
    &__form-fields .company-autocomplete {
        width: 100%;
        margin-bottom: 16px;
    }

    // FORM: FILES
    &__files-list-item {
        display: flex;
        align-items: center;

        @media (max-width: 768px) {
            justify-content: space-between;
        }
    }

    &__files-list-item-section {
        display: flex;
        align-items: center;
    }

    &__files-list-item-section:nth-child(1) {
        max-width: 545px;
        width: 100%;
    }

    &__files-list-item-section:nth-child(2) {
        max-width: 200px;
        width: 100%;

        @media (max-width: 768px) {
            max-width: 30px;
            width: 100%;
        }
    }

    &__file-icon {
        width: 50px;
        height: 50px;
        margin-right: 12px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }

    &__file-icon.icon-0 {
        background-image: url("/images/pages/Resources/Blacklist/card-item_icon-file-0.svg");
    }

    &__file-icon.icon-1 {
        background-image: url("/images/pages/Resources/Blacklist/card-item_icon-file-1.svg");
    }

    &__file-icon.icon-2 {
        background-image: url("/images/pages/Resources/Blacklist/card-item_icon-file-2.svg");
    }

    &__file-remove {
        width: 18px;
        height: 18px;
        cursor: pointer;
    }

    // FORM: ACTIONS
    &__form-actions {
        display: flex;
        align-items: center;
    }

    &__form-actions .custom-button__value {
        font-weight: 600;
    }

    & .file__input-box {
        margin-top: 32px;
    }
}
