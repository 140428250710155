/* react-transition-group */

// fade
.fade-animation-enter {
  opacity: 0;
}
.fade-animation-enter-active {
  opacity: 1;
}
.fade-animation-exit {
  opacity: 1;
}
.fade-animation-exit-active {
  opacity: 0;
}
.fade-animation-exit-active,
.fade-animation-enter-active {
  transition: opacity 300ms ease;
}

// scale
.scale-animation-enter {
  opacity: 0;
  transform: translate3d(0, 0, 0) scale(0.9);
}
.scale-animation-enter-active {
  opacity: 1;
  transform: translate3d(0, 0, 0) scale(1);
}
.scale-animation-exit {
  opacity: 1;
  transform: translate3d(0, 0, 0) scale(1);
}
.scale-animation-exit-active {
  opacity: 0;
  transform: translate3d(0, 0, 0) scale(0.9);
}
.scale-animation-exit-active,
.scale-animation-enter-active {
  transition: opacity 300ms ease, transform 300ms ease;
}

// fadeIn right fadeOut right
.fadeRight-animation-enter {
  opacity: 0;
  transform: translate3d(-5%, 0, 0);
}
.fadeRight-animation-enter-active {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}
.fadeRight-animation-exit {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}
.fadeRight-animation-exit-active {
  opacity: 0;
  transform: translate3d(5%, 0, 0);
}
.fadeRight-animation-exit-active,
.fadeRight-animation-enter-active {
  transition: opacity 300ms ease, transform 300ms ease;
}

// fadeIn left fadeOut left
.fadeLeft-animation-enter {
  opacity: 0;
  transform: translate3d(5%, 0, 0);
}
.fadeLeft-animation-enter-active {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}
.fadeLeft-animation-exit {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}
.fadeLeft-animation-exit-active {
  opacity: 0;
  transform: translate3d(-5%, 0, 0);
}
.fadeLeft-animation-exit-active,
.fadeLeft-animation-enter-active {
  transition: opacity 300ms ease, transform 300ms ease;
}

// fadeIn right fadeOut left
.fadeMixRight-animation-enter {
  opacity: 0;
  visibility: hidden;
  transform: translate3d(5%, 0, 0);
}
.fadeMixRight-animation-enter-active {
  opacity: 1;
  visibility: visible;
  transform: translate3d(0, 0, 0);
}
.fadeMixRight-animation-exit {
  opacity: 1;
  visibility: visible;
  transform: translate3d(0, 0, 0);
}
.fadeMixRight-animation-exit-active {
  opacity: 0;
  visibility: hidden;
  transform: translate3d(5%, 0, 0);
}
.fadeMixRight-animation-exit-active,
.fadeMixRight-animation-enter-active {
  transition: opacity 300ms ease, transform 300ms ease;
}

// fadeIn left fadeOut right
.fadeMixLeft-animation-enter {
  opacity: 0;
  transform: translate3d(-5%, 0, 0);
}
.fadeMixLeft-animation-enter-active {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}
.fadeMixLeft-animation-exit {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}
.fadeMixLeft-animation-exit-active {
  opacity: 0;
  transform: translate3d(-5%, 0, 0);
}
.fadeMixLeft-animation-exit-active,
.fadeMixLeft-animation-enter-active {
  transition: opacity 300ms ease, transform 300ms ease;
}

// fadeIn top fadeOut bottom
.fadeMixTop-animation-enter {
  opacity: 0;
  transform: translate3d(0, -10%, 0);
}
.fadeMixTop-animation-enter-active {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}
.fadeMixTop-animation-exit {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}
.fadeMixTop-animation-exit-active {
  opacity: 0;
  transform: translate3d(0, -10%, 0);
}
.fadeMixTop-animation-exit-active,
.fadeMixTop-animation-enter-active {
  transition: opacity 300ms ease, transform 300ms ease;
}
