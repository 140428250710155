:root {
  --font-family: "Montserrat", sans-serif;
  --text-cl: #000c37;
  --white-cl: #fff;
  --bg-cl: #030a27;
  --blue-cl: #08f;
  --transition: all 300ms ease;
}

$breakpoints: (
        mobile: 375px,
        tablet: 768px,
        desktop: 1170px,
        desktop-m: 1240px,
        desktop-l: 1546px,
);

@mixin for-size($range) {
  $mobile: map-get($breakpoints, mobile);
  $tablet: map-get($breakpoints, tablet);
  $desktop: map-get($breakpoints, desktop);
  $desktop-m: map-get($breakpoints, desktop-m);
  $desktop-l: map-get($breakpoints, desktop-l);

  @if $range == mobile-only {
    @media screen and (max-width: #{$tablet - 1}) {
      @content;
    }
  } @else if $range == mobile {
    @media screen and (min-width: $mobile) {
      @content;
    }
  } @else if $range == tablet {
    @media screen and (min-width: $tablet) {
      @content;
    }
  } @else if $range == desktop {
    @media screen and (min-width: $desktop) {
      @content;
    }
  } @else if $range == desktop-m {
    @media screen and (min-width: $desktop-m) {
      @content;
    }
  } @else if $range == desktop-l {
    @media screen and (min-width: $desktop-l) {
      @content;
    }
  }
}

.demurrage-and-detention {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 16px;
  line-height: 162%;
  color: var(--white-cl);
  background-color: var(--bg-cl);

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  img {
    display: block;
    max-width: 100%;
    height: auto;
  }

  h1,
  h2,
  h3,
  h4,
  p {
    margin: 0;
  }

  a {
    text-decoration: none;
  }

  strong {
    font-weight: 400;
  }

  a,
  button,
  input {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  button {
    cursor: pointer;
  }

  .container {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 16px;
    padding-right: 16px;
  }

  .visually-hidden {
    position: absolute;
    clip: rect(1px 1px 1px 1px);
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0;
    border: 0;
    height: 1px;
    width: 1px;
    overflow: hidden;
    transition: all 250ms ease;
  }

  .section-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 11px 32px;
    width: 100%;
    font-weight: 600;
    font-size: 16px;
    line-height: 156%;
    color: #fff;
    color: var(--white-cl);
    border-radius: 6px;
    background-color: var(--text-cl);
  }

  .section-link-btn {
    display: flex;
    align-items: center;
    gap: 9px;
    padding: 0;
    width: 100%;
    font-weight: 600;
    font-size: 16px;
    line-height: 162%;
    text-align: right;
    color: var(--blue-cl);
  }

  .section-title {
    margin-bottom: 24px;
    font-weight: 600;
    font-size: 22px;
    line-height: 145%;
    text-align: center;
  }

  .section-img {
    width: 100%;
  }

  .section {
    .text {
      .bold {
        font-weight: 600;
      }
    }

    .underline {
      text-decoration: underline;
    }
  }

  .demurrage-and-detention-slider {
    max-width: 100%;

    .swiper-wrapper {
      @media (max-width: 375px) {
        .swiper-slide {
          height: auto !important;
        }
      }
    }
  }

  th, td {
    vertical-align: middle;
  }

  table {
    border-collapse: separate;
  }

  a {
    color: inherit;
  }

  .section.hero {
    padding: 108px 0 120px 0;
    overflow: hidden;

    .container {
      position: relative;
      z-index: 2;

      &::after {
        position: absolute;
        top: 20%;
        right: -80%;
        content: "";
        display: block;
        width: 100%;
        height: 200px;
        background-image: linear-gradient(
                        180deg,
                        #2400ff 0%,
                        rgba(0, 194, 255, 0) 100%
        );
        filter: blur(230px);
        z-index: -1;
      }
    }

    .img-container {
      margin-bottom: 40px;
    }

    .main-title {
      margin-bottom: 24px;
      font-weight: 600;
      font-size: 24px;
      line-height: 142%;
    }

    .text-box {
      .text {
        &:not(:last-child) {
          margin-bottom: 17px;
        }
      }
    }
  }

  .section.charges {
    padding: 0 0 122px 0;

    .section-title {
      margin: 0 auto 32px auto;
      max-width: 280px;
    }

    .subtitle {
      margin-bottom: 60px;
    }

    .text-box {
      .box-item {
        &:not(:last-child) {
          margin-bottom: 58px;
        }
      }

      .title {
        margin-bottom: 8px;
        font-weight: 700;
        font-size: 18px;
        line-height: 156%;
      }

      .text {
        &:not(:last-child) {
          margin-bottom: 26px;
        }
      }
    }
  }

  .section.understanding {
    padding: 0 0 114px 0;

    .table-container {
      margin-bottom: 45px;
      padding: 0 10px;
    }

    .section-title {
      margin: 0 auto 48px auto;
      max-width: 300px;
    }

    .text-box {
      margin-bottom: 50px;

      .text {
        &:not(:last-child) {
          margin-bottom: 28px;
        }
      }
    }

    .table {
      margin: 0 auto;
      border-spacing: 3.5px;

      .table-title,
      .table-head,
      .table-data {
        font-weight: 600;
        font-size: 11.5px;
        line-height: 167%;
        text-align: center;
        background-color: rgba(255, 255, 255, 0.04);
        border: 0.45px solid rgba(255, 255, 255, 0.04);
        border-radius: 11px;
      }

      .table-title {
        padding: 10px 14px;
        text-align: left;
      }

      .table-head {
        &:nth-child(1) {
          padding: 11px 21px 11px 17px;
          width: 100px;
        }

        &:nth-child(2) {
          padding: 11px;
          width: 103px;
        }

        &:last-child {
          padding: 10px 15px;
          text-align: left;
          width: 115px;
        }
      }

      .table-data {
        height: 32.5px;
        font-weight: 400;
      }
    }
  }

  .section.factors {
    position: relative;
    padding: 0 0 120px 0;

    &::after {
      position: absolute;
      top: 15%;
      right: 0;
      content: "";
      display: block;
      width: 100%;
      height: 200px;
      background-image: linear-gradient(
                      180deg,
                      #2400ff 0%,
                      rgba(0, 194, 255, 0) 100%
      );
      filter: blur(230px);
      z-index: 1;
    }

    .container {
      position: relative;
      z-index: 2;
    }

    .section-title {
      margin: 0 auto 24px auto;
      max-width: 260px;
    }

    .subtitle {
      margin: 0 auto 32px auto;
      text-align: center;
    }

    .title {
      margin-bottom: 16px;
      font-weight: 600;
      font-size: 20px;
      line-height: 150%;
    }

    .text {
      margin-bottom: 32px;
    }

    .benefits-list {
      .item {
        &::before {
          content: "";
          display: block;
          margin-bottom: 15px;
          width: 40px;
          height: 40px;
          backdrop-filter: blur(71px);
          background-color: rgba(255, 255, 255, 0.04);
          background-image: url(/images/pages/Resources/DemurrageAndDetention/mobile/icons/doc.png);
          background-repeat: no-repeat;
          background-size: 15px;
          background-position: center;
          border: 1px solid rgba(255, 255, 255, 0.02);
          border-radius: 8px;

          @media screen and (min-device-pixel-ratio: 2),
          screen and (min-resolution: 192dpi),
          screen and (min-resolution: 2dppx) {
            background-image: url(/images/pages/Resources/DemurrageAndDetention/mobile/icons/doc@2x.png);
          }
        }

        &:not(:last-child) {
          margin-bottom: 24px;
        }

        &:last-child {
          .item-title {
            font-size: 20px;
            line-height: 150%;
          }
        }
      }

      .item-title {
        margin-bottom: 8px;
        font-weight: 600;
        font-size: 18px;
        line-height: 156%;
      }
    }
  }

  .section.strategies {
    position: relative;
    padding: 0 0 120px 0;

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      content: "";
      display: block;
      width: 100%;
      height: 150px;
      background-image: linear-gradient(
                      180deg,
                      #2400ff 0%,
                      rgba(0, 194, 255, 0) 100%
      );
      filter: blur(230px);
      z-index: 1;
    }

    .container {
      position: relative;
      z-index: 2;
    }

    .section-title {
      margin: 0 auto 24px auto;
      max-width: 328px;
    }

    .subtitle {
      margin: 0 auto 48px auto;
      max-width: 328px;
      text-align: center;
    }

    .benefits-list {
      .item {
        &:not(:last-child) {
          margin-bottom: 25px;
        }
      }
      .title {
        margin-bottom: 8px;
        font-weight: 600;
      }
    }
  }

  .section.minimise {
    padding: 0 0 120px 0;

    .section-title {
      margin-bottom: 32px;
    }

    .text-box-top {
      margin-bottom: 31px;

      .text {
        &:not(:last-child) {
          margin-bottom: 27px;
        }
      }
    }

    .text-box-bottom {
      margin-top: 33px;

      .text {
        &:not(:last-child) {
          margin-bottom: 26px;
        }
      }
    }
  }

  .section.features {
    position: relative;
    padding: 0 0 120px 0;

    &::after {
      position: absolute;
      top: 50%;
      right: 0;
      content: "";
      display: block;
      width: 100%;
      height: 150px;
      background-image: linear-gradient(
                      180deg,
                      #2400ff 0%,
                      rgba(0, 194, 255, 0) 100%
      );
      filter: blur(230px);
      z-index: 1;
    }

    .container {
      position: relative;
      z-index: 2;
    }

    .list {
      .item {
        &:not(:last-child) {
          margin-bottom: 42px;
        }

        &:nth-child(3) {
          margin-bottom: 32px;
        }

        &:nth-child(4) {
          margin-bottom: 33px;
          .title {
            font-size: 18px;
            line-height: 156%;
          }

          .text {
            &:not(:last-child) {
              margin-bottom: 27px;
            }
          }
        }
      }

      .title {
        margin-bottom: 8px;
        font-weight: 600;
        font-size: 20px;
        line-height: 150%;
      }
    }
  }

  .section.key {
    padding: 0 0 122px 0;

    .section-title {
      margin: 0 auto 31px auto;
      max-width: 280px;
    }

    .benefits-list {
      .item {
        &::before {
          content: "";
          display: block;
          margin-bottom: 8px;
          width: 40px;
          height: 40px;
          backdrop-filter: blur(71px);
          background-color: rgba(255, 255, 255, 0.04);
          background-image: url(/images/pages/Resources/DemurrageAndDetention/mobile/icons/doc.png);
          background-repeat: no-repeat;
          background-size: 15px;
          background-position: center;
          border: 1px solid rgba(255, 255, 255, 0.02);
          border-radius: 8px;

          @media screen and (min-device-pixel-ratio: 2),
          screen and (min-resolution: 192dpi),
          screen and (min-resolution: 2dppx) {
            background-image: url(/images/pages/Resources/DemurrageAndDetention/mobile/icons/doc@2x.png);
          }
        }

        &:not(:last-child) {
          margin-bottom: 31px;
        }
      }

      .item-title {
        margin-bottom: 8px;
        font-weight: 600;
        font-size: 18px;
        line-height: 156%;
      }
    }
  }

  .section.impact {
    padding: 0 0 120px 0;

    .section-title {
      margin-bottom: 32px;
      text-align: left;
    }

    .text-box {
      .text {
        &:not(:last-child) {
          margin-bottom: 24px;
        }
      }
    }
  }

  .section.key-osra {
    padding: 0 0 120px 0;

    .section-title {
      margin: 0 auto 32px auto;
      max-width: 300px;
    }

    .benefits-list {
      margin-bottom: 34px;
      padding-left: 4px;
      counter-reset: item;
      list-style: none;

      .benefits-item {
        counter-increment: item;

        &:not(:last-child) {
          margin-bottom: 26px;
        }

        &:nth-child(1) {
          .item-title {
            gap: 10px;
          }
        }
      }

      .item-title {
        display: flex;
        gap: 5px;
        font-weight: 600;

        &::before {
          content: counter(item) ". ";
          font-weight: bold;
        }
      }
      .list {
        padding-left: 20px;
        list-style: disc;
      }
    }
  }

  .section.trucking {
    padding: 0 0 80px 0;

    .section-title {
      margin: 0 auto 54px auto;
      max-width: 328px;
    }

    .text-box {
      .text {
        &:not(:last-child) {
          margin-bottom: 29px;
        }

        .list {
          margin-top: 15px;
          padding-left: 20px;
          list-style: disc;
        }
      }
    }
  }

  .section.sign-up {
    padding: 120px 0 80px 0;

    .box {
      padding: 24px 16px 15px 16px;
      background-color: rgba(255, 255, 255, 0.08);
      border: 1px solid rgba(255, 255, 255, 0.23);
      border-radius: 24px;
    }

    .submit-box {
      margin-bottom: 32px;

      .title {
        display: block;
        margin-bottom: 16px;
        font-weight: 600;
        font-size: 24px;
        line-height: 142%;
      }

      .text {
        margin-bottom: 25px;
        width: 90%;
      }

      .section-btn {
        background-color: var(--blue-cl);
      }
    }

    .info-box {
      padding: 24px;
      background-color: var(--blue-cl);
      border-radius: 16px;

      .title {
        display: block;
        margin: 24px 0;
        font-weight: 600;
        font-size: 22px;
        line-height: 145%;
      }
    }
  }

  .section.faq {
    padding: 120px 0 80px 0;

    .section-title {
      margin: 0 auto 31px auto;
      max-width: 280px;
    }

    .faq-list {
      .item {
        padding: 16px 6px 16px 24px;
        border: 1px solid rgba(255, 255, 255, 0.06);
        border-radius: 16px;
        background-color: rgba(255, 255, 255, 0.08);
        transition: var(--transition);
        overflow: hidden;
        cursor: pointer;
        margin-bottom: 14px;

        &:not(:last-child) {
          margin-bottom: 6px;
        }
      }

      .question-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .question {
          font-family: var(--font-family);
          font-weight: 600;
          font-size: 18px;
          line-height: 156%;
          text-align: left;
          color: var(--white-cl);
        }

        .faq-btn {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 2px;
          transform: rotate(-45deg);
          transition: var(--transition);

          .icon {
            width: 20px;
            height: 20px;
            fill: var(--text-cl);
          }

          &.is-close {
            transform: rotate(0deg);

            .icon {
              fill: #c9cfdb;
            }
          }
        }
      }

      .answer-box {
        max-height: 0;
        opacity: 0;
        transition: all 0.3s;

        &.is-open {
          max-height: 500px;
          opacity: 1;
          transition: all 0.3s;
          margin-top: 8px;
        }

        .answer {
        }
      }
    }
  }

  .section.cta {
    padding: 40px 0 80px 0;

    .box {
      padding: 53px 24px 24px 24px;
      background-color: rgba(255, 255, 255, 0.06);
      background-image: url(/images/pages/Resources/DemurrageAndDetention/mobile/cta-bg.png);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center top;
      border: 1px solid rgba(255, 255, 255, 0.06);
      border-radius: 24px;

      @media screen and (min-device-pixel-ratio: 2),
      screen and (min-resolution: 192dpi),
      screen and (min-resolution: 2dppx) {
        background-image: url(/images/pages/Resources/DemurrageAndDetention/mobile/cta-bg@2x.png);
      }
    }

    .section-title {
      margin-bottom: 16px;
    }

    .text {
      margin-bottom: 37px;
      text-align: center;
    }

    .section-btn {
      padding: 15px;
      color: var(--text-cl);
      background-color: var(--white-cl);
    }
  }

  .section.blog {
    padding: 38px 0 160px 0;

    .box {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      margin-bottom: 24px;

      .section-title {
        margin: 0;
      }

      .more-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 11px 24px;
        font-weight: 600;
        font-size: 16px;
        line-height: 162%;
        color: var(--white-cl);
        background-color: var(--text-cl);
        border-radius: 10px;
      }
    }

    .blog-list {
      .item {
        background-color: rgba(255, 255, 255, 0.06);
        border: 1px solid rgba(255, 255, 255, 0.06);
        border-radius: 24px;
        overflow: hidden;

        &:not(:last-child) {
          margin-bottom: 15px;
        }
      }

      .item-link {
        text-decoration: none;
      }

      .img-box {
        height: 183px;

        .blog-img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center top;
        }
      }

      .content-box {
        padding: 24px 24px 28px 24px;

        .info-box {
          display: flex;
          align-items: center;
          gap: 16px;
          margin-bottom: 16px;

          .date,
          .rubric {
            font-weight: 500;
            font-size: 12px;
            line-height: 167%;
            color: var(--white-cl);
          }
        }

        .title {
          font-weight: 600;
          font-size: 18px;
          line-height: 156%;
          color: var(--white-cl);
        }
      }
    }
  }

  @include for-size(mobile) {
    .section.understanding {
      .text-box {
        margin-bottom: 52px;

        .text {
          &:not(:last-child) {
            margin-bottom: 25px;
          }
        }
      }

      .table-container {
        margin-bottom: 47px;
      }

      .table {
        .table-title,
        .table-head,
        .table-data {
          font-size: 12px;
        }

        .table-title {
          padding: 9px 15px;
        }

        .table-head {
          height: 145px;

          &:nth-child(1) {
            width: 106px;
          }

          &:nth-child(2) {
            width: 106px;
          }

          &:last-child {
            width: 121px;
          }
        }

        .table-data {
          height: 34px;
        }
      }
    }

    .section.factors {
      padding: 0 0 121px 0;

      .section-title {
        margin: 0 auto 25px auto;
        max-width: 345px;
      }

      .subtitle {
        margin: 0 auto 30px auto;
        max-width: 333px;
      }
    }

    .section.strategies {
      padding: 0 0 121px 0;

      .benefits-list {
        .item {
          &:nth-child(2) {
            .text {
              width: 95%;
            }
          }

          &:nth-child(4) {
            .text {
              width: 98.5%;
            }
          }
        }
      }
    }

    .section.minimise {
      .text-box-top {
        .text {
          &:not(:last-child) {
            margin-bottom: 26px;
          }
        }
      }
    }

    .section.features {
      .list {
        .item {
          &:not(:last-child) {
            margin-bottom: 41px;
          }

          &:nth-child(3) {
            margin-bottom: 34px;
            .text {
              width: 98%;
            }
          }

          &:nth-child(4) {
            margin-bottom: 33px;
          }
        }
      }
    }

    .section.key {
      .section-title {
        margin: 0 auto 31px auto;
        max-width: 350px;
      }
    }

    .section.sign-up {
      .submit-box {
        .text {
          width: 100%;
        }
      }

      .info-box {
        .title {
          margin: 24px auto 24px 0;
          max-width: 250px;
        }
      }
    }

    .section.faq {
      padding: 119px 0 102px 0;

      .section-title {
        margin: 0 auto 32px auto;
      }

      .faq-list {
        .item {
          padding: 16px 21px 16px 24px;
        }
      }
    }

    .section.cta {
      padding: 0 0 80px 0;

      .box {
        padding: 45px 32px 32px 32px;
        background-image: url(/images/pages/Resources/DemurrageAndDetention/mobile-m/cta-bg.png);

        @media screen and (min-device-pixel-ratio: 2),
        screen and (min-resolution: 192dpi),
        screen and (min-resolution: 2dppx) {
          background-image: url(/images/pages/Resources/DemurrageAndDetention/mobile-m/cta-bg@2x.png);
        }

        .text {
          margin-bottom: 36px;
        }
      }
    }

    .section.blog {
      padding: 39px 0 80px 0;
    }
  }

  @include for-size(tablet) {
    .section-btn {
      display: inline-flex;
      padding: 11px 24px;
      width: auto;
      text-wrap: nowrap;
      font-size: 16px;
    }

    .section-link-btn {
      width: auto;
      text-wrap: nowrap;
      font-size: 14px;
      line-height: 157%;
    }

    .section-title {
      margin-bottom: 48px;
      font-size: 32px;
      line-height: 131%;
    }

    .section.hero {
      padding: 180px 0 160px 0;
      overflow: visible;

      .container {
        &::after {
          top: -10%;
          right: 0;
          width: 70%;
          height: 250px;
          filter: blur(280px);
        }
      }

      .img-container {
        margin: 0 auto;
        width: 96%;
      }

      .main-title {
        margin-bottom: 40px;
        font-size: 44px;
        line-height: 123%;
        text-align: center;
      }

      .text-box {
        display: flex;
        gap: 40px;
        flex-wrap: wrap;

        .text {
          flex-basis: calc((100% - 40px * 1) / 2);

          &:not(:last-child) {
            margin-bottom: 0;
          }
        }
      }
    }

    .section.charges {
      padding: 0 0 122px 0;

      .section-title {
        margin: 0 auto 50px auto;
        max-width: 780px;
      }

      .subtitle {
        margin-bottom: 56px;
      }

      .text-box {
        display: flex;
        gap: 56px;

        .box-item {
          flex-basis: calc((100% - 56px * 1) / 2);
          &:not(:last-child) {
            margin-bottom: 0;
          }
        }

        .title {
          font-size: 20px;
          line-height: 150%;
        }
      }
    }

    .section.understanding {
      padding: 39px 0 160px 0;

      .section-title {
        max-width: 620px;
        font-size: 36px;
        line-height: 133%;
      }

      .text-box {
        margin-bottom: 50px;

        .text {
          &:not(:last-child) {
            margin-bottom: 28px;
          }
        }
      }

      .table-container {
        margin-bottom: 43px;
      }

      .table {
        border-spacing: 8px;
        border-collapse: separate;

        td {
          vertical-align: middle;
        }

        .table-title {
          padding: 23px 30px;
          margin: 0 auto;
          width: 98%;
        }

        .table-title,
        .table-head {
          font-size: 20px;
          line-height: 150%;
        }

        .table-title,
        .table-head,
        .table-data {
          border-radius: 24px;
        }

        .table-head {
          height: 165px;

          &:nth-child(1) {
            width: 229px;
          }

          &:nth-child(2) {
            width: 229px;
          }

          &:last-child {
            padding: 10px 32px;
            width: 262px;
          }
        }

        .table-data {
          height: 72px;
          font-size: 16px;
          line-height: 162%;

          &:last-child {
            padding-top: 7px;
          }
        }
      }
    }

    .section.factors {
      padding: 0 0 160px 0;

      &::after {
        height: 170px;
      }

      .section-title {
        max-width: 650px;
      }

      .subtitle {
        margin: 0 auto 47px auto;
        max-width: 460px;
      }

      .benefits-list {
        display: flex;
        gap: 40px 26px;
        flex-wrap: wrap;

        .item {
          width: 48%;

          &:not(:last-child) {
            margin-bottom: 0;
          }
        }

        .item-title {
          font-size: 20px;
          line-height: 150%;
        }
      }
    }

    .section.strategies {
      padding: 0 0 159px 0;

      &::after {
        top: 70%;
      }

      .section-title {
        max-width: 728px;
      }

      .subtitle {
        max-width: 635px;
      }

      .benefits-list {
        .item {
          &:nth-child(4) {
            .text {
              width: 86.5%;
            }
          }
        }
      }
    }

    .section.minimise {
      padding: 0 0 161px 0;

      .section-title {
        margin-bottom: 48px;
      }

      .text-box-top {
        margin-bottom: 47px;

        .text {
          &:not(:last-child) {
            margin-bottom: 26px;
          }
        }
      }

      .text-box-bottom {
        margin-top: 48px;
        column-count: 2;
        column-gap: 64px;

        .text {
          &:not(:last-child) {
            margin-bottom: 0;
          }
        }
      }
    }

    .section.features {
      padding: 0 0 160px 0;

      .list {
        .item {
          &:nth-child(3) {
            margin-bottom: 41px;
          }

          &:nth-child(4) {
            margin-bottom: 40px;

            .title {
              font-size: 20px;
              line-height: 150%;
            }
          }
        }
      }
    }

    .section.key {
      padding: 0 0 160px 0;

      .section-title {
        margin: 0 auto 55px auto;
        max-width: 700px;
      }

      .benefits-list {
        display: flex;
        flex-wrap: wrap;
        gap: 39px 64px;

        .item {
          width: 45.5%;

          &::before {
            margin-bottom: 16px;
          }

          &:not(:last-child) {
            margin-bottom: 0;
          }
        }

        .item-title {
          font-size: 20px;
          line-height: 150%;
        }
      }
    }

    .section.impact {
      padding: 0 0 160px 0;

      .section-title {
        margin-bottom: 48px;
        text-align: center;
      }

      .text-box {
        column-count: 2;
        column-gap: 56px;

        .text {
          &:not(:last-child) {
            margin-bottom: 0;
          }
        }
      }
    }

    .section.key-osra {
      .section-title {
        margin: 0 auto 47px auto;
        max-width: 520px;
      }
    }

    .section.trucking {
      padding: 40px 0 80px 0;

      .section-title {
        margin: 0 auto 55px auto;
        max-width: 460px;
      }

      .text-box {
        .text {
          &:not(:last-child) {
            margin-bottom: 29px;
          }
        }
      }
    }

    .section.sign-up {
      padding: 158px 0 80px 0;

      .box {
        padding: 16px;
      }

      .submit-box {
        padding: 8px 8px 0 8px;
        margin-bottom: 32px;

        .title {
          display: block;
          margin-bottom: 24px;
        }

        .tablet-flex {
          display: flex;
          align-items: flex-end;
          gap: 17px;
        }

        .text {
          margin-bottom: 0;
        }

        .section-btn {
          min-width: 148px;
        }
      }

      .info-box {
        padding: 40px;

        .title {
          margin: 52px auto 32px 0;
          font-size: 32px;
          line-height: 131%;
          max-width: 370px;
        }
      }
    }

    .section.faq {
      padding: 160px 0 144px 0;

      .section-title {
        margin: 0 auto 47px auto;
        max-width: unset;
      }

      .faq-list {
        .item {
          padding: 24px 21px 24px 32px;
          border-radius: 24px;

          &:not(:last-child) {
            margin-bottom: 14px;
          }

          &:nth-child(1) {
            .question-box {
              .faq-btn {
                top: 1px;
              }
            }
          }
        }

        .question-box {
          align-items: baseline;

          .question {
            font-size: 20px;
            line-height: 150%;
          }

          .faq-btn {
            position: relative;
            top: 1px;
          }
        }
      }
    }

    .section.cta {
      padding: 0 0 159px 0;

      .box {
        padding: 63px 64px 64px 64px;
        background-image: url(/images/pages/Resources/DemurrageAndDetention/tablet/cta-bg.png);

        @media screen and (min-device-pixel-ratio: 2),
        screen and (min-resolution: 192dpi),
        screen and (min-resolution: 2dppx) {
          background-image: url(/images/pages/Resources/DemurrageAndDetention/tablet/cta-bg@2x.png);
        }
      }

      .section-title {
        margin-bottom: 13px;
        text-align: left;
      }

      .text {
        max-width: 393px;
        text-align: left;
      }

      .section-btn {
        padding: 11px 24px;
      }
    }

    .section.blog {
      padding: 0 0 158px 0;
      overflow: hidden;

      .box {
        align-items: center;
        margin-bottom: 30px;
      }

      .blog-list {
        display: flex;
        flex-wrap: nowrap;
        gap: 24px;

        .item {
          min-width: 374px;
          height: inherit;

          &:not(:last-child) {
            margin-bottom: 0;
          }
        }

        .content-box {
          padding: 24px;

          .info-box {
            margin-bottom: 18px;
          }

          .title {
            font-size: 20px;
            line-height: 150%;
          }
        }
      }
    }
  }

  @include for-size(desktop) {
    .section-title {
      margin-bottom: 24px;
      font-size: 36px;
      line-height: 133%;
    }

    .section-link-btn {
      font-size: 16px;
      line-height: 162%;
    }

    .section.hero {
      padding: 180px 0 242px 0;

      .container {
        &::after {
          top: -100%;
          width: 70%;
          height: 600px;
          filter: blur(400px);
        }
      }

      .img-container {
        width: 86%;
      }

      .main-title {
        margin-bottom: 42px;
        font-size: 48px;
        line-height: 125%;
      }

      .text-box {
        .text {
          flex-basis: calc((100% - 40px * 2) / 3);
        }
      }
    }

    .section.charges {
      position: relative;

      &::after {
        position: absolute;
        top: 70%;
        left: 50%;
        transform: translateX(-50%);
        content: "";
        display: block;
        width: 40%;
        height: 150px;
        background-image: linear-gradient(
                        180deg,
                        #2400ff 0%,
                        rgba(0, 194, 255, 0) 100%
        );
        filter: blur(300px);
        z-index: 1;
      }

      .container {
        position: relative;
        z-index: 2;
      }

      .section-title {
        margin: 0 auto 55px auto;
      }

      .subtitle {
        margin: 0 auto 57px auto;
        max-width: 800px;
        text-align: center;
      }
    }

    .section.understanding {
      position: relative;
      padding: 118px 0 160px 0;

      .section-title {
        margin: 0 auto 56px auto;
      }

      .text-box {
        margin: 0 auto 81px auto;
        max-width: 800px;
      }

      .table-container {
        margin-bottom: 48px;
      }

      .table {
        .table-title {
          padding: 23px 29px;
        }

        .table-head {
          height: 108px;
          vertical-align: middle;

          &:nth-child(1) {
            width: 229px;
          }

          &:nth-child(2) {
            width: 229px;
          }

          &:last-child {
            padding: 10px 32px;
            width: 431px;
          }
        }

        .table-data {
          &:last-child {
            padding-top: 0;
          }
        }
      }

      .text-box-bottom {
        margin: 0 auto;
        max-width: 800px;
      }
    }

    .section.factors {
      padding: 81px 0 160px 0;

      &::after {
        top: 50%;
        height: 100px;
      }

      .subtitle {
        margin: 0 auto 54px auto;
        max-width: 460px;
      }

      .benefits-list {
        position: relative;
        left: -3px;
        gap: 40px 30px;
        justify-content: center;

        .item {
          width: 31.5%;

          &:not(:last-child) {
            margin-bottom: 0;
          }
        }

        .item-title {
          font-size: 20px;
          line-height: 150%;
        }
      }
    }

    .section.strategies {
      padding: 56px 0 159px 0;

      &::after {
        width: 0;
        height: 0;
        background-image: none;
        filter: unset;
      }

      .subtitle {
        margin: 0 auto 55px auto;
      }

      .benefits-list {
        margin: 0 auto;
        max-width: 800px;

        .item {
          &:nth-child(2) {
            .text {
              width: 93%;
            }
          }

          &:nth-child(4) {
            .text {
              width: 95.5%;
            }
          }
        }
      }
    }

    .section.minimise {
      padding: 80px 0 161px 0;

      .section-title {
        margin: 0 auto 57px auto;
        max-width: 800px;
      }

      .text-box-top {
        margin-bottom: 56px;
        column-count: 2;
        column-gap: 64px;
      }

      .text-box-bottom {
        margin-top: 57px;
        column-gap: 117px;
      }
    }

    .section.features {
      padding: 54px 0 160px 0;

      &::after {
        filter: blur(430px);
      }

      .container {
        max-width: 832px;
      }

      .list {
        .item {
          &:not(:last-child) {
            margin-bottom: 40px;
          }

          &:nth-child(3) {
            margin-bottom: 41px;

            .text {
              width: 100%;
            }
          }
        }
      }
    }

    .section.key {
      padding: 81px 0 160px 0;

      .section-title {
        margin: 0 auto 54px auto;
      }

      .benefits-list {
        display: flex;
        flex-wrap: wrap;
        gap: 66px;

        .item {
          width: 29.5%;
        }
      }
    }

    .section.impact {
      padding: 80px 0 160px 0;

      .section-title {
        margin-bottom: 58px;
      }
    }

    .section.key-osra {
      position: relative;
      padding: 80px 0 120px 0;

      &::after {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        content: "";
        display: block;
        width: 40%;
        height: 200px;
        background-image: linear-gradient(
                        180deg,
                        #2400ff 0%,
                        rgba(0, 194, 255, 0) 100%
        );
        filter: blur(230px);
        z-index: 1;
      }

      .container {
        position: relative;
        max-width: 832px;
        z-index: 2;
      }

      .section-title {
        margin: 0 auto 56px auto;
        max-width: 590px;
      }

      .benefits-list {
        margin-bottom: 65px;

        .benefits-item {
          &:nth-child(1) {
            margin-bottom: 0;
          }
        }
      }
    }

    .section.trucking {
      padding: 120px 0 160px 0;

      .section-title {
        margin: 0 auto 56px auto;
        max-width: 520px;
      }

      .text-box {
        column-count: 2;
        column-gap: 56px;

        .text {
          width: 557px;

          &:not(:last-child) {
            margin-bottom: 0;
          }

          &:nth-child(1) {
            margin-bottom: 26px;
          }

          &:nth-child(2) {
            margin-bottom: 54px;
          }

          &:nth-child(4) {
            margin-bottom: 26px;
          }

          &:nth-child(5) {
            margin-bottom: 27px;
          }

          &:nth-child(6) {
            margin-bottom: 26px;
          }

          .list {
            margin-top: 0;
            padding-left: 24px;
            list-style: disc;
          }
        }
      }
    }

    .section.sign-up {
      padding: 80px 0 161px 0;

      .box {
        display: flex;
        align-items: center;
        gap: 40px;
        padding: 8px 8px 8px 40px;
      }

      .submit-box {
        padding: 0;
        margin-bottom: 0;
        width: 402px;

        .tablet-flex {
          flex-direction: column;
          align-items: flex-start;
          gap: 0;
        }

        .text {
          margin-bottom: 40px;
        }

        .section-btn {
          padding: 11px 30px;
          min-width: unset;
        }
      }

      .info-box {
        padding: 40px 40px 75px 40px;
        width: 680px;

        .title {
          margin: 99px auto 32px 0;
          max-width: 405px;
          font-size: 36px;
          line-height: 133%;
        }
      }
    }

    .section.faq {
      padding: 80px 0 144px 0;

      .section-title {
        margin: 0 auto 55px auto;
      }

      .faq-list {
        margin: 0 auto;
        max-width: 970px;

        .item {
          padding: 24px 30px 24px 32px;

          &:nth-child(7) {
            .faq-btn {
              top: 18px;
            }
          }
        }
      }
    }

    .section.cta {
      padding: 80px 0 162px 0;

      .box {
        padding: 70px 64px 70px 60px;
        background-image: url(/images/pages/Resources/DemurrageAndDetention/desktop/cta-bg.png);

        @media screen and (min-device-pixel-ratio: 2),
        screen and (min-resolution: 192dpi),
        screen and (min-resolution: 2dppx) {
          background-image: url(/images/pages/Resources/DemurrageAndDetention/desktop/cta-bg@2x.png);
        }

        .text {
          margin-bottom: 45px;
          max-width: 600px;
        }
      }

      .section-title {
        margin-bottom: 21px;
      }
    }

    .section.blog {
      padding: 80px 0 239px 0;

      .blog-list {
        .content-box {
          padding: 25px 24px 22px 22px;
        }
      }
    }
  }

  @include for-size(desktop-m) {
    .section.hero {
      .text-box {
        .text {
          position: relative;

          &:nth-child(2) {
            left: 10px;
          }

          &:nth-child(3) {
            left: 22px;
          }
        }
      }
    }
  }

}

@include for-size(tablet) {
  .demurrage-and-detention .container {
    max-width: 992px;
  }
}

@include for-size(desktop) {
  .demurrage-and-detention {
    font-size: 16px;
    line-height: 162%;
  }

  .demurrage-and-detention .container {
    max-width: 1170px;
  }
}

@include for-size(desktop-m) {
  .demurrage-and-detention .container {
    max-width: 1200px;
  }
}
