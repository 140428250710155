.navigation {
    padding: 0;
    width: 30%;
    list-style: none;

    &__item {
        padding: 10px 0;
        display: flex;
        align-items: center;
        position: relative;
        cursor: pointer;
        border-radius: 5px;
        transition: $transition;

        .ant-collapse > .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header {
            padding: 0;
        }

        .ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
            padding: 0;
        }

        .ant-collapse.ant-collapse-icon-position-left.ant-collapse-ghost{
            width: 100%;
        }
    }

    &__item.isActive {
        background-color: $blue-extra-light;
    }

    &__item.isActive &__text {
        color: $blue;
    }

    &__item::before {
        content: '';
        display: block;
        height: 100%;
        width: 4px;
        position: absolute;
        top: 0;
        left: 0;
        background-color: $blue;
        transition: $transition;
        opacity: 0;
    }

    &__item.isActive::before {
        opacity: 1;
    }

    &__item.isActive.isChildItem::before {
        opacity: 1;
    }

    &__icon {
        width: 15px;
        height: 15px;
        color: $dark-grey;
        margin-right: 10px;
    }

    &__text {
        @include body1();
        color: $dark-grey;
        text-decoration: none;
        pointer-events: none;
    }

    &__text.isMain {
        padding-left: 40px;
        font-weight: 600;
    }

    &__text.isChild {
        padding-left: 70px;
        position: relative;
    }

    &__sublist {
        padding: 10px 0;
        list-style: none;
    }
}

@media (max-width: 768px) {
    .navigation {
        width: 100%;
    }
}


