section.privacy-policy {
    padding: 140px 0 120px;

    h1 {
        margin: 0;
        padding-bottom: 45px;
        font-size: 35px;
        text-align: center;
        font-weight: 500;
        line-height: 39px;
        color: $dark-blue;
    }

    h3 {
        font-weight: 600;
        font-size: 24px;
        line-height: 28px;
        color: $dark-blue;
        margin: 0;
        list-style: none;
        text-decoration: none;
        padding: 20px 0;
    }

    p {
        text-decoration: none;
        margin: 0 0 15px;
        padding: 0;
        font-size: 16px;
        line-height: 22px;
        color: $dark-blue;
    }

    a {
        color: $accent;
    }

    .list {
        margin-left: 40px;

        li {
            list-style-type: disc;
        }
    }

    strong {
        font-weight: bold;
    }
}
