.notification-page {
    padding: 100px 0 250px 0;
    background-image: url("/images/global/map.svg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    &__message {
        text-align: center;
        @include new-text(16px, 400, 20px, none, none, $blue-dark);
    }
}
