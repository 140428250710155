.blog-posts {
    padding: 65px 0;
    background: $white1;

    &__list {
        margin-top: 20px;

        display: grid;
        grid-template-columns: repeat(3, 1fr);

        @media (max-width: 1024px) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media (max-width: 700px) {
            grid-template-columns: repeat(1, 1fr);
        }

        grid-gap: 30px;
    }

    &__title {
        @include h3_v3;

        a {
            color: currentColor;

            @include opacity-hover(1, .7)
        }
    }

    &__groups {
        margin-top: 60px;

        &-item {

            & + & {
                margin-top: 50px;
            }
        }
    }

    &__pagination-box {
      display: flex;
      justify-content: flex-end;
    }
}

.post-item {
    $parent: &;
    $footer: 75px;

    position: relative;
    display: flex;
    flex-direction: column;

    width: 100%;
    background-color: rgb(255, 255, 255);
    box-shadow: rgb(58 112 191 / 10%) 0 10px 15px;
    overflow: hidden;
    border-radius: 6px;
    transition: all 0.3s ease 0s;

    cursor: pointer;

    &__image {
        position: relative;
        width: 100%;
        height: 200px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        & .shape {
            overflow: hidden;
            color: rgb(255, 255, 255);
            right: 0;
            bottom: 0;
            left: 0;
            position: absolute;
            pointer-events: none;

            svg {
                transform: scale(2);
                width: 100%;
                height: auto;
                transform-origin: center top;
                display: block;
                color: rgb(255, 255, 255);
            }
        }
    }

    &__info {
        min-heighth: 105px;
        overflow: hidden;

        &-link {
            color: rgb(12, 24, 64);
            width: 100%;
            text-decoration: none;
            font-size: 19px;
            font-weight: 500;
            line-height: 26px;

            &::after {
                content: "";

                position: absolute;
                width: 100%;
                height: calc(100% - #{$footer});

                inset: 0;
                z-index: 1;
                pointer-events: auto;
                background-color: rgba(0, 0, 0, 0);
            }
        }

        &-title {
            padding: 28px 28px 16px;
            @include h3_v2;
        }
    }

    &__footer {
        height: $footer;
        line-height: 22px;
        padding: 14px 28px;
        box-sizing: border-box;
        cursor: pointer;

        margin-top: auto;

        background: $white1;
        position: relative;

        display: flex;
        align-items: flex-end;
        justify-content: space-between;

        span {
            @include body8;
            color: #607385;
        }

        &:before {
            content: '';
            display: block;
            width: 80%;
            position: absolute;
            top: 0;
            left: 50%;
            margin-left: -40%;
            height: 1px;
            background: radial-gradient(ellipse at center,#d1dbe7 0,rgba(255,255,255,0) 75%);
        }
    }

    &__subject {
        display: flex;
        flex-direction: column;

        &-tag {
            color: #9fb1c1 !important;
        }
    }

    &__statistics {
        display: flex;

        &-item {
            display: flex;
            align-items: center;

            svg {
                width: 15px;
                fill: #607385;
                margin-right: 5px;

                &.isLike {
                    fill: tomato;
                }
            }

            & + & {
                margin-left: 15px;
            }
        }
    }

    &:hover {
        transform: translateY(-3px);

        #{$parent} {

            &__info {

                &-title {
                    opacity: .8;
                }
            }
        }
    }

    &.no-pointer {
        cursor: default;
        transform: translateY(0) !important;

        padding: 30px 24px;

        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    &__social {
        display: flex;
        align-items: center;
        justify-content: space-between;

        img {
            width: 30px;
        }

        a {
            display: inline-block;
            width: 92px;
            height: 30px;
            color: #0C1840;
            text-align: center;
            font-weight: 500;
            border: 2px solid #0C1840;
            font-size: 12px;
            border-radius: 6px;
            line-height: 25px;
            text-decoration: none;

            @include opacity-hover(1, .7)
        }
    }
}

@include media-breakpoint-down(sm) {
   .post-item {

       &__social {
           margin: 20px 0;
       }
   }
}
