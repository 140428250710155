.btn-edit {
    position: fixed;
    z-index: 100;

    left: 5px;
    top: 25vh;

    padding: 10px;
    width: 35px;
    height: 35px;

    border-radius: 50px;
    background: $accent;

    display: flex;
    align-items: center;
    justify-content: center;

    @include shadow_1;
    @include opacity-hover(1, .7);

    &--card {
        position: absolute;

        top: 5px;
        border-radius: 6px;
    }
}
