section.members-regulations {
    padding-top: 130px;
    padding-bottom: 60px;
    color: $dark-blue;

    @include media-breakpoint-down(xs) {
        padding-bottom: 0;
    }

    .heading__section {
        margin-bottom: 15px;
        text-align: center;
        font-weight: 500;
        font-size: 60px;
        line-height: 64px;
        letter-spacing: 0.035em;

        @include media-breakpoint-down(xs) {
            font-size: 40px;
        }
    }

    h2 {
        margin: 0;
        text-align: center;
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: 0.035em;
        color: $accent;

        @include media-breakpoint-down(xs) {
            font-size: 20px;
        }
    }

    .main-wrap {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: stretch;
        padding-top: 50px;

        .regulations-box {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 auto;
            padding: 30px;
            width: 100%;
            max-width: 1365px;
            height: 875px;
            background: url('/images/main/members-regulations/members-regulations.svg') no-repeat center center;
            background-size: 1365px;

            @include media-breakpoint-down(xs) {
                height: auto;
                background: transparent;
            }
        }

        .box-text {
            margin-bottom: 117.51px;
            margin-right: 40px;
            width: 100%;
            max-width: 350px;

            p {
                margin-bottom: 30px;
                font-weight: 500;
                font-size: 16px;
                line-height: 16px;
                letter-spacing: 0.035em;
                color: $dark-blue;

                @include media-breakpoint-down(xs) {
                    font-size: 14px;
                }
            }
        }
    }

}
