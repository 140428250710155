$error: #f52c5c;
$grey: #9ea9b9;

.select {
    $select: &;
    position: relative;

    &__panel {
        $panel: &;
        position: relative;

        background: #ffffff;
        height: 50px;

        border: 1px solid #e0e4ec;
        box-sizing: border-box;
        border-radius: 6px;

        padding: 0 16px;
        cursor: pointer;

        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #9ea9b9;

        transition: 0.3s 0s;

        &-text {
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.02em;

            user-select: none;

            &-disabled {
                pointer-events: none;
                --placeholder: #9ea9b9;

                &::placeholder {
                    color: var(--placeholder) !important;
                }
            }
        }

        &-svg {
            fill: #c9cfdb;
            transition: 0.2s 0s ease-out;

            &--active {
                fill: #0139ff;
                transform: rotate(-180deg);
            }
        }

        &-number {
            width: 48px;
            margin-left: 4px;

            font-size: 14px;
            line-height: 20px;

            margin-right: 3px;
            user-select: none;
            transition: .3s 0s;

            &--fill {
                color: $dark-blue;
            }
        }

        &--active {
            fill: #fff;
            border: 1px solid $accent;
            @include shadow_1;
        }

        &--error {
            border: 1px solid #f52c5c !important;
            color: #f52c5c !important;

            #{$panel} {
                &-text {
                    color: #f52c5c !important;
                }
            }
        }

        &--phone {
            padding-right: 0;
        }

        &:active {
            border: 1px solid $accent;
            @include shadow_1;
        }

        &:hover {
            border: 1px solid $accent;
        }
    }

    &__wrapper {
        z-index: 2;
        position: absolute;
        top: calc(100% + 4px);
        left: 0;

        height: auto;
        overflow: auto;

        width: 100%;
        //transition: 0.25s 0s ease-out;

        background: #ffffff;
        box-shadow: 0 4px 15px rgba(58, 112, 191, 0.1);
        border-radius: 6px;

        &-content {
            margin: 0;
            padding: 4px;

            list-style: none;

            .flag-icon {
                margin-right: 8px;
            }
        }
    }

    &__country,
    &__item {
        padding: 15px 16px;
        border-radius: 6px;

        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.02em;
        user-select: none;

        cursor: pointer;
        color: $dark-blue;
        transition: 0.25s 0s;

        &--hover {
            background: #f2f9ff;
        }

        &--active,
        &:hover {
            color: #0088ff;
            background: #f2f9ff;
        }
    }

    &__country {
        padding: 11px 16px;

        &-name {
            margin: 0 8px 0 13px;
            color: #000c37;
        }

        &-iso {
            color: #6e7e92;
        }
    }

    &__input {
        width: 100%;
        height: 100%;

        border: none;
        outline: none;
        padding: 0 16px;
        color: #000c37;

        font-size: 14px;
        line-height: 20px;

        border-radius: 6px;
        font-family: Montserrat, sans-serif;

        &--phone {
            position: relative;
            box-sizing: border-box;
            background: transparent;

            z-index: 1;
        }

        &::placeholder {
            color: #9ea9b9;
        }

        &[readonly] {
            color: #9ea9b9;
        }
    }

    &__phone {
        display: flex;
        align-items: center;

        padding: 5px 12px 5px 0;
        border-right: 1px solid #e0e4ec;

        &-wrapper {
            position: relative;
            height: 100%;
        }
    }

    &__mask {
        position: absolute;
        z-index: 0;

        top: 50%;
        left: 16px;

        font-size: 14px;

        transform: translateY(-50%);

        span {

            &.hide {
                opacity: 0;
                visibility: visible;
            }
        }
    }

    &--autocomplate {

        #{$select} {

            &__panel {
                border: 1px solid transparent;
                padding: 0;

                &-text {
                    @include body1;
                    border: none;

                    width: 100%;
                    height: 100%;

                    box-sizing: border-box;
                    outline: none;
                    border-radius: 5px;

                    padding-left: 50px;

                    &::placeholder {
                        color: $light-grey;
                    }
                }

                &-image {
                    position: absolute;

                    img {
                        margin-left: 20px;
                    }

                    .flag-icon {
                        width: 20px;
                        margin-left: 16px;
                    }
                }

                &--active {
                    border-color: $accent;
                }
            }

            &__item {
                padding: 8px 12px;

                &.no-hover {
                    background: $white !important;
                    color: $dark-blue !important;
                }
            }
        }
    }
}
