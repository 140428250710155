$burger-height: 10px;
$burger-width: 16px;
$burger-item: 2px;
$burger-item-offset: $burger-height - $burger-item * 2;
$burger-item-shadow: -1 * $burger-item - ($burger-item-offset - $burger-item) / 2;

.burger {
    border-radius: $burger-item / 2;
    height: $burger-height;
    user-select: none;
    width: $burger-width;

    &:before,
    &:after {
        border-radius: inherit;
        background: $white;
        content: "";
        display: block;
        height: $burger-item;
        transition: .3s;
    }

    &:after {
        filter: drop-shadow(0 $burger-item-shadow currentColor);
        transform: translateY($burger-item-offset);
    }

    &__wrapper {
        padding: 5px;
        cursor: pointer;
    }

    &.isOpen {

        &:before {
            transform: translateY($burger-height / 2 - $burger-item * 0.5) rotateZ(45deg);
        }

        &:after {
            filter: initial;
            transform: translateY($burger-height / 2 - $burger-item * 1.5) rotateZ(-45deg);
        }
    }
}

@include media-breakpoint-up(md) {
    .burger {
        display: none;
    }
}
