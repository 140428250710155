


.cargoes-runner {

    h1 {
        @include mediaMixin(max, 768) {
            font-size: 44px;
            line-height: 54px;
        }

        @include mediaMixin(max, 576) {
            font-size: 24px;
            line-height: 34px;
        }
    }

    h2 {
        @include mediaMixin(max, 768) {
            font-size: 32px;
            line-height: 42px;
        }

        @include mediaMixin(max, 576) {
            font-size: 22px;
            line-height: 32px;
        }
    }

    h3 {
        @include mediaMixin(max, 576) {
            font-size: 18px;
            line-height: 28px;
        }
    }

    h4 {
        @include mediaMixin(max, 576) {
            font-size: 16px;
            line-height: 26px;
        }
    }

    &__header {
        padding: 150px 0;
        background-image: url('/images/main/cargoesRunner/3.png');
        background-size: cover;
        min-height: 800px;
        overflow: hidden;
        position: relative;

        @include mediaMixin(max, 998) {
            min-height: 1024px;
            background-position-x: -475px;
        }

        @include mediaMixin(max, 576) {
            min-height: 800px;
        }
    }

    &__header-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__header-content-description {
        position: relative;
        z-index: 2;
        flex: 0 0 40%;

        @include mediaMixin(max, 998) {
            flex: 0 0 70%;
        }

        @include mediaMixin(max, 576) {
            flex: 0 0 100%;
        }
    }

    &__header-content-description-label {
        padding: 10px 20px;
        color: $white;
        text-align: center;
        border-radius: 6px;
        text-transform: uppercase;
        background: rgba(255,255,255,.1);
        font-size: 12px;
        display: inline-block;
        margin-bottom: 24px;
    }

    &__header-content-description-title {
        @include text(48px, 600, none, unset, $white);
        line-height: 58px;
        margin-bottom: 24px;
    }

    &__header-content-description-text {
        @include text(16px, 400, none, unset, $gray-light);
        line-height: 26px;
        margin-bottom: 24px;
    }

    &__header-content-description-buttons-box {
        display: flex;

        @include mediaMixin(max, 576) {
            flex-direction: column;
        }
    }

    &__header-content-description-button {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 48px;
        width: 168px;
        border-radius: 6px;
        background: $blue-middle;
        @include text(16px, 600, none, unset, $white);
        margin-right: 10px;

        @include mediaMixin(max, 576) {
            width: 100%;
            margin-top: 10px;
        }
    }

    &__header-content-description-button.empty {
        background: transparent;
        color: $blue-middle;

        .c-arrow {
            stroke: $blue-middle;
        }

        &:hover {
            .c-arrow {
                @include addArrowAnimation();
            }
        }
    }

    &__header-content-image-box {
        flex: 0 0 40%;
        position: absolute;
        right: -74px;
        top: -197px;

        @include mediaMixin(max, 1440) {
            right: -270px;
        }

        @include mediaMixin(max, 998) {
            top: unset;
            bottom: 22px;
            right: -101px;
        }
    }

    &__header-content-image {
        max-width: 923px;

        @include mediaMixin(max, 998) {
            max-width: 820px;
        }

        @include mediaMixin(max, 767) {
            max-width: 635px;
        }

        @include mediaMixin(max, 576) {
            max-width: 450px;
        }
    }

    &__explain {
        padding: 150px 0;
    }

    &__explain-what-is {
        display: flex;
        justify-content: space-between;
        margin-bottom: 160px;

        @include mediaMixin(max, 998) {
            flex-direction: column;
        }
    }

    &__explain-video-preview-box {
        flex: 0 0 40%;
    }

    &__explain-video-preview {
        display: inline-block;
    }

    &__explain-description {
        flex: 0 0 40%;

        @include mediaMixin(max, 998) {
            margin-top: 64px;
        }
    }

    &__explain-description-title {
        max-width: 80%;
        @include text(36px, 600, none, unset, $dark);
        line-height: 48px;
        margin-bottom: 32px;

        @include mediaMixin(max, 998) {
            max-width: 100%;
        }
    }

    &__explain-description-text {
        @include text(16px, 400, none, unset, $dark);
        line-height: 26px;
    }

    &__explain-why-choose-us {

    }

    &__explain-why-choose-us-title-box {
        display: flex;
        margin-bottom: 32px;
    }

    &__explain-why-choose-us-title {
        @include text(36px, 600, none, unset, $dark);
        line-height: 48px;
    }

    &__explain-why-choose-us-title-vector {
        margin-left: 40px;
        max-width: 80px;
        margin-top: -20px;

        @include mediaMixin(max, 576) {
            display: none;
        }
    }

    &__explain-why-choose-us-cards-box {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        @include mediaMixin(max, 768) {
            justify-content: unset;
        }
    }

    &__explain-why-choose-us-card {
        flex: 0 0 23%;
        min-height: 256px;
        color: #000c37;
        background: #ffffff;
        box-shadow: 0px 4px 15px rgba(58, 112, 191, 0.1);
        padding: 24px;
        border-radius: 10px;

        @include mediaMixin(max, 768) {
            flex: 0 0 39%;
            margin: 0 32px 32px 0;
        }

        @include mediaMixin(max, 576) {
            flex: 0 0 100%;
            margin: 0 0 32px 0;
        }

        svg {
            margin-bottom: 20px;
        }
    }

    &__explain-title {
        @include text(16px, 600, none, unset, $dark);
        line-height: 26px;
        margin-bottom: 16px;
        padding-bottom: 16px;
        border-bottom: 1px solid $gray-disabled;
    }

    &__explain-text {
        @include text(14px, 400, none, unset, $gray-dark);
        line-height: 24px;
    }

    &__features {
        padding: 150px 0;
        background: $dark;
    }

    &__features-description-box {
        margin-bottom: 80px;
    }

    &__features-description-title {
        @include text(36px, 600, none, unset, $white);
        line-height: 48px;
        margin-bottom: 24px;
    }

    &__features-description-text {
        @include text(16px, 400, none, unset, $gray-light);
        line-height: 26px;
        max-width: 50%;

        @include mediaMixin(max, 998) {
            max-width: 100%;
        }
    }

    &__features-cards-box {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    &__features-card {
        display: flex;
        flex-direction: column;
        flex: 0 0 25%;
        margin-bottom: 100px;

        @include mediaMixin(max, 768) {
            flex: 0 0 30%;
        }

        @include mediaMixin(max, 576) {
            flex: 0 0 45%;
        }

        svg {
            max-width: 48px;
            margin-bottom: 24px;
        }
    }

    &__features-card:nth-last-child(-n+4) {
        margin-bottom: 0px;
    }

    @include mediaMixin(max, 576) {
        &__features-card:nth-last-child(n+4) {
            margin-bottom: 100px;
        }
    }

    &__features-card-text {
        @include text(18px, 600, none, unset, $white);
        line-height: 28px;

        @include mediaMixin(max, 576) {
            font-size: 14px;
            line-height: 22px;
        }
    }

    &__overview {
        padding: 150px 0;
        background: $gray-background;
        position: relative;
    }

    .vector {
        position: absolute;
        right: 100px;
        top: 50px;
    }

    &__overview-title-box {
        margin-bottom: 48px;
        position: relative;

        svg {
            position: absolute;
            left: -16px;
            top: -25px;

            @include mediaMixin(max, 1200) {
                left: 14px;
                width: 20px;
                top: -27px;
            }
        }
    }

    &__overview-title {
        @include text(36px, 600, none, unset, $dark);
        line-height: 48px;
        margin-left: 20px;
    }

    .slider-navigation-box {
        margin-top: 100px;
        display: flex;
        height: 30px;
        padding: 0 10px;
        justify-content: space-between;
        max-width: 100px;
    }

    .slider-navigation {
        width: 20px;
        height: 20px;
        border-bottom: 3px solid $dark;
        border-right: 3px solid $dark;
        transform: rotate(-45deg);
        transition: all .2s linear;

        &:hover {
            cursor: pointer;
            border-bottom: 3px solid $accent;
            border-right: 3px solid $accent;
        }

        &.swiper-button-disabled {
            border-bottom: 3px solid $gray-middle;
            border-right: 3px solid $gray-middle;

            &:hover {
                pointer-events: none;
                border-bottom: 3px solid $gray-middle;
                border-right: 3px solid $gray-middle;
            }
        }

        &.prev {
            transform: rotate(135deg);
        }
    }

    &__overview-slide {
        margin-left: 20px;
    }

    &__overview-slide-image {
        margin-bottom: 70px;
        max-width: 769px;
        width: 100%;
    }

    &__overview-slide-content {
    }

    &__overview-slide-title {
        @include text(24px, 600, none, unset, $dark);
        line-height: 34px;
        margin-bottom: 24px;
    }

    &__overview-slide-list {
        margin-left: -20px;
    }

    &__overview-slide-list-item {
        margin-bottom: 20px;
        max-width: 70%;
        display: flex;

        @include mediaMixin(max, 768) {
            max-width: 100%;
        }

        span {
            line-height: 26px;
        }
    }

    &__overview-slide-list-item-marker {
        content: "";
        width: 3px;
        padding: 2px;
        height: 3px;
        background: #000c37;
        border-radius: 50%;
        margin-right: 15px;
        margin-top: 11px;
    }

    .swiper-container {
        overflow: unset !important;
    }

    &__form {
        padding: 150px 0;
        background-image: url("/images/main/cargoesRunner/8.png");
        background-size: cover;
    }
}
