.events.global-freight-summit .intro-section {
    background-image: url('/images/pages/Events/GlobalFreightSummit/global-freight-bg.png');

    &__description-title {
        max-width: 565px;
    }

    .intro-section__button {
        flex: 5 0 61%;
    }

    .intro-section__button:last-child {
        margin-left: 16px;

        @media (max-width: 576px) {
            margin-left: unset;
        }
    }

    .intro-section__description {
        @media (max-width: 575px) {
            width: 100%;
            text-align: center;
        }
    }

    .intro-section__description-buttons-box {
        @media (max-width: 575px) {
            max-width: 100%;
        }
    }

    .intro-section__plaque {
        @media (max-width: 575px) {
            width: 100%;
        }
    }
}

.events.global-freight-summit .description-section {
    padding: 60px 0 0 0;

    @media (max-width: 768px) {
        padding: 80px 0 0 0;
    }

    &__title {
        margin-bottom: 16px;
        letter-spacing: 1.2px;
        @include new-text(12px, 600, 20px, uppercase, none, $gray-middle-02);
    }

    &__content {
        display: flex;
        justify-content: space-between;

        @media (max-width: 768px) {
            display: block;
        }
    }

    &__paragraphs-box {
        flex: 0 0 43%;
    }

    &__paragraphs-text:not(:last-child) {
        margin-bottom: 24px;
    }

    &__paragraphs-text {
        @include new-text(16px, 400, 22px, none, none, $blue-dark);

        a {
            color: $blue-middle;
        }

        @media (max-width: 768px) {
            margin-bottom: 24px;
        }
    }

    &__paragraphs-text.bold {
        font-weight: 600;
    }
}

.events.global-freight-summit .video-section {
    padding: 120px 0 0 0;

    @media (max-width: 768px) {
        padding: 100px 0 0 0;
    }

    @media (max-width: 575px) {
        padding: 80px 0 0 0;
    }

    &__content {
        display: flex;
        justify-content: center;
        align-items: center;

    }

    &__player {
        width: 828px;
        height: 466px;
        border-radius: 8px;

        @media (max-width: 860px) {
            width: 100%;
            height: 500px;
        }

        @media (max-width: 575px) {
            width: 100%;
            height: 224px;
        }

    }

    &__player[poster] {
        object-fit: cover;
    }
}

.events.global-freight-summit .sponsor-section {
    padding: 120px 0;

    @media (max-width: 768px) {
        padding: 100px 0;
    }

    @media (max-width: 575px) {
        padding: 80px 0;
    }

    &__content {

    }

    &__title {
        margin-bottom: 24px;
        @include new-text(36px, 600, 48px, none, none, $blue-dark);

        @media (max-width: 575px) {
            @include new-text(22px, 600, 32px, none, none, $blue-dark);
        }
    }

    &__paragraph {
        margin-bottom: 16px;
        @include new-text(16px, 400, 40px, none, none, $blue-dark);

        @media (max-width: 575px) {
            @include new-text(14px, 400, 32px, none, none, $blue-dark);
        }
    }

    &__paragraph.bold {
        font-weight: 600;
    }

    &__paragraph.assistance {
        margin-top: 32px;
    }

    & a {
        margin-top: 32px;
        width: 242px;
        background-color: $blue-dark;

        @media (max-width: 575px) {
            width: 100%;
        }
    }

    &__email-link {
        color: $blue-dark;
        background-color: #ffffff !important;
        text-decoration: underline;
    }
}
