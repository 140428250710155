.vision-strategy {
  .link {
    color: $white;
    text-decoration-line: underline;
    transition: all 0.2s linear;

    &:hover {
      color: $accent;
    }
  }

  section {
    padding: 160px 0;

    @include mediaMixin(max, 1100) {
      padding: 100px 0;
    }

    @include mediaMixin(max, 576) {
      padding: 70px 0;
    }
  }

  h1 {
    @include text(54px, 600, none, unset, $white);
    line-height: 72px;

    @include mediaMixin(max, 1100) {
      font-size: 44px;
      line-height: 54px;
    }

    @include mediaMixin(max, 576) {
      font-size: 32px;
      line-height: 32px;
    }
  }

  h3 {
    @include text(32px, 600, none, unset, $white);
    line-height: 42px;

    @include mediaMixin(max, 1100) {
      font-size: 24px;
      line-height: 34px;
    }

    @include mediaMixin(max, 576) {
      font-size: 20px;
      line-height: 30px;
    }
  }

  &__head {
    background-image: url('/images/pages/Company/VisionStrategy/2.png');
    background-size: cover;
    background-position-x: 50%;

    @include mediaMixin(max, 1100) {
      background-position-x: 80%;
    }

    .container {
      position: relative;
    }

    .head-svg {
      position: absolute;
      bottom: 0;
      right: -50px;

      @include mediaMixin(max, 1100) {
        right: 50px;
      }

      @include mediaMixin(max, 576) {
        display: none;
      }
    }
  }

  &__head-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    @include mediaMixin(max, 1100) {
      flex-direction: column;
      align-items: unset;
    }
  }

  &__head-image-box {
    flex: 0 0 40%;
    position: relative;
    min-height: 600px;

    @include mediaMixin(max, 1100) {
      width: 100%;
      min-height: 455px;
    }

    @include mediaMixin(max, 576) {
      min-height: 405px;
    }
  }

  &__head-image {
    max-width: 1201px;
    position: absolute;
    left: -137%;
    top: -68%;

    @include mediaMixin(max, 1100) {
      left: -340px;
      top: -295px;
      max-width: 800px;
    }

    @include mediaMixin(max, 576) {
      left: -250px;
      top: -235px;
      max-width: 600px;
    }
  }

  &__head-description-box {
    flex: 0 0 45%;

    @include mediaMixin(max, 1100) {
      max-width: 480px;
    }
  }

  &__head-suptitle {
    display: inline-block;
    @include text(12px, 600, none, unset, rgba(255, 255, 255, 0.8));
    text-transform: uppercase;
    padding: 8px 16px;
    border: 0.5px solid rgba(255, 255, 255, 0.35);
    background: rgba(255, 255, 255, 0.1);
    border-radius: 5px;
    margin-bottom: 32px;
    letter-spacing: 0.1em;
  }

  &__head-title {
    margin-bottom: 24px;
  }

  &__head-text {
    @include text(16px, 400, none, unset, rgba(255, 255, 255, 0.7));
    line-height: 26px;

    a {
      color: $white;
      text-decoration: underline;
    }
  }

  &__head-button {
    font-size: 14px;
    font-weight: 600;
    color: $dark;
    background: white;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 193px;
    padding: 10px 0;
    margin-top: 32px;
    height: 38px;

    svg {
      margin-right: 10px;
    }

    @include mediaMixin(max, 576) {
      width: 100%;
      height: 58px;
    }
  }

  &__head-count-box {
    width: 320px;
    display: flex;
    justify-content: space-between;
    position: relative;
    margin-top: 128px;

    @include mediaMixin(max, 1100) {
      margin-top: 80px;
    }
  }

  &__head-count-item {
  }

  .statistics__section {
    position: relative;
    text-align: center;
  }

  .statistics__section:last-child:after {
    content: '';
    width: 2px;
    height: 100%;
    position: absolute;
    background: radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%);
    top: 0;
    left: -40%;
  }

  &__head-count-number {
    @include text(36px, 600, unset, none, $white);
    line-height: 48px;
  }

  &__head-count-title {
    @include text(16px, 400, unset, none, rgba(255, 255, 255, 0.7));
    line-height: 26px;
    display: block;
  }

  &__stat {
  }

  &__stat-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 100px 0;
  }

  &__stat-text {
    text-align: center;
    @include text(32px, 600, unset, none, $dark);
    line-height: 42px;
    margin-top: 40px;

    @include mediaMixin(max, 1100) {
      font-size: 24px;
      line-height: 34px;
    }

    @include mediaMixin(max, 576) {
      font-size: 20px;
      line-height: 30px;
    }
  }

  &__dp {
    padding: 0 !important;
    margin-bottom: 150px;
    border-bottom: 1px solid #c9cfdb;
    border-top: 1px solid #c9cfdb;
  }

  &__dp-content {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include mediaMixin(max, 1100) {
      flex-direction: column;
    }
  }

  &__dp-description {
    flex: 0 0 40%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    //padding-left: 15px;

    @include mediaMixin(max, 1100) {
      align-items: unset;
      margin-top: 100px;
    }
  }

  &__dp-description-text {
    @include text(16px, 400, unset, none, $dark);
    line-height: 26px;
    margin-top: 32px;
    max-width: 422px;
    display: inline-block;

    @include mediaMixin(max, 1100) {
      max-width: unset;
      width: 95%;
    }
  }

  &__dp-description-text:first-child {
    margin-bottom: 30px;
  }

  &__dp-image-box {
    flex: 0 0 40%;
    margin-left: 30px;

    @include mediaMixin(max, 1100) {
      margin-left: 0;
      margin-top: 80px;
    }
  }

  &__dp-image {
    max-width: 947px;
    height: 100%;
    display: flex;

    @include mediaMixin(max, 1100) {
      width: 100%;
      max-width: unset;
    }
  }

  //&__dp-description-logo-box {
  //  width: 422px;
  //}

  &__digitization {
    .container {
      position: relative;

      svg {
        position: absolute;
        bottom: 0;
        right: 0;
      }
    }
  }

  &__digitization-content {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include mediaMixin(max, 1100) {
      flex-direction: column;
    }
  }

  &__digitization-image-box {
    flex: 0 0 40%;
  }

  &__digitization-image {
    display: flex;
    max-width: 490px;
    width: 100%;
  }

  &__digitization-description {
    flex: 0 0 40%;

    @include mediaMixin(max, 1100) {
      margin-bottom: 100px;
      margin-top: 40px;
    }
  }

  &__digitization-description-text {
    @include text(16px, 400, unset, none, $dark);
    line-height: 26px;
  }

  &__range {
    background-image: url('/images/pages/Company/VisionStrategy/10.png');
    background-size: cover;
    background-position-x: 50%;
  }

  &__range-title {
    text-align: center;
    margin-bottom: 64px;
  }

  &__range-list {
  }

  &__range-list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__range-list-number {
    flex: 0 0 5%;
    font-size: 24px;
    color: $white;
    font-weight: 600;
    text-align: center;
  }

  &__range-list-content-box {
    flex: 0 0 90%;
    padding: 80px 98px;
    border: 1px solid #c9cfdb;
    border-bottom: 0;
    display: flex;
    justify-content: space-between;

    @include mediaMixin(max, 768) {
      padding: 32px;
    }

    @include mediaMixin(max, 720) {
      flex-direction: column;
    }
  }

  &__range-list-item:last-child {
    .vision-strategy__range-list-content-box {
      border-bottom: 1px solid #c9cfdb;
    }
  }

  &__range-list-text {
    @include text(16px, 400, unset, none, rgba(255, 255, 255, 0.7));
    line-height: 26px;
    width: 75%;

    @include mediaMixin(max, 768) {
      width: 95%;
    }
  }

  &__range-list-text:last-child {
    margin-top: 30px;
  }

  &__range-list-description {
    flex: 0 0 70%;
  }

  &__range-list-image-box {
    flex: 0 0 30%;
    display: flex;
    align-items: center;
    justify-content: center;

    @include mediaMixin(max, 720) {
      justify-content: unset;
      margin-top: 30px;
    }
  }

  &__range-list-image {
    @include mediaMixin(max, 720) {
      width: 170px;
    }
  }

  &__range-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 200px;

    @include mediaMixin(max, 1000) {
      flex-direction: column;
      margin-top: 120px;
    }
  }

  &__range-footer-image-box {
    flex: 0 0 40%;
    margin-right: 30px;

    @include mediaMixin(max, 1000) {
      margin-right: 0;
      margin-bottom: 50px;
    }
  }

  &__range-footer-image {
    max-width: 563px;
    width: 100%;
  }

  &__range-footer-text-box {
    flex: 0 0 40%;
  }

  &__range-footer-text {
    @include text(16px, 400, unset, none, rgba(255, 255, 255, 0.7));
    line-height: 26px;
  }

  &__range-footer-text:first-child {
    margin-bottom: 30px;
  }

  &__join-us {
    padding: 80px 0;

    h3 {
      color: $dark;
    }
  }

  &__join-us-content {
    display: flex;
    justify-content: space-between;

    @include mediaMixin(max, 576) {
      flex-direction: column-reverse;
    }
  }

  &__join-us-description-box {
    flex: 0 0 48%;
  }

  &__join-us-title {
    margin-bottom: 24px;
  }

  &__join-us-button {
    padding: 11px 24px;
    background: $accent;
    border-radius: 6px;
    color: $white;
    font-size: 16px;
    font-weight: 600;
    transition: all 0.2s linear;

    &:hover {
      background: rgba(0, 150, 255);
    }

    @include mediaMixin(max, 576) {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 56px;
    }
  }

  &__join-us-image-box {
    flex: 0 0 40%;
    margin-left: 30px;
    text-align: center;

    @include mediaMixin(max, 576) {
      margin-left: 0;
      text-align: left;
      margin-bottom: 40px;
    }

    svg {
      @include mediaMixin(max, 576) {
        width: 120px;
      }
    }
  }
}
