/* Custom checkbox template:
<label className="checkbox-container">
    <input type="checkbox"/>
    <span className="checkmark"/>
    <span>Text</span>
</label>
*/

$text-color: $dark-blue;
$hover-background: $light-grey;
$unchecked-background: $grey;
$checked-color: $accent;

.checkbox-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    @include media-breakpoint-up(lg) {
        &:hover input ~ .checkmark {
            background-color: $hover-background;
        }
    }

    .checkbox__text {
        font-weight: 500;
        font-size: 1.1rem;
        color: $text-color;
    }

    .checkmark {
        position: relative;
        margin-right: 0.7rem;
        display: block;
        height: 20px;
        width: 20px;
        background-color: $unchecked-background;

        &:after {
            content: "";
            position: absolute;
            display: none;
        }
    }

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;

        &[type=checkbox] {
            & ~ .checkmark {
                border-radius: 4px;
            }
        }

        &[type=radio] {
            & ~ .checkmark {
                border-radius: 50%;
            }
        }

        &:checked ~ .checkmark {
            background-color: $white;
            border: 1px solid $checked-color;
            box-shadow: 0 0 0 2px rgba(0, 136, 255, 0.2);

            &:after {
                display: block;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                width: 7px;
                height: 7px;
                border-radius: 50%;
                background: $checked-color;
            }
        }
    }
}
