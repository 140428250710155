.file {
    &__input-box {
        text-align: center;
    }

    &__input-label {
        box-sizing: border-box;
        padding: 8px;
        border-radius: 6px;
        background: $gray-disabled;
        cursor: pointer;
        @include new-text(14px, 500, 20px, none, none, $blue-dark);
    }

    &__input {
        width: 0;
        height: 0;
        visibility: hidden;
    }

    &__name {
        margin-top: 12px;
    }
}
