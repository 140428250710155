.breadcrumbs {
  display: flex;
  gap: 8px;
  max-width: 1170px;
  position: absolute;
  top: 24px;
  z-index: 2;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 8px;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 90%;

  .last-item {
    font-weight: 700;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  @media (max-width: 475px) {
    font-size: 12px;
  }
}

.breadcrumbs-light {
  color: $white;

  a {
    color: $white !important;

    &:hover {
      opacity: 0.8;
    }
  }
}

.breadcrumbs-dark {
  color: $dubai;

  a {
    color: $dubai !important;

    &:hover {
      opacity: 0.5;
    }
  }
}