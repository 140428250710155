.modal {
    $parent: &;
    position: fixed;
    top: 0;
    left: 0;

    z-index: 999;

    width: 100vw;
    height: 100vh;

    opacity: 0;
    visibility: hidden;
    transition: 0.3s 0s ease-out;

    background: rgba(8, 19, 62, 0.2);
    backdrop-filter: blur(12px);

    display: flex;
    align-items: center;
    justify-content: center;

    overflow: auto;

    &__icon {
        height: 30px;
        width: 30px;
    }

    &__close-icon {
        position: absolute;
        top: 22px;
        right: 22px;
        cursor: pointer;
        transition: $transition;
        fill: #9EA9B9;
    }

    &__close-icon:hover {
        fill: $dubai;
    }

    &__body {
        position: relative;

        width: 530px;
        height: 320px;

        padding: 20px;

        background: #ffffff;
        box-shadow: 0 10px 30px rgba(58, 112, 191, 0.15);
        border-radius: 10px;

        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        &-title {
            margin: 34px 0 16px;

            font-weight: 600;
            font-size: 20px;
            line-height: 30px;

            text-align: center;

            color: #000c37;
        }

        &-text {
            margin: 0;

            font-size: 14px;
            line-height: 22px;
            text-align: center;

            color: #6e7e92;
        }

        &-accent-text {
            margin-top: 16px;
            text-align: center;
            font-size: 14px;
            font-weight: 600;
            line-height: 22px;
            color: $dubai;
        }

        &-btn {
            margin-top: 24px;
        }

        &-close {
            position: absolute;
            top: 12px;
            right: 12px;

            cursor: pointer;
            display: flex;

            padding: 6px;

            svg {
                transition: 0.2s 0s;
            }

            &:hover {
                svg {
                    fill: #a7a9b3;
                }
            }
        }
    }

    &--open {
        opacity: 1;
        visibility: visible;
    }

    &--small {

        #{$parent} {

            &__body {
                width: 450px;
                height: 240px;

                &-title {
                    margin: 20px 0 16px;
                }
            }
        }
    }

    &--medium {

    }

    &__buttons {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 15px;
    }

    @include media-breakpoint-down(sm) {
        display: flex;

        &__body {
            width: 100%;
            height: auto;

            padding: 66px 16px 16px;
            margin: 10vh 16px 5vh;

            &-page {
                margin-top: 32px;
                width: 100%;
                height: 56px;
            }
        }

        &--open {
            opacity: 1;
            visibility: visible;
        }
    }
}
