.new-container {
    @include new-container();
}

.new-section {

}

.new-section.video {
    overflow-x: hidden;
}

// HEADINGS:
// 36px, 600, 48px, uppercase, none, $white
.page-heading-h1 {
    font-size: 36px;
    font-weight: 600;
    line-height: 48px;
    text-transform: uppercase;
    color: $white;
}

// TEXT:
.new-text--bold {
    font-weight: 600;
}

.new-text--italic {
    font-style: italic;
}

// LINK: TEXT LINK
.new-link {
    @include new-text(16px, 600, 26px, none, underline, $blue-dark);
    transition: $transition;
}

// LINK: BUTTON LINKS
.new-link-block {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 185px;
    padding: 7px 0;
    border-radius: 6px;
    transition: $transition;
    @include new-text(16px, 600, 26px, none, none, $white);

    &.full {
        border: 1px solid $blue-middle;
        background-color: $blue-middle;
    }

    &.hollow {
        color: $blue-middle;
        background-color: unset;
        border: 1px solid $blue-middle;
    }

    &.dark {
        color: $white;
        border: 1px solid $blue-dark;
        background-color: $blue-dark;
    }

    &.full:hover,
    &.hollow:hover {
        color: $white;
        background-color: $blue-middle-hover;
        border: 1px solid $blue-middle-hover;
    }

    &.dark:hover {
        background-color: $blue-dark-hover;
    }
}

// LINK: ARROW LINK
.new-link.arrow {
    @include new-text(16px, 600, 26px, none, none, $blue-middle);

    .link-arrow {
        stroke: $blue-middle;
    }

    &:hover {
        .link-arrow {
            &__line {
                opacity: 1;
                transition: .15s ease;
            }

            &__tip {
                transform: translate3d(3px,0,0);
                transition: .15s ease;
            }
        }
    }
}

// LINK: PRESET FOR THE ANIMATED ARROW LINK
.link-arrow {
    position: relative;
    display: inline-block;
    height: 10px;
    width: 10px;
    margin: 0 0 0 5px;
    stroke-width: 1.5px;
    fill: none;
    stroke-linecap: round;
    stroke: $blue-middle;
    transition: 0.25s ease;

    &__line {
        opacity: 0;
        transition: 0.15s ease;
    }

    &__tip {
        transform: translate(0, 0);
        transition: 0.15s ease;
    }

    &_directionLeft {
        transform: translate(0, 0%) scaleX(-1);
    }

    &.directionRight {
        transform: translate(0, 0%) scaleX(1);
    }

    &_directionUp {
        transform: rotate(-90deg);
    }

    &_directionDown {
        transform: rotate(90deg);
    }

    &_positionLeft {
        margin: 0 10px 0 0;
    }

    &_positionRight {
        margin: 0 0 0 10px;
    }
}
