@keyframes ldio {
    0% {
        opacity: 1;
        backface-visibility: hidden;
        transform: translateZ(0) scale(1.5, 1.5);
    }
    100% {
        opacity: 0;
        backface-visibility: hidden;
        transform: translateZ(0) scale(1, 1);
    }
}

.preloader-wrap {
    width: 100%;
    height: 100%;
    background: #fff;
    //box-shadow: $MainShadow;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ldio div > div {
    position: absolute;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: $dark-grey;
    animation: ldio 1s linear infinite;
}

.ldio div:nth-child(1) > div {
    left: 74px;
    top: 44px;
    animation-delay: -0.875s;
}

.ldio > div:nth-child(1) {
    transform: rotate(0deg);
    transform-origin: 80px 50px;
}

.ldio div:nth-child(2) > div {
    left: 65px;
    top: 65px;
    animation-delay: -0.75s;
}

.ldio > div:nth-child(2) {
    transform: rotate(45deg);
    transform-origin: 71px 71px;
}

.ldio div:nth-child(3) > div {
    left: 44px;
    top: 74px;
    animation-delay: -0.625s;
}

.ldio > div:nth-child(3) {
    transform: rotate(90deg);
    transform-origin: 50px 80px;
}

.ldio div:nth-child(4) > div {
    left: 23px;
    top: 65px;
    animation-delay: -0.5s;
}

.ldio > div:nth-child(4) {
    transform: rotate(135deg);
    transform-origin: 29px 71px;
}

.ldio div:nth-child(5) > div {
    left: 14px;
    top: 44px;
    animation-delay: -0.375s;
}

.ldio > div:nth-child(5) {
    transform: rotate(180deg);
    transform-origin: 20px 50px;
}

.ldio div:nth-child(6) > div {
    left: 23px;
    top: 23px;
    animation-delay: -0.25s;
}

.ldio > div:nth-child(6) {
    transform: rotate(225deg);
    transform-origin: 29px 29px;
}

.ldio div:nth-child(7) > div {
    left: 44px;
    top: 14px;
    animation-delay: -0.125s;
}

.ldio > div:nth-child(7) {
    transform: rotate(270deg);
    transform-origin: 50px 20px;
}

.ldio div:nth-child(8) > div {
    left: 65px;
    top: 23px;
    animation-delay: 0s;
}

.ldio > div:nth-child(8) {
    transform: rotate(315deg);
    transform-origin: 71px 29px;
}

.loadingio-spinner-spin {
    width: 50px;
    height: 50px;
    overflow: hidden;
}

.ldio {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(0.5);
    backface-visibility: hidden;
    transform-origin: 0 0;
}
