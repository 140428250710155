.directory {
    position: relative;
    z-index: 100;

    padding: 120px 0 100px;

    &__title {
        @include h2;

        text-align: center;
        color: $black;

        margin: 0 auto 80px;
        max-width: 940px;
    }

    &__empty {
        display: flex;
        flex-direction: column;
        align-items: center;

        padding: 120px 0;

        &-title {
            margin-top: 45px;
            @include h3;
        }

        &-text {
            margin-top: 16px;
            @include body4;
        }
    }
}

@include media-breakpoint-down(md) {
    .directory {
        padding: 130px 0 70px;

        &__title {
            margin: 0 auto 60px;
            max-width: 660px;
        }

        &__empty {
            padding: 90px 0;

            &-title {
                margin-top: 30px;
            }

            &-text {
                margin-top: 12px;
            }
        }
    }
}

@include media-breakpoint-down(sm) {
    .directory {

        &__title {
            margin: 0 auto 48px;
        }

        &__empty {
            padding: 60px 0;

            &-title {
                margin-top: 20px;
            }

            &-text {
                margin-top: 8px;
            }
        }
    }
}
