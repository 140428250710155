.header {
  $parent: &;
  width: 100%;
  height: 65px;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 101;

  backdrop-filter: blur(37px);
  background-color: $blue-dark;

  border-bottom: 1px solid $gray-dark;

  &__logo {
    position: relative;
    z-index: 10;

    img {
      width: 118px;
      user-select: none;
    }
  }

  &__body {
    display: flex;
    align-items: center;
    justify-content: space-between;

    height: 100%;
  }

  &__nav {
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: flex-end;

    &-list {
      position: relative;
      display: flex;
      height: 100%;
      margin-right: 40px;

      @include media-breakpoint-down(sm) {
        height: auto;
      }
    }

    &-list > &-item > a {
      //color: $dark;
      color: $white;
    }

    &-list:last-child > &-item > a {
      color: $dark;
    }

    &-list:last-child {
      margin-right: 0;
    }

    &-list:last-child > &-item > a {
      padding: 10px 20px;
      background: #eff1f6;
      border-radius: 20px;
      color: $blue-dark;
    }

    &-item {
      position: relative;
      display: flex;
      align-items: center;

      height: 100%;

      &-label-box {
        display: flex;
        align-items: flex-end;
        color: $white;
      }

      &:hover {
        cursor: pointer;

        .header__sublink {
          transform: translateX(-50%) translateY(0);
          opacity: 1;
          visibility: visible;
        }

        .c-arrow {
          @include addArrowAnimation;
        }
      }

      &:hover > .submenu-wrapper {
        visibility: visible;
      }

      @include media-breakpoint-down(sm) {
        display: flex;
        flex-direction: column;
      }

      &-right {
        cursor: pointer;
        position: relative;

        @include media-breakpoint-down(sm) {
          display: none;
        }

        .header__nav__user-icon {
          position: relative;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          width: 28px;
          height: 28px;
          background: #eff1f6;
          border-radius: 50%;
          background-image: url('/images/directory/user.svg');
          background-position: center;
          background-repeat: no-repeat;
          background-size: 10.5px 13px;
          transition: 0.2s;

          &.active {
            background-image: url('/images/directory/user_blue.svg');
          }
        }

        @include media-breakpoint-up(md) {
          &:hover {
            .header__nav__user-icon {
              background-image: url('/images/directory/user_blue.svg');
            }
          }
        }
      }

      &.isActive {
        a {
          @include media-breakpoint-up(md) {
            &:before {
              width: 24px;
            }
          }
        }
      }

      & > a {
        display: flex;
        align-items: center;
        justify-content: center;

        padding: 5px 0;

        font-weight: 500;
        font-size: 14px;
        line-height: 100%;

        color: $blue-dark;
        text-decoration: none !important;
        letter-spacing: 0.04em;

        &:hover {
          &:before {
            width: 24px;
          }
        }

        &:before {
          //content: "";
          //
          //position: absolute;
          //top: 100%;
          //
          //width: 0;
          //height: 2px;
          //background-color: $accent;
          //border-radius: 3px;
          //transition: .3s ease-in;
        }
      }

      &--sublink {
        position: relative;
        color: $white;
        font-size: 14px;
        font-weight: 500;
        transition: all 0.2s linear;

        &.active {
          color: $accent;
        }

        .admin__lock {
          fill: $white;
          transition: 0.3s 0s;

          max-width: 18px;
          cursor: pointer;

          path {
            stroke: $white;
            transition: 0.3s 0s;
          }
        }

        &:hover {
          .admin__lock {
            fill: $accent;

            path {
              stroke: $accent;
            }
          }
        }
      }

      &--sublink.true {
        color: $blue-dark;
      }

      & + & {
        margin-left: 40px;
      }

      .submenu-wrapper {
        position: absolute;
        bottom: -50px;
        left: 0;
        padding: 10px;
        border-radius: 4px;
        border: 1px solid #e2e2e2;
        background-color: #f4f6fb;

        & a {
          padding: 5px 0;
          color: #777777;

          &:not(:last-of-type) {
            border-bottom: 1px solid #e2e2e2;
          }

          &::before {
            background-color: transparent;
            height: 0;
            opacity: 0;
          }

          &:hover {
            color: $accent;

            &::before {
              background-color: transparent;
              height: 0;
              opacity: 0;
            }
          }
        }

        @include media-breakpoint-up(md) {
          visibility: hidden;
        }

        @include media-breakpoint-down(sm) {
          position: relative;
          bottom: initial;
          left: initial;
          padding: 10px;
          border: none;
          background-color: initial;
        }
      }
    }
  }

  &__sublink {
    position: absolute;

    top: calc(100% + 24px);
    left: 50%;
    transform: translateX(-50%) translateY(10px);
    opacity: 0;
    visibility: hidden;
    transition: 0.3s 0s;
    min-width: 180px;

    background: #fff;
    border-radius: 4px;
    box-shadow: 0 6px 13px rgb(221 228 247 / 80%);

    display: flex;
    grid-column-gap: 20px;
    flex-direction: column;
    justify-content: space-evenly;

    &-link {
      padding: 10px;
      margin-top: 10px;
    }

    &-title {
      display: flex;
      align-items: center;

      font-size: 14px;
      text-transform: uppercase;
      color: #8898aa;
      margin: 10px 0;
      font-weight: 500;
    }

    &-link {
      display: block;
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 5px;

      color: $blue-dark;
      transition: 0.3s 0s;

      &:hover {
        color: $blue-middle;
      }
    }

    //&:after {
    //    content: '';
    //    position: absolute;
    //
    //    width: 14px;
    //    height: 14px;
    //
    //    top: -7px;
    //    left: 50%;
    //    transform: translateX(-50%) rotate(45deg);
    //
    //    border-radius: 3px;
    //    background: $purple;
    //}
    //
    //&:before {
    //    content: '';
    //    position: absolute;
    //
    //    width: 18px;
    //    height: 14px;
    //
    //    top: 0;
    //    left: 50%;
    //    transform: translateX(-50%);
    //
    //    background: #fff;
    //    z-index: 1;
    //}
  }
}

// 1200
@include media-breakpoint-down(lg) {
  .header {
    &__nav {
      &-item {
        & + & {
          margin-left: 30px;
        }
      }
    }
  }
}

// 992
@include media-breakpoint-down(md) {
  .header {
    &__nav {
      &-item {
        & + & {
          margin-left: 15px;
        }
      }
    }
  }

  .header__nav-list:first-child {
    margin-right: 15px;
  }
}

// 768
@include media-breakpoint-down(sm) {
  .header {
    &__nav {
      top: 0;
      left: 0;
      transform: translateY(-100%);

      position: absolute;
      flex-direction: column;

      margin-left: 0;

      transition: 0.3s 0s ease;
      background: $white;
      @include shadow_2;

      height: calc(100vh - 63px);
      overflow: auto;

      &-list {
        width: 100%;
        flex-direction: column;
        padding: 0;

        a {
          width: 100%;
        }

        & + & {
          a {
            color: $accent;
          }
        }
      }

      &-list:last-child {
        width: auto;
      }

      .mobileActiveLink {
        color: $accent !important;

        a {
          color: $accent !important;
        }
      }

      .isActive > a {
        color: $accent !important;
      }

      &-item {
        a {
          margin: 0 auto;
          color: $dark;
        }

        & + & {
          margin: 40px 0 0;
        }

        &:hover {
          a {
            &:before {
              display: none;
            }
          }
        }
      }

      &.isOpen {
        top: 100%;
        transform: translateY(0);
        background: $white;
        justify-content: space-evenly;
        justify-content: flex-start;
      }

      &.isOpen > &-list:first-child {
        margin-right: 0;
        margin-left: 0;
      }

      &.isOpen > &-list:last-child > &-item > a {
        margin-top: 30px;
      }

      .active {
        .mobile-arrow {
          transform: translate(-50%, 50%) rotate(225deg) !important;
          border-right: 2px solid $accent !important;
          border-bottom: 2px solid $accent !important;
        }
      }

      .header__nav-item--sublink {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: 30px !important;
      }

      &.isOpen > &-list > &-item > &-item--sublink:nth-child(n) {
        padding: 0;
        border-bottom: 1px solid #e0e4ec;
        width: 100%;
        position: relative;

        a {
          display: block;
          width: 100%;
        }

        .mobile-arrow-wrapper {
          //position: absolute;
          margin-top: -10px;
          right: 30px;
          top: 40%;
          width: 20px;
          height: 20px;
          //padding: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
          .mobile-arrow {
            //position: absolute;
            width: 10px;
            height: 10px;
            border-right: 2px solid $blue-dark;
            border-bottom: 2px solid $blue-dark;
            transition: all 0.2s linear;
            //right: 30px;
            //top: 50%;
            transform: translate(-50%, 50%) rotate(45deg);
          }
        }
      }

      &.isOpen > &-list:first-child > &-item {
        align-items: flex-start;
        margin: 0;

        a {
          padding: 20px;
          justify-content: flex-start;
          border-bottom: 1px solid #e0e4ec;
        }
      }
    }

    &__sublink-box {
      width: 100%;
      visibility: hidden;
      opacity: 0;
      transition: all 0.2s linear;
      height: 0;

      &.active {
        visibility: visible;
        opacity: 1;
        height: auto;
        margin-top: 20px;
      }
    }

    &__sublink-link {
      color: $blue-dark;
      border-bottom: none !important;
      padding: 10px 20px 10px 40px !important;
    }
  }
}

// Rewrite all Header layout and styles!

.header__nav.isOpen > .header__nav-list > .header__nav-item > a {
  color: $dark;
}

.header__nav.isOpen .admin__lock {
  fill: $dark;
}
