// !!! THESE ARE OLD VALUES BUT CURRENTLY USED IN PROJECT !!!
// !!! EVERYTHING FROM /src/styles/utils/_colors WILL BE REMOVED !!!
// !!! USE STYLES FROM /src/styles/global/_colors INSTEAD IF POSSIBLE !!!

$white: #ffffff;

$blue-dark: #000c37;
$blue-dark-1: #030a27;
$blue-dark-hover: #001357;
$blue-accent: #0088ff;
$future-hover: #50adff;
$lapland: #f8f9fd;
$dubai: #000c37;

$disabled-background: #eff1f6;
$disabled-text: #9ea9b9;
$input-text: #e0e4ec;

$red-error: #ff317b;
$red-hover: #f52c5c;

// Color names according to design system naming.
// Todo: Change the previous usages to new naming.
$lapland: #F8F9FD;
$ice: rgba(239, 241, 246, 0.8);
$mood: #E0E4EC;
$rain: #C9CFDB;
$grey-storm: #9EA9B9;
$storm: rgba(158, 169, 185, 0.2);
$dirty: rgba(110, 126, 146, 0.2);
$future: #0088FF;
$future-active: #006FF1;
$future-hover: #50ADFF;
$future-light: #F0F8FF;
$dubai: #000C37;
$dubai-hover: #001357;
$disco-man-active: #3F4CF1;
$disco-man-hover: #868EF9;
$disco-man-light: #F5F6FF;
$kill-bill: #F52C5C;
$kill-bill-active: #F20C43;
$kill-bill-hover: #FF517B;
$kill-bill-light: #FFF2F5;
$amber: #FC7E1D;
$amber-active: #F8501C;
$amber-hover: #FCAA6B;
$amber-light: #FC9748;
$cucumber: #02BCB1;
$cucumber-active: #019990;
$cucumber-hover: #09D1C5;
$cucumber-light: #DDF8F6;
$input-border: #e7edf8;
$ellipsis-term: #0f0f19;

$gradient-dubai: linear-gradient(270deg, #01051B 74.03%, #000A2C 111.03%);