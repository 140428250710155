.signup {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 96px 15px 24px 15px;

  @media (max-width: 768px) {
    width: auto;
  }

  @media (max-width: 475px) {
    padding-top: 88px;
  }

  &__wrapper {
    box-shadow: 0px 4px 15px 0px #3a70bf1a;
    background-color: $white;
    display: flex;
    gap: 48px;
    padding: 24px;
    padding-left: 48px;

    @media (max-width: 768px) {
      padding: 48px;
    }

    @media (max-width: 475px) {
      padding: 40px 24px;
    }
  }

  &__emailStep {
    & .input__body {
      height: 48px;
    }
    &-text {
      margin-top: 8px;

      @include new-text(14px, 400, 22px, none, none, $gray-dark);

      @media (max-width: 475px) {
        @include new-text(12px, 400, 20px, none, none, $gray-dark);
        margin-bottom: 16px;
      }
    }
  }

  &__button {
    margin-top: 32px;

    & .btn {
      @media (max-width: 475px) {
        min-height: 48px !important;
      }
    }
  }

  &__form {
    // max-width: 360px;
    margin-top: 24px;
    // width: 100%;
    width: 360px;

    @media (max-width: 768px) {
      margin-top: 0px;
    }

    @media (max-width: 475px) {
      max-width: 342px;
      width: 100%;
    }

    &-data {
      .input {
        margin-top: 24px;

        @media (max-width: 475px) {
          margin-top: 16px;
        }
      }
    }

    &-title {
      @include new-text(20px, 700, 30px, none, none, $blue-dark);

      @media (max-width: 475px) {
        @include new-text(18px, 700, 28px, none, none, $blue-dark);
      }
    }
    &-subtitle {
      margin-top: 8px;
      margin-bottom: 32px;
      @include new-text(14px, 400, 22px, none, none, $gray-dark);
    }
    &-blue {
      color: $blue-middle;
    }
  }
  &__info {
    background-color: $dark-blue;
    width: 690px;
    border-radius: 6px;
    padding: 72px 80px;
    position: relative;
    overflow: hidden;

    @media (max-width: 768px) {
      display: none;
    }

    &-vector1 {
      position: absolute;
      top: 0;
      right: 0;
    }

    &-vector2 {
      position: absolute;
      bottom: 0;
      left: 0;
    }

    &-logos {
    }
    &-title {
      @include new-text(48px, 600, 60px, none, none, $gray-disabled);
      margin-top: 150px;
      position: relative;
      z-index: 2;
    }
    &-description {
      @include new-text(16px, 400, 26px, none, none, $gray-disabled);
      margin-top: 16px;
      position: relative;
      z-index: 2;
    }
    &-text {
      // background: linear-gradient(0deg, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12)),
      //   linear-gradient(0deg, rgba(255, 255, 255, 0.06), rgba(255, 255, 255, 0.06));
      background: rgba(255, 255, 255, 0.06);
      border: 1px solid rgba(255, 255, 255, 0.12);
      border-radius: 16px;
      margin-top: 158px;
      padding: 40px;
      border: 1px solid #ffffff1f;
      @include new-text(16px, 600, 26px, none, none, $gray-disabled);
      position: relative;
      z-index: 2;
    }
  }
  &__private-info {
    margin-top: 8px;
    &-subtitle {
      margin-bottom: -8px;
      @include new-text(14px, 400, 22px, none, none, $gray-dark);
    }
    &-inputs {
      & .input__error {
        margin-top: 4px;
      }
      & .input__body {
        height: 48px;
      }
      & > div {
        margin-top: 32px;
        margin-bottom: 32px;

        @media (max-width: 768px) {
          margin-top: 24px;
        }
      }

      & > div:last-child {
        margin-bottom: 17px;
      }

      .new-select {
        margin-top: -5px;

        .new-select__label-text {
          margin-bottom: 2px;
        }

        .new-select__input-icon {
          top: 46px;
          right: 22px;
          transform: scale(1.2);
        }
      }

      .input__body-eye {
        top: 70%;
      }

      .select__panel {
        height: 48px;
        justify-content: flex-start;
      }
    }
    &-email {
      position: relative;

      input {
        background: linear-gradient(0deg, #f8f9fd, #f8f9fd), linear-gradient(0deg, #c9cfdb, #c9cfdb);
      }

      &-icon {
        position: absolute;
        bottom: 14px;
        right: 16px;
        cursor: pointer;
      }
    }
    &-name {
      display: flex;
      gap: 16px;
      & input {
        width: 100%;
      }
    }

    &-links {
      @media (max-width: 768px) {
        margin-top: -20px;
      }
    }

    &-links,
    a {
      @include new-text(14px, 400, 22px, none, none, $gray-dark);

      @media (max-width: 475px) {
        @include new-text(12px, 400, 20px, none, none, $gray-dark);
      }
    }
    & .new-select__input {
      position: relative;
      background: #fff;
      height: 48px;
      padding: 0 16px;
      outline: none;
      border: 1px solid #e0e4ec;
      box-sizing: border-box;
      border-radius: 6px;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.02em;
      font-family: Montserrat, sans-serif;
      transition: 0.3s 0s;
    }

    & .new-select__input-icon {
      top: 55px;
    }

    & .signup__button {
      margin-top: 15px;
      position: relative;

      .common__error {
        bottom: -24px;
        position: absolute;
        letter-spacing: 0.06em;
        color: #f52c5c;
        font-weight: bold;
        font-size: 10px;
        line-height: 12px;
      }
    }
  }

  &__verify {
    @media (max-width: 475px) {
      width: 294px;
    }
    &-subtitle {
      margin-top: 8px;
      margin-bottom: 24px;
      @include new-text(14px, 400, 22px, none, none, $gray-dark);

      @media (max-width: 475px) {
        @include new-text(12px, 400, 20px, none, none, $gray-dark);
      }

      span {
        @include new-text(14px, 500, 22px, none, none, $blue-dark);

        @media (max-width: 475px) {
          @include new-text(12px, 500, 20px, none, none, $blue-dark);
        }
      }
    }
    &-code {
      display: flex;
      justify-content: space-between;
      &-item {
        @include new-text(14px, 600, 22px, none, none, $blue-dark);
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        width: 12px;
        height: 20px;
        padding: 13px 16px;
        border-radius: 6px;
        border: 1px solid #c9cfdb;

        &:focus {
          border-color: $dark-blue;
          outline: none !important;
        }
      }

      &-error {
        border-color: #f52c5c;
      }
      &-notification {
        position: absolute;
        margin-top: 4px;
        @include new-text(12px, 500, 20px, none, none, $blue-dark);

        &-input {
          border-color: $dark-blue;
        }
      }
    }
    &-links {
      div:first-child {
        margin-bottom: 16px;
      }

      @include new-text(14px, 400, 22px, none, none, $blue-dark);
      margin-top: 24px;
      // text-align: center;
      & a {
        @include new-text(14px, 400, 22px, none, underline, $blue-dark);
      }
      span {
        text-decoration: underline;
        cursor: pointer;
      }

      @media (max-width: 475px) {
        @include new-text(12px, 400, 20px, none, none, $blue-dark);
      }
    }
  }

  &__footer {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex: 1;
    margin-bottom: 24px;
    gap: 50px;
    a {
      display: block;
      @include new-text(14px, 400, 22px, none, none, $gray-dark);
    }
  }

  &__error-text {
    margin-top: 4px;
    position: absolute;
    @include new-text(12px, 500, 20px, none, none, #f52c5c);
  }
}
