.success-registration {
    min-height: 400px;
    display: flex;
    align-items: center;
    padding: 100px 0;

    .container {
        text-align: center;
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }

    &__image {
        width: 100px;
        margin-top: 60px;
    }

    &__text {
        width: 60%;
        margin: auto;
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;

        @media (max-width: 576px) {
            width: 100%;
        }
        //@include mediaMixin(max, 576) {
        //    width: 100%;
        //}
    }

    &__title {
        font-size: 36px;
        font-weight: 600;
        line-height: 64px;
        margin-bottom: 30px;
    }

    &__button-box {
        display: flex;
        width: 100%;
        justify-content: center;
    }

    &__button {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-weight: 600;
        color: $white;
        width: 20%;
        border-radius: 6px;
        padding: 9px;
        line-height: 22px;
        background: $blue-dark;
        margin-top: 100px;
        transition: all .2s linear;

        @media (max-width: 576px) {
            width: 40%;
        }
        //@include mediaMixin(max, 576) {
        //    width: 40%;
        //}

        &:hover {
            background: $blue-dark-hover;
            cursor: pointer;
        }
    }

}
