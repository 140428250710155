@import "../../../global/colors";

.freight-forwarder-landing {
    padding: 65px 0 0 0;
    position: relative;
    background: $gradient-dubai;

    &__head {
        padding: 256px 0;
        background-image: url('/images/pages/Resources/FreightDictionary/landings/head-bg.png');
        background-size: cover;
        overflow: hidden;

        @media (max-width: 768px) {
            padding: 245px 0 296px 0;
        }

        @media (max-width: 575px) {
            padding: 136px 0;
        }
    }

    &__head .container {
        position: relative;
    }

    // IMAGE BLOCK
    &__head-image-box {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 500px;
        position: absolute;
        top: -75px;
        right: 0;
        z-index: 1;

        @media (max-width: 1024px) {
            top: -75px;
            right: -156px;
        }

        @media (max-width: 768px) {
            right: -256px;
        }

        @media (max-width: 575px) {
            display: none;
        }
    }

    &__head-image {
        width: 100%;
        object-fit: contain;
    }

    // HEAD: DESCRIPTION BLOCK
    &__head-description-box {
        max-width: 520px;
        width: 100%;
        position: relative;
        z-index: 2;
    }

    &__head-title {
        position: relative;
        font-size: 48px;
        font-weight: 600;
        line-height: 60px;
        color: $grey-winter;
        display: flex;
        flex-direction: column;

        @media (max-width: 768px) {
            font-size: 44px;
            line-height: 54px;
        }

        svg {
            position: absolute;
            top: -128px;
            right: -86px;

            @media(max-width: 768px) {
                right: -8px;
            }

            @media(max-width: 575px) {
                display: none;
            }
        }
    }

    &__head-text {
        margin-top: 24px;
        font-size: 16px;
        font-weight: 400px;
        line-height: 26px;
        color: $grey-winter;
    }

    &__head-country-box {
        position: relative;
    }

    &__head-flag {
        position: absolute;
        bottom: 13px;
        margin-left: 18px;
        width: 39px;
        height: 26px;

        @media (max-width: 768px) {
            bottom: 10px;
        }

        @media (max-width: 575px) {
            bottom: 12px;
            width: 35px;
            height: 22px;
        }
    }

    &__head .flag-icon {
        min-width: unset;
    }

    // HEAD: OVERRIDES - LINK BLOCK
    &__head .new-header-hero__links-box {
        margin-top: 24px;

        @media (max-width: 575px) {
            flex-direction: column;
        }
    }

    &__head .new-header-hero__links-box .new-link-block.full {
        margin-right: 0;
        width: 234px;

        @media (max-width: 575px) {
            width: 100%;
            margin: unset;
        }
    }

    &__head .new-header-hero__links-box .new-link.arrow {
        @media (max-width: 575px) {
            margin-top: 24px;
        }
    }

    // SECTION: INSTRUCTIONS
    &__instructions {}

    &__instructions-section {
        display: flex;
        align-items: center;
        column-gap: 48px;

        @media (max-width: 1024px) {
            flex-direction: column;
        }
    }

    &__instructions-section.steps,
    &__instructions-section.become {
        margin-top: 160px;

        @media (max-width: 575px) {
            margin-top: 80px;
        }
    }

    &__instructions-description-box.start,
    &__instructions-description-box.become,
    &__instructions-description-box.steps {
        @media (max-width: 1024px) {
            margin-top: 60px;
        }

        @media (max-width: 768px) {
            margin-top: 40px;
        }
    }

    &__instructions-description-box.steps {
        @media (max-width: 1024px) {
            order: 2;
        }
    }

    &__instructions-image-box {
        max-width: 540px;
        width: 100%;
        border-radius: 24px;

        @media (max-width: 1024px) {
            width: 100%;
            display: flex;
            justify-content: center;
        }
    }

    &__instructions-image-box img {
        border-radius: 24px;
    }

    &__instructions-title,
    &__instructions-list-title {
        @include text(36px, 600, none, none, $white);
        line-height: 48px;
        margin-top: 24px;

        @media (max-width: 768px) {
            font-size: 32px;
            line-height: 42px;
        }

        @media (max-width: 575px) {
            font-size: 22px;
            line-height: 32px;
        }
    }

    &__instructions-list {
        list-style: auto;
    }

    &__instructions-list-item {
        @include text(16px, 400, none, none, $white);
        line-height: 26px;
        margin-top: 16px;

        @media (max-width: 768px) {
            font-size: 16px;
            line-height: 22px;
        }

        // @media (max-width: 575px) {
        //     font-size: 12px;
        //     line-height: 20px;
        // }
    }

    &__instructions-section.membership &__instructions-title {
        @media (max-width: 1024px) {
            margin-top: 60px;
        }

        @media (max-width: 768px) {
            margin-top: 40px;
        }
    }

    &__instructions-paragraphs {
        margin-top: 32px;

        @media (max-width: 768px) {
            margin-top: 24px;
        }
    }

    &__instructions-paragraphs .new-link-block.full {
        @include text(16px, 600, none, none, $dubai);
        padding: 12px 24px;
        max-width: 260px;
        width: 100%;
        background-color: $white;
        border: none;
        margin-top: 24px;

        @media (max-width: 575px) {
            max-width: unset;
            width: 100%;
        }
    }

    &__instructions-list {
        padding-left: 18px;
        list-style: decimal;
    }

    &__instructions-list-item,
    &__instructions-list-item a {
        @include text(16px, 400, none, none, $white);
        line-height: 26px;

        @media (max-width: 575px) {
            font-size: 16px;
            line-height: 22px;
        }
    }

    &__instructions-list-item a {
        color: $white;
        text-decoration: underline;
    }

    &__instructions-paragraph,
    &__instructions-summary {
        @include text(16px, 400, none, none, $white);
        line-height: 26px;
        margin-top: 16px;

        @media (max-width: 768px) {
            font-size: 16px;
            line-height: 22px;
        }

        // @media (max-width: 575px) {
        //     font-size: 12px;
        //     line-height: 20px;
        // }
    }

    &__instructions-summary {
        margin-top: 34px;
    }

    &__instructions-paragraph a,
    &__instructions-summary a {
        @include text(16px, 400, none, none, $white);
        line-height: 26px;
        margin-top: 16px;
        text-decoration: underline;

        @media (max-width: 768px) {
            font-size: 16px;
            line-height: 22px;
        }

        // @media (max-width: 575px) {
        //     font-size: 12px;
        //     line-height: 20px;
        // }
    }

    &__instructions-paragraph.become {
        margin-bottom: 16px;
    }

    // overriding styles if country has content:
    &__instructions.with-content {
        padding: 120px 0;
        background-color: $grey-winter;

        @media(max-width: 768px) {
            padding: 100px 0;
        }

        @media(max-width: 575px) {
            padding: 80px 0;
        }

        .freight-forwarder-landing__instructions-title,
        .freight-forwarder-landing__instructions-paragraph,
        .freight-forwarder-landing__instructions-paragraph a,
        .freight-forwarder-landing__instructions-list-item,
        .freight-forwarder-landing__instructions-list-item a,
        .freight-forwarder-landing__instructions-summary,
        .freight-forwarder-landing__instructions-summary a {
            color: $dubai !important;
        }

        .freight-forwarder-landing__instructions-title:not(:first-child) {
            margin-top: 80px;

            @media(max-width: 768px) {
                margin-top: 60px;
            }

            @media(max-width: 575px) {
                margin-top: 40px 0;
            }
        }
    }

    // SECTION: CALL TO ACTION
    &__cta {
        padding: 80px 0;
        background-color: $grey-winter;
    }

    &__cta-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    &__cta-title {
        font-size: 36px;
        font-weight: 600;
        line-height: 48px;
        text-align: center;
        color: $dubai;
    }

    &__cta-description {
        margin-top: 24px;
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;
        color: $dubai;
    }

    &__cta .new-link-block.full {
        margin-top: 24px;
        width: 256px;
        color: $grey-winter;
        border-color: $dubai;
        background-color: $dubai;
    }

    // SECTION: POSTS
    & .freight-dictionary__posts {
        margin-top: 160px;

        @media (max-width: 575px) {
            margin-top: 80px;
        }
    }

    & .freight-dictionary__posts-title-text,
    & .freight-dictionary__post-item-title {
        color: $white;
    }

    & .freight-dictionary__post-item {
        background-color: $storm;
    }

    & .freight-dictionary__post-item-date {
        color: $ice;
    }

    // SECTION: ABOUT
    &__about {
        margin-top: 160px;

        @media (max-width: 575px) {
            margin-top: 80px;
        }
    }

    &__about-overlay {
        padding: 80px 48px;
        border-radius: 60px;
        background-color: $storm;
    }

    &__about-content {
        max-width: 1000px;
        width: 100%;
        margin: 0 auto;
    }

    &__about-title {
        @include text(36px, 600, none, none, $white);
        line-height: 48px;
        text-align: center;
        margin: 0 auto;

        @media (max-width: 768px) {
            font-size: 22px;
            line-height: 32px;
        }

        @media (max-width: 575px) {
            font-size: 18px;
            line-height: 28px;
        }
    }

    &__about-paragraph {
        @include text(16px, 500, none, none, $white);
        line-height: 26px;
        text-align: center;
        margin-top: 24px;

        @media (max-width: 768px) {
            font-size: 16px;
            line-height: 22px;
            margin-top: 16px;
        }

        @media (max-width: 575px) {
            // font-size: 18px;
            // line-height: 28px;
            margin-top: 8px;
        }
    }

    // SECTION: LICENSE
    &__license {
        margin-top: 160px;

        @media (max-width: 575px) {
            margin-top: 80px;
        }
    }

    &__license-title {
        @include text(36px, 600, none, none, $white);
        line-height: 26px;
        text-align: center;

        @media (max-width: 768px) {
            font-size: 32px;
            line-height: 42px;
        }

        @media (max-width: 575px) {
            font-size: 22px;
            line-height: 32px;
        }
    }

    &__license-description-box {
        margin-top: 24px;

        @media (max-width: 768px) {
            margin-top: 16px;
        }
    }

    &__license-paragraph {
        @include text(16px, 600, none, none, $white);
        line-height: 26px;
        max-width: 1000px;
        width: 100%;
        text-align: center;
        margin: 0 auto;

        @media (max-width: 768px) {
            font-size: 16px;
            line-height: 22px;
        }

        // @media (max-width: 575px) {
        //     font-size: 12px;
        //     line-height: 20px;
        // }
    }

    &__license-button-box {
        margin-top: 24px;
        display: flex;
        justify-content: center;
        align-self: center;
    }

    &__license .new-link-block.full {
        @include text(16px, 600, none, none, $dubai);
        padding: 12px 24px;
        width: unset;
        background-color: $white;
        border: none;

        @media (max-width: 575px) {
            width: 100%;
        }
    }

    // SECTION: OTHER LOCATIONS
    &__other-locations {
        padding: 80px 0;
        background-color: $grey-winter;
    }

    &__other-locations-title {
        margin: 0 auto;
        font-size: 48px;
        font-weight: 600;
        line-height: 60px;
        color: $dubai;

        @media(max-width: 768px) {
            font-size: 42px;
            line-height: 44px;
        }

        @media(max-width: 575px) {
            font-size: 36px;
            line-height: 46px;
        }
    }

    &__other-locations-list {
        margin-top: 16px;
        display: flex;
        gap: 24px;

        @media(max-width: 575px) {
            flex-direction: column;
            gap: 16px;
        }
    }
}