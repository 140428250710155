.services {
  background-color: $blue-dark-1;
  padding-top: 232px;
  padding-bottom: 168px;

  background-image: url('/images/pages/Services/eclipse.png');
  background-position: center;
  background-repeat: no-repeat;


  @media (max-width: 969px) {
    padding-top: 245px;
    padding-bottom: 180px;
  }

  @media (max-width: 475px) {
    padding-top: 120px;
    padding-bottom: 56px;
  }

  &-container {
    //max-width: 1320px;
    margin: 0 auto;

    &__breadcrumbs {
      @media (max-width: 475px) {
        padding: 0;
      }
    }

    @media (max-width: 475px) {
      padding: 0 16px;
    }
  }

  &-title {
    display: flex;

    svg {
      transform: scale(0.6);
    }

    &__prev {
      display: none;
      margin-right: 8px;
      @media (max-width: 475px) {
        display: block;
      }
    }
    &__next {
      display: none;
      margin-left: 8px;
      @media (max-width: 475px) {
        display: block;
      }
    }
    h1 {
      flex: 1;
      @include new-text(48px, 600, 60px, none, none, $white);
      text-align: center;

      @media (max-width: 969px) {
        @include new-text(44px, 600, 54px, none, none, $white);
      }

      @media (max-width: 475px) {
        text-align: left;
        @include new-text(24px, 600, 34px, none, none, $white);
      }
    }
  }



  .servicesSwiper {
    margin-top: 64px;
    max-width: 1178px;
    margin-left: 0;
    margin-right: 0;

    @media (max-width: 475px) {
      margin-top: 20px;
    }

    .swiper-wrapper {
      display: grid;
      grid-auto-flow: column;
    }
  }

  &-slider-block {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;

    @media (max-width: 969px) {
      gap: 19px;
    }

    &__prev {
      margin-top: 66px;

      @media (max-width: 969px) {
        margin-right: -4px;
        margin-left: 4px;
      }

      @media (max-width: 475px) {
        display: none;
      }
    }
    &__next {
      margin-top: 66px;

      @media (max-width: 969px) {
        margin-right: 4px;
        margin-left: -4px;
      }

      @media (max-width: 475px) {
        display: none;
      }
    }

    &__pagination {
      margin-top: 24px;
      display: flex;
      justify-content: center;
      gap: 8px;

      @media (max-width: 969px) {
        margin-top: 47px;
      }

      @media (max-width: 475px) {
        margin-top: 34px;
      }

      &-item {
        width: 8px;
        height: 8px;
        border-radius: 8px;
        background-color: $white;
        opacity: 0.2;

        &-active {
          opacity: 0.8;
          width: 24px;
        }
      }
    }
  }

  &-Swiper {
    &__item {
      display: flex;
      flex-direction: column;
      padding: 24px;
      color: $white;
      min-height: 344px;
      height: inherit;
      border: 1px solid #FFFFFF17;
      box-shadow: 0px 4px 20px 0px #FFFFFF0A inset;
      background: rgba(255, 255, 255, 0.03);



      border-radius: 16px;

      @media (max-width: 969px) {
        min-height: 422px;
      }

      @media (max-width: 475px) {
        padding: 24px 16px;
        min-height: 396px;
      }

      &-imageWrapper {
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #D8E0FF1A;
        border-radius: 8px;
      }

      &-header {
        display: flex;
        align-items: center;
        gap: 16px;
        border-bottom:  1px solid #FFFFFF17;
        padding-bottom: 16px;
      }

      &-title {
        @include new-text(20px, 600, 30px, none, none, $white);

        @media (max-width: 475px) {
          @include new-text(18px, 600, 28px, none, none, $white);
        }
      }

      &-text {
        margin-top: 16px;
        @include new-text(16px, 400, 26px, none, none, $white);
        flex: 2;
      }

      &-link {
        @include new-text(16px, 600, 26px, none, none, $white);
        display: flex;
        align-items: center;
        gap: 4px;

        div {
          display: flex;
          align-items: center;
        }
      }
    }
  }
}