.notification {
    margin-bottom: 16px;
    padding: 14px 24px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border-radius: 10px;
    background-color: $blue-extra-light;

    @media(max-width: 991px) {
        padding: 16px;
    }

    @media(max-width: 767px) {
        flex-direction: column;
    }

    &__wrapping {
        display: flex;
    }

    &__image-box {
        margin-right: 15px;
    }

    &__image {

    }

    &__description-box {
        max-width: 1050px;
        padding-right: 20px;

        @media(max-width: 767px) {
            padding-right: 0;
        }
    }

    &__title {
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        margin-bottom: 5px;
    }

    &__link-box {
        width: 185px;
        display: flex;
        align-self: center;

        @media(max-width: 1070px) {
            width: 210px;
            align-self: flex-start;
        }

        @media(max-width: 991px) {
            width: 240px;
        }

        @media(max-width: 900px) {
            width: 290px;
        }

        @media(max-width: 767px) {
            margin-left: 34px;
        }
    }

    &__link-box .btn.btn-arrow-link {
        color: $dark;
    }

    &__link-box .btn.btn-arrow-link.isDisabled {
        color: $disabled-text;
        pointer-events: none;
    }

    &__link-box .btn.btn-arrow-link:after {
        background-color: $dark;
    }

    &__link-box .btn.btn-arrow-link.isDisabled:after {
        background-color: $disabled-text;
    }

}
