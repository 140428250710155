@import "../../../global/colors";

.resources {
    background-color: $dubai-dark;

    // SECTION: HEADER HERO
    &__header-hero {
        position: relative;
        padding: 240px 0 180px;
        

        @media(max-width: 768px) {
            padding: 220px 0 160px;
        }

        @media(max-width: 575px) {
            padding: 200px 0 140px;
        }
    }

    &__header-hero-bg-image {
        position: absolute;
        z-index: 1;
    }

    &__header-hero-bg-image.left {
        width: 550px;
        left: 0;
        bottom: 0;

        @media(max-width: 1440px) {
            width: 37%;
        }

        @media(max-width: 768px) {
            width: 50%;
        }
    }

    &__header-hero-bg-image.right {
        width: 650px;
        right: 0;
        top: 0;

        @media(max-width: 1440px) {
            width: 45%;
        }

        @media(max-width: 768px) {
            width: 50%;
        }

        @media(max-width: 525px) {
            width: 60%;
        }
    }

    &__header-hero-title-box {
        position: relative;
        z-index: 2;
        margin: 0 auto;
        max-width: 800px;
        width: 100%;
    }

    &__header-hero-title {
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: 48px;
        font-weight: 600;
        line-height: 60px;
        color: $grey-winter;

        @media(max-width: 768px) {
            text-align: center;
            font-size: 32px;
            line-height: 44px;
        }
    
        @media(max-width: 575px) {
            font-size: 24px;
            line-height: 36px;
        }
    }

    &__header-hero-title-description {
        margin-top: 32px;
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;
        color: $grey-winter;
    }

    // SECTION: CARDS
    &__cards {
        padding: 90px 0;

        @media(max-width: 768px) {
            padding: 90px 0;
        }

        @media(max-width: 575px) {
            padding: 90px 0;
        }
    }

    &__cards-title {
        font-size: 36px;
        font-weight: 600;
        line-height: 48px;
        text-align: center;
        color: $grey-winter;
    
        @media (max-width: 768px) {
            font-size: 32px;
            line-height: 44px;
        }
    
        @media (max-width: 575px) {
            font-size: 24px;
            line-height: 36px;
        }
    }

    &__cards-list {
        margin-top: 32px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 24px;
    }

    &__cards-list-item {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 374px;
        padding: 24px;
        border-radius: 24px;
        background-color: rgba(255, 255, 255, 0.06);
    }

    &__cards-list-item-title,
    &__cards-list-item-description {
        text-align: left;
        color: $grey-winter;
    }

    &__cards-list-item-title {
        font-size: 20px;
        font-weight: 600;
        line-height: 30px;
    }

    &__cards-list-item-description {
        margin-top: 16px;
        min-height: 156px;
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;
    }

    &__cards-list-item-buttons {
        margin-top: 16px;
    }
}