// !!! THESE ARE VALUES ACCORDING TO DFA DESIGN SYSTEM !!!
// !!! USE THESE VALUES WHILE DEVELOPING AND REFACTORING !!!
// !!! THERE ARE OLD VALUES LOCATED IN /src/styles/utils/_typography CURRENTLY USED IN PROJECT !!!
// !!! USE STYLES FROM /src/styles/global/_typography !!!

// RULES: FONT SIZE AND LINE HEIGHT RELATIONS
// 72 - 84
// 54 - 60
// 48 - 60
// 44 - 54
// 36 - 46
// 32 - 42
// 24 - 34
// 20 - 30
// 18 - 28
// 16 - 26
// 14 - 22
// 12 - 20
// 10 - 12

// RULES: FONT WEIGHT
// headings:
// 700 - bold
// 600 - semibold
// text:
// 700 - bold
// 600 - semibold
// 500 - medium
// 400 - regular

@import "./colors";

// HEADING
@mixin heading-h1($weight: 600, $align: center, $color: $winter) {
    font-size: 72px;
    line-height: 84px;
    font-weight: $weight;
    text-align: $align;
    color: $color;

    @media(max-width: 768px) {
        font-size: 54px;
        line-height: 72px;
    }

    @media(max-width: 575px) {
        font-size: 54px;
        line-height: 60px;
    }
}

@mixin heading-h2($weight: 600, $align: center, $color: $dubai) {
    font-size: 48px;
    line-height: 60px;
    font-weight: $weight;
    text-align: $align;
    color: $color;

    @media(max-width: 768px) {
        font-size: 44px;
        line-height: 54px;
    }

    @media(max-width: 575px) {
        font-size: 24px;
        line-height: 34px;
    }
}

@mixin heading-h3($weight: 600, $align: center, $color: $dubai) {
    font-size: 36px;
    line-height: 48px;
    font-weight: $weight;
    text-align: $align;
    color: $color;

    @media(max-width: 768px) {
        font-size: 32px;
        line-height: 42px;
    }

    @media(max-width: 575px) {
        font-size: 22px;
        line-height: 32px;
    }
}

@mixin heading-h4($weight: 600, $align: center, $color: $dubai) {
    font-size: 20px;
    line-height: 30px;
    font-weight: $weight;
    text-align: $align;
    color: $color;

    @media(max-width: 575px) {
        font-size: 18px;
        line-height: 28px;
    }
}

@mixin heading-h5($weight: 600, $align: center, $color: $dubai) {
    font-size: 18px;
    line-height: 28px;
    font-weight: $weight;
    text-align: $align;
    color: $color;

    @media(max-width: 575px) {
        font-size: 16px;
        line-height: 26px;
    }
}

@mixin heading-h6($weight: 600, $align: center, $color: $dubai) {
    font-size: 16px;
    line-height: 26px;
    font-weight: $weight;
    text-align: $align;
    color: $color;
}

// TEXT
@mixin text-01($weight: 400, $transform: none, $decoration: none, $color: $dubai, $align: left) {
    font-size: 16px;
    line-height: 26px;
    font-weight: $weight;
    text-transform: $transform;
    text-decoration: $decoration;
    text-align: $align;
    color: $color;
}

@mixin text-02($weight: 400, $transform: none, $decoration: none, $color: $dubai, $align: left) {
    font-size: 14px;
    line-height: 22px;
    font-weight: $weight;
    text-transform: $transform;
    text-decoration: $decoration;
    text-align: $align;
    color: $color;
}

@mixin text-03($weight: 400, $transform: none, $decoration: none, $color: $dubai, $align: left) {
    font-size: 12px;
    line-height: 20px;
    font-weight: $weight;
    text-transform: $transform;
    text-decoration: $decoration;
    text-align: $align;
    color: $color;
}
