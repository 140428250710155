.surveys {
    padding: 120px 0;

    input {
        color: $dark-blue;

        &.input__other {
            margin-left: 1rem;
            position: relative;
            background: #ffffff;
            height: 35px;
            width: 100%;
            max-width: 460px;
            padding: 0 16px;
            outline: none;
            border: 1px solid #e0e4ec;
            border-radius: 6px;
            box-sizing: border-box;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.02em;
            transition: 0.3s 0s;

            @include media-breakpoint-up(md) {
                &:hover:enabled {
                    border: 1px solid $accent;
                }
            }

            @include media-breakpoint-down(sm) {
                margin-left: 0;
            }
        }
    }

    .surveys__header {
        text-align: center;

        h1 {
            @include h1;
        }

        h3 {
            @include h3;
            color: $dark-grey;
            padding-top: 1rem;
        }
    }

    .surveys__main-form {
        padding-top: 3rem;

        .preview-input-wrapper {
            padding: 2rem;
            margin-bottom: 2rem;
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: repeat(5, 1fr);
            grid-column-gap: 2rem;
            grid-row-gap: 1rem;
            box-shadow: 0 10px 30px rgba(58, 112, 191, 0.15);
            border-radius: 8px;

            @include media-breakpoint-down(lg) {
                grid-template-columns: 1fr;
                grid-template-rows: repeat(9, 1fr);
                grid-column-gap: initial;
            }

            div.input:last-of-type {
                max-width: initial;
                grid-column-start: 1;
                grid-column-end: 3;

                @include media-breakpoint-down(lg) {
                    grid-column-end: 2;
                }

                input {
                    pointer-events: none;
                }
            }
        }

        .main-form__submit-btn {
            margin: 3rem auto 0;
        }
    }

    .question {
        padding-top: 1rem;

        .question__title {
            @include h3;
            margin-bottom: 0.9rem;
        }

        .elements-wrapper__line {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            & > * {
                margin: 0!important;
            }
        }

        .checkbox-container__outer {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 100%;

            &.with_field {
                margin-bottom: 0.5rem;

                & > label {
                    margin-bottom: 0;
                }
            }
        }

        .checkbox-container {
            padding-left: 1rem;
            margin-bottom: 0.95rem;

            .input__other {
                opacity: 1;
                margin-left: 0;
            }

            .customNumEl_box {
                display: flex;
                max-width: 100px;
                //border: 1px solid tomato;
                margin-right: 1rem;

                span {
                    display: flex;
                    width: 21px;
                    height: 21px;
                    border: 1px solid $light-grey;
                    border-radius: 50%;
                    text-align: center;
                    align-items: center;
                    justify-content: center;
                    color: $light-grey;
                    transition: 0.2s;

                    @include media-breakpoint-up(md) {
                        &:hover {
                            color: $accent;
                            border: 1px solid $accent;
                        }
                    }
                }

                input {
                    position: initial;
                    opacity: 1;
                    cursor: initial;
                    height: auto;
                    width: 22px;
                    margin: 0 0.2rem;
                    text-align: center;
                    border-color: transparent;
                    font-weight: 600;
                    font-size: 0.9rem;
                    color: $white;
                    background: $accent;
                }
            }
        }

        &.question_7 {
            .checkbox-container {
                margin-bottom: 0;
                height: 48px;
                line-height: 25px;
            }
        }
    }
}
