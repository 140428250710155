.input {
    position: relative;
    display: flex;
    flex-direction: column;

    &__label {
        font-weight: bold;
        font-size: 10px;
        line-height: 12px;
        letter-spacing: 0.06em;
        text-transform: uppercase;

        margin-bottom: 8px;

        color: #000c37;
    }

    &__body {
        position: relative;

        background: #ffffff;
        height: 50px;

        padding: 0 16px;

        outline: none;
        border: 1px solid #e0e4ec;
        box-sizing: border-box;
        border-radius: 6px;

        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.02em;
        font-family: Montserrat, sans-serif;

        transition: 0.3s 0s;

        &-eye {
            position: absolute;
            width: 35px;

            cursor: pointer;

            right: 10px;
            top: 50%;
            transform: translateY(-50%);

            padding: 5px;

            img {
                width: 100%;
            }
        }

        &:active {
            border: 1px solid $accent;
            @include shadow_1;
        }

        &:hover {
            border: 1px solid $accent;
        }

        &::placeholder {
            color: #9ea9b9;
        }

        &--error {
            border: 1px solid #f52c5c !important;
            color: #f52c5c !important;

            &::placeholder {
                color: #f52c5c !important;
            }
        }

        &--select {
            color: #9ea9b9;
            appearance: none !important;
        }
    }

    &__error {
        position: absolute;
        top: calc(100%);
        left: 0;

        letter-spacing: 0.06em;
        font-family: 'SF Pro Text', sans-serif;

        color: #f52c5c;

        font-weight: bold;
        font-size: 10px;
        line-height: 12px;
    }

    &__textarea {
        position: relative;
        font-family: inherit;

        background: #ffffff;
        padding: 16px;

        outline: none;
        border: 1px solid #e0e4ec;
        box-sizing: border-box;
        border-radius: 6px;

        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.02em;

        resize: none;
        height: 140px;

        transition: 0.3s 0s;

        &--error {
            border: 1px solid #f52c5c !important;

            &::placeholder {
                color: #f52c5c !important;
            }
        }

        &:active {
            border: 1px solid $accent;
            @include shadow_1;
        }

        &:hover {
            border: 1px solid $accent;
        }

        &::placeholder {
            color: #9ea9b9;
        }
    }
}

@include media-breakpoint-down(md) {
    .input {

        &-textarea {
            height: 106px;
        }
    }
}
