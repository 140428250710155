.events.events__members-meeting {
    // SECTION: INTRO
    .intro-section {
        background-image: url(/images/pages/Events/MembersMeeting/intro-section__bg.png);
        background-size: cover;
        display: flex;
        align-items: center;
        padding: 131px 0 80px;
        background-position-x: 50%;
        overflow: hidden;
    }

    .intro-section__plaque {
        @media (max-width: 998px) {
            width: 350px;
        }

        @media (max-width: 575px) {
            max-width: 340px;
            width: 100%;
        }
    }

    // SECTION: DESCRIPTION
    .article-section {
        padding-bottom: 150px;

        @media (max-width: 768px) {
            padding-bottom: 50px;
        }
    }

    .article-section__description {
        width: 100%;
    }

    .article-section__description-content {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        @media (max-width: 768px) {
            display: block;
        }
    }

    .article-section__description-box {
        width: 45%;

        @media (max-width: 768px) {
            width: 100%;
        }
    }

    .article-section__description-box:first-child {
        @media (max-width: 768px) {
            margin-bottom: 20px;
        }
    }

    .article-section__description-text:last-child {
        display: flex;
        flex-direction: column;
    }

    // SECTION: AGENDA
    .agenda-section {
        padding: 0 0 170px 0;

        @media (max-width: 990px) {
            position: relative;
            z-index: 1;
        }

        @media (max-width: 768px) {
            padding-bottom: 60px;
        }
    }

    .agenda-section .container {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media (max-width: 575px) {
            flex-direction: column;
        }
    }

    .agenda-section__box,
    .agenda-section__info-box {
        width: 45%;
    }

    .agenda-section__box {
        width: 56%;
        height: 518px;

        @media (max-width: 575px) {
            width: auto;
            height: auto;
            margin-bottom: 100px;
        }
    }

    .agenda-section__image-box--desktop {
        overflow: hidden;
        height: 518px;
        position: relative;

        @media (max-width: 990px) {
            display: none;
        }
    }

    .agenda-section__image-box--tablet {
        display: none;
        overflow: hidden;
        width: 56%;
        height: 518px;
        position: absolute;
        top: 0;
        z-index: 2;

        @media (max-width: 990px) {
            display: block;
            left: -120px;
            width: 70%;
        }

        @media (max-width: 768px) {
            width: 370px;
            height: 365px;
            top: 70px;
            left: -70px;
        }

        @media (max-width: 575px) {
            display: none;
        }
    }

    .agenda-section__image-box--mobile {
        display: none;
        width: 100%;

        @media (max-width: 575px) {
            display: block;
        }
    }

    .agenda-section__image {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;

        @media (max-width: 575px) {
            position: unset;
        }
    }

    .agenda-section__info-box {
        @media (max-width: 575px) {
            width: unset;
        }
    }

    .agenda-section__info-title {
        margin-bottom: 16px;
        @include new-text(16px, 600, 26px, none, none, $blue-dark);
    }

    .agenda-section__info-list {
        margin-bottom: 16px;
        list-style: disc;
        @include new-text(16px, 400, 20px, none, none, $blue-dark);

        @media (max-width: 575px) {
            padding-left: 20px;
        }
    }

    .agenda-section__info-list-item {
        margin-bottom: 5px;
    }

    .agenda-section__info-paragraph {
        @include new-text(16px, 400, 26px, none, none, $blue-dark);
    }

    .agenda-section__buttons-box {
        margin-top: 32px;
    }

}
