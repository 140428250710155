.log-in {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    &__form {
        position: relative;
        z-index: 1;
        width: 360px + (2 * 20);
        padding: 0 20px;
        transition: 0.2s ease;

        &.hidden {
            opacity: 0;
        }

        &-title {
            @include h2;
            margin-bottom: 30px;
            text-align: center;
        }

        .input {
            margin-bottom: 20px;
        }

        & .btn-full {
            margin-bottom: 10px;
        }

        & .btn-full.isDisabled {
            color: $dark-grey;
            pointer-events: none;
        }

        & .btn-full a {
            display: block;
            padding: 10px;
            color: $white;
        }

        .control__link {
            text-align: center;

            &:not(:last-of-type) {
                margin: 1.5rem auto 1rem;

                a {
                    padding-left: .5rem;
                }
            }
        }
    }

    &__bg {
        position: absolute;

        bottom: 0;
        left: 0;

        width: 100vw;
        height: calc(100vh - 65px);

        img {
            width: 100%;
            height: 100%;

            object-fit: cover;

            user-select: none;
        }
    }

    .contact__loader {
        top: 6px;
    }
}

@include media-breakpoint-down(xs) {
    .log-in {

        .contact__loader {
            top: 16px;
        }
    }
}
