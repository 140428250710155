.multiple-select {
    &__label {
        display: flex;
        flex-direction: column;
        position: relative;
    }

    &__label-text {
        @include new-text(10px, 700, 22px, uppercase, none, $blue-dark);
        letter-spacing: 1px;
        margin-bottom: 8px;
    }

    &__input {
        width: inherit;
        padding: 8px 16px;
        border: 1px solid $gray-middle-02;
        border-radius: 6px;
        outline: none;
        cursor: pointer;
    }

    &__input::placeholder {
        @include new-text(14px, 400, 22px, none, none, $gray-middle-02);
    }

    &__input-icon {
        position: absolute;
        top: 45px;
        right: 15px;
        transform: rotate(0);
        transition: $transition;
    }

    &__input-icon.is-opened {
        transform: rotate(180deg);
    }

    &__input.error {
        border: 1px solid $icon-crimson-hover;
    }

    &__error {
        margin-top: -4px;
        @include new-text(11px, 400, 100%, none, none, $icon-crimson-hover);
    }

    &__dropdown {
        padding: 4px;
        border-radius: 5px;
        box-shadow: $box-shadow;
        transition: $transition;
        opacity: 0;
        visibility: hidden;
        height: 0;
    }

    &__dropdown.is-opened {
        opacity: 1;
        visibility: visible;
        height: auto;
    }

    &__dropdown-item {
        //padding: 8px 12px;
        //display: flex;
        //justify-content: space-between;
        //align-items: center;
        //border-radius: 5px;
        //cursor: pointer;
        //transition: $transition;
    }

    &__dropdown-item:hover {
        background-color: $blue-light-accent;
    }

    &__checkbox-item {

    }

    &__checkbox-item-label {
        padding: 8px 12px;
        border-radius: 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        transition: $transition;
        position: relative;
    }

    &__checkbox-item-label:hover {
        background-color: $blue-light-accent;
    }

    &__checkbox-input {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
    }

    &__checkbox-checkmark {
        width: 16px;
        height: 16px;
        border: 1px solid $gray-middle-02;
        border-radius: 2px;
        position: relative;
        cursor: pointer;
    }

    &__checkbox-checkmark:after {
        content: '';
        display: block;
        width: 10px;
        height: 10px;
        position: absolute;
        top: 2px;
        left: 2px;
        background: url("/images/pages/Resources/Blacklist/icon_tick.svg");
        opacity: 0;
        transition: $transition;
    }

    &__checkbox-input:checked ~ .multiple-select__checkbox-checkmark {
        background-color: $blue-dark;

    }

    &__checkbox-input:checked ~ .multiple-select__checkbox-checkmark:after {
        opacity: 1;
    }
}
