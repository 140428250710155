$flag-icon-css-path: '/images/flags' !default;
$flag-icon-rect-path: '/4x3' !default;

.flag-icon-background {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}

.flag-icon {
  @extend .flag-icon-background;
  position: relative;
  display: inline-block;
  width: (4 / 3) * 1em;
  min-width: (4 / 3) * 1em;
  line-height: 1em;
  box-shadow: 0 1px 2px 1px rgba(119, 149, 255, 0.1);
  border-radius: 2px;
  background-size: cover;
  &:before {
    content: '\00a0';
  }
}

@mixin flag-icon($country) {
  .flag-icon-#{$country} {
    background-image: url(#{$flag-icon-css-path}#{$flag-icon-rect-path}/#{$country}.svg);
  }
}

$include: ad, ae, af, ag, ai, al, am, ao, aq, ar, as, at, au, aw, ax, az, ba, bb,
  bd, be, bf, bg, bh, bi, bj, bl, bm, bn, bo, bq, br, bs, bt, bv, bw, by, bz, ca,
  cc, cd, cf, cg, ch, ci, ck, cl, cm, cn, co, cr, cu, cv, cw, cx, cy, cz, de, dj,
  dk, dm, do, dz, en, ec, ee, eg, eh, er, es, et, fi, fj, fk, fm, fo, fr, ga, gb,
  gd, ge, gf, gg, gh, gi, gl, gm, gn, gp, gq, gr, gs, gt, gu, gw, gy, hk, hm, hn,
  hr, ht, hu, id, ie, il, im, in, io, iq, ir, is, it, je, jm, jo, jp, ke, kg, kh,
  ki, km, kn, kp, kr, kw, ky, kz, la, lb, lc, li, lk, lr, ls, lt, lu, lv, ly, ma,
  mc, md, me, mf, mg, mh, mk, ml, mm, mn, mo, mp, mq, mr, ms, mt, mu, mv, mw, mx,
  my, mz, na, nc, ne, nf, ng, ni, nl, no, np, nr, nu, nz, om, pa, pe, pf, pg, ph,
  pk, pl, pm, pn, pr, ps, pt, pw, py, qa, re, ro, rs, ru, rw, sa, sb, sc, sd, se,
  sg, sh, si, sj, sk, sl, sm, sn, so, sr, ss, st, sv, sx, sy, sz, tc, td, tf, tg,
  th, tj, tk, tl, tm, tn, to, tr, tt, tv, tw, tz, ua, ug, um, us, uy, uz, va, vc,
  ve, vg, vi, vn, vu, wf, ws, ye, yt, za, zm, zw, n1, n2, n3, es-cn, s0, ic, an, xk; // custom
@each $country in $include {
  @include flag-icon($country);
}
