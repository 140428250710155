.app:has(.page-404) {
  background-color: $blue-dark-1;
}

.page-404 {
  background-color: $blue-dark-1;

  &__texts {
    background-image: url("/images/pages/page404/ooops.png");
    background-repeat: no-repeat;
    background-position: center 240px;
    padding-top: 287px;
    margin: 0 auto;
    text-align: center;

    @media (max-width: 769px) {
      padding-top: 344px;
      background-size: 75%;
      background-position: center 320px;
    }

    @media (max-width: 475px) {
      padding-top: 138px;
    }


    &-text {
      @include new-text(16px, 400, 26px, none, none, $white);

      a {
        @include new-text(16px, 700, 26px, none, none, $white);
      }

    }

    &-btn {
      margin-top: 48px;
      @include new-text(16px, 600, 26px, none, none, $dubai);
      padding: 10px 24px;
      cursor: pointer;
      border-radius: 6px;
      font-family: Montserrat;
      border: none;

      @media (max-width: 475px) {
        margin-top: 22px;
      }
    }

  }

  &__images {
    margin-top: 64px;
    margin-bottom: -160px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    @media (max-width: 1440px) {
      align-items: flex-start;
    }

    @media (max-width: 769px) {
      margin-top: 166px;
      margin-bottom: -114px;
    }

    @media (max-width: 475px) {
      margin: 48px -16px 26px;
    }


    &-404 {
      margin-bottom: 155px;

      @media (max-width: 1440px) {
        margin-top: 185px;
        margin-bottom: 0;
      }

      @media (max-width: 769px) {
        margin-top: 106px;
      }

      @media (max-width: 475px) {
        margin-top: 66px;

        &:first-child {
          margin-left: -41px;
          margin-right: 28px;
        }

        &:last-child {
          margin-left: 11px;
          margin-right: -12px;
        }

        img {
          height: 155px;
        }
      }
    }

    &-center {
      position: relative;
      border-radius: 100%;
      background-color: $blue-dark-1;
      display: flex;
      justify-content: center;
      width: 0;

      img {
        border-radius: 100%;
        background-color: $blue-dark-1;

        @media (max-width: 769px) {
          border-radius: 100%;
          background-color: $blue-dark-1;
          width: 480px;
        }

        @media (max-width: 475px) {
          width: 279px;
        }
      }
    }
  }
}