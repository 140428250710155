.new-input {


    &__label {
        display: flex;
        flex-direction: column;
    }

    &__label-text {
        @include new-text(10px, 700, 22px, uppercase, none, $blue-dark);
        letter-spacing: 1px;
        margin-bottom: 8px;
    }

    &__element {
        box-sizing: border-box;
        width: inherit;
        padding: 8px 16px;
        border: 1px solid $gray-middle-02;
        border-radius: 6px;
        outline: none;
    }

    &__element.error {
        border: 1px solid $icon-crimson-hover;
    }

    &__element::placeholder {
        @include new-text(14px, 400, 22px, none, none, $gray-middle-02);
    }

    &__error {
        margin-top: 4px;
        @include new-text(11px, 400, 100%, none, none, $icon-crimson-hover);
    }
}
