:root {
    --margin: 30px;
}

.custom-tooltip {
    display: inline-block;
    position: relative;

    &__tip {
        width: 300px;
        position: absolute;
        padding: 16px;
        border-radius: 10px;
        text-align: justify;
        @include text(14px, 400, none, none, $disabled-text);
        box-shadow: $shadow-01;
        z-index: 100;
    }
}
