.d-filter {
    position: relative;
    display: grid;
    grid-template-columns: 34% 45% auto;
    gap: 2px;

    padding: 4px;

    border-radius: 10px;
    background: $white;
    @include shadow_1;

    .input {

        &__body {
            border: 1px solid transparent;
            @include body1;

            &:focus {
                border: 1px solid $accent;
            }
        }

        &:after {
            content: '';

            @include absolut-center-y;
            left: 100%;

            width: 2px;
            height: 28px;

            background: $white2;

            border-radius: 5px;
        }
    }

    &__loader {
        position: absolute;

        transition: .3s 0s;

        opacity: 1;
        visibility: visible;

        width: 100%;
        height: 100%;

        display: flex;
        align-items: center;

        border-radius: 10px;
        background: rgba($white, .7);

        .contact__loader {
            position: relative;
            margin: 0 auto;

            border-top-color: $dark-blue;
        }

        &.hide {
            opacity: 0;
            visibility: hidden;
        }
    }

    &__filter {
        position: relative;

        border-radius: 6px;
        transition: .3s 0s;

        &-body {
            width: 100%;
            height: 100%;
            position: relative;

            display: flex;
            align-content: center;
            justify-content: center;

            cursor: pointer;

            &:hover {
                background: rgba($white3, .7);
            }

            img {
                width: 12px;
                user-select: none;
            }

            &-dot {
                position: absolute;
                background-color: #FF003D;
                border-radius: 100%;
                top: 31%;
                right: 32%;
                width: 7px;
                height: 7px;
                box-shadow: 0px 1px 3px 0px #FF003D66;

            }
        }

        &.isOpen {
            background: $white3;
        }
    }

    &__small {
        position: absolute;
        cursor: default;

        width: 240px;
        padding: 0 16px;

        top: 100%;
        right: 0;

        background: $white;
        border-radius: 6px;
        @include shadow_1;

        opacity: 0;
        visibility: hidden;
        transition: .3s 0s;

        .input {
            margin: 16px 0;

            &:after {
                display: none;
            }
        }

        .select {

            &__item {
                padding: 8px 12px;
            }

            &__panel {
                height: 38px;
            }
        }

        &.isOpen {
            opacity: 1;
            visibility: visible;
        }
    }

    &__btn {
        width: 100%;
        height: 100%;

        @include reset-btn;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        cursor: pointer;
        transition: 0.2s ease;

        font-family: 'SF Pro Text', sans-serif;

        text-decoration: none !important;
        user-select: none;

        border: 1px solid $dark-blue;
        background: $dark-blue;
        color: #fff;
        border-radius: 6px;

        img {
            margin-right: 10px;
            user-select: none;
        }

        &:hover {
            background: $btn-hover-color;
            box-shadow: 0 7px 25px rgba(0, 12, 55, 0.3);
        }

        &:active {
            background: $btn-active-color;
        }
    }

    &__container {

        &-select {
            display: grid;
            grid-template-columns: 1fr 1fr 52px;
            gap: 2px;
        }

        &-btn {
            margin-left: 15%;
        }
    }
}

@include media-breakpoint-down(md) {
    .d-filter {
        grid-template-columns: 1fr;
        gap: 8px;

        background: transparent;
        box-shadow: none;

        padding: 0;
        border-radius: 0;

        &__container {

            &-btn,
            &-select,
            &-text {
                padding: 4px;
                border-radius: 10px;
                @include shadow_1;
                background: $white;
            }

            &-text {

                .input {

                    &:after{
                        display: none;
                    }
                }
            }

            &-btn {
                margin-left: 0;
                min-height: 60px;
            }
        }
    }
}

@include media-breakpoint-down(xs) {
    .d-filter {

        .input {

            &:after{
                display: none;
            }
        }

        &__filter {
            position: absolute;
            bottom: 100%;
            right: 0;

            width: 52px;
            height: 52px;

            border-radius: 6px;
            transition: .3s 0s;

            &-body {
                width: 100%;
                height: 100%;

                display: flex;
                align-content: center;
                justify-content: center;

                cursor: pointer;

                &:hover {
                    background: rgba($white3, .7);
                }

                img {
                    width: 12px;
                    user-select: none;
                }
            }

            &.isOpen {
                background: $white3;
            }
        }

        &__container {

            &-select {
                grid-template-columns: 1fr;
                gap: 8px;

                background: transparent;
                box-shadow: none;

                padding: 0;
                border-radius: 0;

                & > .input {
                    padding: 4px;
                    border-radius: 10px;
                    @include shadow_1;
                    background: $white;
                }
            }

            &-btn {
                min-height: 70px;
            }
        }
    }
}
