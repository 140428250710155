#blog-post {
    padding-top: 105px;
}

.post-breadcrumbs {
    margin-bottom: 48px;
}

.post {
    position: relative;
    display: flex;
    flex-direction: column;

    // HEADER
    &__header {
        border-radius: 5px;

        background: $white1;
        padding: 16px 0 35px;

        &-image {
            width: 100%;
            //height: 380px;

            object-fit: cover;

            user-select: none;
            pointer-events: none;
        }

        &-title {
            @include h3_v3();
            padding: 25px 20px 50px 50px;
        }

        &-footer {
            padding: 18px 50px;

            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: $white1;
        }

        &-info {
            border-radius: 6px;
            background-color: $white;
            overflow: hidden;
            @include shadow_1();

            img {
                height: 100%;
            }
        }

        &-views {
            display: flex;
            justify-content: center;
            align-items: center;
            color: $grey1;
            margin-right: 20px;
        }

        &-subject {
            color: $grey1;
            display: flex;
            align-items: center;
            gap: 8px;

            svg {
                width: 16px;
                fill: $grey1;
            }
        }

        &-likes {
            display: flex;
            justify-content: center;
            align-items: center;
            color: $grey1;
        }

        &-data {
            display: flex;
            align-items: center;
            gap: 12px;
        }
    }

    &__progress {
        width: 100%;
        height: 6px;
        margin-bottom: 50px;
        position: sticky;
        top: 0;

        background: rgba(151, 166, 181, 0.37);

        &-el {
            height: 100%;
            background: #000c37;
        }
    }

    &__statistics {
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
            width: 20px;
            margin-right: 10px;
        }
    }

    &__content {
        max-width: 720px;
        margin: auto;

        // POST CONTENT
        img {
            width: 100% !important;
            height: auto !important;
        }

        tr, td,
        table {
            border: 1px solid $white4;
        }

        td {
            padding: 8px;
        }

        & h1 {
            @include h1();
            margin-bottom: 20px;
        }

        & h2 {
            @include h2();
            margin-bottom: 20px;
        }

        & h3 {
            @include h3();
            margin-bottom: 20px;
        }

        & h4 {
            @include h4();
            margin-bottom: 20px;
        }

        & p {
            @include body1();
            margin-bottom: 10px;
            text-align: justify;
        }

        & ul {
            list-style: disc;
            padding-left: 25px;

            margin: 14px 0;
        }

        & li {
            margin: 6px 0;
        }
    }

    // POST - AUTHOR
    &__author {
        margin-top: 50px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding: 30px 30px 15px 30px;
        border-radius: 5px;

        &-conteiner {
            display: flex;
            flex-direction: column;
            gap: 12px;
        }

        &-social {
            display: flex;
            gap: 10px;
            align-items: center;

            a {
                transition: .2s;

                svg {
                    fill: #000C37;
                }

                &:hover {
                    opacity: .7;
                }
            }
        }

        &-wrapper {
            background-color: $white1;
        }

        &-credentials {
            display: flex;
            align-items: center;
            width: 30%;
        }

        &-image-box {
            min-width: 80px;
            height: 80px;
            border-radius: 50%;
            margin-right: 20px;
        }

        &-image {
            width: 100%;
            height: 100%;
            border-radius: 50%;

            user-select: none;
            pointer-events: none;
        }

        &-description {
            @include body4();
            width: 70%;
            text-align: justify;

            &:last-child p + div {
                display: none;
                height: 0;
            }
        }
    }

    & strong {
        font-weight: bold !important;
    }

    & em {
        font-style: italic !important;
    }

    & ol, ul {
        list-style: unset !important;
        margin-left: 20px;
    }
}

// MEDIA
@include media-breakpoint-down(md) {
    .post {
        // HEADER
        &__header {
            padding: 16px 0 30px;

            &__progress {
                margin-bottom: 40px;
            }

            svg {
                width: 18px;
                margin-right: 8px;
            }

            &-image {
                height: 250px;
            }

            &-title {
                padding: 20px 15px 35px 35px;
            }

            &-footer {
                padding: 18px 35px;
            }

            &-views {
                margin-right: 15px;
            }
        }
    }
}

@include media-breakpoint-down(sm) {
    .post {
        // HEADER
        &__header {
            padding: 12px 0 20px;

            &__progress {
                margin-bottom: 30px;
            }

            svg {
                width: 16px;
            }

            &-image {
                height: 200px;
            }

            &-title {
                padding: 15px 10px 25px 25px;
            }

            &-footer {
                padding: 12px 25px;
            }

            &-views {
                margin-right: 10px;
            }
        }
    }
}

@media (max-width: 700px) {
    .post {
        &__author {
            flex-direction: column;
        }

        &__author-credentials {
            width: 100%;
            margin-bottom: 30px;
        }

        &__author-description {
            width: 100%;
        }
    }
}

@media (max-width: 575px) {
    .post {
        &__author {
            padding: 30px 10px;
        }
    }
}

@media (max-width: 425px) {
    .post {
        &__author {
            padding: 15px 10px 10px 10px;
        }
    }
}
