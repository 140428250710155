.d-profile {
    padding: 24px;
    margin-bottom: 32px;

    border-radius: 8px;
    background: $white;
    @include shadow_1;

    &__header {
        padding-bottom: 16px;
        border-bottom: 1px solid $grey;

        display: flex;
        align-items: center;
        justify-content: space-between;

        &-title {
            @include body5;
            color: $black;
        }

        &-link {
            @include body5;
            @include opacity-hover(1, .7)
        }

        &-actions {
            display: flex;
            align-items: center;

            gap: 24px;
        }
    }

    &__main {
        padding-top: 16px;

        display: flex;
        align-items: center;
        justify-content: space-between;

        &-info {
            display: grid;

            grid-template-columns: 64px repeat(4, auto);
            grid-template-rows: repeat(2, 32px);

            grid-column-gap: 18px;
        }

        &-image {
            grid-row-start: 1;
            grid-row-end: 3;

            border-radius: 50%;

            display: flex;
            align-items: center;
            justify-content: center;

            @include body4;
            user-select: none;

            img {
                width: 100%;
                object-fit: cover;
                height: 100%;
                border-radius: 50%;
            }
        }

        &-row {
            @include body3;

            display: flex;
            align-items: center;

            color: $light-grey;

            a {
                color: $light-grey;
                @include opacity-hover(1, .7)
            }

            b, i {
                font-weight: 600;
                color: $black;
            }

            b {
                min-width: 60px;
            }
        }

        .MuiLinearProgress-root {
            width: 100%;
        }
    }

    &__download {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
    }

    &__download-item {
        display: flex;
        align-items: center;
        margin-left: 4px;
        margin-bottom: 4px;

        &__link {
            min-width: 120px;
            display: flex;
            align-items: baseline;
            background: $white3;
            padding: 4px 8px;
            border-radius: 8px;
            box-shadow: none;
            border: none;
            cursor: pointer;

            @include opacity-hover(1, .7);

            img {
                width: 15px;
                margin-right: 8px;
            }

            span, .pdf-link-text {
                @include body5;
                color: $dark-grey;
                font-size: 12px;
            }

            & + & {
                margin-left: 15px;
            }
        }
    }
}

@include media-breakpoint-down(md) {
    .d-profile {
        padding: 16px;
        margin-bottom: 112px;

        &__header {

            &-actions {
                gap: 35px;
            }
        }

        &__main {
            flex-direction: column;
            align-items: flex-start;

            &-info {
                grid-template-areas: "logo company email id"
                                     "logo name phone validity"
                                     ". . country city";
            }

            #logo {
                grid-area: logo;
                max-height: 64px;
            }

            #company {
                grid-area: company;
            }

            #email {
                grid-area: email;
            }

            #country {
                grid-area: country;
            }

            #id {
                grid-area: id;
            }

            #name {
                grid-area: name;
            }

            #phone {
                grid-area: phone;
            }

            #city {
                grid-area: city;
            }

            #validity {
                grid-area: validity;
            }
        }

        &__download {
            margin-top: 16px;
        }
    }
}

@include media-breakpoint-down(sm) {
    .d-profile {
        margin-bottom: 116px;

        &__header {

            &-actions {
                gap: 16px;
            }
        }

        &__main {

            &-info {
                grid-template-areas: "logo company"
                                     "logo name"
                                     "email email"
                                     "phone phone"
                                     "country country"
                                     "id id"
                                     "validity validity"
                                     "city city";
                grid-row-gap: 8px;
            }
        }

        &__download {
            width: 100%;
            justify-content: flex-start;

            &__download-item {
                margin-bottom: 0;
            }
        }
    }
}
