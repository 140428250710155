.d-table {
    margin-top: 32px;

    &__table {
        padding: 16px 0 0;

        background: $white;
        border-radius: 8px;

        overflow-x: auto;

        @include shadow_1;

        &-list {
            transition: .3s 0s;
        }

        &-content {
            min-width: 1100px;
            margin: 0 16px;
        }

        &-hash {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        &-hash img {
            width: 15px;
            height: 15px;
        }

        &-hash.active {
            cursor: pointer;
            transition: all 0.3s ease;
        }

        &-hash.active:hover {
            color: $accent;
        }

        &--grid {
            display: grid;
            grid-template-columns: .7fr 1.1fr 1.6fr 1fr 1.2fr .6fr 1.3fr .9fr .4fr;
            // for "validity" column hidden
            //grid-template-columns: .7fr 1.1fr 1.6fr 1fr 1.2fr .6fr 1.3fr .2fr;
            grid-column-gap: 16px;
        }
    }

    &__header {
        @include body6;
        color: $black;

        padding-bottom: 16px;
        align-items: center;

        border-bottom: 1px solid $grey;
    }

    &__item {
        @include body4;
        color: $black;

        align-items: center;
        margin: 23px 0;

        div {
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        a {
            color: $black;
            @include opacity-hover(1, .7)
        }

        b {
            font-weight: 600;
        }

        &-flag {
            margin-right: 8px;
        }

        &-logo {
            width: 24px;
            height: 24px;
            max-width: 24px;
            max-height: 24px;

            margin-right: 8px;
            object-fit: contain;

            border-radius: 50%;
            border: 2px solid transparent;
            vertical-align: middle;

            &--request {
                border: 1px solid #f52c5c;
            }
        }
    }

    &__footer {
        margin-top: 30px;

        display: flex;
        align-items: center;
        justify-content: flex-end;

        &-page {
            font-size: 12px;
            line-height: 100%;
            letter-spacing: 0.01em;

            margin-right: 15px;
            color: $grey1;
        }
    }

    &__blur {
        color: transparent !important;
        text-shadow: 0 0 5px rgba(0, 0, 0, 0.4) !important;
        user-select: none;
    }

    &__verified {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 20px;
        height: 20px;
        min-height: 20px;
        min-width: 20px;

        svg {
            width: 100%;
            height: 100%;
        }
    }
}

@include media-breakpoint-down(md) {
    .d-table {
        margin-top: 80px;

        &__header {
            padding-bottom: 8px;
        }

        &__table {

            &-content {
                min-width: 1500px;
            }
        }

        &__item {
            margin: 16px 0;
        }

        &__footer {
            margin-top: 22px;
        }
    }
}

@include media-breakpoint-down(sm) {
    .d-table {
        margin-top: 64px;
    }
}

@include media-breakpoint-down(xs) {
    .d-table {

        &__footer {
            align-items: flex-end;
            flex-direction: column;

            &-page {
                margin: 0 0 10px;
            }
        }
    }
}
