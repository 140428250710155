$color-bg-intro: linear-gradient(111.78deg, #000b32 6.74%, #000517 91.78%);
$color-dark: #000c37;
$color-white: #ffffff;
$color-blue: #0088ff;
$color-input: #e0e4ec;
$color-white-opacity: rgba(255, 255, 255, 0.1);
$color-disabled: #eff1f6;
$color-icon: #c9cfdb;
$color-grey: #9ea9b9;
$color-btn-hover: #3da4fe;
$color-btn-press: #0075ff;

@mixin mediaMixin($minMax, $width) {
  @media only screen and (#{$minMax}-width: #{$width}px) {
    @content;
  }
}

.container {
  max-width: 1200px;
  margin: auto;
  padding: 0 15px;
  position: static;
}

.finance {
  position: relative;
  top: 65px;
  margin-bottom: 65px;
  padding-bottom: 160px;
  background-color: #030a27;

  background-image: url('/images/main/logisticsFinance/intro/2.webp');
  background-position-x: 50%;
  background-size: contain;
  background-repeat: no-repeat;

  @include mediaMixin(max, 768) {
    padding-bottom: 74px;
  }

  @include mediaMixin(max, 568) {
    padding-bottom: 14px;
  }

  .intro-section {
    padding: 93px 0 86px;
    position: relative;
    overflow: hidden;

    @include mediaMixin(max, 768) {
      padding-top: 100px;
    }

    @include mediaMixin(max, 568) {
      padding-top: 72px;
      padding-bottom: 70px;
    }

    &__vector {
      position: absolute;
      top: 0;

      @include mediaMixin(max, 768) {
        top: 0;
      }
    }

    &__content {
      position: relative;
      z-index: 2;
      display: flex;
      justify-content: space-between;

      @include mediaMixin(max, 768) {
        flex-direction: column;
      }
    }

    &__description {
      flex: 0 0 45%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: flex-start;

      @include mediaMixin(max, 768) {
        align-items: center;
        text-align: center;
        margin-bottom: 90px;
      }

      @include mediaMixin(max, 568) {
        text-align: left;
        align-items: flex-start;
        margin-bottom: 0px;
      }
    }

    &__heading-h1 {
      @include h1;
      color: $color-white;
      margin-bottom: 16px;
      position: relative;
    }

    &__heading-image {
      position: absolute;
      right: 76px;
      top: -33px;

      @include mediaMixin(max, 768) {
        right: -32px;
        top: -52px;
      }

      @include mediaMixin(max, 568) {
        top: -37px;
      }
    }

    &__text {
      width: 80%;
      color: $color-input;
      line-height: 26px;
      margin-bottom: 32px;

      @include mediaMixin(max, 568) {
        margin-bottom: 0px;
        width: 100%;
      }
    }

    &__buttons {
      display: flex;
      width: 80%;
      justify-content: space-between;

      @include mediaMixin(max, 768) {
        justify-content: center;
      }

      @include mediaMixin(max, 568) {
        width: 100%;
        margin-top: 40px;

        & a {
          width: 100%;
          min-height: 56px !important;
        }
      }
    }

    &__link {
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.2s linear;
    }

    @include mediaMixin(max, 576) {
      &__link:first-child {
        margin-bottom: 8px;
      }
    }

    &__image-box {
      flex: 0 0 45%;

      @include mediaMixin(max, 768) {
        width: 100%;
        display: flex;
        justify-content: center;
      }

      @include mediaMixin(max, 568) {
      }
    }

    &__image {
      display: inline-block;
      max-width: 633px;

      @include mediaMixin(max, 768) {
        position: static;
        max-width: 500px;
      }

      @include mediaMixin(max, 568) {
        max-width: 100%;
        margin-top: 45px;
      }
    }
  }

  .intro-section__link {
    padding: 10px;
    text-align: center;
    border-radius: 6px;
    text-decoration: none;
    color: $color-white;

    &.primary {
      // flex: 0 0 48%;
      background: $color-blue;
      min-height: 48px;
      min-width: 168px;
    }

    &.primary:hover {
      background: $color-btn-hover;
    }

    &.primary:focus {
      background: $color-btn-press;
    }
  }

  .explain-section {
    padding: 28px 0 80px;

    @include mediaMixin(max, 768) {
      padding-top: 83px;
    }

    @include mediaMixin(max, 568) {
      padding-top: 0px;
      padding-bottom: 75px;
    }

    &__content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      border-radius: 24px;
      background: rgba(255, 255, 255, 0.02);
      backdrop-filter: blur(17px);
      padding: 80px;

      @include mediaMixin(max, 768) {
        padding: 0;
        overflow: hidden;
        border: 1px solid rgba(255, 255, 255, 0.08);
        background: rgba(255, 255, 255, 0.04);
      }

      @include mediaMixin(max, 568) {
        border-radius: 16px;
        border: 1px solid rgba(255, 255, 255, 0.08);
        background: rgba(255, 255, 255, 0.04);
      }
    }

    &__question {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 62px;

      @include mediaMixin(max, 768) {
        gap: 0;
        align-items: initial;
      }

      @include mediaMixin(max, 568) {
        flex-direction: column;
      }
    }

    &__question_image-box {
      @include mediaMixin(max, 768) {
        overflow: hidden;
      }
    }

    &__question-image {
      display: inline-block;
      max-width: 100%;
      margin-top: 9px;
      margin-left: 5px;

      transform: scale(1.3);

      @include mediaMixin(max, 768) {
        transform: scale(2.5);
        position: relative;
        left: -190px;
        top: 183px;
      }

      @include mediaMixin(max, 568) {
        transform: scale(1.175);
        left: -1px;
        top: 0px;
      }
    }

    &__question-description {
      max-width: 528px;

      @include mediaMixin(max, 768) {
        max-width: 360px;
        padding: 24px 0px 24px 0;
        margin-right: 25px;
      }

      @include mediaMixin(max, 568) {
        padding: 37px 16px 20px;
        margin-right: 0px;
        padding-right: 14px;
      }

      strong {
        font-weight: bold;
      }
    }

    &__question-description > p:not(:last-child) {
      margin-bottom: 26px;
    }

    &__heading-h2 {
      @include h2;
      color: $white;
      margin-bottom: 35px;

      @include mediaMixin(max, 768) {
        margin-bottom: 32px;
      }
    }

    &__question-text {
      color: $white;
      line-height: 26px;
    }

    &__cards-vector {
      position: absolute;
      top: -20px;
      left: 0;

      @include mediaMixin(max, 1140) {
        width: 850px;
      }

      @include mediaMixin(max, 1040) {
        width: 780px;
      }

      @include mediaMixin(max, 998) {
        display: none;
      }
    }
  }

  .solutions-section {
    color: $color-white;
    padding: 83px 0 264px;
    position: relative;

    @include mediaMixin(max, 1150) {
      padding-bottom: 160px;
      padding-top: 80px;
    }
    @include mediaMixin(max, 568) {
      padding-top: 0px;
      padding-bottom: 77px;
    }

    &__vector {
      position: absolute;
      top: -30%;

      @include mediaMixin(max, 1150) {
        top: -30%;
        left: -50%;
        transform: scale(0.7);
      }
    }

    .container {
      display: flex;
      gap: 24px;
      position: relative;
      z-index: 2;

      @include mediaMixin(max, 1150) {
        gap: 0;

        display: grid;
        grid-column-gap: 5px;
        grid-row-gap: 24px;
        place-items: flex-end;

        grid-template-areas:
          'title title card1 card2'
          'card3 card3 card4 card5';
      }

      @include mediaMixin(max, 568) {
        place-items: flex-start;
        grid-template-areas:
          'title title'
          'card1 card2'
          'card3 card4'
          'card5 card5';
        grid-column-gap: 16px;
        grid-row-gap: 16px;
        place-content: center;
      }
    }

    &__title-h2 {
      @include h2;
      color: $color-white;
      margin-bottom: 24px;
      position: relative;
      width: 215px;
      margin-right: 36px;

      @include mediaMixin(max, 1150) {
        width: auto;
        margin-right: 0px;
        place-self: normal;
      }

      @include mediaMixin(max, 568) {
        margin-bottom: 35px;
      }

      grid-area: title;
    }

    &__title-image {
      position: absolute;
      left: 0;
      bottom: -24px;

      @include mediaMixin(max, 1150) {
        left: auto;
        bottom: 24px;
        right: 14px;
        transform: scale(0.95);
      }

      @include mediaMixin(max, 568) {
        left: auto;
        bottom: -53px;
        right: 28px;
        transform: scale(0.4);
      }
    }

    &-card {
      padding: 16px;
      display: flex;
      width: 160px;
      height: 176px;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      flex-shrink: 0;
      border-radius: 16px;
      background: rgba(255, 255, 255, 0.02);
      border: 1px solid rgba(255, 255, 255, 0.08);

      @include new-text(18px, 600, 28px, none, none, $white);

      @include mediaMixin(max, 568) {
        @include new-text(16px, 600, 26px, none, none, $white);
        width: 164px;
        padding: 18px;
        padding-top: 14px;
        padding-right: 25px;
        border-radius: 16px;
        background: rgba(255, 255, 255, 0.02);
      }
    }

    .card1 {
      grid-area: card1;
    }
    .card2 {
      grid-area: card2;
    }
    .card3 {
      grid-area: card3;
    }
    .card4 {
      grid-area: card4;
    }
    .card5 {
      grid-area: card5;
    }
  }

  .how-it-works {
    &__title {
      margin-bottom: 51px;
      text-align: center;
      @include new-text(48px, 600, 60px, none, none, $white);

      @include mediaMixin(max, 1150) {
        @include new-text(36px, 600, 48px, none, none, $white);
        margin-bottom: 64px;
      }

      @include mediaMixin(max, 568) {
        @include new-text(22px, 600, 32px, none, none, $white);
        margin-bottom: 48px;
      }
    }

    &__cards {
      padding-top: 50px;
      position: relative;
      @include new-text(48px, 600, 60px, none, none, $white);
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(2, 1fr);
      grid-column-gap: 152px;
      grid-row-gap: 110px;

      @include mediaMixin(max, 1150) {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(3, 1fr);
        grid-column-gap: 0px;
        grid-row-gap: 77px;

        grid-template-areas:
          'card1 card2'
          'card6 card3'
          'card5 card4';
      }

      @include mediaMixin(max, 568) {
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(6, 1fr);
        grid-column-gap: 0px;
        grid-row-gap: 0px;

        place-items: center;
        padding-top: 13px;

        grid-template-areas:
          'card1'
          'card2'
          'card3'
          'card6'
          'card5'
          'card4';
      }

      &-vector {
        position: absolute;
        right: -53px;
        z-index: 1;

        @include mediaMixin(max, 1150) {
          right: 0px;
          left: 0;
          width: 100%;

          img {
            display: block;
            margin: 0 auto;
          }
        }

        @include mediaMixin(max, 568) {
          right: 1px;
          top: 0;
        }
      }

      .card {
        text-align: center;
        position: relative;
        z-index: 2;

        @include mediaMixin(max, 1150) {
          max-width: 266px;
        }

        @include mediaMixin(max, 568) {
          min-height: 154px;
        }

        &-title {
          margin-bottom: 8px;
          @include new-text(18px, 600, 28px, none, none, $white);
        }
        &-text {
          @include new-text(16px, 400, 26px, none, none, $white);
        }
      }

      .card1 {
        margin-right: 15px;
        @include mediaMixin(max, 1150) {
          grid-area: card1;
          margin-left: auto;
          margin-right: 42px;
        }

        @include mediaMixin(max, 568) {
          grid-area: card1;
          margin-left: 39px;
          margin-top: 20px;
        }
      }
      .card2 {
        @include mediaMixin(max, 1150) {
          grid-area: card2;
          margin-left: 8px;
        }

        @include mediaMixin(max, 568) {
          grid-area: card2;
          margin-left: 0px;
          margin-top: 22px;
        }
      }
      .card3 {
        margin-left: 11px;
        @include mediaMixin(max, 1150) {
          grid-area: card3;
          margin-left: 8px;
        }
        @include mediaMixin(max, 568) {
          grid-area: card3;
          margin-left: 0px;
          margin-top: 24px;
        }
      }
      .card4 {
        @include mediaMixin(max, 1150) {
          grid-area: card4;
          margin-left: 8px;
        }

        @include mediaMixin(max, 568) {
          grid-area: card4;
          margin-left: 0px;
          margin-top: 30px;
        }
      }
      .card5 {
        @include mediaMixin(max, 1150) {
          grid-area: card5;
          margin-left: auto;
          margin-right: 47px;
        }

        @include mediaMixin(max, 568) {
          grid-area: card5;
          margin-left: 45px;
          margin-top: 31px;
        }
      }
      .card6 {
        margin-right: -13px;
        @include mediaMixin(max, 1150) {
          grid-area: card6;
          margin-left: auto;
          margin-right: 8px;
        }

        @include mediaMixin(max, 568) {
          grid-area: card6;
          margin-left: 7px;
          margin-top: 26px;
        }
      }
    }
  }

  .choose-us {
    padding-top: 280px;
    padding-bottom: 83px;
    position: relative;

    @include mediaMixin(max, 768) {
      padding-bottom: 100px;
      padding-top: 160px;
    }

    @include mediaMixin(max, 568) {
      padding-top: 66px;
      padding-bottom: 49px;
    }

    &__vector {
      position: absolute;
      top: -50%;
      width: 100%;

      @include mediaMixin(max, 768) {
        left: -32%;
      }
    }

    .container {
      position: relative;
      z-index: 2;
    }

    &__title {
      @include new-text(36px, 600, 48px, none, none, $white);
      position: relative;
      width: max-content;
      margin: 0 auto;

      @include mediaMixin(max, 768) {
        @include new-text(32px, 600, 42px, none, none, $white);
      }

      @include mediaMixin(max, 568) {
        @include new-text(22px, 600, 32px, none, none, $white);
        margin: 0;
      }

      .vector {
        position: absolute;
        bottom: -39px;
        right: -63px;

        @include mediaMixin(max, 768) {
          bottom: -35px;
          right: -42px;
        }

        @include mediaMixin(max, 568) {
          bottom: -35px;
          right: -64px;
        }
      }
    }

    &__slider {
      margin-top: 51px;

      @include mediaMixin(max, 768) {
        margin-top: 42px;
      }

      @include mediaMixin(max, 568) {
        display: none;
      }
      .slides {
        display: flex;
        gap: 23px;
        justify-content: center;

        @include mediaMixin(max, 768) {
          gap: 0;
        }
      }

      .slide {
        max-width: 275px;
        width: 100%;

        @include mediaMixin(max, 768) {
          width: auto;
        }
      }

      .title {
        @include new-text(16px, 600, 26px, none, none, $white);
        margin-top: 24px;
        margin-bottom: 16px;

        @include mediaMixin(max, 768) {
          margin-top: 14px;
          margin-bottom: 8px;
        }
      }
      .text {
        @include new-text(16px, 400, 26px, none, none, $white);
      }

      .pagination {
        gap: 30px;
        margin-top: 56px;
        display: flex;
        justify-content: center;

        @include mediaMixin(max, 768) {
          margin-top: 33px;
        }
      }
    }

    &__mobile-cards {
      margin-top: 50px;

      .card {
        max-width: 253px;
        margin-bottom: 32px;
      }

      .title {
        @include new-text(16px, 600, 26px, none, none, $white);
        margin-top: 13px;
        margin-bottom: 8px;
      }

      .text {
        @include new-text(16px, 400, 26px, none, none, $white);
      }
    }
  }

  .contact-us {
    padding-left: 15px;
    padding-right: 15px;

    @include mediaMixin(max, 768) {
      padding-left: 15px;
      padding-right: 15px;
    }
    z-index: 2;
    .container {
      border-radius: 24px;
      border: 1px solid rgba(255, 255, 255, 0.03);
      background: rgba(255, 255, 255, 0.04);
      position: relative;
      overflow: hidden;

      display: flex;
      justify-content: space-between;
      padding: 60px;
      max-width: 971px;

      @include mediaMixin(max, 768) {
        padding: 32px;
        padding-bottom: 22px;
        padding-right: 14px;
        justify-content: flex-start;
        align-items: baseline;
      }

      @include mediaMixin(max, 568) {
        flex-direction: column;
        padding: 32px;
        padding-bottom: 16px;
      }
    }

    &__vector {
      position: absolute;
      top: 0;
      left: 0;
    }

    &__texts {
      .title {
        margin-bottom: 8px;
        @include new-text(48px, 600, 60px, none, none, $white);

        @include mediaMixin(max, 768) {
          @include new-text(44px, 600, 54px, none, none, $white);
        }
        @include mediaMixin(max, 568) {
          @include new-text(22px, 600, 32px, none, none, $white);
          text-align: center;
          margin-bottom: 16px;
        }
      }
      .text {
        @include new-text(16px, 400, 26px, none, none, $white);

        @include mediaMixin(max, 568) {
          text-align: center;
        }
      }
    }

    &__info {
      margin-right: 10px;
      @include new-text(16px, 400, 26px, none, none, $white);

      @include mediaMixin(max, 768) {
        margin-left: 37px;
      }

      @include mediaMixin(max, 568) {
        margin: 0px;
        display: flex;
        flex-direction: column;
        margin-top: 14px;
      }

      .card1 {
        @include mediaMixin(max, 568) {
          order: 3;
        }
      }

      .card2 {
        @include mediaMixin(max, 568) {
          order: 1;
        }
      }

      .card3 {
        @include mediaMixin(max, 568) {
          order: 2;
        }
      }

      strong {
        font-weight: 700;
      }

      > div {
        margin-bottom: 16px;

        @include mediaMixin(max, 568) {
          margin-bottom: 17px;
        }
      }
    }
  }

  .disclamer {
    position: relative;
    padding-top: 152px;

    @include mediaMixin(max, 768) {
      padding-top: 138px;
    }

    @include mediaMixin(max, 568) {
      padding-top: 78px;
    }

    z-index: 2;
    &-title {
      margin-bottom: 35px;
      text-align: center;
      @include new-text(36px, 600, 48px, none, none, $white);

      @include mediaMixin(max, 568) {
        @include new-text(22px, 600, 32px, none, none, $white);
        margin-bottom: 32px;
      }
    }

    &-texts {
      gap: 21px;
      display: flex;
      @include new-text(16px, 400, 26px, none, none, $white);

      @include mediaMixin(max, 768) {
        flex-direction: column;
        gap: 0px;
      }

      .show-text {
        cursor: pointer;
        font-weight: 700;
      }
    }

    &-column {
      flex: 1;
      strong {
        font-weight: 700;
      }
      p {
        margin-bottom: 27px;

        @include mediaMixin(max, 768) {
          margin-bottom: 24px;
        }
      }
    }
  }
}
