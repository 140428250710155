.custom-checkbox {
    &.isBordered {
        position: relative;
        margin-bottom: 24px;
    }

    &.isBordered.isActive .custom-checkbox__label {
        border: 1px solid $accent;
    }

    &__label {
        width: 270px;
        min-height: 60px;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 24px 10px 24px 24px;
        border: 1px solid $disabled-background;
        border-radius: 10px;
        cursor: pointer;
        transition: $transition;
    }

    &__label.isError {
        border: 1px solid $red-error;
    }

    &__label:hover {
        border: 1px solid $accent;
    }

    &__label:hover .custom-checkbox__checkmark {
        border: 1px solid $accent;
    }

    &.isBordered.isActive .custom-checkbox__checkmark {
        border: 1px solid $accent;
    }

    &__label-text {
        font-size: 14px;
        margin-left: 30px;
    }

    &__input {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
    }

    &__checkmark {
        position: absolute;
        top: calc(50% - 8px);
        left: 20px;
        width: 16px;
        height: 16px;
        border: 1px solid $disabled-background;
        border-radius: 2px;
        transition: $transition;
    }

    &.isActive .custom-checkbox__checkmark {
        background-color: $accent;
    }

    &__checkmark:after {
        content: '';
        width: 10px;
        height: 10px;
        position: absolute;
        top: 3px;
        left: 2px;
        opacity: 0;
        transition: $transition;
        background-image: url(/images/global/mark.svg);
        background-repeat: no-repeat;
        background-position: center;
    }

    &__label .custom-checkbox__input:checked ~ .custom-checkbox__checkmark:after {
        opacity: 1;
    }

    &__error {
        margin-top: 5px;
        @include text(12px, 400, none, none, $red-error);
        transition: $transition;
    }

    &__tooltip-icon {
        fill: $disabled-text;
        transition: $transition;
    }

    &__tooltip-icon:hover {
        fill: $accent;
    }
}

// Media:
@media (max-width: 930px) {
    .custom-checkbox__label {
        width: 200px;
        padding: 18px 10px 18px 10px;
    }

    .custom-checkbox__label-text {
        margin-left: 40px;
        padding-right: 20px;
    }
}

@media (max-width: 768px) {
    .custom-checkbox.isBordered {
        margin-bottom: 5px;
    }

    .custom-checkbox__label {
        width: 320px;
    }
}
