@keyframes spinner {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(360deg);
    }
}

.contact {
    $padding: 24px;

    background: #ffffff;
    box-shadow: 0 10px 30px rgba(58, 112, 191, 0.15);
    border-radius: 10px;
    padding: 8px;

    display: grid;
    grid-template-columns: 1.8fr 3.2fr;

    @media (max-width: 767px) {
        padding-top: 24px;
    }

    @media (max-width: 475px) {
        padding-top: 8px;
    }

    &__info {
        position: relative;
        color: #ffffff;

        background: radial-gradient(58.66% 50.86% at 20.49% -5.39%, rgba(212, 42, 255, 0.2) 0%, rgba(212, 42, 255, 0) 100%), radial-gradient(54.39% 37.37% at -24.02% 109.42%, rgba(0, 136, 255, 0.6) 0%, rgba(0, 136, 255, 0) 100%), linear-gradient(73.58deg, #000C37 4.98%, #5864FF 250.34%);
        border-radius: 10px;

        display: flex;
        flex-direction: column;
        overflow: hidden;

        padding: 70px 40px 40px;

        &-title {
            padding: 0 0 40px;

            font-weight: 600;
            font-size: 52px;
            line-height: 60px;
        }

        &-list {
            margin: 0;
            padding: 0;

            list-style: none;
        }

        &-text {
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;

            margin-left: 20px;
            display: block;
        }

        &-link {
            color: #ffffff;
            text-decoration: none;
            transition: 0.2s 0s;

            &:hover {
                color: #e0e4ec;
                text-decoration: none;
            }
        }

        &-item {
            display: flex;
            align-items: flex-start;

            & + & {
                margin-top: 32px;
            }
        }

        &-text_1 {
            font-size: 16px;
            line-height: 24px;
            font-weight: 500;
        }
    }

    &__social {
        position: relative;
        z-index: 2;
        margin: auto 0 0;
        padding: 0;
        list-style: none;

        display: flex;
        align-items: center;

        &-item {
            & + & {
                margin-left: 40px;
            }
        }

        &-link {
            display: flex;
            align-items: center;
        }
    }

    &__close {
        position: absolute;

        top: 20px;
        right: 20px;

        cursor: pointer;
        display: flex;

        padding: 6px;

        svg {
            transition: 0.2s 0s;
        }

        &:hover {
            svg {
                fill: #a7a9b3;
            }
        }
    }

    &__loader {
        left: 0;
        position: absolute;
        height: 25px;
        width: 25px;
        margin-left: 10px;
        border-radius: 50%;
        border-top: 2px solid #fff;
        border-right: 2px solid transparent;
        animation: spinner 1s linear infinite;
    }

    &__wrapper {
        padding: 150px 0 140px;
    }

    &__small {
        display: flex;
        flex-direction: column;
        gap: 15px;

        &-btn {
            position: relative;

            font-weight: 600;
            font-size: 16px;
            line-height: 24px;

            background: #0088ff;
            box-shadow: 0 4px 20px rgba(0, 136, 255, 0.15);
            border-radius: 6px;

            color: #ffffff;
            border: none;
            outline: none !important;

            width: 100%;
            height: 40px;

            transition: 0.3s 0s;

            &:hover {
                background: #2197ff;
                box-shadow: 0 4px 20px rgba(0, 136, 255, 0.15);
            }

            &:active {
                background: #0075ff;
                box-shadow: 0 4px 20px rgba(0, 136, 255, 0.15);
            }
        }

        &-modal {
            position: relative;
            background: #fff;
            border-radius: 15px;
            padding: 40px 20px;
            min-width: 320px;
            max-width: 420px;
        }

        &-title {
            font-size: 22px;
            line-height: 22px;

            color: #000c37;
            font-weight: 600;
            margin-bottom: 0;
        }

        &-text {
            font-size: 14px;
            color: #000c37;
            font-weight: 400;

            margin-bottom: 10px;
        }

        &-close {
            position: absolute;

            top: 20px;
            right: 20px;

            cursor: pointer;
            display: flex;

            padding: 6px;

            svg {
                transition: 0.2s 0s;
            }

            &:hover {
                svg {
                    fill: #a7a9b3;
                }
            }
        }
    }

    &__numbers {
        margin-top: 32px;

        &-list {
            list-style: none;
            margin: 0;
            padding: 0;

            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 20px;
        }

        &-item {
            display: flex;
            align-items: center;
            gap: 10px;

            a {
                font-weight: 500;
                font-size: 14px;
                line-height: 22px;

                color: white;
                text-decoration: none;


                &:hover {
                    opacity: .8;
                }
            }
        }

        &-flag {
            width: 16px;
        }
    }

    @include media-breakpoint-down(lg) {

        &__numbers {
            margin-bottom: 12px;

            &-list {
                grid-template-columns: repeat(1, 1fr);
            }
        }
    }

    @include media-breakpoint-down(md) {
        $padding: 24px;
        grid-template-columns: 1.8fr 3.2fr;

        &__info {
            padding: 52px 13% 40px;

            &-title {
                font-size: 32px;
                line-height: 42px;
            }

            &-text {
                margin-left: 16px;
            }
        }

        &__social {
            justify-content: space-between;

            &-item {
                & + & {
                    margin-left: auto;
                }
            }
        }

        &__container {
            margin: 44px auto 120px;
        }

        &__wrapper {
            padding: 110px 0 120px;
        }
    }

    @include media-breakpoint-down(sm) {
        $padding: 16px;
        grid-template-columns: 1fr;

        &__info {
            padding: 30px 16px;
            border-radius: 10px;

            &-title {
                padding: 0 0 30px;
                font-size: 22px;
                line-height: 32px;
            }

            &-text {
                margin-left: 20px;
            }

            &-item {
                & + & {
                    margin-top: 16px;
                }
            }
        }

        &__social {
            padding: 0 4px;
            margin-top: 56px;
        }

        &__container {
            max-width: 1170px + $padding * 2;
            padding: 0 $padding;
            margin: 24px auto 120px;
        }

        &__wrapper {
            padding: 90px 0 100px;
        }

        &__numbers {

            margin-bottom: 12px;

            &-list {
                grid-template-columns: repeat(2, 1fr);
            }
        }
    }

    @include media-breakpoint-down(xs) {

        &__numbers {

            &-list {
                grid-template-columns: repeat(1, 1fr);
            }
        }
    }
}

.contact-form {
    padding: 50px 10%;

    display: flex;
    flex-direction: column;
    justify-content: center;

    &__header {
        display: flex;
        align-items: center;

        margin-bottom: 32px;

        &-icon {
            user-select: none;
        }

        &-title {
            font-weight: 600;
            font-size: 22px;
            line-height: 32px;

            margin: 0;

            margin-left: 16px;
            color: #000c37;
        }
    }

    &__grid {
        width: 100%;

        display: grid;
        grid-template-columns: repeat(2, 1fr);

        grid-column-gap: 24px;
        grid-row-gap: 32px;
    }

    &__btn {
        position: relative;
        margin-left: auto;

        font-weight: 600;
        font-size: 16px;
        line-height: 24px;

        background: #0088ff;
        box-shadow: 0 4px 20px rgba(0, 136, 255, 0.15);
        border-radius: 6px;

        color: #ffffff;
        border: none;
        outline: none;

        width: 220px;
        height: 40px;

        transition: 0.3s 0s;

        &-wrapper {
            margin-top: 32px;
            display: flex;
        }

        &:hover {
            background: #2197ff;
            box-shadow: 0 4px 20px rgba(0, 136, 255, 0.15);
        }

        &:active {
            background: #0075ff;
            box-shadow: 0 4px 20px rgba(0, 136, 255, 0.15);
        }

        &-wrapper {
            margin-top: 32px;
            display: flex;
        }
    }

    @include media-breakpoint-down(md) {
        padding: 32px;

        &__header {
            &-title {
                font-size: 16px;
                line-height: 26px;
            }
        }

        &__grid {
            grid-template-columns: repeat(1, 1fr);
        }
    }

    @include media-breakpoint-down(sm) {
        padding: 32px 16px 16px;

        &__header {
            margin-bottom: 24px;
        }

        &__btn {
            height: 56px;
            width: 100%;

            &-wrapper {
                margin-top: 24px;
            }
        }
    }
}
