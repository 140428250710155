.freight-forwarder {
  padding: 65px 0;
  position: relative;

  // HEAD: IMAGE BLOCK
  &__head-image-box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 65%;
    position: absolute;
    top: 50px;
    right: 140px;
    z-index: 1;

    @media (max-width: 1440px) {
      width: 75%;
      top: 75px;
      right: 140px;
    }

    @media (max-width: 1250px) {
      width: 80%;
      top: 95px;
      right: 100px;
    }

    @media (max-width: 1024px) {
      width: 120%;
      right: -65px;
    }

    @media (max-width: 768px) {
      width: 1300px;
      height: 1300px;
      top: -190px;
      left: -290px;
    }

    @media (max-width: 575px) {
      width: 1000px;
      height: 1000px;
      top: -150px;
      left: -290px;
    }
  }

  &__head-image {
    width: 100%;
    object-fit: contain;
  }

  &__head {
    padding: 245px 0;
    background-image: url('/images/pages/Resources/FreightDictionary/head-bg.png');
    background-size: cover;
    overflow: hidden;

    @media (max-width: 768px) {
      padding: 245px 0 390px 0;
    }

    @media (max-width: 575px) {
      padding: 315px 0 40px 0;
    }
  }

  // HEAD: DESCRIPTION BLOCK
  &__head-description-box {
    max-width: 520px;
    width: 100%;
    position: relative;
    z-index: 2;
  }

  &__head-title {
    @include page-heading-h1($white, true);
    //@include text(48px, 600, none, none, $white);
    position: relative;

    //@media (max-width: 768px) {
    //  font-size: 44px;
    //}
    //
    //@media (max-width: 575px) {
    //  font-size: 24px;
    //}
  }

  &__head-title-svg {
    position: absolute;
    top: 60px;
    left: 255px;

    @media (max-width: 575px) {
      top: 40px;
      left: 50px;
    }
  }

  &__head-text {
    @include page-heading-h1-description($white, true);
    //@include text(16px, 400, none, none, $white);
    //margin-top: 32px;
    //line-height: 26px;
  }

  &__head .new-header-hero__links-box {
    margin-top: 16px;

    @media (max-width: 768px) {
      margin-top: 40px;
    }

    @media (max-width: 575px) {
      flex-direction: column;
    }
  }

  // HEAD: OVERRIDES - LINK BLOCK
  &__head .new-header-hero__links-box {
    @media (max-width: 575px) {
      margin-top: 40px;
    }
  }

  &__head .new-header-hero__links-box .new-link-block.full {
    @media (max-width: 575px) {
      width: 100%;
      margin: unset;
    }
  }

  &__head .new-header-hero__links-box .new-link.arrow {
    @media (max-width: 575px) {
      margin-top: 24px;
    }
  }

  // COUNTRIES
  &__countries {
    padding-top: 160px;

    @media (max-width: 768px) {
      padding-top: 120px;
    }

    @media (max-width: 575px) {
      padding-top: 64px;
    }
  }

  &__countries-title {
    text-align: center;
    //@include page-heading-h2($blue-dark, true);
    @include text(46px, 700, none, none, $blue-dark);

    @media (max-width: 768px) {
      text-align: left;
      font-size: 32px;
    }

    @media (max-width: 575px) {
      font-size: 22px;
    }
  }

  // TERMS: SEARCH PANEL
  &__search-panel {
    margin-top: 80px;

    @media (max-width: 768px) {
      margin-top: 64px;
    }

    @media (max-width: 575px) {
      margin-top: 34px;
    }
  }

  &__search {
    position: relative;
  }

  &__search-input {
    width: 100%;
    padding: 16px 90px 16px 24px;
    box-sizing: border-box;
    border: 1px solid $grey-storm;
    border-radius: 10px;
    outline: none;
    transition: $transition;
    font-family: 'Montserrat', sans-serif !important;
  }

  &__search-input:hover {
    border: 1px solid $dubai-hover;
  }

  &__search-input:focus {
    border: 1px solid $dubai;
  }

  &__search-input::placeholder {
    @include text(16px, 500, none, none, $grey-storm);
    font-family: 'Montserrat', sans-serif !important;
  }

  &__search-clear {
    @include text(16px, 500, none, none, $dubai);
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    position: absolute;
    top: 13px;
    right: 56px;
    cursor: pointer;
    color: $dubai;
    background-color: $mood;
  }

  &__search-button {
    width: 48.5px;
    height: 48.5px;
    position: absolute;
    top: 1px;
    right: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background-color: $dubai;
    box-shadow: $box-shadow;
    cursor: pointer;
  }

  &__search-button:hover {
    background-color: $dubai-hover;
  }

  // TERMS: LISTS BLOCK
  &__countries-list-container {
    margin-top: 80px;
  }

  &__countries-list:not(:first-child) {
    margin-top: 16px;

    @media (max-width: 575px) {
      margin-bottom: 24px;
    }
  }

  &__countries-list-title {
    @include text(36px, 700, none, none, $dubai);

    @media (max-width: 768px) {
      font-size: 32px;
    }

    @media (max-width: 575px) {
      font-size: 22px;
    }
  }

  &__countries-list-items {
    margin-top: 24px;
  }

  &__countries-list-item {
    @include text(16px, 600, none, none, $dubai);
    padding: 8px 24px;
    display: inline-block;
    border-radius: 8px;
    text-align: center;
    background-color: $dirty;
  }

  &__countries-list-item {
    margin-bottom: 24px;

    @media (max-width: 575px) {
      margin-bottom: 16px;
    }
  }

  &__countries-list-item:not(:last-child) {
    margin-right: 24px;

    @media (max-width: 575px) {
      margin-right: 16px;
    }
  }

  & section {
    position: relative;
  }
}