:root {
  --font-family: "Montserrat", sans-serif;
  --text-cl: #000c37;
  --white-cl: #fff;
  --bg-cl: #030a27;
  --blue-cl: #08f;
  --transition: all 300ms ease;
}

$breakpoints: (
        mobile: 375px,
        tablet: 768px,
        desktop: 1170px,
        desktop-m: 1240px,
        desktop-l: 1546px,
);

@mixin for-size($range) {
  $mobile: map-get($breakpoints, mobile);
  $tablet: map-get($breakpoints, tablet);
  $desktop: map-get($breakpoints, desktop);
  $desktop-m: map-get($breakpoints, desktop-m);
  $desktop-l: map-get($breakpoints, desktop-l);

  @if $range == mobile-only {
    @media screen and (max-width: #{$tablet - 1}) {
      @content;
    }
  } @else if $range == mobile {
    @media screen and (min-width: $mobile) {
      @content;
    }
  } @else if $range == tablet {
    @media screen and (min-width: $tablet) {
      @content;
    }
  } @else if $range == desktop {
    @media screen and (min-width: $desktop) {
      @content;
    }
  } @else if $range == desktop-m {
    @media screen and (min-width: $desktop-m) {
      @content;
    }
  } @else if $range == desktop-l {
    @media screen and (min-width: $desktop-l) {
      @content;
    }
  }
}

.bill-of-lading {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 16px;
  line-height: 162%;
  color: var(--white-cl);

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  img {
    display: block;
    max-width: 100%;
    height: auto;
  }

  h1,
  h2,
  h3,
  h4,
  p {
    margin: 0;
  }

  a {
    text-decoration: none;
  }

  strong {
    font-weight: 400;
  }

  a,
  button,
  input {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  button {
    cursor: pointer;
  }

  .container {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 16px;
    padding-right: 16px;
  }

  .visually-hidden {
    position: absolute;
    clip: rect(1px 1px 1px 1px);
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0;
    border: 0;
    height: 1px;
    width: 1px;
    overflow: hidden;
    transition: all 250ms ease;
  }

  .section-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 11px 32px;
    width: 100%;
    font-weight: 600;
    font-size: 16px;
    line-height: 156%;
    color: #fff;
    color: var(--white-cl);
    border-radius: 6px;
    background-color: var(--text-cl);
  }

  .section-link-btn {
    display: flex;
    align-items: center;
    gap: 9px;
    padding: 0;
    width: 100%;
    font-weight: 600;
    font-size: 16px;
    line-height: 162%;
    text-align: right;
    color: var(--blue-cl);
  }

  .section-title {
    margin-bottom: 24px;
    font-weight: 600;
    font-size: 22px;
    line-height: 145%;
    text-align: center;
  }

  .section-img {
    width: 100%;
  }

  .section {
    .text {
      .bold {
        font-weight: 600;
      }
    }
  }

  .section.hero {
    position: relative;
    padding: 104px 0 40px 0;
    background-color: var(--bg-cl);
    background-image: linear-gradient(
                    180deg,
                    rgba(0, 10, 44, 1) 20%,
                    #01051b 100%
    );
    overflow: hidden;

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      content: "";
      display: block;
      width: 100%;
      height: 400px;
      background-image: linear-gradient(153deg, #006ff1 0%, #00408b 100%);
      filter: blur(300px);
      z-index: 1;
    }

    .container {
      position: relative;
      z-index: 2;
    }

    .main-title {
      margin-bottom: 16px;
      font-weight: 600;
      font-size: 24px;
      line-height: 142%;
    }

    .text {
      margin-bottom: 36px;
    }

    .img-box {
      position: relative;
      right: -22px;
      display: block;
      width: 234px;
      margin-left: auto;
    }
  }

  .bill-of-lading-slider {
    max-width: 100%;

    .swiper-wrapper {
      @media (max-width: 375px) {
        .swiper-slide {
          height: auto !important;
        }
      }
    }
  }

  .section.about {
    padding: 80px 0;

    .section-title {
      position: relative;
      color: var(--text-cl);

      &::after {
        position: absolute;
        top: -25px;
        right: 49px;
        content: "";
        display: block;
        width: 52px;
        height: 47px;
        background-image: url(/images/pages/Resources/BillOfLading/mobile/icons/title-icon.png);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;

        @media screen and (min-device-pixel-ratio: 2),
        screen and (min-resolution: 192dpi),
        screen and (min-resolution: 2dppx) {
          background-image: url(/images/pages/Resources/BillOfLading/mobile/icons/title-icon@2x.png);
        }
      }
    }

    .subtitle {
      margin-bottom: 56px;
      text-align: center;
      color: var(--text-cl);
    }

    .text-box {
      margin-bottom: 58px;

      .text {
        &:not(:last-child) {
          margin-bottom: 25px;
        }
      }
    }

    .text {
      color: var(--text-cl);
    }
  }

  .section.about-bill {
    position: relative;
    padding: 80px 0 80px 0;
    background-color: var(--bg-cl);
    overflow: hidden;

    &::after {
      position: absolute;
      top: 20%;
      right: -100%;
      content: "";
      display: block;
      width: 100%;
      height: 300px;
      background-image: linear-gradient(153deg, #006ff1 0%, #00408b 100%);
      filter: blur(272.7982482910156px);
      z-index: 1;
    }

    .container {
      position: relative;
      padding: 0 32px;
      z-index: 2;
    }

    .section-title {
      text-align: left;
    }

    .subtitle {
      margin-bottom: 32px;
    }

    .title {
      margin-bottom: 16px;
      font-weight: 600;
      font-size: 18px;
      line-height: 156%;
    }

    .text-box {
      .text {
        margin-bottom: 59px;
      }

      .benefits-list {
        padding-left: 24px;
        list-style: disc;
      }
    }
  }

  .section.types-bill {
    padding: 80px 0 80px 0;
    background-color: var(--bg-cl);

    .section-title {
      margin-bottom: 41px;
    }

    .subtitle {
      margin-bottom: 40px;
    }

    .title {
      margin-bottom: 16px;
      font-weight: 600;
      font-size: 20px;
      line-height: 150%;
    }

    .text {
      margin-bottom: 32px;
    }

    .benefits-list {
      margin-bottom: 42px;

      .item {
        &::before {
          content: "";
          display: block;
          margin-bottom: 15px;
          width: 40px;
          height: 40px;
          backdrop-filter: blur(71px);
          background-color: rgba(255, 255, 255, 0.04);
          background-image: url(/images/pages/Resources/BillOfLading/mobile/icons/doc.png);
          background-repeat: no-repeat;
          background-size: 15px;
          background-position: center;
          border: 1px solid rgba(255, 255, 255, 0.02);
          border-radius: 8px;

          @media screen and (min-device-pixel-ratio: 2),
          screen and (min-resolution: 192dpi),
          screen and (min-resolution: 2dppx) {
            background-image: url(/images/pages/Resources/BillOfLading/mobile/icons/doc@2x.png);
          }
        }

        &:not(:last-child) {
          margin-bottom: 32px;
        }
      }

      .item-title {
        margin-bottom: 8px;
        font-weight: 600;
        font-size: 18px;
        line-height: 156%;
      }
    }
  }

  .section.importance {
    padding: 80px 0 80px 0;

    .section-title {
      margin-bottom: 56px;
      color: var(--text-cl);
    }

    .text-box {
      color: var(--text-cl);

      .text {
        &:nth-child(1) {
          margin-bottom: 34px;
        }

        &:nth-child(2) {
          margin-bottom: 57px;
        }

        &:nth-child(3) {
          margin-bottom: 34px;
        }
      }
    }
  }

  .section.differences {
    padding: 81px 0 0 0;
    background-color: var(--bg-cl);

    .section-title {
      margin: 0 auto 24px auto;
      max-width: 280px;

      .tablet-br {
        display: none;
      }
    }

    .subtitle {
      margin: 0 auto 50px auto;
      max-width: 270px;
      text-align: center;
    }

    .text {
      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }
  }

  .section.challenges {
    padding: 80px 0 0 0;
    background-color: var(--bg-cl);

    .subtitle {
      margin-bottom: 26px;
      text-align: center;
    }

    .benefits-list {
      .item {
        &:not(:last-child) {
          margin-bottom: 26px;
        }
      }
    }
  }

  .section.digital {
    padding: 80px 0 80px 0;
    background-color: var(--bg-cl);

    .subtitle {
      margin-bottom: 41px;
      text-align: center;

      .underline {
        text-decoration: underline;
      }
    }

    .benefits-list {
      .item {
        &:not(:last-child) {
          margin-bottom: 33px;
        }
      }

      .item-title {
        margin-bottom: 8px;
        font-weight: 600;
        font-size: 18px;
        line-height: 156%;
      }
    }
  }

  .section.practical {
    padding: 81px 0 80px 0;

    .section-title {
      margin-bottom: 56px;
      color: var(--text-cl);
    }

    .subtitle {
      margin-bottom: 56px;
      color: var(--text-cl);
    }

    .benefits-list {
      margin-bottom: 27px;

      .item {
        &:not(:last-child) {
          margin-bottom: 27px;
        }
      }

      .item-title {
        font-weight: 600;
        color: var(--text-cl);
      }

      .item-text {
        color: var(--text-cl);
      }
    }

    .text-box {
      .text {
        color: var(--text-cl);

        &:nth-child(1) {
          margin-bottom: 27px;
        }

        &:nth-child(2) {
          margin-bottom: 58px;
        }

        &:nth-child(3) {
          margin-bottom: 57px;
        }
      }
    }
  }

  .section.key {
    padding: 80px 0 80px 0;
    background-color: var(--bg-cl);

    .section-title {
      margin-bottom: 34px;
    }

    .benefits-list {
      .item {
        &:not(:last-child) {
          margin-bottom: 24px;
        }
      }
    }
  }

  .section.sign-up {
    padding: 80px 0 80px 0;
    background-color: var(--bg-cl);

    .box {
      padding: 24px 16px 16px 16px;
      background-color: rgba(255, 255, 255, 0.08);
      border: 1px solid rgba(255, 255, 255, 0.23);
      border-radius: 24px;
    }

    .submit-box {
      margin-bottom: 32px;

      .title {
        display: block;
        margin-bottom: 16px;
        font-weight: 600;
        font-size: 24px;
        line-height: 142%;
      }

      .text {
        margin-bottom: 25px;
        width: 90%;
      }

      .section-btn {
        background-color: var(--blue-cl);
      }
    }

    .info-box {
      padding: 24px;
      background-color: var(--blue-cl);
      border-radius: 16px;

      .title {
        display: block;
        margin: 24px 0;
        font-weight: 600;
        font-size: 22px;
        line-height: 145%;
      }
    }
  }

  .section.faq {
    padding: 80px 0 80px 0;
    background-color: var(--bg-cl);

    .section-title {
      margin-bottom: 31px;
    }

    .faq-list {
      .item {
        padding: 16px 6px 16px 24px;
        border: 1px solid rgba(255, 255, 255, 0.06);
        border-radius: 16px;
        background-color: rgba(255, 255, 255, 0.08);
        transition: var(--transition);
        overflow: hidden;
        cursor: pointer;
        margin-bottom: 14px;

        &:not(:last-child) {
          margin-bottom: 8px;
        }

        &:nth-child(2) {
          padding: 16px 5px 16px 24px;
        }
      }

      .question-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .question {
          font-family: var(--font-family);
          font-weight: 600;
          font-size: 18px;
          line-height: 156%;
          text-align: left;
          color: var(--white-cl);
        }

        .faq-btn {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 2px;
          transform: rotate(-45deg);
          transition: var(--transition);

          .icon {
            width: 20px;
            height: 20px;
            fill: var(--text-cl);
          }

          &.is-close {
            transform: rotate(0deg);

            .icon {
              fill: #c9cfdb;
            }
          }
        }
      }

      .answer-box {
        max-height: 0;
        opacity: 0;
        transition: all 0.3s;

        &.is-open {
          max-height: 500px;
          opacity: 1;
          transition: all 0.3s;
          margin-top: 8px;
        }

        .answer {
        }
      }
    }
  }

  .section.cta {
    padding: 172px 0 80px 0;
    background-color: var(--bg-cl);

    .box {
      padding: 53px 24px 24px 24px;
      background-color: rgba(255, 255, 255, 0.06);
      background-image: url(/images/pages/Resources/BillOfLading/mobile/cta-bg.png);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center top;
      border: 1px solid rgba(255, 255, 255, 0.06);
      border-radius: 24px;

      @media screen and (min-device-pixel-ratio: 2),
      screen and (min-resolution: 192dpi),
      screen and (min-resolution: 2dppx) {
        background-image: url(/images/pages/Resources/BillOfLading/mobile/cta-bg@2x.png);
      }
    }

    .section-title {
      margin-bottom: 16px;
    }

    .text {
      margin-bottom: 35px;
      text-align: center;
    }

    .section-btn {
      padding: 15px;
      color: var(--text-cl);
      background-color: var(--white-cl);
    }
  }

  .section.blog {
    padding: 80px 0 160px 0;
    background-color: var(--bg-cl);

    .box {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      margin-bottom: 24px;

      .section-title {
        margin: 0;
      }

      .more-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 11px 24px;
        font-weight: 600;
        font-size: 16px;
        line-height: 162%;
        color: var(--white-cl);
        background-color: var(--text-cl);
        border-radius: 10px;
      }
    }

    .blog-list {
      .item {
        background-color: rgba(255, 255, 255, 0.06);
        border: 1px solid rgba(255, 255, 255, 0.06);
        border-radius: 24px;
        overflow: hidden;

        &:not(:last-child) {
          margin-bottom: 15px;
        }
      }

      .item-link {
        text-decoration: none;
      }

      .img-box {
        height: 183px;

        .blog-img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center top;
        }
      }

      .content-box {
        padding: 24px 24px 28px 24px;

        .info-box {
          display: flex;
          align-items: center;
          gap: 16px;
          margin-bottom: 16px;

          .date,
          .rubric {
            font-weight: 500;
            font-size: 12px;
            line-height: 167%;
            color: var(--white-cl);
          }
        }

        .title {
          font-weight: 600;
          font-size: 18px;
          line-height: 156%;
          color: var(--white-cl);
        }
      }
    }
  }

  @include for-size(mobile) {
    .section.about {
      padding: 41px 0 39px 0;

      .section-title {
        &::after {
          top: -17px;
          right: 41px;
        }
      }

      .text-box {
        margin-bottom: 55px;
      }
    }

    .section.about-bill {
      padding: 62px 0 60px 0;

      .container {
        padding: 0 16px;
      }
    }

    .section.types-bill {
      padding: 0 0 38px 0;
    }

    .section.importance {
      padding: 40px 0 40px 0;
    }

    .section.differences {
      padding: 41px 0 0 0;

      .section-title {
        max-width: unset;
      }

      .subtitle {
        max-width: unset;
      }
    }

    .section.digital {
      padding: 80px 0 40px 0;

      .subtitle {
        margin-bottom: 40px;
      }
    }

    .section.practical {
      padding: 40px 0 40px 0;

      .section-title {
        margin-bottom: 58px;
      }
    }

    .section.key {
      padding: 40px 0 80px 0;
    }

    .section.sign-up {
      .submit-box {
        .text {
          width: 100%;
        }
      }

      .info-box {
        .title {
          margin: 24px auto 24px 0;
          max-width: 250px;
        }
      }
    }

    .section.faq {
      padding: 38px 0 80px 0;

      .section-title {
        margin-bottom: 30px;
      }

      .faq-list {
        .item {
          padding: 14px 21px 16px 24px;

          &:nth-child(1) {
            .question-box {
              align-items: baseline;
              .faq-btn {
                position: relative;
                top: 11px;
              }
            }
          }

          &:nth-child(2) {
            padding: 16px 20px 16px 24px;
          }
        }
      }
    }

    .section.cta {
      padding: 0 0 80px 0;

      .box {
        padding: 45px 32px 32px 32px;
        background-image: url(/images/pages/Resources/BillOfLading/mobile-m/cta-bg.png);

        @media screen and (min-device-pixel-ratio: 2),
        screen and (min-resolution: 192dpi),
        screen and (min-resolution: 2dppx) {
          background-image: url(/images/pages/Resources/BillOfLading/mobile-m/cta-bg@2x.png);
        }

        .text {
          margin-bottom: 36px;
        }
      }
    }

    .section.blog {
      padding: 0 0 80px 0;
    }
  }

  @include for-size(tablet) {
    .section-btn {
      display: inline-flex;
      padding: 11px 24px;
      width: auto;
      text-wrap: nowrap;
      font-size: 16px;
    }

    .section-link-btn {
      width: auto;
      text-wrap: nowrap;
      font-size: 14px;
      line-height: 157%;
    }

    .section-title {
      margin-bottom: 24px;
      font-size: 32px;
      line-height: 131%;
    }

    .section.hero {
      padding: 152px 0 90px;

      &::before {
        top: 5%;
        left: -10%;
        height: 200px;
        background-image: linear-gradient(153deg, #006ff1 0%, #00408b 100%);
        filter: blur(300px);
      }

      .main-title {
        max-width: 736px;
        font-size: 44px;
        line-height: 123%;
      }

      .text {
        margin-bottom: 82px;
        max-width: 736px;
      }

      .img-box {
        right: -20px;
        margin: 0 auto;
        width: 384px;
        height: 489px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    .section.about {
      padding: 80px 0 80px 0;

      .section-title {
        margin: 0 auto 24px auto;
        max-width: 736px;

        &::after {
          top: -7px;
          right: 0px;
          width: 85px;
          height: 72px;
          background-image: url(/images/pages/Resources/BillOfLading/tablet/icons/title-icon.png);

          @media screen and (min-device-pixel-ratio: 2),
          screen and (min-resolution: 192dpi),
          screen and (min-resolution: 2dppx) {
            background-image: url(/images/pages/Resources/BillOfLading/tablet/icons/title-icon@2x.png);
          }
        }

        .mob-br {
          display: none;
        }
      }

      .text-box {
        margin-bottom: 56px;
        column-gap: 24px;
        column-count: 3;

        .text {
          min-height: 208px;
          &:not(:last-child) {
            margin-bottom: 0;
          }
        }
      }
    }

    .section.about-bill {
      padding: 80px 0 27px 0;
      overflow: visible;

      &::after {
        top: 50%;
        right: 0;
        height: 80px;
        background-image: linear-gradient(153deg, #006ff1 0%, #00408b 100%);
        filter: blur(270px);
      }

      .section-title {
        margin-bottom: 40px;
        text-align: center;
      }

      .subtitle {
        margin-bottom: 60px;
        text-align: center;
      }

      .title {
        margin-bottom: 25px;
        text-align: center;
        font-size: 20px;
        line-height: 150%;
      }

      .text-box {
        display: flex;
        justify-content: space-around;
        gap: 56px;

        .text,
        .benefits-list {
          flex-basis: calc((100% - 56px * 1) / 2);
        }
      }
    }

    .section.types-bill {
      padding: 0 0 80px 0;

      .text {
        margin-bottom: 39px;
      }

      .benefits-list {
        display: flex;
        gap: 40px;
        flex-wrap: wrap;

        .item {
          flex-basis: calc((100% - 40px * 1) / 2);

          &:not(:last-child) {
            margin-bottom: 0;
          }

          &:nth-child(2) {
            padding-left: 12px;
          }

          &:nth-child(4) {
            padding-left: 3px;
          }
        }

        .item-title {
          font-size: 20px;
          line-height: 150%;
        }
      }
    }

    .section.importance {
      padding: 80px 0 80px 0;

      .text-box {
        margin: 0 auto;
        max-width: 560px;

        .text {
          &:nth-child(1) {
            margin-bottom: 33px;
          }

          &:nth-child(3) {
            margin-bottom: 32px;
          }
        }
      }
    }

    .section.differences {
      padding: 80px 0 0 0;

      .section-title {
        margin: 0 auto 25px auto;

        .tablet-br {
          display: block;
        }
      }

      .subtitle {
        margin: 0 auto 49px auto;
        max-width: 690px;
      }
    }

    .section.challenges {
      position: relative;
      padding: 120px 0 0 0;

      &::after {
        position: absolute;
        top: 50%;
        right: 0;
        content: "";
        display: block;
        width: 100%;
        height: 80px;
        background-image: linear-gradient(153deg, #006ff1 0%, #00408b 100%);
        filter: blur(270px);
        z-index: 1;
      }

      .container {
        z-index: 2;
      }

      .section-title {
        max-width: 590px;
        margin: 0 auto 24px auto;
      }

      .subtitle {
        max-width: 590px;
        margin: 0 auto 49px auto;
      }

      .benefits-list {
        column-count: 2;
        column-gap: 56px;

        .item {
          min-height: 156px;

          &:not(:last-child) {
            margin-bottom: 0;
          }
        }
      }
    }

    .section.digital {
      padding: 120px 0 55px 0;

      .subtitle {
        margin: 0 auto 41px auto;
        max-width: 736px;

        a {
          color: inherit;
        }
      }

      .benefits-list {
        display: flex;
        gap: 64px;

        .item {
          flex-basis: calc((100% - 64px * 1) / 2);

          &:not(:last-child) {
            margin-bottom: 0;
          }
        }

        .item-title {
          margin-bottom: 16px;
          font-size: 20px;
          line-height: 150%;
        }
      }
    }

    .section.practical {
      padding: 80px 0 80px 0;

      .section-title {
        margin: 0 auto 56px auto;
        max-width: 520px;
      }

      .benefits-list {
        margin-bottom: 23px;
      }
    }

    .section.key {
      padding: 97px 0 80px 0;

      .section-title {
        margin-bottom: 51px;
      }

      .benefits-list {
        display: flex;
        flex-wrap: wrap;
        gap: 24px;

        .item {
          flex-basis: calc((100% - 24px * 1) / 2);

          &:not(:last-child) {
            margin-bottom: 0;
          }
        }
      }
    }

    .section.sign-up {
      padding: 79px 0 80px 0;

      .box {
        padding: 16px;
      }

      .submit-box {
        padding: 8px 8px 0 8px;
        margin-bottom: 32px;

        .title {
          display: block;
          margin-bottom: 24px;
        }

        .tablet-flex {
          display: flex;
          align-items: flex-end;
          gap: 17px;
        }

        .text {
          margin-bottom: 0;
        }

        .section-btn {
          min-width: 148px;
        }
      }

      .info-box {
        padding: 40px;

        .title {
          margin: 52px auto 32px 0;
          font-size: 32px;
          line-height: 131%;
          max-width: 370px;
        }
      }
    }

    .section.faq {
      padding: 133px 0 142px 0;

      .section-title {
        margin-bottom: 56px;
      }

      .faq-list {
        .item {
          padding: 24px 21px 24px 32px;
          border-radius: 24px;

          &:not(:last-child) {
            margin-bottom: 14px;
          }

          &:nth-child(1) {
            .question-box {
              .faq-btn {
                top: 1px;
              }
            }
          }

          &:nth-child(2) {
            padding: 24px 21px 24px 32px;
          }
        }

        .question-box {
          align-items: baseline;

          .question {
            font-size: 20px;
            line-height: 150%;
          }

          .faq-btn {
            position: relative;
            top: 1px;
          }
        }
      }
    }

    .section.cta {
      padding: 0 0 120px 0;

      .box {
        padding: 63px 64px 64px 64px;
        background-image: url(/images/pages/Resources/BillOfLading/tablet/cta-bg.png);

        @media screen and (min-device-pixel-ratio: 2),
        screen and (min-resolution: 192dpi),
        screen and (min-resolution: 2dppx) {
          background-image: url(/images/pages/Resources/BillOfLading/tablet/cta-bg@2x.png);
        }
      }

      .section-title {
        margin-bottom: 13px;
        text-align: left;
      }

      .text {
        max-width: 393px;
        text-align: left;
      }

      .section-btn {
        padding: 11px 24px;
      }
    }

    .section.blog {
      padding: 0 0 158px 0;
      overflow: hidden;

      .box {
        align-items: center;
        margin-bottom: 30px;
      }

      .blog-list {
        display: flex;
        flex-wrap: nowrap;
        gap: 24px;

        .item {
          max-width: 374px;
          width: 100%;
          height: inherit;

          &:not(:last-child) {
            margin-bottom: 0;
          }
        }

        .content-box {
          padding: 24px;

          .info-box {
            margin-bottom: 18px;
          }

          .title {
            font-size: 20px;
            line-height: 150%;
          }
        }
      }
    }
  }

  @include for-size(desktop) {
    .section-title {
      margin-bottom: 24px;
      font-size: 36px;
      line-height: 133%;
    }

    .section-link-btn {
      font-size: 16px;
      line-height: 162%;
    }

    .section.hero {
      padding: 200px 0 100px 0;

      &::before {
        height: 100px;
      }

      .container {
        display: flex;
        gap: 100px;
      }

      .text-box {
        width: 621px;
      }

      .main-title {
        font-size: 48px;
        line-height: 125%;
      }

      .img-box {
        margin: 0;
        right: 0;

        &::after {
          position: absolute;
          top: 20%;
          left: 0;
          content: "";
          width: 384px;
          height: 200px;
          background-image: linear-gradient(153deg, #006ff1 0%, #00408b 100%);
          filter: blur(300px);
        }
      }
    }

    .section.about {
      padding: 160px 0 160px 0;

      .section-title {
        &::after {
          top: -68px;
          right: -13px;
        }
      }

      .text-box {
        .text {
          min-height: 131px;
        }
      }
    }

    .section.about-bill {
      padding: 120px 0 95px 0;

      &::after {
        top: 30%;
        height: 100px;
      }

      .subtitle {
        margin: 0 auto 60px auto;
        max-width: 800px;
      }

      .text-box {
        gap: 64px;
      }
    }

    .section.types-bill {
      padding: 0 0 160px 0;

      .section-title {
        margin-bottom: 40px;
      }

      .benefits-list {
        margin-bottom: 41px;

        .item {
          &:nth-child(2) {
            padding-left: 5px;
          }

          &:nth-child(4) {
            padding-left: 5px;
          }
        }
      }
    }

    .section.importance {
      padding: 120px 0 120px 0;
      background-image: url(/images/pages/Resources/BillOfLading/desktop/icons/section-arrow.png);
      background-repeat: no-repeat;
      background-size: 90px;
      background-position: 41% 81.5%;

      @media screen and (min-device-pixel-ratio: 2),
      screen and (min-resolution: 192dpi),
      screen and (min-resolution: 2dppx) {
        background-image: url(/images/pages/Resources/BillOfLading/desktop/icons/section-arrow@2x.png);
      }

      .text-box {
        max-width: unset;
        column-count: 2;
        column-gap: 56px;
      }
    }

    .section.differences {
      padding: 162px 0 0 0;

      .text-box {
        column-count: 2;
        column-gap: 56px;

        .text {
          &:not(:last-child) {
            margin-bottom: 0;
          }
        }
      }
    }

    .section.challenges {
      padding: 159px 0 0 0;

      .section-title {
        max-width: 650px;
      }

      .benefits-list {
        .item {
          min-height: 104px;
        }
      }
    }

    .section.digital {
      padding: 160px 0 160px 0;
    }

    .section.practical {
      padding: 160px 0 159px 0;

      .section-title {
        max-width: 605px;
      }

      .text-container {
        margin: 0 auto;
        max-width: 800px;
      }
    }

    .section.key {
      padding: 160px 0 80px 0;

      .section-title {
        margin-bottom: 57px;
      }

      .benefits-list {
        display: flex;
        flex-wrap: wrap;
        gap: 24px;

        .item {
          flex-basis: calc((100% - 24px * 2) / 3);

          &:not(:last-child) {
            margin-bottom: 0;
          }
        }
      }
    }

    .section.sign-up {
      padding: 80px 0 80px 0;

      .box {
        display: flex;
        align-items: center;
        gap: 40px;
        padding: 8px 8px 8px 40px;
      }

      .submit-box {
        padding: 0;
        margin-bottom: 0;
        width: 402px;

        .tablet-flex {
          flex-direction: column;
          align-items: flex-start;
          gap: 0;
        }

        .text {
          margin-bottom: 40px;
        }

        .section-btn {
          padding: 11px 30px;
          min-width: unset;
        }
      }

      .info-box {
        padding: 40px 40px 75px 40px;
        width: 680px;

        .title {
          margin: 99px auto 32px 0;
          max-width: 405px;
          font-size: 36px;
          line-height: 133%;
        }
      }
    }

    .section.faq {
      padding: 80px 0 160px 0;

      .faq-list {
        margin: 0 auto;
        max-width: 970px;

        .item {
          padding: 24px 30px 24px 32px;

          &:nth-child(2) {
            padding: 24px 30px 24px 32px;
          }
        }
      }
    }

    .section.cta {
      padding: 0 0 162px 0;

      .box {
        padding: 70px 64px 70px 60px;
        background-image: url(/images/pages/Resources/BillOfLading/desktop/cta-bg.png);

        @media screen and (min-device-pixel-ratio: 2),
        screen and (min-resolution: 192dpi),
        screen and (min-resolution: 2dppx) {
          background-image: url(/images/pages/Resources/BillOfLading/desktop/cta-bg@2x.png);
        }

        .text {
          margin-bottom: 45px;
          max-width: 600px;
        }
      }

      .section-title {
        margin-bottom: 21px;
      }
    }

    .section.blog {
      margin-top: -1px;
      padding: 0 0 239px 0;
    }
  }

  @include for-size(desktop-m) {
    .section.importance {
      .text-box {
        .text {
          position: relative;
          &:nth-child(1),
          &:nth-child(2) {
            left: -4px;
          }
        }
      }
    }
  }
}

@include for-size(tablet) {
  .bill-of-lading .container {
    max-width: 992px;
  }
}

@include for-size(desktop) {
  .bill-of-lading {
    font-size: 16px;
    line-height: 162%;
  }

  .bill-of-lading .container {
    max-width: 1170px;
  }
}

@include for-size(desktop-m) {
  .bill-of-lading .container {
    max-width: 1200px;
  }
}