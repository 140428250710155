.d-table__footer {
    .css-w05zow-MuiButtonBase-root-MuiPaginationItem-root {
        font-weight: 600;
        font-size: 12px;
        line-height: 12px;
        color: #2B3847;
        transition: .2s 0s;

        &:hover {
            color: $white;
            background: rgba(#2B3847, .4);
        }

        &.Mui-selected {
            color: $white;
            background: #2B3847;

            &:hover {
                color: $white;
                background: #2B3847;
            }
        }
    }
}
