.dfa-search {
    width: 100%;
    display: flex;

    &__input-box {
        max-width: 94%;
        width: 100%;
        position: relative;

    }

    &__input {
        width: 100%;
        padding: 13px 80px 13px 44px;
        outline: none;
        border: none;
        border-radius: 5px;
        @include shadow_1();
    }

    &__input::placeholder {
        @include placeholder();
    }

    &__icon-search {
        position: absolute;
        top: 17px;
        left: 17px;
        color: $grey1;
        pointer-events: none;
    }

    &__icon-clear {
        position: absolute;
        top: 15px;
        right: 53px;
        height: 20px;
        width: 20px;
        color: $grey1;
        cursor: pointer;
        transition: $transition;
    }

    &__icon-clear:hover {
        color: $blue;
    }


}

.dfa-filter {
    height: 35px;
    width: 35px;
    padding-top: 8px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background-color: $blue-extra-light;
    position: absolute;
    top: 7px;
    right: 10px;
    z-index: 2;
    text-align: center;

    .ant-collapse > .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header {
        padding: 0;
        text-align: center;
    }

    .ant-checkbox-wrapper + .ant-checkbox-wrapper {
        margin-left: 0;
    }

    &__icon {
        height: 14px;
        width: 14px;
        color: $dark;
        pointer-events: none;
        transition: $transition;
    }

    &__dropdown {
        display: block;
        width: 200px;
        padding: 16px;
        border-radius: 6px;
        @include shadow_1();
        background-color: $white;
        position: absolute;
        top: 45px;
        right: -10px;
    }

    &__dropdown.isClosed {
        display: none;
    }

    &__dropdown span {
        font-size: 10px;
        font-weight: 600;
        color: $dark;
        text-transform: uppercase;
        letter-spacing: 0.6px;
    }

    &__title {
        text-align: left;
        margin-bottom: 15px;
    }

    &__options {
        display: flex;
        flex-direction: column;
        margin-bottom: 15px;
    }

    &__buttons {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}
