.latest-news {
    position: relative;

    &__heading {
        margin-bottom: 40px;
        @include new-text(36px, 600, 48px, none, none, $blue-dark);

        @media (max-width: 768px) {
            margin-bottom: 24px;
            @include new-text(32px, 600, 42px, none, none, $blue-dark);
        }

        @media (max-width: 575px) {
            @include new-text(22px, 600, 32px, none, none, $blue-dark);
        }
    }

    &__link-box {
        position: absolute;
        top: 12px;
        right: 0;

        @media (max-width: 768px) {
            top: 10px;
        }

        @media (max-width: 575px) {
            top: unset;
            bottom: -40px;
            right: calc(50% - 70px);
        }
    }

    &__card-list {
        padding: 12px 0;
        display: flex;
        justify-content: space-between;
        overflow-x: auto;

        @media (max-width: 575px) {
            flex-direction: column;
            overflow-x: unset;
        }
    }

    &__card-list::-webkit-scrollbar {
        width: 5px;
        height: 5px;
        border: 3px;
        -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.3);
        border-radius:10px;
        -webkit-border-radius: 10px;
    }

    &__card-list::-webkit-scrollbar-track {
        background-color: $gray-middle;
        border-radius: 3px;
    }

    &__card-list::-webkit-scrollbar-thumb {
        border-radius:10px;
        background-color: $gray-middle-02;
    }

    // CARD
    &__card:not(:last-child) {
        margin-right: 24px;

        @media (max-width: 575px) {
            margin-right: 0;
        }
    }

    &__card-link {
        width: 374px;
        height: 145px;
        padding: 16px 16px 16px 18px;
        border-radius: 6px;
        box-shadow: $box-shadow;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        cursor: pointer;

        @media (max-width: 575px) {
            width: 100%;
        }
    }

    &__card-indicator {
        width: 2px;
        height: 60px;
        position: absolute;
        top: 8px;
        left: 0;
        transition: $transition;
    }

    &__card-link:hover &__card-indicator {
        height: 125px;
    }

    &__card-indicator.index-01 {
        background: $icon-purple;
    }

    &__card-indicator.index-02 {
        background: $icon-green;
    }

    &__card-indicator.index-03 {
        background: $icon-crimson;
    }

    &__card-icon {
        position: absolute;
        top: 24px;
        right: 16px;
        transition: $transition;
    }

    &__card-link:hover &__card-icon {
        fill: $blue-middle;
    }

    &__card-title {
        width: 90%;
        max-height: 90px;
        overflow-y: auto;
        @include new-text(20px, 600, 30px, none, none, $blue-dark);
    }

    &__card-title::-webkit-scrollbar {
        width: 5px;
        border: 3px;
        -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.3);
        border-radius:10px;
        -webkit-border-radius: 10px;
    }

    &__card-title::-webkit-scrollbar-track {
        background-color: $gray-middle;
        border-radius: 3px;
    }

    &__card-title::-webkit-scrollbar-thumb {
        border-radius:10px;
        background-color: $gray-middle-02;
    }

    &__card-details {
        display: flex;
        align-items: center;
        @include new-text(12px, 500, 20px, none, none, $gray-middle-02);
    }

    &__card-details span:not(:last-child) {
        margin-right: 16px;
    }

    &__card-center-dot {
        font-size: 20px;
    }

    // PRELOADER OVERRIDE
    & .preloader-wrap {
        background: none;
    }
}
