.financial-survey {
    position: relative;

    &__header {
        margin-bottom: 72px;
        position: absolute;
        top: 20px;
        left: 40%;
        max-width: 575px;
        width: 100%;
    }

    &__icon-box {
        margin-bottom: 8px;
        text-align: right;
        position: absolute;
        top: 0;
        right: 0;
    }

    &__icon-close {
        fill: #000C37;
        cursor: pointer;
        transition: all 0.2s ease;
    }

    &__icon-close:hover {
        fill: $red-hover;
    }
}

.survey-form {

    &__body {
        display: flex;
        justify-content: space-between;
    }

    &__image-box {
        width: 350px;
        min-height: 584px;
        margin-right: 56px;
        position: relative;
    }

    &__background-image {

    }

    &__logo {
        width: auto;
        position: absolute;
        bottom: 56px;
        left: 100px;
    }

    &__content {
        width: 600px;
        margin-top: 100px;
        padding-right: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &__wrap {

    }

    &__question-box {
        margin-bottom: 32px;
    }

    &__question {
        margin-bottom: 15px;
        font-weight: 600;
        line-height: 24px;
    }

    &__description {
        color: $disabled-text;
        line-height: 24px;
    }

    &__answer {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    &__buttons {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__buttons.isRightSide {
        justify-content: right;
    }
}

// Media:
@media (max-width: 1024px) {
    .financial-survey__header {
        left: 34%;
    }

    .survey-form__image-box {
        width: 276px;
        margin-right: 30px;
    }
}

@media (max-width: 930px) {
    .financial-survey__header {
        max-width: 410px;
        left: 42%;
    }

    .survey-form__content {
        width: 430px;
    }
}

@media (max-width: 768px) {
    .financial-survey__header {
        max-width: 320px;
        width: 100%;
        left: 0;
        top: 215px;
    }

    .financial-survey__icon-box {
        top: -200px;
        right: 20px;
        z-index: 2;
    }

    .financial-survey__icon-close {
        fill: $white;
    }

    .survey-form__body {
        flex-direction: column;
    }

    .survey-form__image-box {
        min-height: auto;
        width: 320px;
        margin-right: 0;
    }

    .survey-form__logo {
        top: 15px;
        left: 85px;
    }

    .survey-form__background-image {
        width: 100%;
    }

    .survey-form__content {
        width: 320px;
        padding-right: 0;
    }

    .survey-form__answer {
        margin-bottom: 24px;
    }

    .survey-form__buttons {
        flex-direction: column;
    }

    .survey-form__buttons .custom-button {
        width: 100%;
    }

    .survey-form__buttons .custom-button:first-child {
        margin-bottom: 5px;
    }
}
