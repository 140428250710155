// !!! THESE ARE VALUES ACCORDING TO DFA DESIGN SYSTEM !!!
// !!! USE THESE VALUES WHILE DEVELOPING AND REFACTORING !!!
// !!! THERE ARE OLD VALUES LOCATED IN /src/styles/utils/_colors CURRENTLY USED IN PROJECT !!!
// !!! USE STYLES FROM /src/styles/global/_colors INSTEAD !!!

// dubai
$dubai: #000C37;
$dubai-hover: #001357;
$dubai-gradient: linear-gradient(#01051B, #000A2C);
// future
$future: #0088FF;
$future-active: #006FF1;
$future-hover: #50ADFF;
$future-light-10: #E6F3FF;
$future-light-05: #F2F9FF;
// grey
$winter: #ffffff;
$grey-lapland: #F8F9FD;
$grey-ice: #EFF1F6;
$grey-mood: #E0E4EC;
$grey-rain: #C9CFDB;
$grey-storm: #9EA9B9;
$grey-dirty: #6E7E92;
// kill bill
$kill-bill: #F52C5C;
$kill-bill-active: #F20C43;
$kill-bill-hover: #FF517B;
$kill-bill-light-10: #FEE7EC;
$kill-bill-light-05: #FEF3F6;
// cucmber
$cucumber: #02BCB1;
$cucumber-active: #019990;
$cucumber-hover: #09D1C5;
$cucmber-light-10: #E6F8F7;
$cucmber-light-05: #F2FCFB;
// amber
$amber: #FC7E1D;
$amber-active: #F8501C;
$amber-hover: #FCAA6B;
$amber-light-10: #FFF2E8;
$amber-light-05: #FFF9F4;
// disco man
$disco-man: #5864FF;
$disco-man-active: #3F4CF1;
$disco-man-hover: #868EF9;
$disco-man-light-10: #ECEDFE;
$disco-man-light-05: #F5F6FE;
// gradients
$introvert: linear-gradient(60deg, #50ADFF 0%, #006FF1 100%);
$birthday: linear-gradient(60deg, #72AEFF 0%, #8730FF 100%);
$new-year: linear-gradient(60deg, #13C549 0%, #0DBBBB 100%);
$extrovert: linear-gradient(60deg, #FFAB1D 0%, #F8501C 100%);
$redrum: linear-gradient(60deg, #FF3B57 0%, #892168 100%);
// colors not in design system but used in project on several pages:
$transparent-026: rgba(255, 255, 255, 0.26);
$transparent-010: rgba(255, 255, 255, 0.10);

// !!! THESE COLORS SHOULD BE REPLACED WITH UPPER ONES !!!
// Project colors:
$white: #ffffff;
$grey-winter: #ffffff;
$white-opacity: rgba(255, 255, 255, 0.1);
$white-transparent: rgba(255, 255, 255, 0.12);
$black: #000000;
$grey-winter-bg-01: rgba(255, 255, 255, 0.04);
$grey-winter-bg-02: rgba(255, 255, 255, 0.08);
$grey-winter-dark: rgba(110, 126, 146, 0.50);
$gray-disabled: #eff1f6;
$gray-light: #e0e4ec;
$grey-dirty: #e0e4ec;
$gray-middle: #c9cfdb;
$gray-middle-02: #9ea9b9;
$gray-dark: #6e7e92;
$gray-dark-transparent: rgba(216, 224, 255, 0.3);
$gray-background: #f5f6ff;
$grey-lapland: #F8F9FD;

$blue-light-accent: #F2F9FF;
$blue-light: rgba(185, 199, 234, 0.05);
$blue-middle: #0088ff;
$blue-middle-hover: #50adff;
$blue-future: #006FF1;
$blue-dark: #000c37;

$dubai-dark: #01051B;
$blue-dark-02: #192145;
$blue-dark-03: #292F47;
$blue-dark-hover: #001357;

$violet-light: #6a71b6;
$violet-middle: #4f5590;
$violet-dark: rgba(106, 113, 182, 0.1);

// Home: icons' colors:
$icon-blue: linear-gradient(59.74deg, #2dc8ed 0%, #548af0 100%);
$icon-blue-webkit: -webkit-linear-gradient(59.74deg, #2dc8ed 0%, #548af0 100%);
$icon-blue-hover: #2dc8edff;
$icon-orange: linear-gradient(59.74deg, #ffab1d 0%, #f8501c 100%);
$icon-orange-webkit: -webkit-linear-gradient(59.74deg, #ffab1d 0%, #f8501c 100%);
$icon-orange-hover: #ffab1dff;
$icon-green: linear-gradient(59.74deg, #13c549 0%, #0dbbbb 100%);
$icon-green-webkit: -webkit-linear-gradient(59.74deg, #13c549 0%, #0dbbbb 100%);
$icon-green-hover: #13c549ff;
$icon-crimson: linear-gradient(59.74deg, #ff3b57 0%, #892168 100%);
$icon-crimson-webkit: -webkit-linear-gradient(59.74deg, #ff3b57 0%, #892168 100%);
$icon-crimson-hover: #ff3b57ff;
$icon-azure: linear-gradient(59.74deg, #7190bf 0%, #56f1d4 100%);
$icon-azure-hover: #7190bfff;
$icon-purple: linear-gradient(59.74deg, #72aeff 0%, #8730ff 100%);
$icon-purple-webkit: -webkit-linear-gradient(59.74deg, #72aeff 0%, #8730ff 100%);
$icon-purple-hover: #72aeffff;

// Benefits: icons' colors:
$digital-tools-icon-blue: linear-gradient(32.11deg, #0056FB -9.6%, #6F85FA 126.88%);
$digital-tools-icon-green: linear-gradient(134.41deg, #14C53B -6.31%, #11C88D 33.16%, #0CBBBB 94.74%);
$digital-tools-icon-gray: linear-gradient(225.82deg, #595D5D -1.44%, #252829 103.01%);;
$digital-tools-icon-border: #e89313;

// CargoesFlow icons' colors:
$advantages-icon-crimson: #be0b34;
$advantages-icon-purple: #5f3bff;
$advantages-icon-orange: #ff6c1d;

$dp-purple: linear-gradient(93.05deg, #5154B6 4.29%, #312A82 31.75%, #261D5E 72.43%, #1E1343 102.54%);

