.events.global-freight-summit-2024 .intro-section {
    background-image: url('/images/pages/Events/GlobalFreightSummit/global-freight-2024_bg.png');
    background-position: center;

  &__description-title {
    max-width: 575px;
    width: 100%;
  }

  & .new-link-block.full {
    color: $dubai;
    background-color: $white;
    border-color: $white;

    @media (max-width: 575px) {
      width: 100%;
    }
  }
}

.events.global-freight-summit-2024 .description-section {
  margin-top: 80px;

  @media (max-width: 768px) {
    margin-top: 96px;
  }

  @media (max-width: 575px) {
    margin-top: 68px;
  }

  &__title {
    @include new-text(12px, 600, 20px, uppercase, none, $grey-storm);
    letter-spacing: 1.2px;
  }

  &__content {
    margin-top: 16px;
    display: flex;
    justify-content: space-between;

    @media (max-width: 768px) {
      display: block;
    }
  }

  &__content-box {
    width: 42%;

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  &__paragraphs-text {
    @include new-text(16px, 400, 26px, none, none, $dubai);

    @media (max-width: 768px) {
      margin-top: 24px!important;
    }
  }

  &__content-box:first-child .description-section__paragraphs-box .description-section__paragraphs-text:last-child {
    margin-top: 24px;

    @media (max-width: 768px) {
      margin-top: unset;
    }
  }
}

.events.global-freight-summit-2024 .video-section {
  margin-top: 120px;

  @media (max-width: 768px) {
    margin-top: 96px;
  }

  @media (max-width: 575px) {
    margin-top: 68px;
  }

  &__content {
     display: flex;
    justify-content: center;
    align-items: center;
  }

  &__player {
    width: 100%;
    border-radius: 10px;
  }
}

.events.global-freight-summit-2024 .advantages-section {
  margin-top: 120px;
  margin-bottom: 80px;

  @media (max-width: 768px) {
    margin-top: 96px;
  }

  @media (max-width: 575px) {
    margin-top: 68px;
  }

  &__title {
    @include new-text(36px, 600, 46px, none, none, $dubai);

    @media (max-width: 575px) {
      font-size: 22px;
      line-height: 32px;
    }
  }

  &__paragraph {
    margin-top: 24px;
    @include new-text(16px, 400, 26px, none, none, $dubai);
  }

  &__paragraph.bold {
    font-weight: 600;
  }

  &__buttons-box {
    margin-top: 24px;
  }

  & .new-link-block.full {
    color: $white;
    background-color: $dubai;
    border-color: $dubai;

    @media (max-width: 575px) {
      width: 100%;
    }
  }

  & .new-link-block.full:hover {
    color: $white;
    background-color: $dubai-hover;
    border-color: $dubai;
  }
}