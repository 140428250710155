body {
    font-family: "Montserrat", sans-serif !important;
    color: $dark-blue;
    overflow-y: auto !important;
}

.app {
    display: flex;
    flex-direction: column;
    //overflow: hidden;

    min-height: 100vh;

    &__main {
        flex: 1;

        display: flex;
        flex-direction: column;
    }

    &__main--empty {
        height: 100vh;
        display: flex;
        flex-direction: column;
    }
}

.container {
    $padding: 20;
    margin: 0 auto;
    padding: 0 #{$padding}px;
    width: 100%;
    max-width: #{1170 + ($padding * 2)}px;
    position: relative;

    @include media-breakpoint-down(sm) {
        $padding: 16;
        padding: 0 #{$padding}px;
    }

    @include media-breakpoint-down(xs) {
        $padding: 10;
        padding: 0 #{$padding}px;
    }

    &--1100 {
        max-width: #{1100 + ($padding * 2)}px;
    }
    &--920 {
        max-width: #{920 + ($padding * 2)}px;
    }
    &--720 {
        max-width: #{720 + ($padding * 2)}px;
    }
}

.section {

    &__100vh {
        height: 100vh;
    }
}

.section-quick-registration {
    padding: 60px 0;
    background-color: $white1;
}

.section-padding-top {
    padding: 100px 0 0 0;
}

.text-content {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 160%;
    color: $dark-grey;

    @include media-breakpoint-down(sm) {
        font-size: 16px;
    }

    @include media-breakpoint-down(xs) {
        font-size: 14px;
    }
}

.hidden {
    display: none;
}

.relative {
    position: relative;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
