.contactApp {

    @media (max-width: 475px) {
        margin-top: 32px;
    }

    &-modal {
        width: 100%;
        height: 100%;

        display: flex;
        align-items: center;
        justify-content: center;

        overflow: auto;

        background: rgba(8, 19, 62, 0.2);
        backdrop-filter: blur(12px);
    }
}
