$image-path: '/images/pages/Resources/FreightDictionary/glossary-page';

.freight-dictionary {
  // BREADCRUMBS
  .breadcrumbs {
    position: static;
    margin-bottom: 32px;

    a {
      font-weight: 600;
    }

    .last-item {
      font-weight: 600;
      color: $disabled-text;
    }
  }

  // HERO
  &__head {
    padding: 144px 0 103px 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-image: url('#{$image-path}/mobile/hero-bg.png');

    @media screen and (min-device-pixel-ratio: 2),
    screen and (min-resolution: 192dpi),
    screen and (min-resolution: 2dppx) {
      background-image: url('#{$image-path}/mobile/hero-bg@2x.png');
    }

    @include for-size(tablet) {
      padding: 118px 0 410px 0;
      background-image: url('#{$image-path}/tablet/hero-bg.png');

      @media screen and (min-device-pixel-ratio: 2),
      screen and (min-resolution: 192dpi),
      screen and (min-resolution: 2dppx) {
        background-image: url('#{$image-path}/tablet/hero-bg@2x.png');
      }
    }

    @include for-size(desktop-m) {
      padding: 205px 0 208px 0;
      background-image: url('#{$image-path}/desktop/hero-bg.png');

      @media screen and (min-device-pixel-ratio: 2),
      screen and (min-resolution: 192dpi),
      screen and (min-resolution: 2dppx) {
        background-image: url('#{$image-path}/desktop/hero-bg@2x.png');
      }
    }
  }

  &__head-title {
    position: relative;
    margin-bottom: 24px;
    max-width: 530px;
    font-weight: 600;
    font-size: 54px;
    line-height: 111%;
    color: $white;

    &::after {
      position: absolute;
      bottom: -10px;
      right: 106px;
      content: '';
      display: block;
      width: 154px;
      height: 10px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      background-image: url('#{$image-path}/mobile/icons/design-line.svg');
    }

    @include for-size(tablet) {
      margin-bottom: 39px;
    }

    @include for-size(desktop-m) {
      margin-bottom: 32px;
      max-width: 750px;
      font-size: 72px;
      line-height: 111%;

      &::after {
        bottom: -15px;
        width: 208px;
        height: 13px;
        background-image: url('#{$image-path}/desktop/icons/design-line.svg');
      }
    }
  }

  &__head-text {
    margin-bottom: 24px;
    @include body4();
    color: $input-text;

    @include for-size(tablet) {
      margin-bottom: 32px;
      @include body7();
      color: $input-text;
    }
  }

  &__list-benefits {
    padding-left: 3px;
    margin-bottom: 40px;
    @include body4();
    color: $input-text;

    .item {
      display: flex;
      align-items: center;
      gap: 8px;
      &:not(:last-child) {
        margin-bottom: 8px;
      }

      &::before {
        content: '';
        display: block;
        width: 16px;
        height: 16px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        background-image: url('#{$image-path}/mobile/icons/check.svg');
      }
    }

    @include for-size(tablet) {
      padding-left: 0;
      @include body7();
      color: $input-text;
    }
  }

  &__links-box {
    display: flex;
    flex-direction: column;
    gap: 27px;

    .new-link-block.full {
      padding: 10px 0;
      width: 100%;
    }
    .new-link.arrow {
      text-align: center;

      .link-arrow {
        margin: 0 0 0 15px;
      }
    }

    @media screen and (min-width: 600px) {
      flex-direction: row;
      align-items: center;
      gap: 24px;

      .new-link-block.full {
        width: 168px;
      }
    }
  }

  // TERMS: SEARCH TAGS
  &__search-tags {
    margin-bottom: 40px;
  }

  &__search-tags-list {
    display: flex;
    flex-wrap: wrap;
    gap: 8px 16px;

    @include for-size(tablet) {
      gap: 16px;
    }

    @include for-size(desktop-m) {
      gap: 13.5px;
    }
  }


  &__search-tag {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 38px;
    border-radius: 8px;

    &.active-tag {
      background-color: $disabled-background;
    }

    @include for-size(desktop-m) {
      height: 34px;
    }
  }

  &__search-tag-text {
    @include body5();
  }

  // TERMS SECTION
  &__terms {
    padding: 48px 0 48px 0;

    @include for-size(tablet) {
      padding: 24px 0 160px 0;
    }
  }

  // TERMS: SEARCH PANEL
  &__search {
    position: relative;
  }

  &__search-input {
    width: 100%;
    padding: 15px 90px 15px 14px;
    font-weight: 500;
    font-size: 16px;
    line-height: 162%;
    box-sizing: border-box;
    border: 2px solid $input-border;
    border-radius: 10px;
    outline: none;
    transition: $transition;

    @include for-size(desktop-m) {
      padding: 15px 90px 15px 22px;
    }
  }

  &__search-input:hover {
    border: 2px solid $dubai-hover;
  }

  &__search-input:focus {
    border: 2px solid $dubai;
  }

  &__search-input::placeholder {
    font-weight: 500;
    font-size: 16px;
    line-height: 162%;
    color: $disabled-text;
  }

  &__search-clear {
    @include text(16px, 500, none, none, $dubai);
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    position: absolute;
    top: 18px;
    right: 65px;
    cursor: pointer;
    color: $dubai;
    background-color: $mood;
  }

  &__search-button {
    width: 57.5px;
    height: 57.5px;
    position: absolute;
    top: 1px;
    right: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background-color: $dubai;
    box-shadow: $box-shadow;
    cursor: pointer;
  }

  &__search-button:hover {
    background-color: $dubai-hover;
  }

  // TERMS: LISTS BLOCK
  &__terms-list-container {
    margin-top: 40px;

    @include for-size(tablet) {
      margin-top: 78px;
    }

    @include for-size(tablet) {
      margin-top: 80px;
    }

    @include for-size(desktop-m) {
      &.terms-list-container-searched {
        display: flex;
        flex-wrap: wrap;
        gap: 24px;
      }
    }
  }

  &__terms-list {
    &:not(:last-child) {
      padding-bottom: 24px;
      margin-bottom: 24px;
      border-bottom: 1px solid $mood;
    }

    @include for-size(tablet) {
      display: flex;
      justify-content: space-between;

      &:not(:last-child) {
        padding-bottom: 40px;
        margin-bottom: 40px;
      }
    }
  }

  &__terms-list-title {
    font-weight: 600;
    font-size: 24px;
    line-height: 142%;

    @include for-size(tablet) {
      font-size: 48px;
      line-height: 125%;
    }
  }

  &__terms-list-items {
    margin-top: 24px;

    @include for-size(tablet) {
      margin-top: 0;
      width: 66%;
    }

    @include for-size(desktop-m) {
      display: flex;
      flex-wrap: wrap;
      gap: 24px;
      width: 85.5%;
    }
  }

  &__sublist-item {
    padding: 24px;
    border-radius: 16px;
    min-height: 246px;
    background-color: $disabled-background;

    &:not(:last-child) {
      margin-bottom: 24px;
    }

    @include for-size(tablet) {
      min-height: 205px;
    }

    @include for-size(desktop-m) {
      flex-basis: calc((100% - 24px * 1) / 2);

      &:not(:last-child) {
        margin-bottom: 0;
      }
    }
  }

  &__term-link {
    display: block;
    margin-bottom: 10px;
    @include text(22px, 600, none, underline, $dubai);
    line-height: 145%;

    @include for-size(tablet) {
      @include text(32px, 600, none, underline, $dubai);
      line-height: 131%;
    }

    @include for-size(desktop-m) {
      @include text(36px, 600, none, underline, $dubai);
      line-height: 133%;
    }
  }

  &__text-ellipsis {
    height: 154px;
    @include body7();
    color: $ellipsis-term;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    overflow: hidden;

    @include for-size(tablet) {
      height: 105px;
      -webkit-line-clamp: 4;
    }
  }
}


.freight-dictionary {
  // SIGN UP
  &__sign-up {
    margin-top: 160px;

    @media (max-width: 575px) {
      margin-top: 100px;
    }
  }

  &__sign-up-wrap {
    padding: 8px 8px 8px 40px;
    border-radius: 24px;
    background-color: $dubai;
    display: flex;

    @media (max-width: 768px) {
      flex-direction: column;
      padding: 16px;
    }
  }

  &__sign-up-join-us {
    flex: 1.2;
    align-self: center;
  }

  &__sign-up-title {
    @include text(24px, 600, none, none, $white);
    display: flex;
    flex-direction: column;
  }

  &__sign-up-description {
    @include text(16px, 400, none, none, $white);
    margin-top: 24px;
    line-height: 26px;
  }

  &__sign-up-button-box {
    margin-top: 40px;
  }

  &__sign-up-membership {
    flex: 2;
    padding: 40px;
    margin-left: 40px;
    border-radius: 16px;
    background-color: $future-active;

    @media (max-width: 768px) {
      margin: 24px 0 0 0;
    }

    @media (max-width: 575px) {
      padding: 24px;
    }
  }

  &__sign-up-membership-logos-logo:not(:last-child) {
    margin-right: 42px;
  }

  &__sign-up-membership &__sign-up-title {
    @include text(36px, 600, none, none, $white);
    line-height: 48px;
    margin-top: 100px;

    @media (max-width: 768px) {
      margin-top: 52px;
      font-size: 32px;
    }

    @media (max-width: 575px) {
      font-size: 22px;
      line-height: 32px;
    }
  }

  &__sign-up-membership &__sign-up-description {
    margin-top: 32px;
  }

  // POSTS
  &__posts {
    margin-top: 160px;

    @media (max-width: 768px) {
      margin-top: 100px;
    }
  }

  &__posts-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__posts-title-text {
    @include text(36px, 700, none, none, $dubai);
    position: relative;

    @media (max-width: 768px) {
      font-size: 32px;
    }

    @media (max-width: 575px) {
      font-size: 22px;
    }
  }

  &__posts-title-text svg {
    position: absolute;
    top: -80px;
    left: 340px;

    @media (max-width: 768px) {
      width: 100px;
      height: 100px;
      left: 285px;
    }

    @media (max-width: 575px) {
      width: 85px;
      height: 85px;
      top: -60px;
      left: 195px;
    }
  }

  &__posts-list {
    margin-top: 32px;
    display: flex;
    overflow-x: auto;
    padding: 12px;
    margin: 32px -12px;
  }

  &__posts-list::-webkit-scrollbar {
    height: 6px;
    width: 5px;
    border: 3px;
    -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.3);
    border-radius:10px;
    -webkit-border-radius: 10px;
  }

  &__posts-list::-webkit-scrollbar-track {
    background-color: $mood;
    border-radius: 3px;
  }

  &__posts-list::-webkit-scrollbar-thumb {
    border-radius:10px;
    background-color: $rain;
  }

  &__post-item {
    min-width: 374px;
    border-radius: 16px;
    box-shadow: $shadow-04;

    @media (max-width: 425px) {
      min-width: 308px;
    }
  }

  &__post-item:not(:last-child) {
    margin-right: 24px;
  }

  &__post-item-link {
    text-decoration: none;
  }

  &__post-item-image-box {
    height: 184px;


    img {
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__post-item-content-box {
    padding: 16px 24px;
  }

  &__post-item-date {
    @include text(12px, 500, none, none, $storm);
  }

  &__post-item-title {
    @include text(16px, 600, none, none, $dubai);
    line-height: 26px;
    margin-top: 16px;
    height: 80px;
    overflow-y: auto;

    @media (max-width: 575px) {
      font-size: 14px;
      line-height: 22px;
    }

  }

  &__post-item-title::-webkit-scrollbar {
    width: 5px;
    border: 3px;
    -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.3);
    border-radius:10px;
    -webkit-border-radius: 10px;
  }

  &__post-item-title::-webkit-scrollbar-track {
    background-color: $mood;
    border-radius: 3px;
  }

  &__post-item-title::-webkit-scrollbar-thumb {
    border-radius:10px;
    background-color: $rain;
  }
}