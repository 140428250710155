// !!! THESE ARE VALUES ACCORDING TO DFA DESIGN SYSTEM !!!
// !!! USE THESE VALUES WHILE DEVELOPING AND REFACTORING !!!
// !!! THERE ARE OLD VALUES LOCATED IN /src/styles/utils/_mixins CURRENTLY USED IN PROJECT !!!
// !!! USE STYLES FROM /src/styles/global/_mixins INSTEAD !!!

@mixin new-container() {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
}

@mixin new-text($size, $weight, $height, $transform, $decoration, $color) {
    font-size: $size;
    font-weight: $weight;
    line-height: $height;
    text-transform: $transform;
    text-decoration: $decoration;
    color: $color;
}

@mixin page-heading-h1($color, $marginBottom) {
    font-size: 36px;
    font-weight: 600;
    line-height: 48px;
    text-transform: uppercase;
    color: $color;

    @if $marginBottom {
        margin-bottom: 40px;
    }

    @media (max-width: 768px) {
        font-size: 32px;
        line-height: 42px;
        @if $marginBottom {
            margin-bottom: 32px;
        }
    }

    @media (max-width: 575px) {
        font-size: 24px;
        line-height: 32px;
        @if $marginBottom {
            margin-bottom: 24px;
        }
    }
}

@mixin page-heading-h1-description($color, $marginBottom) {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: $color;

    @if $marginBottom {
        margin-bottom: 40px;
    }

    @media (max-width: 768px) {
        @if $marginBottom {
            margin-bottom: 32px;
        }
    }

    @media (max-width: 575px) {
        @if $marginBottom {
            margin-bottom: 24px;
        }
    }
}

@mixin page-heading-h2($color, $marginBottom) {
    font-size: 36px;
    font-weight: 600;
    line-height: 48px;
    color: #000c37;

    @if $marginBottom {
        margin-bottom: 32px;
    }

    @media (max-width: 768px) {
        font-size: 32px;
        line-height: 42px;

        @if $marginBottom {
            margin-bottom: 32px;
        }
    }

    @media (max-width: 575px) {
        font-size: 24px;
        line-height: 34px;

        @if $marginBottom {
            margin-bottom: 24px;
        }
    }
}
