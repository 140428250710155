@mixin mediaMixin($minMax, $width) {
  @media only screen and (#{$minMax}-width: #{$width}px) {
    @content;
  }
}

.events .form-section {
  background-image: url('/images/main/events/1.png');
  background-size: cover;
  padding: 120px 0 135px;

  @include mediaMixin(max, 576) {
    padding: 64px 0 80px;
  }

  &__content {
  }

  &__main-box {
    background: $white-opacity;
    border: 1px solid $white-opacity;
    border-radius: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include mediaMixin(max, 998) {
      justify-content: center;
      min-height: 474px;
    }
  }

  &__image-box {
    flex: 0 0 27%;
    padding: 15px;

    @include mediaMixin(max, 998) {
      display: none;
    }
  }

  &__image {
    display: inline-block;
    border-radius: 16px 20% 16px 16px;
    max-width: 425px;
  }

  &__form-content-box {
    flex: 0 0 57%;

    @include mediaMixin(max, 998) {
      flex: 0 0 100%;
      padding: 84px 0 56px;
    }

    @include mediaMixin(max, 576) {
      padding: 60px 0 24px;
    }
  }

  &__form-content-box-header {
    margin-bottom: 25px;
  }

  &__form-title-h3 {
    @include h3;
    font-size: 24px;
    color: $white;
    font-weight: 600;
    position: relative;

    @include mediaMixin(max, 998) {
      padding-left: 60px;
    }
  }

  &__vector {
    position: absolute;
  }

  &__vector-1 {
    top: -5px;
    left: -30px;
    transform: translate(-50%, -50%);

    @include mediaMixin(max, 998) {
      top: -35px;
      left: 35px;
    }

    @include mediaMixin(max, 576) {
      top: -25px;
    }
  }

  &__vector-2 {
    bottom: -30px;
    right: 10%;
    transform: translate(0, 0);

    @include mediaMixin(max, 576) {
      right: 8%;
    }
  }

  &__disclamer-box {
    margin-top: 120px;
  }

  &__disclamer-title-h3 {
    @include h3;
    color: $white;
    font-size: 24px;
    margin-bottom: 24px;
  }

  &__disclamer-content {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  &__text {
    font-size: 14px;
    color: $gray-light;
    font-weight: 400;
    max-width: 400px;
    line-height: 22px;
    flex: 0 0 45%;
    margin-bottom: 24px;

    @include mediaMixin(max, 576) {
      flex: 0 0 100%;
    }
  }

  &__text:first-child {
    margin-right: 68px;

    @include mediaMixin(max, 998) {
      margin-right: 30px;
    }
  }
}

.events .demo-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-right: 64px;

  @include mediaMixin(max, 998) {
    padding: 0 30px;
    width: 100%;
  }

  label {
    position: relative;
    width: auto;
  }

  label[for='firstName']:after,
  label[for='lastName']:after,
  label[for='number']:after,
  label[for='email']:after {
    content: '*';
    color: #f52c5c;
    font-size: 16px;
    position: absolute;
    left: -4px;
    transform: translate(-50%, -50%);
  }

  .select__phone {
    border-right: 1px solid $gray-middle-02;
  }

  .select__panel--phone {
    border: 1px solid $gray-middle-02;

    &:hover {
      border: 1px solid #08f;
      cursor: pointer;
    }

    &:focus {
      border: 1px solid #08f;
    }
  }

  .select__panel {
    background: transparent;
    border: 1px solid $gray-middle-02;

    &:hover,
    &:focus {
      border: 1px solid #08f;
    }
  }

  .input {
    flex: 0 0 48%;
    margin-bottom: 45px;

    @include mediaMixin(max, 576) {
      flex: 0 0 100%;
      margin-bottom: 40px;
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: white;
  }

  .input__body {
    background: transparent;
    border: 1px solid $gray-middle-02;
    color: $white;
    transition: border 0.3s linear;

    &:hover {
      border: 1px solid #08f;
      cursor: pointer;
    }

    &:focus {
      border: 1px solid #08f;
    }
  }

  .input__label {
    font-weight: 700;
    color: $white;
    margin-bottom: 8px;
  }

  .select__panel--phone {
    background: transparent;
  }

  .select__panel-number--fill {
    color: $white;
  }

  .select__input {
    background: transparent;
    color: $white;
    padding: 0 16px;
  }

  .input__textarea {
    background: transparent;
    color: $white;
    border: 1px solid $gray-middle-02;

    &:focus {
      border: 1px solid #08f;
    }
  }

  &__button-wrapper {
    flex: 0 0 100%;
    display: flex;
    justify-content: center;

    a {
      text-decoration: none;
      color: white;
    }
  }

  &__tablet {
    color: $white;
    display: flex;
    flex-direction: column;
    align-items: center;

    mark {
      color: $accent;
      text-decoration: underline;
      background: transparent;
    }
  }

  &__tablet-title {
    font-size: 24px;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 20px;
  }

  &__tablet-subtitle {
    font-size: 18px;
    color: $grey1;
  }

  &__button {
    position: relative;
    padding: 10px 60px;
    font-size: 16px;
    color: $white;
    font-weight: 500;
    background: $blue-middle;
    border-radius: 6px;
    outline: none;
    border: none;

    @include mediaMixin(max, 576) {
      width: 100%;
      max-width: 285px;
    }
  }

  &__loader {
    left: 15px;
    top: 15%;
    position: absolute;
    height: 25px;
    width: 25px;
    margin-left: 10px;
    border-radius: 50%;
    border-top: 2px solid #fff;
    border-right: 2px solid transparent;
    animation: spinner 1s linear infinite;
  }
}

@keyframes spinner {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
