.btn {
    @include reset-btn;
    min-height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 100%;
    cursor: pointer;
    transition: .2s ease;

    position: relative;
    font-family: 'Montserrat', sans-serif;

    text-decoration: none !important;

    &.btn-dark {
        width: 222px;
        min-width: 222px;
        border: 1px solid $dark-blue;
        background: $dark-blue;
        color: #fff;
        border-radius: 6px;

        @include media-breakpoint-up(md) {
            &:hover {
                background: $btn-hover-color;
                box-shadow: 0 7px 25px rgba(0, 12, 55, 0.3);
            }

            &:active {
                background: $btn-active-color;
            }
        }

        @include media-breakpoint-down(xs) {
            width: 100%;
            min-height: 56px;
            min-width: 100%;
        }
    }

    &.btn-blue {
        background: $accent;
        color: #fff;
        border-radius: 6px;

        @include media-breakpoint-up(md) {
            @include opacity-hover(1, .8);

            &:active {
                opacity: .8;
            }
        }
    }

    &.btn-light {
        @extend .btn-dark;
        background: #fff;
        border: 1px solid #fff;
        color: $dark-blue;

        @include media-breakpoint-up(md) {
            &:hover {
                background: $dark-blue;
                border: 1px solid $dark-blue;
                box-shadow: 0 0 35px -10px rgba(188, 203, 255, 0.6);
                color: #fff;
            }

            &:active {
                background: $btn-active-color;
            }
        }
    }

    &.btn-arrow-link {
        color: $dark-grey;
        position: relative;
        transition: 0.3s;

        &:after {
            position: absolute;
            content: "";
            display: block;
            top: 14px;
            right: -18px;
            width: 8px;
            height: 12px;
            mask: url('/images/main/home/chevron.svg') no-repeat center / cover;
            -webkit-mask: url('/images/main/home/chevron.svg') no-repeat center / cover;
            background-color: $dark-grey;
            transition: 0.2s ease;
        }

        @include media-breakpoint-up(md) {
            &:hover {
                &:after {
                    top: 14px;
                    right: -24px;
                    mask: url('/images/main/home/arrow.svg') no-repeat center / cover;
                    -webkit-mask: url('/images/main/home/arrow.svg') no-repeat center / cover;
                    width: 18px;
                    height: 12px;
                }
            }
        }
    }

    &.btn-arrow-link-blue {
        color: $accent;

        @extend .btn-arrow-link;
        &:after {
            background-color: $accent;
        }
    }

    &.btn-full {
        width: 100%;
        min-width: 100%;
    }

    &.size {

        &-s {
            width: 168px;
            height: 48px;
        }

        &-xs {
            width: 160px;
            min-width: 160px;
        }

        &-xxs {
            $width: 80px;
            $height: 30px;

            width: $width;
            min-width: $width;
            height: $height;
            min-height: $height;

            font-weight: 500;
            font-size: 14px;
        }
    }

    &__wrapper {
        display: flex;
        align-items: center;
        gap: 10px;
        width: 100%;

        margin-top: 20px;
        justify-content: flex-end;

        @include media-breakpoint-down(sm) {
            margin-top: 0;
        }

        @include media-breakpoint-down(xs) {
            margin-top: -20px;
        }
    }
}

.btn-group {
    display: flex;

    .btn:not(:first-of-type) {
        margin-left: 49px;
    }
}
