.company-autocomplete {
    &__label {
        display: flex;
        flex-direction: column;
        position: relative;
    }

    &__label-text {
        @include new-text(10px, 700, 22px, uppercase, none, $blue-dark);
        letter-spacing: 1px;
        margin-bottom: 8px;
    }

    &__input {
        width: inherit;
        padding: 8px 16px;
        border: 1px solid $gray-middle-02;
        border-radius: 6px;
        outline: none;
        cursor: pointer;
    }

    &__input::placeholder {
        @include new-text(14px, 400, 22px, none, none, $gray-middle-02);
    }

    &__input-icon {
        position: absolute;
        top: 45px;
        right: 15px;
        transform: rotate(0);
        transition: $transition;
    }

    &__input-icon.is-opened {
        transform: rotate(180deg);
    }

    &__dropdown {
        max-height: 0;
        width: 100%;
        padding: 4px;
        position: absolute;
        top: 100%;
        left: 0;
        border-radius: 5px;
        box-shadow: $box-shadow;
        transition: $transition;
        overflow-y: scroll;
        visibility: hidden;
        opacity: 0;
    }

    &__dropdown.is-opened {
        opacity: 1;
        max-height: 300px;
        visibility: visible;
        background: $white;
        z-index: 1;
    }

    &__dropdown-item {
        display: flex;
        padding: 8px 12px;
        border-radius: 5px;
        cursor: pointer;
        transition: $transition;
    }

    &__dropdown-item:hover {
        background-color: $blue-light-accent;
    }

    &__dropdown-item .flag-icon {
        width: 21px;
        height: 16px;
        margin: 5px 16px 0 0;
    }

    &__dropdown-item-info-line {
        @include new-text(12px, 400, 16px, none, none, $blue-dark);
    }

    &__dropdown-item-info-line.company-name {
        @include new-text(14px, 400, 18px, none, none, $blue-dark);
    }

    &__input.error {
        border: 1px solid $icon-crimson-hover;
    }

    &__error {
        margin-top: 4px;
        @include new-text(11px, 400, 100%, none, none, $icon-crimson-hover);
    }

    &__notification {
        padding: 8px 12px;
        @include new-text(10px, 700, 22px, uppercase, none, $blue-dark);
    }

    & .preloader {
        width: 20px;
        height: 20px;
    }
}

