.tag {
    $parent: &;

    width: 100%;
    min-height: 65px;

    display: flex;
    align-items: center;

    background: $white1;

    &__content {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__wrapper {
        display: flex;
        align-items: center;

        &-title {
            margin-right: 10px;
            @include body7;
            color: $light-grey;
        }
    }

    &__list {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        &-item {
            background: $grey2;
            margin: 3px;

            border-radius: 50px;
            @include body9;

            cursor: pointer;
            display: flex;
            align-items: center;

            a {
                color: $blue;
                transition: .22s 0s;

                padding: 3px 10px;
            }

            &:hover {

                a {
                    color: $accent;
                }
            }
        }
    }

    &__search {
        position: relative;

        input {
            width: 190px;
            height: 28px;

            font-size: 14px;
            border-radius: 6px;
            box-shadow: 0 10px 14px rgb(126 164 220 / 8%);

            border: 1px solid #DEE6ED;
            outline: none;

            background: $white;
            padding-left: 14px;
        }

        button {
            cursor: pointer;
            position: absolute;

            top: 50%;
            right: 12px;

            display: flex;
            align-items: center;
            justify-content: center;

            transform: translateY(-50%);

            @include reset-btn;

            img {
                width: 18px;
            }
        }
    }

    &--fixed {
        position: fixed;
        min-height: 55px !important;
        top: 0;
        left: 0;
        box-shadow: 0 10px 15px rgb(58 112 191 / 10%);
        z-index: 999;
        padding: 4px 0;
    }

    &__empty {

        &--fixed {
            width: 100%;
            height: 65px;
        }
    }
}

@include media-breakpoint-down(md) {
    .tag {

        &__wrapper {
            flex-direction: column;
            align-items: flex-start;

            &-title {
                display: none;
            }
        }
    }
}
